import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export const DEFAULT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';


/**
 * Returns a formatted date string, check dayjs docs
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
export const formatDate = (date, format = DEFAULT_FORMAT) => dayjs(date).format(format);


/**
 * Retuns a dayjs object for the current time
 * @param {string} format
 * @return {string}
 */
export const now = (format = DEFAULT_FORMAT) => dayjs().format(format);


/**
 * Returns the diff from now to the specified date and units
 * Positive should be in the future, negative in the past
 * @param {string} date
 * @param {string} unit
 * @returns {number}
 */
export const diffFromNow = (date, unit = 'seconds') => dayjs(date).diff(dayjs(), unit);


/**
 * Returns a diff between two dates in the specified unit
 * Returns positive if dateA is ahead of dateB
 * @param {string} dateA
 * @param {string} dateB
 * @param {string} unit
 * @returns {number}
 */
export const diff = (dateA, dateB, unit = 'seconds') => dayjs(dateA).diff(dayjs(dateB), unit);


/**
 * Returns the current time in a special format to prefix the
 * Print UUID. Makes it easier to identify when prints were created.
 * date param is optional. Date parsed in UTC
 * @param {string} date UTC
 */
export const printDatePrefix = (date) =>
  dayjs(date).utc().format('YYMMDD-HHmmss');
