import { ORIENTATION_PORTRAIT } from '../../WithPrint/constants';

import logo from './logo.png';
import metaImage from './og-banner.jpg';

import ultra from './event-thumbs/tribexmaverick-2022-ultra.jpg';
import marathon from './event-thumbs/tribexmaverick-2022-marathon.jpg';
import half from './event-thumbs/tribexmaverick-2022-half.jpg';
import short from './event-thumbs/tribexmaverick-2022-short.jpg';

import themeNames from '../../WithThemes/themes/tribexmaverick/theme-names'

export const themes = [{
  themeName: themeNames.ultra,
  image: ultra,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Ultra 2022',
  subtitle: '3rd September',
}, {
  themeName: themeNames.marathon,
  image: marathon,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Marathon 2022',
  subtitle: '3rd September',
}, {
  themeName: themeNames.half,
  image: half,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Half Marathon 2022',
  subtitle: '3rd September',
}, {
  themeName: themeNames.short,
  image: short,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Short 2022',
  subtitle: '3rd September',
}];

export default {
  logo,
  badge: logo,
  uri: 'tribe-x-maverick-run-free',
  link: 'https://www.maverick-race.com/products/tribe-x-maverick-run-free-2022',
  linkText: 'maverick-race.com',
  blurb: `With a new set of longer distances & a stunning new location, TRIBE x Maverick Run Free Marathon+ is set to be the best yet.<br />
<br />
The four incredible trails showcase the Surrey Hills AONB with a stunning mix of magical pine woods, beautiful heaths, epic views, & alpine-like forest trails, leading you through some of the best locations the Surrey Hills has to offer, from Pitch Hill to Hurtwood Forest & Leith Hill.`,
  title: `TRIBE x Maverick\\nRun Free MARATHON+`,
  description: 'Select your event to personalise your print',
  logoMaxWidth: '39rem',
  metaTitle: 'TRIBE x Maverick Custom Prints',
  metaDescription: 'Turn your TRIBE x Maverick adventure into a work of art',
  metaImage,
  themes,
  themeNames: Object.values(themeNames),
};
