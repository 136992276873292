import { css } from 'styled-components';

import { fontGroup } from '../../styles';
import MapboxMap from '../../../../components/MapboxMap';
import { LogoCredits, PoweredByStrava } from '../../../../components/Logo';
import { PrintLabel, PrintLabelValue } from '../../../../components/PrintLabel';
import { PrintTitle } from '../../../../components/PrintTitle';

export default () => css`
  ${fontGroup('montserratAlternateGothic')}

  ${PrintTitle} {
    margin-bottom: 0.2em;
  }

  ${PrintLabelValue} {
    text-transform: initial;
  }

  ${LogoCredits},
  ${PoweredByStrava} {
    color: #FFFFFF;
    opacity: 1;
  }

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      color: #88bdc3;
      opacity: 1;
    }
  }

  ${MapboxMap}:after {
    pointer-events: none;
    content: '';
    width: 30rem;
    height: 44.3rem;
    position: absolute;
    top: 9rem;
    left: 9rem;
    background: transparent  no-repeat center center;
    background-size: cover;
  }

`;
