import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordion as SanfonaAccordion, AccordionItem as SanfonaAccordionItem } from 'react-sanfona';

import { colours, fonts, transitions } from '../../theme';
import { PlainButton } from '../Button';
import { Icon } from '../Icon';
import Badge from '../Badge';

const Arrow = styled(Icon)``;
const AccordionButton = styled(PlainButton)``;

const UnstyledAccordionItem = props => (
  <div className={props.className}>
    <div className="contents">
      {props.children}
    </div>
  </div>
);

UnstyledAccordionItem.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /* eslint-enable */
};

UnstyledAccordionItem.defaultProps = {
  open: false,
  badge: undefined,
};

const AccordionItem = styled(UnstyledAccordionItem)`
  .contents {
    padding-top: 4.5rem;
    padding-bottom: 10rem;
  }
`;

const UnstyledAccordion = (props) => {
  const easeOutExpo = 'cubic-bezier(0.19, 1, 0.22, 1)';
  const duration = 500;

  return (
    <SanfonaAccordion
      className={props.className}
      duration={duration}
      easing={easeOutExpo}
      rootTag="section"
      allowMultiple={props.allowMultiple}
    >
      {React.Children.map(props.children, (child, i) => {
        const childProps = child.props;
        const badge = childProps.badge ? <Badge>{childProps.badge}</Badge> : null;
        const title = (
          <AccordionButton>
            <div className="title-block">
              <span className="title-span">{childProps.title}{badge}</span>
            </div>
            <Arrow icon="arrowDown" />
          </AccordionButton>
        );
        return (
          <SanfonaAccordionItem
            key={`accordion-item-${i}`}
            className="accordion-item"
            title={title}
            expanded={childProps.open}
            expandedClassName="is-open"
            bodyClassName={null}
            titleClassName={null}
          >
            {React.cloneElement(child)}
          </SanfonaAccordionItem>
        );
      })}
    </SanfonaAccordion>
  );
};

UnstyledAccordion.propTypes = {
  allowMultiple: PropTypes.bool,
};
UnstyledAccordion.defaultProps = {
  allowMultiple: false,
};

const Accordion = styled(UnstyledAccordion)`

  .react-sanfona-item-body {
    border-bottom: 2px solid transparent;
    pointer-events: none;
  }

  ${AccordionButton} {
    height: 6rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: ${fonts.size03};
    font-weight: ${fonts.weightNormal};
    color: ${colours.secondary};
    border-bottom: 2px solid ${colours.grey02};

    &:hover {
      color: ${colours.primary};
      border-bottom-color: ${colours.primary};
    }

  }

  .is-open ${AccordionButton} {
    border-bottom-color: transparent;
  }

  ${Arrow} {
    color: ${colours.primary};
    transition: 0.2s ${transitions.buttonEasing};
    transform: rotateZ(0);
    margin-right: 1rem;
  }

  .is-open ${Arrow} {
    transform: rotateZ(-180deg);
  }

  .title-block {

  }

  .title-span {
    position: relative;
    padding-right: 1em;

    ${Badge} {
      padding-top: 0.1em;
      padding-right: 0.1em;
      position: absolute;
      right: 0;
      top: -0.2em;
    }
  }

  .is-open .react-sanfona-item-body {
    /* Fixed a bug where some items would dissapear inside the accordion
    if some properties triggered a rerender */
    overflow: visible !important;

    pointer-events: initial;
    border-bottom-color: ${colours.grey02};
  }

  .react-sanfona-item-body-wrapper {
    opacity: 0;
    transition: 0.4s ease opacity;
  }
  .is-open .react-sanfona-item-body-wrapper {
    opacity: 1;
  }

`;


export { UnstyledAccordion, Accordion, UnstyledAccordionItem, AccordionItem };
export default Accordion;
