import { takeLatest, call, select, put } from 'redux-saga/effects';

import * as Actions from './actions';
import * as Selectors from './selectors';
import * as Constants from './constants';
import { generateSecondaryTitle } from './reducers';
import * as SessionSelectors from '../WithSession/selectors';
import * as ToasterActions from '../WithToaster/actions';
import * as AnalyticsActions from '../WithAnalytics/actions';
import * as ThemeActions from '../WithThemes/actions';

import { apiSavePrint } from '../../api';
import { createPrintUuid } from '../../utils/print';

const { REACT_APP_DEBUG_SAVE_CONFIG, REACT_APP_API_DOMAIN } = process.env;


/**
 * Will try and update the Print label(s) using the Strava name of
 * the athlete if they've authenticated
 *
 */
export function* useStravaName() {
  try {
    const athlete = yield select(SessionSelectors.athleteSelector);
    if (athlete) {
      const athleteName = yield [athlete.firstname, athlete.lastname].join(' ');
      const activities = yield select(Selectors.activitiesSelector);
      const secondaryTitle = yield generateSecondaryTitle(activities, athleteName);
      yield put(Actions.updatePrint({ secondaryTitle }));
    }
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('useStravaName', error.message));
  }
}


/**
 * So far this method is only to be used internally, so no requests should
 * be performed without the correct ENV var and the print status should
 * always be "noorder"
 *
 * NOTE: Not tested, debugging purposes only
 *
 */
export function* savePrintConfig() {
  /* istanbul ignore next */
  try {
    if (!REACT_APP_DEBUG_SAVE_CONFIG) throw Error('Debug setting not properly set to save print config');

    const print = yield select(Selectors.printDataToSave);
    print.status = 'no_order';
    print.id = createPrintUuid();
    const response = yield call(apiSavePrint, print);
    if (response && response.status === 200) {
      yield put(ToasterActions.createToast(`Saved config for ${print.id}, check console…`, 8000));
      console.group('Debug');
      console.info(`* Print config saved: ${window.location.protocol}${REACT_APP_API_DOMAIN}/prints/${print.id}`);
      console.groupEnd();
    } else {
      // TODO: send me an email with the order & print id
      yield put(ToasterActions.createToast(`There was an error saving the config for ${print.id}`, 5000));
    }
  } catch (error) {
    // NO tracking needed
    console.error(error);
  }
}


/**
 * Accepts valid print JSON and will update the current print by
 * replicating config from the file
 *
 */
export function* replacePrintWithJsonSaga(action) {
  try {
    const { printJson } = yield action;
    yield put(ThemeActions.changeTheme(printJson.themeName));
    yield put(Actions.rebasePrint(printJson));
  } catch (error) {
    // TESTME:
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('replacePrintWithJsonSaga', error.message));
  }
}

export function* watchUseStravaName() {
  yield takeLatest(Constants.USE_STRAVA_NAME, useStravaName);
}

export function* watchSavePrintConfig() {
  /* istanbul ignore next */
  yield takeLatest(Constants.SAVE_PRINT_CONFIG, savePrintConfig);
}

export function* watchReplacePrintWithJsonSaga() {
  yield takeLatest(Constants.REPLACE_PRINT_WITH_JSON_SAGA, replacePrintWithJsonSaga);
}

export default [
  watchUseStravaName,
  watchSavePrintConfig,
  watchReplacePrintWithJsonSaga,
];
