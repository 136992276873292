import { createSelector } from 'reselect';
import { ACTIVITIES_DOMAIN } from './constants';

export const withDomain = state => state[ACTIVITIES_DOMAIN];

export const defaultActivitiesSelector = createSelector(
  withDomain,
  domain => domain.defaultActivities,
);

export const eventActivitiesSelector = createSelector(
  withDomain,
  domain => domain.eventActivities,
);

export const activitiesLoadingCountSelector = createSelector(
  withDomain,
  domain => domain.activitiesLoadingCount,
);
