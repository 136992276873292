import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COOKIE_POLICY } from '../../routes';
import { colours } from '../../theme';

import { Toast } from '../Toaster';
import { Link } from '../Link';

const CookieMessage = () => (
  <span>By using this site you agree to our <Link to={COOKIE_POLICY}>cookie policy</Link></span>
);

const UnstyledCookiePolicy = props => (
  <Toast id="cookie-policy" message={CookieMessage()} onClose={props.onClose} />
);

UnstyledCookiePolicy.propTypes = {
  onClose: PropTypes.func,
};
UnstyledCookiePolicy.defaultProps = {
  onClose: () => {},
};


const CookiePolicy = styled(UnstyledCookiePolicy)`
  background-color: ${colours.grey06};
  border-radius: 5px;
  padding: 11rem 17rem 12.4rem;
  max-width: 100rem;
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { UnstyledCookiePolicy, CookiePolicy };
export default CookiePolicy;
