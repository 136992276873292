import * as DateUtils from './utils/dates';

import { SESSION_DOMAIN } from './hocs/WithSession/constants';
import { initialState as initialSessionState } from './hocs/WithSession/model';
import { PRINT_DOMAIN } from './hocs/WithPrint/constants';

const { REACT_APP_COMMIT_HASH } = process.env;

export const CACHE_EXPIRY_TIME = 24 * 7 * 4; // one week
export const CACHE_EXPIRY_UNIT = 'hours';
export const CACHE_KEY = 'store';

export const cacheShouldClear = (store = {}) => {
  const diff = DateUtils.diffFromNow(store[SESSION_DOMAIN].lastSave, CACHE_EXPIRY_UNIT);
  const cacheExpired = (Math.abs(diff) > CACHE_EXPIRY_TIME);
  const commitHashChanged = store[SESSION_DOMAIN].commitHash !== REACT_APP_COMMIT_HASH;
  if (cacheExpired || commitHashChanged) {
    console.group('Cache');
    if (cacheExpired) console.info('* cache expired');
    if (commitHashChanged) console.info('* commit hash changed');
    console.groupEnd();
  }
  return commitHashChanged || cacheExpired;
};

export const load = () => {
  try {
    const cache = localStorage.getItem(CACHE_KEY);
    if (cache === null) return undefined;

    const store = JSON.parse(cache);
    const { showCookiePolicy } = store[SESSION_DOMAIN];

    if (cacheShouldClear(store)) {
      localStorage.removeItem(CACHE_KEY);
      return {
        // persist the cookie policy
        // We have to fill the session domain with the initial state
        // because combineReducers can't merge partial state (unless it's
        // hard to find out how). So here we're keeping the cookie setting and
        // ensuring the passed-in reducer state is the default model
        [SESSION_DOMAIN]: {
          ...initialSessionState,
          showCookiePolicy,
        },
      };
    }

    return store;
  } catch (err) {
    return undefined;
  }
};

export const save = (store) => {
  try {
    const session = store[SESSION_DOMAIN];
    const cache = JSON.stringify({
      [PRINT_DOMAIN]: store[PRINT_DOMAIN],
      [SESSION_DOMAIN]: {
        ...session,
        commitHash: REACT_APP_COMMIT_HASH,
        lastSave: DateUtils.now(),
      },
    });
    localStorage.setItem(CACHE_KEY, cache);
  } catch (err) {
    // don't do anything, really.
  }
};
