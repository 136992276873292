import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import styled, { css } from 'styled-components'
import { uiState } from 'react-redux-ui-state'
import _debounce from 'lodash.debounce'
import _get from 'lodash.get'
import Helmet from 'react-helmet'

import WithOrders from '../../hocs/WithOrders'
import WithToaster from '../../hocs/WithToaster'
import WithEvents from '../../hocs/WithEvents'
import { eventPropTypes } from '../../hocs/WithEvents/model'
import WithPrint from '../../hocs/WithPrint'
import WithThemes from '../../hocs/WithThemes'
import WithActivities from '../../hocs/WithActivities'
import WithSession from '../../hocs/WithSession'
import WithGoogleMaps from '../../hocs/WithGoogleMapsApi'
import WithAnalytics from '../../hocs/WithAnalytics'

import { matchPropTypes, historyPropTypes } from '../../proptypes'
import { colours, fonts, dimensions, transitions, shadows } from '../../theme'
import { media, zIndexes } from '../../styles'
import * as routes from '../../routes'
import {
  extractStravaIds,
  queryStringToObject,
  objectToQueryString,
  isPrintIdFormat,
} from '../../utils/strings'
import { formatCurrency } from '../../utils/numbers'
import { getPrintMaxSize, getPrintRatio, mediaSizes } from '../../utils/sizes'
import { godMode } from '../../utils/funcs'
import {
  allLabelFieldsEmpty,
  labelFieldIsEmpty,
  isAffiliateTheme,
  removeUnusedPrintData,
  removeUnusedActivityData,
} from '../../utils/print'

import Sortable, { SortableHandle } from '../../components/Sortable'
import FileDroploader from '../../components/FileDroploader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { lockScrolling, DeviceScreen } from '../../utils/dom'
import { filesAreJson, filesAreXml } from '../../utils/files'
import {
  PageSplit,
  PageMain,
  PageSidebar,
  SIDEBAR_WIDTH,
} from '../../components/PageSplit'
import {
  Button,
  UploadButton,
  LayoutButton,
  SizeButton,
  VariantSizeButton,
  PlainButton,
} from '../../components/Button'
import { Accordion, AccordionItem } from '../../components/Accordion'
import { SmallText } from '../../components/Text'
import { Logo, StravaLogo } from '../../components/Logo'
import {
  Input,
  InputLabel,
  FileInput,
  InputRow,
  Checkbox,
} from '../../components/Input'
import {
  Link,
  NavLink,
  IconNavLink,
  LinkAsPrimaryButton,
  ShopNavLink,
} from '../../components/Link'
import { Icon } from '../../components/Icon'
import { IconGrid } from '../../components/Grid'
import {
  ActivityCard,
  ActivityCardSkeleton,
} from '../../components/ActivityCard'
import PrintFrame from '../../components/PrintFrame'
import Print from '../../components/Print'
import { PrintDetails } from '../../components/PrintDetails'
import ElevationProfile from '../../components/ElevationProfile'
import { ColourPicker } from '../../components/ColourPicker'
import {
  ThemePicker,
  ThemePickerButton,
  ThemeGrid,
  ThemesGroup,
} from '../../components/ThemePicker'
import { OriginalPrice, Price } from '../../components/Price'
import { ForScale } from '../../components/ForScale'
import EventThemeLink from '../../components/EventThemeLink'

import SiteHeader from '../SiteHeader'
import SiteContainer from '../../components/SiteContainer'
import { FrameSamplesLightbox } from '../../components/FrameSamplesLightbox'

import {
  MapboxMap,
  propTypes as mapPropTypes,
} from '../../components/MapboxMap'
import { calcCartOriginalTotal, productVariantMatch } from '../../utils/cart'

const { REACT_APP_DEBUG_SAVE_CONFIG } = process.env

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const PaperNote = styled((props) => <SmallText light {...props} />)``
const LabelsHelperText = styled(SmallText)``

const LogoLink = styled(Link)``
const DownloadButton = styled(Button)``
const PreviewButton = styled(Button)``
const CheckoutButton = styled(LinkAsPrimaryButton)``
const MobileCheckoutButton = styled(LinkAsPrimaryButton)``
const StravaAuthButton = styled(UploadButton)`
  margin-bottom: 2.5rem;
`
const StravaInput = styled(Input)``
const DefaultActivityHelpText = styled((props) => (
  <SmallText {...props} light />
))``
const ForScaleCheckbox = styled(Checkbox)``
const RecenterMapCheckbox = styled(Checkbox)``
const SidebarButton = styled(PlainButton)``
const ViewSamplesButton = styled(PlainButton)``
const NumberInputRow = styled(InputRow)`
  ${Input} {
    max-width: 10rem;
  }
  ${InputLabel} {
    flex: 1 0 auto;
  }
`

const PRINT_BORDER_PADDING = 60
const PRINT_BORDER_PADDING_MOBILE = 40
const HEADER_HEIGHT = 150
const HEADER_HEIGHT_MOBILE = 130

class UnstyledCreate extends React.Component {
  static propTypes = {
    userPrintActivities: PropTypes.arrayOf(PropTypes.object),
    defaultPrintActivities: PropTypes.arrayOf(PropTypes.object),
    printActivities: PropTypes.arrayOf(PropTypes.object),
    printId: PropTypes.string,
    printOrientation: PropTypes.string.isRequired,
    printLayout: PropTypes.string.isRequired,
    printLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
    printTitle: PropTypes.string.isRequired,
    printSecondaryTitle: PropTypes.string,
    printStyles: PropTypes.objectOf(PropTypes.string).isRequired,
    printZoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    printCenter: PropTypes.arrayOf(PropTypes.number),
    printRotation: PropTypes.number,
    printQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    printProduct: PropTypes.string,
    printProductAttributes: PropTypes.shape({}),
    printOptions: PropTypes.shape({
      orientation: PropTypes.arrayOf(PropTypes.object),
      layout: PropTypes.arrayOf(PropTypes.object),
      padding: PropTypes.arrayOf(PropTypes.object),
      printColors: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    themeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    themeFirstTextMapLayerLayerId: PropTypes.string,
    renderBeforeLayerId: PropTypes.string,
    printActivitiesDashed: PropTypes.bool,
    printActivitiesElevationProfiles: PropTypes.bool,
    printActivitiesThickness: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    printPadding: PropTypes.bool,
    printPorthole: PropTypes.bool,

    hasElevationProfile: PropTypes.bool.isRequired,
    hasActivityEndpoints: PropTypes.bool.isRequired,
    printSize: PropTypes.shape({}).isRequired, // TODO:
    mapComponentProps: PropTypes.shape({}).isRequired, // TODO:
    printComponentProps: PropTypes.shape({}).isRequired, // TODO:
    printDetailsComponentProps: PropTypes.shape({}).isRequired, // TODO:
    elevationProfileComponentProps: PropTypes.shape({}).isRequired, // TODO:

    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    currentProduct: PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
    }),

    themeName: PropTypes.string.isRequired,
    themeMapStyles: mapPropTypes.mapStyles.isRequired,
    themeColorPalette: PropTypes.arrayOf(PropTypes.string),
    isAuthedWithStrava: PropTypes.bool.isRequired,
    activitiesLoadingCount: PropTypes.number.isRequired,
    hasTouch: PropTypes.bool.isRequired,

    eventThemeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    events: PropTypes.arrayOf(eventPropTypes).isRequired,

    updatePrint: PropTypes.func.isRequired,
    uploadXmlFiles: PropTypes.func.isRequired,
    uploadJsonFiles: PropTypes.func.isRequired,
    removePrintActivity: PropTypes.func.isRequired,
    getPrintOrder: PropTypes.func.isRequired,

    // getStravaActivities: PropTypes.func.isRequired,
    getStravaActivityStreamsAndDetails: PropTypes.func.isRequired,
    // getStravaRoutes: PropTypes.func.isRequired,
    getStravaRoutesStreamsAndDetails: PropTypes.func.isRequired,

    getStravaAuthToken: PropTypes.func.isRequired,
    editLabel: PropTypes.func.isRequired,
    moveLabel: PropTypes.func.isRequired,
    moveActivity: PropTypes.func.isRequired,
    setActivityColor: PropTypes.func.isRequired,
    changeTheme: PropTypes.func.isRequired,
    selectDefaultActivity: PropTypes.func.isRequired,
    selectAffiliateActivities: PropTypes.func.isRequired,
    resetMapBounds: PropTypes.func.isRequired,
    savePrintConfig: PropTypes.func.isRequired,
    createToast: PropTypes.func.isRequired,
    setRenderBeforeLayerId: PropTypes.func.isRequired,
    setActivityThicknesses: PropTypes.func.isRequired,
    setActivityNodesVisible: PropTypes.func.isRequired,
    setActivitiesDashed: PropTypes.func.isRequired,
    setActivitiesElevationProfiles: PropTypes.func.isRequired,
    setActivityElevationProfileColor: PropTypes.func.isRequired,
    trackCtaClicked: PropTypes.func.isRequired,

    // Ui State
    setUiState: PropTypes.func.isRequired,
    uiDroploaderOpen: PropTypes.bool,
    uiForScaleChecked: PropTypes.bool,
    uiPrintScale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    uiSidebarMenuOpen: PropTypes.bool,
    frameSamplesOpen: PropTypes.bool,

    history: historyPropTypes.isRequired,
    match: matchPropTypes.isRequired,
  }

  static defaultProps = {
    userPrintActivities: [],
    defaultPrintActivities: [],
    printActivities: [],
    printId: undefined,
    printSecondaryTitle: undefined,
    printZoom: undefined,
    printCenter: undefined,
    printRotation: undefined,
    printQuantity: undefined,
    printProduct: undefined,
    printProductAttributes: undefined,
    printPadding: undefined,
    printPorthole: undefined,
    themeFirstTextMapLayerLayerId: undefined,
    themeColorPalette: undefined,
    renderBeforeLayerId: undefined,
    printActivitiesDashed: false,
    printActivitiesElevationProfiles: false,
    printActivitiesThickness: undefined,
    uiDroploaderOpen: false,
    uiForScaleChecked: false,
    uiPrintScale: 0.5,
    uiSidebarMenuOpen: false,
    frameSamplesOpen: false,

    currentProduct: {},
  }

  constructor(props) {
    super(props)

    // ref to Mapbox when component mounts
    this._mapbox = undefined

    this._screen = new DeviceScreen()

    // bit of a hack, but need the activites accordion open when the user has
    // first landed on the page (with default activities), but also to force it
    // open if the number of activities changes in componentWillReceiveProps
    this._activitesAccordionOpen = !!this.props.defaultPrintActivities.length

    this.props.setUiState({
      uiPrintScale: this.getPrintScale(),
      frameSamplesOpen: false,
    })
  }

  componentDidMount() {
    this._screen.updateSizes()

    const { themeName } = this.extractWindowSearchParams()

    if (isAffiliateTheme(themeName)) {
      this.props.selectAffiliateActivities(themeName)
    } else if (!this.props.printId) {
      this.props.selectDefaultActivity(undefined, themeName)
    } else if (themeName) {
      this.props.changeTheme(themeName)
    }

    window.addEventListener('resize', this.onWindowResize)

    this.checkForGodMode()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.printOrientation && this.props.printOrientation) {
      this.onWindowResize()
    }

    if (
      nextProps.userPrintActivities.length !==
      this.props.userPrintActivities.length
    ) {
      this._activitesAccordionOpen = true
    }

    if (nextProps.uiSidebarMenuOpen) {
      lockScrolling(true)
    } else {
      lockScrolling(false)
    }
  }

  componentDidUpdate(prevProps) {
    this.shouldMapBoxResize(prevProps)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  // EVENTS & CALLBACKS
  // ---------------------------------------------------------------------------

  onWindowResize = _debounce(() => {
    // Dont do anything if the keyboard in a touch device has been triggered
    if (this._screen.mobileDeviceUiTriggeredResize(this.props.hasTouch)) return

    this._screen.updateSizes()
    this.props.setUiState({
      uiPrintScale: this.getPrintScale(),
    })
  }, 500)

  onPrintTitleChange = (evt) => {
    const { value: title } = evt.target
    this.props.updatePrint({ title })
  }

  onPrintSecondaryTitleChange = (evt) => {
    const { value: secondaryTitle } = evt.target
    this.props.updatePrint({ secondaryTitle })
  }

  onProductChanged = (product, attributes) => () => {
    this.props.updatePrint({ product, productAttributes: attributes })
  }

  onLayoutChange = (layout) => () => {
    this.props.updatePrint({ layout })
  }

  onOrientationChange = (orientation) => () => {
    this.props.updatePrint({ orientation })
  }

  onFileUpload = (evt) => {
    this.props.uploadXmlFiles(evt.target.files)
  }

  onRemoveActivity = (id) => () => {
    this.props.removePrintActivity(id)
  }

  onStravaActivitySubmit = (evt) => {
    evt.preventDefault()
    const input = evt.target['strava-id']
    if (isPrintIdFormat(input.value)) {
      this.props.getPrintOrder(input.value.trim())
    } else {
      const stravaIds = extractStravaIds(input.value)
      if (input.value.indexOf('routes') !== -1) {
        this.props.getStravaRoutesStreamsAndDetails(stravaIds)
      } else {
        this.props.getStravaActivityStreamsAndDetails(stravaIds)
      }
    }
    input.value = ''
  }

  onStravaAuthClick = (evt) => {
    evt.preventDefault()
    this.props.getStravaAuthToken()
  }

  onZoomChanged = (zoom) => {
    this.props.updatePrint({ zoom })
  }

  onCenterChanged = (center) => {
    this.props.updatePrint({ center })
  }

  onRotationChanged = (rotation) => {
    this.props.updatePrint({ rotation })
  }

  onLabelChange = (id, propName) => (evt) => {
    const { value } = evt.target
    this.props.editLabel(id, propName, value)
  }

  onThemeOptionClick = (themeName) => () => {
    this.props.changeTheme(themeName)
  }

  onPrintColourChange = (propKey) => (hexColour) => {
    this.props.updatePrint({ [propKey]: hexColour })
  }

  onTogglePrintProp = (printKey, printProp) => () => {
    this.props.updatePrint({ [printKey]: !this.props[printProp] })
  }

  onActivityColourChange = (activityId) => (hexColour) => {
    this.props.setActivityColor(activityId, hexColour)
  }

  onElevationProfileColourChange = (activityId) => (hexColour) => {
    this.props.setActivityElevationProfileColor(activityId, hexColour)
  }

  onActivitiyThicknessChange = (evt) => {
    const { value } = evt.target
    this.props.setActivityThicknesses(value)
  }

  onRenderActivityBeforeLayerId = ({ target }) => {
    const placeLayerId = target.checked
      ? this.props.themeFirstTextMapLayerLayerId
      : null
    this.props.setRenderBeforeLayerId(placeLayerId)
  }

  onToggleActivitiesDashed = () => {
    this.props.setActivitiesDashed(!this.props.printActivitiesDashed)
  }

  onToggleActivityNodes = ({ target }) => {
    this.props.setActivityNodesVisible(target.checked)
  }

  onToggleActivitiesElevationProfiles = () => {
    this.props.setActivitiesElevationProfiles(
      !this.props.printActivitiesElevationProfiles,
    )
  }

  onCheckout = async () => lockScrolling(false)

  onForScaleChange = () => {
    this.props.trackCtaClicked('For scale')
    this.props.setUiState({ uiForScaleChecked: !this.props.uiForScaleChecked })
  }

  onDroploaderDrop = (evt) => {
    evt.preventDefault()
    const newUiState = { uiDroploaderOpen: false }
    const { files } = evt.dataTransfer
    if (filesAreXml(files)) {
      this.props.uploadXmlFiles(files)
    } else if (filesAreJson(files)) {
      this.props.uploadJsonFiles(files)
    } else {
      this.props.createToast('Sorry, we only accept GPX/KML files 😅', 5000)
    }
    this.props.setUiState(newUiState)
    evt.dataTransfer.clearData()
  }

  onDroploaderDragOver = (evt) => {
    evt.preventDefault()
  }

  onDroploaderDragEnter = () => {
    //
  }

  onDroploaderDragLeave = () => {
    this.props.setUiState({ uiDroploaderOpen: false })
  }

  onResetMapBounds = () => {
    this.props.trackCtaClicked('Re-centre')
    this.props.resetMapBounds()
  }

  onSidebarButtonClick = () => {
    this.props.setUiState({ uiSidebarMenuOpen: !this.props.uiSidebarMenuOpen })
  }

  onSidebarBackgroundClick = (evt) => {
    if (evt.target !== evt.currentTarget) return
    this.props.setUiState({ uiSidebarMenuOpen: false })
  }

  onDownloadConfig = () => {
    this.props.savePrintConfig()
  }

  onSortLabels = (oldIndex, newIndex) => {
    this.props.moveLabel(oldIndex, newIndex)
  }

  onSortActivities = (oldIndex, newIndex) => {
    this.props.moveActivity(oldIndex, newIndex)
  }

  onMapFailed = (
    message = 'Oops, something broke! Please try and reload the page',
  ) => {
    this.props.createToast(message)
  }

  handleFrameSampleToggle = () =>
    this.props.setUiState({
      frameSamplesOpen: !this.props.frameSamplesOpen,
    })

  // MISC
  // ---------------------------------------------------------------------------

  extractWindowSearchParams() {
    const search = queryStringToObject(window.location.search)
    const { themeName, themename, ...restSearch } = search
    const chosenTheme = themeName || themename

    this.props.history.replace({
      pathname: window.location.pathname,
      search: objectToQueryString(restSearch),
    })

    return {
      themeName: chosenTheme,
    }
  }

  shouldScrollIntoView = ({ currentTarget }) => {
    if (
      this.props.hasTouch &&
      window.outerWidth < mediaSizes.nav &&
      currentTarget.scrollIntoView
    ) {
      currentTarget.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  checkForGodMode() {
    godMode(() => {
      let print = { ...this.props.print } // eslint-disable-line
      print.activities = [...print.activities].map((a) =>
        removeUnusedActivityData(a),
      )
      print = removeUnusedPrintData(print)
      const file = new Blob([JSON.stringify(print, null, 2)], {
        type: 'application/json',
      })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank')
    })
  }

  // Here we can decide how quickly we need to resize the map
  // Updating the title should be debounced to avoid numerous triggers
  // from keystrokes, but removing blocks of text should be instantaneous
  shouldMapBoxResize(props) {
    if (
      props.printPadding !== this.props.printPadding ||
      props.printPorthole !== this.props.printPorthole ||
      labelFieldIsEmpty(props.printTitle) !==
        labelFieldIsEmpty(this.props.printTitle) ||
      allLabelFieldsEmpty(props.printLabels) !==
        allLabelFieldsEmpty(this.props.printLabels) ||
      labelFieldIsEmpty(props.printSecondaryTitle) !==
        labelFieldIsEmpty(this.props.printSecondaryTitle) ||
      props.printActivitiesElevationProfiles !==
        this.props.printActivitiesElevationProfiles
    ) {
      this.resizeMapbox()
    } else if (props.printTitle !== this.props.printTitle) {
      this.delayedResizeMapbox()
    }
  }

  resizeMapbox = () => {
    if (this._mapbox && this._mapbox.map) this._mapbox.map.resize()
  }

  delayedResizeMapbox = _debounce(this.resizeMapbox, 500)

  openDroploader = (evt) => {
    const { types } = evt.dataTransfer
    const onlyContainsFiles = Array.from(types).every(
      (type) => type === 'Files',
    )
    this.props.setUiState({
      uiDroploaderOpen: onlyContainsFiles,
    })
    evt.preventDefault()
  }

  // TODO: these vars should live somewhere else
  getPrintScale() {
    const isMobileSize = window.innerWidth <= mediaSizes.create
    let mobileUiOffset = 0
    if (isMobileSize) mobileUiOffset = this.props.hasTouch ? 20 : 80
    const scrollbarWidth = isMobileSize ? 0 : 16
    const windowSize = {
      width: window.innerWidth - scrollbarWidth,
      height: window.innerHeight,
    }
    const minPadding = isMobileSize
      ? PRINT_BORDER_PADDING_MOBILE
      : PRINT_BORDER_PADDING
    const availableWidth = isMobileSize
      ? windowSize.width
      : windowSize.width - SIDEBAR_WIDTH
    const maxPrintSize = getPrintMaxSize(this.props.printOrientation)
    const { printSize } = this.props
    const printAreaWidth = availableWidth - minPadding * 2
    const printAreaHeight =
      windowSize.height -
      mobileUiOffset -
      minPadding * 2 -
      (isMobileSize ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT)
    const clampedPrintWidth = Math.min(
      printSize.width,
      printAreaWidth,
      maxPrintSize.width,
    )
    const clampedPrintHeight = Math.min(
      printSize.height,
      printAreaHeight,
      maxPrintSize.height,
    )
    const ratioWidth = clampedPrintWidth / printSize.width
    const ratioHeight = clampedPrintHeight / printSize.height
    return Math.min(ratioWidth, ratioHeight).toFixed(2)
  }

  getForScaleStyles() {
    const { uiPrintScale, printSize } = this.props
    const title = _get(this.props, 'currentProduct.title')
    const paperSizeFactor = 420 / 297 // A3 height / A4 height

    // TODO: this needs to be more dynamic
    let factor = getPrintRatio()

    switch (title) {
      case 'A3':
        factor /= 1
        break
      case 'A2':
        factor /= paperSizeFactor
        break
      default:
        factor = 1
    }

    const pxFudgeX = 0
    const pxFudgeY = window.innerWidth <= mediaSizes.create ? 50 : 100
    const adjustedScale = (uiPrintScale * factor).toFixed(4)
    const leftAdjust =
      (printSize.width * uiPrintScale) / 2 - PRINT_BORDER_PADDING / 2 + pxFudgeX
    const topAdjust = (printSize.height * uiPrintScale) / 2 - pxFudgeY

    return {
      top: `calc(50% + ${topAdjust}px)`,
      left: `calc(50% - ${leftAdjust}px)`,
      transform: `scale(${adjustedScale}) translateX(-50%) translateY(-50%)`,
    }
  }

  getSizeDetails(productSku) {
    const product =
      this.props.products.find((prod) => prod.sku === productSku) || {}
    const { sizeMetric, sizeImperial } = product
    return (
      <span>
        {sizeMetric}
        <br />
        {sizeImperial}
      </span>
    )
  }

  getTotalPrice() {
    const price = _get(this.props, 'currentProduct.price', 0)
    const quantityPrice = price * Number(this.props.printQuantity)
    const currency = _get(this.props, 'currentProduct.currency', 'GBP')
    return formatCurrency(quantityPrice / 100, currency)
  }

  get originalPrice() {
    const quantityPrice = calcCartOriginalTotal(
      this.props.currentProduct,
      this.props.printQuantity,
    )
    if (quantityPrice === 0) return null
    const currency = _get(this.props, 'currentProduct.currency', 'GBP')
    return formatCurrency(quantityPrice / 100, currency)
  }

  getDroploaderMessage() {
    return "Drop it, we're ready 🤞"
  }

  get selectedProductSize() {
    return _get(this.props, 'currentProduct.title')
  }

  // TODO: replace with selectors from print
  get canToggleElevationProfile() {
    return this.props.printActivities.every(({ elevations }) => !!elevations)
  }

  get colorPalette() {
    const { themeColorPalette } = this.props
    return themeColorPalette
  }

  get printFrameStyles() {
    return {
      transform: `scale(${this.props.uiPrintScale}) translateX(-50%) translateY(-50%)`,
    }
  }

  // RENDERERS
  // ---------------------------------------------------------------------------

  renderThemeColourOptions() {
    const {
      printStyles,
      printActivities,
      printOptions,
      printActivitiesElevationProfiles,
    } = this.props
    const { colorPalette } = this
    return (
      <React.Fragment>
        {printOptions.printColors.map((colorOption) => (
          <ColourPicker
            key={`print-color-option-${colorOption.value}`}
            label={colorOption.label}
            colour={printStyles[colorOption.value]}
            palette={colorPalette}
            onChange={this.onPrintColourChange(colorOption.value)}
          />
        ))}
        {printActivities.map((activity, i) => (
          <React.Fragment key={`${activity.id}-colourpicker`}>
            <ColourPicker
              onChange={this.onActivityColourChange(activity.id)}
              colour={activity.pathColor}
              label={
                printActivities.length > 1 ? `Activity ${i + 1}` : 'Activity'
              }
              palette={colorPalette}
            />
            {printActivitiesElevationProfiles && (
              <ColourPicker
                onChange={this.onElevationProfileColourChange(activity.id)}
                colour={activity.elevationProfileColor}
                label={
                  printActivities.length > 1
                    ? `Elevation Profile ${i + 1}`
                    : 'Elevation Profile'
                }
                palette={colorPalette}
              />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  }

  renderAdvancedOptions() {
    return (
      <React.Fragment>
        {this.canToggleElevationProfile && (
          <InputRow>
            <Checkbox
              id="elevation-profile"
              title="Add an elevation profile"
              label="Elevation Profile"
              description="Please make sure your activities are ordered correctly"
              checked={!!this.props.printActivitiesElevationProfiles}
              onChange={this.onToggleActivitiesElevationProfiles}
            />
          </InputRow>
        )}
        <InputRow>
          <Checkbox
            id="activities-dashed-checkbox"
            title="Toggle between dashed and solid lines"
            label="Use dashed lines"
            checked={!!this.props.printActivitiesDashed}
            onChange={this.onToggleActivitiesDashed}
          />
        </InputRow>
        {!!this.props.themeFirstTextMapLayerLayerId && (
          <InputRow>
            <Checkbox
              id="render-activities-before"
              title="Toggle if activities are displayed above or below map lables"
              label="Display activities under places"
              checked={!!this.props.renderBeforeLayerId}
              onChange={this.onRenderActivityBeforeLayerId}
            />
          </InputRow>
        )}
        <InputRow>
          <Checkbox
            id="activities-endpoints-checkbox"
            title="Toggle endpoints for all activities"
            label="Endpoints for all activities"
            checked={this.props.hasActivityEndpoints}
            onChange={this.onToggleActivityNodes}
          />
        </InputRow>
        <NumberInputRow>
          <Input
            id="activities-paththickness"
            type="number"
            min="1"
            value={this.props.printActivitiesThickness}
            placeholder="¯\_(ツ)_/¯"
            onChange={this.onActivitiyThicknessChange}
          />
          <InputLabel htmlFor="activities-paththickness">
            Activity Thickness
          </InputLabel>
        </NumberInputRow>
      </React.Fragment>
    )
  }

  renderStravaInputs() {
    if (this.props.isAuthedWithStrava) {
      return (
        <form
          className="strava-input-form"
          onSubmit={this.onStravaActivitySubmit}
        >
          <PlainButton>Add +</PlainButton>
          <StravaInput name="strava-id" placeholder="Enter Strava link or ID" />
        </form>
      )
    }
    return (
      <StravaAuthButton onClick={this.onStravaAuthClick}>
        Connect to <StravaLogo />
      </StravaAuthButton>
    )
  }

  renderActivitiesLoaded() {
    const {
      defaultPrintActivities,
      userPrintActivities,
      activitiesLoadingCount,
    } = this.props
    return (
      <React.Fragment>
        {!!defaultPrintActivities.length && !activitiesLoadingCount && (
          <DefaultActivityHelpText>
            This is just a sample for you to play with. Try adding an activity
            from Strava or uploading one!
          </DefaultActivityHelpText>
        )}
        <div className="activities-list">
          <Sortable
            handle
            className="activities"
            delayPress={this.props.hasTouch}
            onSort={this.onSortActivities}
          >
            {userPrintActivities.map(this.renderActivityCard)}
          </Sortable>
          {activitiesLoadingCount
            ? new Array(activitiesLoadingCount).fill(null).map((_null, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ActivityCardSkeleton key={`activity-loading-skel-${i}`} />
              ))
            : null}
        </div>
        {this.renderStravaInputs()}
        <FileInput multiple accept=".gpx,.kml" onChange={this.onFileUpload}>
          <span>Upload GPX/KML files</span>
        </FileInput>
      </React.Fragment>
    )
  }

  renderActivityCard = (activity) => (
    <ActivityCard
      key={activity.id}
      source={activity.source}
      id={activity.id}
      title={activity.title}
      polyline={activity.polyline}
      date={activity.date}
      distance={activity.distance}
      time={activity.elapsedTime}
      onRemove={this.onRemoveActivity(activity.id)}
    />
  )

  renderPrintLabels() {
    return (
      <React.Fragment>
        <div className="print-header-inputs">
          <InputRow>
            <Input
              placeholder="Print title"
              onFocus={this.shouldScrollIntoView}
              onChange={this.onPrintTitleChange}
              value={this.props.printTitle}
            />
          </InputRow>
          <InputRow>
            <Input
              placeholder="Print subtitle"
              onFocus={this.shouldScrollIntoView}
              onChange={this.onPrintSecondaryTitleChange}
              value={this.props.printSecondaryTitle}
            />
          </InputRow>
        </div>
        <Sortable
          handle
          className="print-label-inputs"
          delayPress={this.props.hasTouch}
          onSort={this.onSortLabels}
        >
          {this.props.printLabels.map(this.renderPrintLabelInputs)}
        </Sortable>
      </React.Fragment>
    )
  }

  renderPrintLabelInputs = (label, i) => (
    <InputRow key={label.id}>
      <Input
        placeholder={`Value ${i + 1}`}
        onFocus={this.shouldScrollIntoView}
        onChange={this.onLabelChange(label.id, 'value')}
        value={label.value}
      />
      <Input
        placeholder={`Label ${i + 1}`}
        onFocus={this.shouldScrollIntoView}
        onChange={this.onLabelChange(label.id, 'title')}
        value={label.title}
      />
    </InputRow>
  )

  renderSizeAndFormat() {
    const parentProduct = this.props.products.find(({ sku, variants }) => {
      if (variants && variants.length) {
        return variants.some(
          ({ sku: variantSku }) => variantSku === this.props.printProduct,
        )
      }
      return this.props.printProduct === sku
    })
    if (!parentProduct) return null
    return (
      <React.Fragment>
        <IconGrid style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
          {this.props.products.map((product) => {
            const active = parentProduct.sku === product.sku
            const sizeDetails = this.getSizeDetails(product.sku)
            return (
              <div
                className="product-option-wrapper"
                key={`product-option-${product.sku}`}
              >
                <SizeButton
                  onClick={this.onProductChanged(product.sku)}
                  active={active}
                  title={product.description}
                >
                  <span>{product.title}</span>
                  <SmallText light={active}>{sizeDetails}</SmallText>
                  <SmallText className="price">
                    {formatCurrency(Math.abs(product.price) / 100, 'GBP')}
                  </SmallText>
                </SizeButton>
              </div>
            )
          })}
        </IconGrid>
        {parentProduct && parentProduct.variants && (
          <IconGrid
            style={{ gridTemplateColumns: 'repeat(1, 1fr)', marginTop: '6rem' }}
          >
            {parentProduct.variants.map((variant, i) => {
              const active = productVariantMatch(
                variant,
                this.props.printProduct,
                this.props.printProductAttributes,
              )
              const priceDifference =
                Math.abs(variant.price - parentProduct.price) / 100
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`product-option-variant-${variant.sku}-${i}`}>
                  <VariantSizeButton
                    onClick={this.onProductChanged(
                      variant.sku,
                      variant.attributes,
                    )}
                    active={active}
                  >
                    <span>
                      <h5>
                        {variant.ctaLabel ? variant.ctaLabel : variant.title}
                      </h5>
                      <SmallText light className="description">
                        {variant.description}
                      </SmallText>
                    </span>
                    {priceDifference !== 0 && (
                      <SmallText className="price">
                        + {formatCurrency(priceDifference, 'GBP', 0)}
                      </SmallText>
                    )}
                  </VariantSizeButton>
                </div>
              )
            })}
          </IconGrid>
        )}
        <ViewSamplesButton onClick={this.handleFrameSampleToggle}>
          View frame samples
        </ViewSamplesButton>
      </React.Fragment>
    )
  }

  renderSelectedEventThemeNotes() {
    const { themeName } = this.props.printStyles
    if (!this.props.eventThemeNames.includes(themeName)) return null

    const event = this.props.events.find((e) =>
      e.themeNames.includes(themeName),
    )
    const eventTheme = event.themes.find(
      (theme) => theme.themeName === themeName,
    )

    const link = `${routes.EVENTS}/${event.uri}`
    // const title = `${event.title} ${eventTheme.title} theme`;
    const title = `${eventTheme.title} theme`

    return <EventThemeLink to={link}>{title}</EventThemeLink>
  }

  renderThemeOptions() {
    const { themeName } = this.props.printStyles
    return (
      <ThemesGroup>
        <ThemeGrid>
          {this.props.themeOptions.map((themeOption) => (
            <ThemePicker
              key={`themeoption-${themeOption.value}`}
              active={themeOption.value === themeName}
              label={themeOption.label}
              image={themeOption.image}
              color={themeOption.color}
              onClick={this.onThemeOptionClick(themeOption.value)}
            />
          ))}
        </ThemeGrid>
      </ThemesGroup>
    )
  }

  renderOrientationOptions() {
    const { printOptions, printOrientation } = this.props
    return (
      <IconGrid>
        {printOptions.orientation.map((opt) => (
          <LayoutButton
            title={opt.label}
            active={printOrientation === opt.value}
            key={`orientation-option-${opt.value}`}
            onClick={this.onOrientationChange(opt.value)}
          >
            <Icon icon={opt.icon} />
            <div>{opt.label}</div>
          </LayoutButton>
        ))}
      </IconGrid>
    )
  }

  renderLayoutOptions() {
    const { printOptions, printLayout } = this.props
    return (
      <IconGrid>
        {printOptions.layout.map((opt) => (
          <LayoutButton
            title={opt.label}
            active={printLayout === opt.value}
            key={`layout-option-${opt.value}`}
            onClick={this.onLayoutChange(opt.value)}
          >
            <Icon icon={opt.icon} />
          </LayoutButton>
        ))}
      </IconGrid>
    )
  }

  renderOptions() {
    const { userPrintActivities } = this.props

    const activitiesBadge =
      userPrintActivities.length > 0 ? userPrintActivities.length : undefined

    const activitiesTitle =
      userPrintActivities.length > 0 ? 'Edit Activities' : 'Add Activities'

    return (
      <Accordion>
        <AccordionItem
          title={activitiesTitle}
          badge={activitiesBadge}
          open={this._activitesAccordionOpen}
        >
          {this.renderActivitiesLoaded()}
        </AccordionItem>
        <AccordionItem title="Labels">
          {this.renderPrintLabels()}
          <LabelsHelperText light>
            To remove any labels from your poster, simply delete the text from
            the field.
          </LabelsHelperText>
        </AccordionItem>
        <AccordionItem title="Layout">
          {this.renderOrientationOptions()}
          {this.renderLayoutOptions()}
        </AccordionItem>
        <AccordionItem title="Themes">
          {/* {this.renderSelectedEventThemeNotes()} */}
          {this.renderThemeOptions()}
        </AccordionItem>
        <AccordionItem title="Colours">
          {this.renderThemeColourOptions()}
        </AccordionItem>
        <AccordionItem title="Details">
          {this.renderAdvancedOptions()}
        </AccordionItem>
        <AccordionItem title="Size / Format" open>
          {this.renderSizeAndFormat()}
          <PaperNote light>
            We use 200gsm Enhanced Matte Art paper for all our prints
          </PaperNote>
        </AccordionItem>
      </Accordion>
    )
  }

  renderPrint() {
    const {
      hasElevationProfile,
      printComponentProps,
      themeMapStyles,
      elevationProfileComponentProps,
      printDetailsComponentProps,
      mapComponentProps,
      printSize,
    } = this.props
    return (
      <Print
        {...printComponentProps}
        style={printSize}
        mapStyles={themeMapStyles}
      >
        <MapboxMap
          {...mapComponentProps}
          ref={(r) => (this._mapbox = r)}
          animate
          controls
          styles={this.props.themeMapStyles}
          onMapFailed={this.onMapFailed}
          onZoomChanged={this.onZoomChanged}
          onCenterChanged={this.onCenterChanged}
          onRotationChanged={this.onRotationChanged}
        />
        {hasElevationProfile && (
          <ElevationProfile {...elevationProfileComponentProps} />
        )}
        <PrintDetails {...printDetailsComponentProps} />
      </Print>
    )
  }

  render() {
    if (!this.props.printId) return null
    return (
      <div className={this.props.className} onDragEnter={this.openDroploader}>
        <ScrollToTopOnMount />
        <FrameSamplesLightbox open={this.props.frameSamplesOpen} />
        <Helmet>
          <title>Create</title>
        </Helmet>
        <PageSplit>
          <PageMain>
            <SiteHeader />
            <div className="print-container">
              <PrintFrame style={this.printFrameStyles}>
                {this.renderPrint()}
              </PrintFrame>
              <ForScale
                style={this.getForScaleStyles()}
                className={this.props.uiForScaleChecked && 'is-visible'}
              />
            </div>
            <div className="map-actions">
              <ForScaleCheckbox
                label="Beer for scale"
                checked={this.props.uiForScaleChecked}
                onChange={this.onForScaleChange}
              />
              <RecenterMapCheckbox
                label="Re-centre map"
                button={<Icon icon="crosshair" />}
                checked={false}
                onChange={this.onResetMapBounds}
              />
            </div>
            <div className="mobile-actions">
              <SidebarButton onClick={this.onSidebarButtonClick}>
                Edit
                <Icon icon="arrowDown" />
              </SidebarButton>
              <MobileCheckoutButton
                to={routes.PREVIEW}
                onClick={this.onCheckout}
              >
                {this.getTotalPrice()} &ndash; Preview &amp; Order
              </MobileCheckoutButton>
            </div>
          </PageMain>

          <PageSidebar onClick={this.onSidebarBackgroundClick}>
            <ul className="side-header">
              <li>
                <NavLink to={routes.CREATE}>Create</NavLink>
              </li>
              <li>
                <NavLink to={routes.ABOUT}>About</NavLink>
              </li>
              <li>
                <NavLink to={routes.FAQ}>Help</NavLink>
              </li>
              <li>
                <IconNavLink to={routes.INSTAGRAM}>
                  <Icon icon="instagram" />
                </IconNavLink>
              </li>
            </ul>
            <div className="print-options">{this.renderOptions()}</div>
            <footer className="side-footer">
              <PriceContainer>
                {this.originalPrice && (
                  <OriginalPrice style={{ lineHeight: 1 }}>
                    {this.originalPrice}
                  </OriginalPrice>
                )}
                <Price>{this.getTotalPrice()}</Price>
              </PriceContainer>
              {REACT_APP_DEBUG_SAVE_CONFIG && (
                <DownloadButton onClick={this.onDownloadConfig}>
                  Cheeky Save
                </DownloadButton>
              )}
              <CheckoutButton to={routes.PREVIEW} onClick={this.onCheckout}>
                Preview &amp; Order
              </CheckoutButton>
              <SmallText light>
                Includes postage &amp; packaging. All{' '}
                <Link to={`${routes.FAQ}/#delivery`}>delivery times</Link> are
                estimated. Framed prints only{' '}
                <Link to={`${routes.FAQ}/#where-do-you-ship-to`}>
                  available to select countries
                </Link>
                . Digital prints can take{' '}
                <Link to={`${routes.FAQ}/#where-is-my-digital-print`}>
                  up to 24hrs to arrive
                </Link>
                .
              </SmallText>
            </footer>
          </PageSidebar>
        </PageSplit>
        <FileDroploader
          open={this.props.uiDroploaderOpen}
          message={this.getDroploaderMessage()}
          onDrop={this.onDroploaderDrop}
          onDragOver={this.onDroploaderDragOver}
          onDragEnter={this.onDroploaderDragEnter}
          onDragLeave={this.onDroploaderDragLeave}
        />
      </div>
    )
  }
}

const Create = styled(UnstyledCreate)`
  flex-grow: 1;
  min-height: 100vh;

  ${SiteHeader} {
    ${SiteContainer} {
      padding-top: 0;
      padding-bottom: 6rem;
    }

    .links {
      display: none;
    }
  }

  ${ViewSamplesButton} {
    margin-top: 2.2rem;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  ${IconNavLink} {
    margin-top: 3px;

    .isvg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  ${FileDroploader} {
    position: fixed;
    z-index: ${zIndexes.dropLoader};
  }

  ${LogoLink} {
    &:hover {
      color: currentColor;
    }
  }
  ${PageSidebar} .inner {
    display: flex;
    flex-direction: column;
  }

  .side-header {
    margin-top: 0.8rem;
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 2rem;
    align-items: center;
    margin-bottom: 10rem;
    align-self: flex-end;
  }

  .side-footer {
    ${SmallText} {
      text-align: center;
      margin-top: 4rem;
      margin-left: auto;
      margin-right: auto;
      padding: 0 2rem;
      max-width: 28em;
    }

    ${PriceContainer} {
      margin-bottom: 2.2rem;
    }
  }

  .print-options {
    margin-bottom: 6rem;

    ${Input} input:empty {
      ::-webkit-input-placeholder {
        color: ${colours.grey04};
      }
      ::-moz-placeholder {
        color: ${colours.grey04};
      }
      :-ms-input-placeholder {
        color: ${colours.grey04};
      }
      :-moz-placeholder {
        color: ${colours.grey04};
      }
    }

    ${DefaultActivityHelpText} {
      text-align: center;
      max-width: 24em;
      margin: 0 auto 3rem;
    }
  }

  .accordion-item {
    padding-bottom: 4rem;
  }

  .print-container {
    align-self: center;
    position: relative;
    width: 100%;
    height: 100%;
  }

  ${PaperNote} {
    margin: 4rem auto 0;
    max-width: 18em;
    text-align: center;
  }

  ${PrintFrame} {
    z-index: ${zIndexes.raised};
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: top left;
    ${(p) =>
      !p.hasTouch &&
      css`
        transition: 0.4s ease transform;
      `}
  }

  .print-header-inputs {
    margin-bottom: 5.5rem;
  }

  ${Logo} {
    max-width: 15rem;
  }

  ${PageSidebar} {
    .inner {
      padding-top: 5rem;
      padding-bottom: ${dimensions.siteGutter};
      padding-left: 5rem;
      padding-right: 5rem;
      height: 100%;
    }
  }

  ${PageMain} {
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
    padding-bottom: calc(${dimensions.siteGutter} + ${dimensions.siteGutter});
    align-items: flex-start;
    position: relative;
    height: 100vh;
  }

  ${RecenterMapCheckbox} {
    .isvg {
      width: 2.1rem;
      height: 2.2rem;
      color: ${colours.primary};
    }
  }

  .map-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-right: ${dimensions.siteGutter};
    padding-left: ${dimensions.siteGutter};
    height: 6rem;

    > div {
      margin: 0 3rem;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .mobile-actions {
    display: none;
    background-color: ${colours.white};
    height: 5.5rem;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: ${zIndexes.createMobileActions};
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: ${shadows.sidebarOpen};

    ${SidebarButton} {
      flex: 0 1 35%;
      height: 100%;
      display: flex;
      padding: 0.3rem 0 0 0;
      color: ${colours.secondary};

      ${Icon} {
        width: 1.2rem;
        color: ${colours.primary};
        transition: 0.2s ${transitions.buttonEasing};
        transform: ${(props) =>
          props.uiSidebarMenuOpen ? 'rotateZ(0)' : 'rotateZ(-180deg)'};
        margin-left: 0.4em;
      }
    }
    ${MobileCheckoutButton} {
      flex: 0 1 65%;
      height: 100%;
      border-radius: 0;
      padding: 0.3rem 0 0 0;
      font-size: ${fonts.size09};
      box-shadow: none;
    }
  }

  ${RecenterMapCheckbox},
  ${ForScaleCheckbox} {
    ${InputLabel} {
      font-weight: ${fonts.weightMedium};
      font-size: ${fonts.size01};
    }
  }

  ${ForScale} {
    position: absolute;
    left: 50%;
    top: 0;
    opacity: 0;
    z-index: ${zIndexes.raised};

    @media (hover: hover) and (pointer: fine) {
      transition: opacity 0.4s ease, transform 0.4s ease;
    }

    transition: 0.4s ease;
    transform-origin: top left;

    &.is-visible {
      opacity: 1;
    }
  }

  ${CheckoutButton} {
    width: 100%;
    height: 8.8rem;
  }

  ${PreviewButton},
  ${DownloadButton} {
    margin-bottom: 1em;
  }

  ${ActivityCardSkeleton},
  ${ActivityCard} {
    margin-bottom: 4rem;
  }

  ${IconGrid}:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }

  ${NumberInputRow},
  ${ColourPicker},
  ${InputRow} {
    margin-bottom: 2rem;
  }

  ${SortableHandle} {
    opacity: 1;
  }

  .activities-list {
    ${SortableHandle} {
      top: 3.2rem;
      height: auto;
    }
  }

  ${LabelsHelperText} {
    text-align: center;
    margin: 6rem auto 0;
    max-width: 20em;
  }

  .strava-input-form {
    position: relative;
    margin-top: 6rem;
    margin-bottom: 4rem;

    ${PlainButton} {
      position: absolute;
      right: 0;
      top: 3px;
      bottom: 3px;
      padding-left: 1rem;
      z-index: ${zIndexes.raised};
    }

    ${StravaInput} {
      .input {
        padding-right: 7rem;
      }
    }

    ${PlainButton}:hover + ${StravaInput} .input {
      border-bottom-style: solid;
      border-bottom-color: ${colours.primary};
    }
  }

  ${EventThemeLink} {
    margin-bottom: 6rem;
  }

  /* MEDIA STYLES */

  ${media.create`

    .side-header {
      display: none;
    }

    .side-footer {
      display: none;
    }

    .print-options {
      margin-bottom: 0;
    }

    ${ThemeGrid} {
      grid-gap: 2.5rem;
    }

    ${ThemePicker}&.active,
    ${ThemePickerButton}:hover {
      .image {
        transform: scale(0.8);
      }
    }

    .accordion-item {
      padding-bottom: 2rem;
    }

    .accordion-button {
      font-size: ${fonts.size02};
    }

    ${AccordionItem} .contents {
      padding-top: 3rem;
      padding-bottom: 6rem;
    }

    ${ActivityCardSkeleton},
    ${ActivityCard} {
      padding-bottom: 3rem;
    }

    ${ActivityCard} {
      .header {
        flex: 0 0 8rem;
        width: 8rem;
        height: 6rem
      }
      .content {
        padding-left: 1.6rem;
      }
    }

    ${LayoutButton} {
      font-size: ${fonts.size09};
      padding: 1rem;
    }

    ${Price} {
      font-size: ${fonts.size03};
    }

    .print-header-inputs {
      margin-bottom: 3rem;
    }

    .print-container {
      margin-bottom: 6rem;
    }

    ${NumberInputRow},
    ${InputRow} {
      margin-bottom: 1rem;
    }

    ${Checkbox},
    ${Input} .input {
      font-size: ${fonts.size09};
    }

    ${StravaInput} .input {
      font-size: ${fonts.size09};
    }

    ${IconGrid}:not(:last-of-type) {
      margin-bottom: 1.2rem;
    }

    ${IconGrid} {
      grid-gap: 1.2rem;
    }

    .map-actions {
      padding-left: 3rem;
      padding-right: 3rem;
      height: 4rem;
    }

    ${ColourPicker} {
      .colour-label {
        font-size: ${fonts.size02};
      }
    }

    ${SizeButton} {
      padding: 2rem 0.5rem;
    }

    ${VariantSizeButton} {
      padding: 2rem 2rem;
    }


    ${PageMain} {
      width: 100%;
      padding-bottom: 5rem;
      padding-top: 0;
    }

    ${LabelsHelperText} {
      margin-top: 4rem;
    }

    ${SiteHeader} {
      padding-top: 2rem;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      background-color: ${colours.white};

      display: block;
      width: 100%;

      .links {
        display: grid;
      }

      ${SiteContainer} {
        padding-bottom: 0;
        padding-left: 3rem;
        padding-right: 3rem;
      }
      ${Logo} {
        max-width: 10rem;
      }
    }

    .map-actions {
      margin-bottom: 5rem;
      justify-content: space-between;

      > div {
        margin: 0;
      }
    }

    .mobile-actions {
      display: flex;
    }

    ${PageSidebar} {
      width: 100vw;
      height: 100vh;
      overflow: auto;
      position: fixed;
      z-index: ${zIndexes.raised};
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      -webkit-overflow-scrolling: touch;
      transition: ${transitions.sidebarDuration} ${
    transitions.sidebarEasing
  } transform;
      transform: ${(props) =>
        props.uiSidebarMenuOpen ? 'translateY(0);' : 'translateY(101%);'};

      .inner {
        width: 100%;
        height: auto;
        margin-top: 30vh;
        padding: 3rem 3rem 9rem;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        transition: ${transitions.sidebarDuration} ${
    transitions.sidebarEasing
  } box-shadow;
        box-shadow: ${(props) =>
          props.uiSidebarMenuOpen
            ? shadows.sidebarOpen
            : shadows.sidebarClosed};
      }
    }

    ${SortableHandle} {
      left: -3rem;
      width: 3rem;
    }

    ${EventThemeLink} {
      font-size: ${fonts.size09};
      margin-bottom: 4rem;
    }

  `}

  ${media.nav`

    ${SiteHeader} {
      &.menu-is-open button {
        top: 3rem;
      }
    }

    ${IconNavLink} {
      .isvg {
        width: 4rem;
        height: 4rem;
      }
    }

  `}
`

const withPrint = WithPrint()
const withEvents = WithEvents()
const withActivities = WithActivities()
const withThemes = WithThemes()
const withSession = WithSession()
const withGoogleMaps = WithGoogleMaps()
const withToaster = WithToaster()
const withAnalytics = WithAnalytics()
const withOrders = WithOrders()

const { uiPrintScale, uiDroploaderOpen, uiForScaleChecked, uiSidebarMenuOpen } =
  UnstyledCreate.defaultProps
const withUiState = uiState({
  name: () => 'Create',
  persist: false,
  state: () => ({
    uiPrintScale,
    uiDroploaderOpen,
    uiForScaleChecked,
    uiSidebarMenuOpen,
  }),
})

export { Create }
export default compose(
  withGoogleMaps,
  withUiState,
  withEvents,
  withThemes,
  withActivities,
  withPrint,
  withSession,
  withToaster,
  withAnalytics,
  withOrders,
)(Create)
