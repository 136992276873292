import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';

import HocComponent from '../HocComponent';

import * as Actions from './actions';
import * as Selectors from './selectors';

export const MakeWithThemesHoc = WrappedComponent => new HocComponent(WrappedComponent, 'withThemesHoc');

export const WithThemesHoc = () => (WrappedComponent) => {
  const WithThemes = MakeWithThemesHoc(WrappedComponent);

  const mapStateToProps = createStructuredSelector({
    theme: Selectors.themeSelector,
    themeMapStyles: Selectors.themeMapStylesSelector,
    themeActivityStyles: Selectors.themeActivityStylesSelector,
    themePrintStyles: Selectors.themePrintStylesSelector,
    themes: Selectors.themesSelector,
    themeName: Selectors.themeNameSelector,
    themeOptions: Selectors.themeOptionsSelector,
    eventThemeOptions: Selectors.eventThemeOptionsSelector,
    themeColorPalette: Selectors.themeColorPaletteSelector,
    themeFirstTextMapLayerLayerId: Selectors.themeFirstTextMapLayerLayerIdSelector,
  });

  const mapDispatchToProps = dispatch => bindActionCreators({
    changeTheme: Actions.changeTheme,
    setThemeName: Actions.setThemeName,
  }, dispatch);

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const composed = compose(withConnect)(WithThemes);

  return composed;
};

export default WithThemesHoc;
