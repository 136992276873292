import { createSelector } from 'reselect';

import { EVENTS_DOMAIN } from './constants';

export const withDomain = state => state[EVENTS_DOMAIN];

export const eventsSelector = createSelector(
  withDomain,
  domain => domain.events,
);

export const eventThemeNamesSelector = createSelector(
  withDomain,
  domain => domain.eventThemeNames,
);
