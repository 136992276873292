import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import ScriptLoader from 'react-script-loader-hoc';

const { REACT_APP_GOOGLE_API_KEY } = process.env;

export const GOOGLE_CALLBACK = '_GoogleMapsApiLoaded';
export const GOOGLE_SRC = `//maps.googleapis.com/maps/api/js?libraries=${['geometry']
  .join(',')}&key=${REACT_APP_GOOGLE_API_KEY}&callback=${GOOGLE_CALLBACK}`;

const GoogleMapsApiHoc = WrappedComponent =>
  // eslint-disable-next-line
  class WithHoc extends React.Component {

    static displayName = `withGoogleMapsApiHoc(${
      WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'
    })`;

    static propTypes = {
      scriptsLoadedSuccessfully: PropTypes.bool.isRequired,
    };

    static defaultProps = {

    };

    constructor() {
      super();
      if (!window[GOOGLE_CALLBACK]) {
        window[GOOGLE_CALLBACK] = this.googleScriptLoaded.bind(this);
      }
    }

    googleScriptLoaded() {
      // Google's api will have loaded
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export const MakeWithGoogleMapsApiHoc = WrappedComponent => new GoogleMapsApiHoc(WrappedComponent);

export const WithGoogleMapsApiHoc = () => (WrappedComponent) => {
  const WithGoogleMapsApi = MakeWithGoogleMapsApiHoc(WrappedComponent);

  const composed = compose(ScriptLoader(GOOGLE_SRC))(WithGoogleMapsApi);

  return composed;
};

export default WithGoogleMapsApiHoc;
