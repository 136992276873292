import turfBbox from '@turf/bbox';
import convert from 'convert-units';
import turfLength from '@turf/length';
import deepEqual from 'fast-deep-equal';
import turfDistance from '@turf/distance';
import { lineString as turfLineString } from '@turf/helpers';

/**
 * Calculates the distance between two lat/lng points
 * @param {number[]} p1 lat/lng
 * @param {number[]} p2 lat/lng
 * @param {string} unit measurements unit
 * @returns {number}
 */
export const getDistanceBetween = (p1, p2, unit = 'km') => {
  const distance = turfDistance(p1, p2, { units: 'kilometers' });
  return (unit !== 'km')
    ? convert(distance).from('km').to(unit)
    : distance;
};


/**
 * Determins the orientation of the line between two points
 * Turf matches GeoJSON spec so points must be converted to lng/lat
 * @param {number[]} p1 lat/lng
 * @param {number[]} p2 lat/lng
 * @returns {string}
 */
export const getOrientaion = (p1, p2) => {
  const bbox = turfBbox(turfLineString(flipLatlngs([p1, p2])));
  const [minX, minY, maxX, maxY] = bbox;
  const sw = [minX, minY];
  const se = [maxX, minY];
  const nw = [minX, maxY];
  const vertical = getDistanceBetween(sw, nw);
  const horizontal = getDistanceBetween(sw, se);
  return (horizontal < vertical)
    ? 'portrait'
    : 'landscape';
};


/**
 * GeoJson and Mapbox take coords in lng/lat format, not lat/lng
 * @param {number[][]} latlngs
 * @return {number[][]}
 */
export const flipLatlngs = (latlngs = []) => latlngs.map(([a, b]) => [b, a]);


/**
 * Returns the distance along a path of coordinates
 * @param {array} latlngs array of lat/lng coordinates
 * @param {string} unit measurements unit
 */
export const getLengthAlongPath = (latlngs = [], unit = 'km') => {
  const path = turfLineString(flipLatlngs(latlngs));
  const length = turfLength(path, { units: 'kilometers' });
  return (unit !== 'km')
    ? convert(length).from('km').to(unit)
    : length;
};


/**
 * Returns true if map styles have changed
 * @param {string|object} stylesA
 * @param {string|object} stylesB
 * @returns boolean
 */
export const mapStylesHaveChanged = (stylesA, stylesB) => !deepEqual(stylesA, stylesB);


/**
 * Returns the min and max from an array of elevations
 * @param {number[]} elevations
 */
export const getMinMaxElevation = (elevations = [0]) => {
  if (!elevations.length) return { min: 0, max: 0 };
  let min = 8848; // everest
  let max = -432; // dead sea
  for (let i = 0; i < elevations.length; i++) {
    const elevation = elevations[i];
    if (elevation > max) max = elevation;
    if (elevation < min) min = elevation;
  }
  return {
    min: Math.round(min),
    max: Math.round(max),
  };
};


/**
 * Returns the difference in meters between sea level and the provided
 * elevation. Offset is optional
 * @param {number} elevation in meters
 * @param {number} offset
 */
export const getHeightAboveSeaLevel = (elevation = 0, offset = 0) =>
  (elevation > offset) ? (elevation - offset) : 0;
