import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  printStyles,
  activityStyles,
  id: 'outlines1',
  name: 'Outlines: 1',
  mapStyles: 'mapbox://styles/papertrails/ck3r43zfr037p1ck6xpmipf8u',
  cssStyles,
  palette,
};
