import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { compose } from 'redux'

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

import SiteContainer from '../../components/SiteContainer'
import WithSessionHoc from '../../hocs/WithSession'
import { StravaLogo } from '../../components/Logo'
import { UploadButton } from '../../components/Button'
import { FeaturedTitle } from '../../components/Title'
import StravaBEMCMicrosite from '../../components/StravaBEMCMicrosite'
import StravaBEMCMicrositeFAQ from '../../components/StravaBEMCMicrositeFAQ'
import * as routes from '../../routes'

import SiteHeaderBEMC2024 from '../../containers/SiteHeaderBEMC2024'
import WithEventsHoc from '../../hocs/WithEvents'
import { ThemesList, ThemesListItem } from '../../components/ThemesList'

const MobilePadding = styled.div`
  @media(max-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

class UnstyledStravaBEMC2024 extends React.Component {
  constructor(props) {
    super(props)

    this.stravaEvent = props.events.find(({ uri }) => uri === 'stravabemc')
  }

  componentWillReceiveProps(nextProps) {}

  onStravaAuthClick = (evt) => {
    evt.preventDefault()
    this.props.getStravaAuthToken()
  }

  render() {

    return (
      <React.Fragment>
        <ScrollToTopOnMount />
        <Helmet>
          <Helmet>
            <title>Strava BEMC 2024</title>
          </Helmet>
        </Helmet>
        <div className={this.props.className}>
          <SiteContainer className="w-full">
            <SiteHeaderBEMC2024 />
            <StravaBEMCMicrosite />
            <MobilePadding>
            <ThemesList>
              {this.stravaEvent.themes.map((theme) => (
                <ThemesListItem
                  image={theme.image}
                  title={theme.title}
                  subtitle={theme.subtitle}
                  key={theme.themeName}
                  orientation={theme.orientation}
                  to={`${routes.CREATE}?themeName=${theme.themeName}`}
                />
              ))}
            </ThemesList>
            </MobilePadding>
          </SiteContainer>
            <hr style={{ border: 'none', height: '1px', backgroundColor: '#D8D8D8', marginBottom: '8rem'}} />
          <SiteContainer className="w-full">  
              <StravaBEMCMicrositeFAQ />
          </SiteContainer>

        </div>
      </React.Fragment>
    )
  }
}

const StravaBEMC = styled(UnstyledStravaBEMC2024)`
  width: 100%;

  ${FeaturedTitle} {
    text-align: center;
    margin: 0 auto 13rem;
    max-width: 22em;
  }
`

const withSession = WithSessionHoc()
const withEvents = WithEventsHoc()

export { StravaBEMC }
export default compose(withSession, withEvents)(StravaBEMC)
