import * as arrayUtils from './arrays';


/**
 * queryStringToObject
 * Converts a URL query string to an object
 * @param {string} str
 */
export const queryStringToObject = (str = '') => {
  const strClean = str.replace('?', '');
  const obj = {};
  const queryParams = strClean.split('&').filter(Boolean);

  for (let i = 0; i < queryParams.length; i++) {
    const params = queryParams[i].split('=');
    const k = params[0];
    const v = params[1] || true;
    obj[k] = v;
  }

  return obj;
};


/**
 * Transforms an object into a query string
 * @param {object} obj
 */
export const objectToQueryString = (obj = {}) =>
  Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');


/**
 * capitalizeFirstLetter
 * Capitalise the first letter only
 * @param {string} str
 */
export const capitalizeFirstLetter = str =>
  str.charAt(0).toUpperCase() + str.slice(1);


/**
 * sanitizeStravaUrl
 * Takes a string of one or more strava urls and returns the
 * ID only
 * @param {string} url
 */
export const sanitizeStravaUrl = (url = '') => {
  const urlParts = arrayUtils.removeFalsy(url.split('/'));
  return urlParts[urlParts.length - 1] || '';
};


/**
 * Tests if the string matches the print ID format
 * @param {string} printId
 */
export const isPrintIdFormat = (printId = '') =>
  new RegExp('(\\d{6}-\\d{6}-[0-9a-zA-Z]{8,12}){1}').test(printId);


/**
 * extractStravaIds
 * Takes a comma-separated list of strava urls/ids and
 * returns an array of extracted ids
 * @param {string} urls
 */
export const extractStravaIds = urls => {
  if (!urls) return [];
  // split the string and trim any spaces
  const splitUrls = urls.split(',').map(url => url.trim());
  return splitUrls.map(url => sanitizeStravaUrl(url));
};


/**
 * Returns a slugified string
 * @param {string} str
 */
export const slugify = (str = '') => str.trim().replace(/ /g, '-').toLowerCase();


/**
 * accepts a string and splits is using a newlline char, returning an array
 * of trimmed strings
 * @param {string} str
 * @returns {string[]}
 */
export const splitNewlines = str => {
  if (!str) return [];
  return str.split(/\\n/g).map(line => line.trim());
};


/**
 * Retuns the file extension from a string
 * @param {string} filename
 * @returns {string}
 */
export const fileExt = (filename = '') => filename.split('.').pop();
