import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fonts, colours } from '../../theme';

import Link from '../Link';
import { Title } from '../Title';
import { Text } from '../Text';

const Component = styled.aside`
  font-size: ${fonts.size02};

  ${Text},
  ${Title},
  ${Link} {
    font-size: inherit;
  }

  ${Text} {
    margin-bottom: 0.2em;
  }

  ${Title} {
    margin-bottom: 1em;
  }

  ${Link} {
    color: ${colours.primary};

    &:hover {
      text-decoration: none;
    }
  }
`;

export const EventThemeLink = styled(props => (
  <Component className={props.className}>
    <Text light>Currently viewing</Text>
    <Title>{props.children}</Title>
    <Link to={props.to}>See all →</Link>
  </Component>
))``;

EventThemeLink.propTypes = {
  to: PropTypes.string,
};
EventThemeLink.defaultProps = {
  to: '',
};

export default EventThemeLink;
