import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';

export * from './PaypalButton';

const PaypalHoc = WrappedComponent =>
  // eslint-disable-next-line
  class WithHoc extends React.Component {

    static displayName = `withPayPalHoc(${
      WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'
    })`;

    static propTypes = {};

    static defaultProps = {};

    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  };

export const MakeWithPaypalHoc = WrappedComponent => new PaypalHoc(WrappedComponent);

export const WithPaypalHoc = () => (WrappedComponent) => {
  const WithStripe = MakeWithPaypalHoc(WrappedComponent);

  const mapStateToProps = createStructuredSelector({
    //
  });

  const mapDispatchToProps = dispatch => bindActionCreators({
    //
  }, dispatch);

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const composed = compose(withConnect)(WithStripe);

  return composed;
};

export default WithPaypalHoc;
