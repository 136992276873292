import { put, takeEvery, delay } from 'redux-saga/effects'

import { toasterUuid } from '../../utils/uuid'
import * as Actions from './actions'
import * as Constants from './constants'
import * as AnalyticsActions from '../WithAnalytics/actions'

export function* createToast(action) {
  try {
    const { message, timeout, variant } = yield action
    const id = yield toasterUuid()
    yield put(Actions.addToast(id, message, timeout, variant))
    if (action.timeout) {
      yield delay(action.timeout)
      yield put(Actions.removeToast(id))
    }
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('createToast', error.message))
  }
}

export function* watchCreateToast() {
  yield takeEvery(Constants.CREATE_TOAST, createToast)
}

export default [watchCreateToast]
