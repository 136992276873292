import * as Constants from './constants';
import { initialState } from './model';

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case Constants.NEW_STRIPE_TOKEN: {
      return state.set('token', action.token);
    }

    default: {
      return state;
    }
  }
};

export default reducers;
