/* global google */
import Immutable from 'seamless-immutable';

import { thinToLength } from './arrays';

export const GOOGLE_POLYLINE_LATLNG_COUNT = 200;


/**
 * Converts a lat/lng coord array to a new Google LatLng instance
 * [Docs here](https://developers.google.com/maps/documentation/javascript/reference/coordinates)
 * @param {number[]} latlng
 * @returns {object} Google LatLng
 */
export const googleLatLng = (latlng = []) => new google.maps.LatLng(...latlng);


/**
 * Decodes a Google Polyline into latlngs
 * @param {string} polyline
 * @return {number[]}
 */
export const googleDecodePolylineToLatLngs = (polyline = '') =>
  google.maps.geometry.encoding.decodePath(polyline);


/**
* Encodes a Google Polyline from latlngs
* @param {number[]}
* @returns {string}
*/
export const googleEncodeLatLngToPolyline = (latlngs = []) => {
  const thinnedLatlngs = thinToLength(latlngs, GOOGLE_POLYLINE_LATLNG_COUNT);
  const gLatLngs = googleGetLatLngs(undefined, thinnedLatlngs);
  return google.maps.geometry.encoding.encodePath(gLatLngs);
};


/**
 *
 * @param {string} [polyline]
 * @param {number[][]} latlngs
 */
export const googleGetLatLngs = (polyline, latlngs = []) => {
  if (polyline) return googleDecodePolylineToLatLngs(polyline);
  // If latlngs comes from the store we have to make sure the object
  // and all immutable traces removed
  const mutableLatlngs = Immutable.asMutable(latlngs);
  return mutableLatlngs.map(latlng => googleLatLng(latlng));
};
