import { takeEvery, put } from 'redux-saga/effects'

import * as routes from '../../routes'

import { trackEvent, trackPage, trackException, trackPurchase } from './'
import * as Constants from './constants'
import * as Actions from './actions'

/**
 * Tracks important metrics from a print
 *
 */
export function* trackPrintMetricsSaga({ print }) {
  const metrics = yield [
    {
      action: Constants.PRINT_METRICS_ACTION_ORIENTATION,
      label: print.orientation,
    },
    {
      action: Constants.PRINT_METRICS_ACTION_LAYOUT,
      label: print.layout,
    },
    {
      action: Constants.PRINT_METRICS_ACTION_THEME_NAME,
      label: print.themeName,
    },
    {
      action: Constants.PRINT_METRICS_ACTION_PRODUCT,
      label: print.product,
    },
  ]

  // TODO: print size?
  for (let i = 0; i < metrics.length; i++) {
    yield put(
      Actions.trackEvent({
        category: Constants.PRINT_METRICS_CATEGORY,
        action: metrics[i].action,
        label: metrics[i].label,
        value: metrics[i].value,
      }),
    )
  }
}

/**
 * Tracks a Google Analytics pageview
 *
 */
export function* trackPageSaga({ page }) {
  let pageName
  // Ensures paths such as '/create/TEST(/*) will be tracked as /create,
  // i.e. removes the print ID for now
  if (page.includes(routes.CREATE)) {
    yield (pageName = routes.CREATE)
  } else {
    yield (pageName = page)
  }
  yield trackPage(pageName)
}

/**
 * Tracks a Google Analytics event
 *
 */
export function* trackEventSaga({
  category,
  action,
  label,
  value,
  nonInteraction,
}) {
  yield trackEvent({
    category,
    action,
    label,
    value,
    nonInteraction,
  })
}

export function* trackPurchaseSaga({ id, value, coupon, quantity, product }) {
  yield trackPurchase({
    id,
    value,
    coupon,
    quantity,
    product,
  })
}

/**
 * Tracks a Google Analytics exception
 *
 */
export function* trackExceptionSaga({ description, fatal }) {
  yield trackException(description, fatal)
}

/**
 * Watchers
 * -----------------------------------------------------------------------------
 *
 */
export function* watchTrackEvent() {
  yield takeEvery(Constants.TRACK_EVENT, trackEventSaga)
}

export function* watchTrackPurchase() {
  yield takeEvery(Constants.TRACK_PURCHASE, trackPurchaseSaga)
}

export function* watchTrackException() {
  yield takeEvery(Constants.TRACK_EXCEPTION, trackExceptionSaga)
}

export function* watchTrackPage() {
  yield takeEvery(Constants.TRACK_PAGE, trackPageSaga)
}

export function* watchTrackPrintMetrics() {
  yield takeEvery(Constants.TRACK_PRINT_METRICS, trackPrintMetricsSaga)
}

export default [
  watchTrackPage,
  watchTrackPurchase,
  watchTrackEvent,
  watchTrackException,
  watchTrackPrintMetrics,
]
