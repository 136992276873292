import * as Constants from './constants';

export const activitiesLoadingCount = (count = 0) => ({
  type: Constants.ACTIVITIES_LOADING_COUNT,
  count,
});

export const selectDefaultActivity = (id, themeName) => ({
  type: Constants.SELECT_DEFAULT_ACTIVITY,
  id,
  themeName,
});

export const selectAffiliateActivities = themeName => ({
  type: Constants.SELECT_AFFILIATE_ACTIVITIES,
  themeName,
});

export const uploadXmlFiles = files => ({
  type: Constants.UPLOAD_XML_FILES,
  files,
});

export const uploadJsonFiles = files => ({
  type: Constants.UPLOAD_JSON_FILES,
  files,
});

export const getStravaActivities = ids => ({
  type: Constants.GET_STRAVA_ACTIVITIES_REQUEST,
  ids,
});

export const getStravaActivityStreamsAndDetails = ids => ({
  type: Constants.GET_STRAVA_ACTIVITY_STREAMS_AND_DETAILS_REQUEST,
  ids,
});

export const getStravaRoutes = ids => ({
  type: Constants.GET_STRAVA_ROUTES_REQUEST,
  ids,
});

export const getStravaRoutesStreamsAndDetails = ids => ({
  type: Constants.GET_STRAVA_ROUTES_STREAMS_AND_DETAILS_REQUEST,
  ids,
});
