import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  printStyles,
  activityStyles,
  id: 'moonraker',
  name: 'Moonraker',
  mapStyles: 'mapbox://styles/papertrails/ck1kbad4j22s41cmj4u4qep1t',
  cssStyles,
  palette,
};
