import { css } from 'styled-components'

import badge from './pcultra-badge-mixed.png'
import MapboxMap from '../../../../../components/MapboxMap'
import commonStyles from '../styles-common'

export default () => css`
  ${commonStyles}

  ${MapboxMap}:after {
    background-image: url(${badge});
    height: 68.1rem;
  }
`
