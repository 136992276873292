import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  printStyles,
  activityStyles,
  id: 'coppermine',
  name: 'Copper Mine',
  mapStyles: 'mapbox://styles/papertrails/ck1jnfk1h00qu1cqxkeffxyt2',
  cssStyles,
  palette,
};
