import { css } from 'styled-components';

import { fontGroup } from '../../styles';
import badge from './badge-solid.svg';
import MapboxMap from '../../../../components/MapboxMap';
import { PrintLabel, PrintLabelValue } from '../../../../components/PrintLabel';
import { PrintTitle } from '../../../../components/PrintTitle';

export default () => css`
  ${fontGroup('montserratAlternateGothic')}

  ${PrintTitle} {
    margin-bottom: 0.2em;
  }

  ${PrintLabelValue} {
    text-transform: initial;
  }

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      color: #7b9028;
      opacity: .8;
    }
  }

  ${MapboxMap}:after {
    pointer-events: none;
    content: '';
    width: 56rem;
    height: 24.2rem;
    position: absolute;
    top: 12rem;
    right: 10rem;
    background: transparent url(${badge}) no-repeat center center;
    background-size: cover;
  }

`;
