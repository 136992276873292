import _get from 'lodash.get'

/**
 * Finds the selected prodcut from a list of available products
 * @param {object[]} products
 * @param {string} products[].sku
 * @param {string} sku
 * @param {object} attributes
 */
export const getProduct = (products = [], sku, attributes) => {
  let product
  products.some((prod) => {
    if (prod.variants) {
      prod.variants.some((variant) => {
        if (productVariantMatch(variant, sku, attributes)) {
          product = variant
          return true
        }
        return false
      })
    } else if (prod.sku === sku) {
      product = prod
      return true
    }
    return false
  })

  return product
}

// TODO: Make tests
export const productVariantMatch = (
  productVariant,
  product,
  productAttributes = undefined,
) => {
  // only copare when we have attributes on print and current variant, otherwise
  // assumed the 'true' result is the variant which equals the parent product
  const attributesMatch =
    productVariant.attributes && productAttributes
      ? JSON.stringify(productAttributes) ===
        JSON.stringify(productVariant.attributes)
      : true
  return product === productVariant.sku && attributesMatch
}

/**
 * Calculates the cart total using the selected product and print quantity
 * @param {object} product
 * @param {number} product.price
 * @param {number} quantity
 */
export const calcCartTotal = (product, quantity = 1) =>
  _get(product, 'price', 0) * quantity

/**
 * Calculates the total for the original price using the selected product and print quantity
 * @param {object} product
 * @param {number} product.originalPrice
 * @param {number} quantity
 */
export const calcCartOriginalTotal = (product, quantity = 1) =>
  _get(product, 'originalPrice', 0) * quantity

/**
 * Accepts a list of products and returns the cheapest one
 * @param {object[]} products
 * @param {number} products[].price
 * @returns {object} product
 */
export const cheapestProductReducer = (products = []) =>
  products.reduce(
    (acc, curr) => (acc = curr.price <= acc.price ? curr : acc),
    products[0],
  )
