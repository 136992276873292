import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getMinMaxElevation, getHeightAboveSeaLevel } from '../../utils/maps';

export const MULTIPLE_ACTIVITY_GAP = 2;

class UnstyledElevationProfile extends React.PureComponent {

  lineTo(x, y) {
    this.path.push(`L ${x} ${y}`);
  }

  moveTo(x, y) {
    this.path.push(`M ${x} ${y}`);
  }

  lineToEnd() {
    this.lineTo(this.props.width, this.maxY);
    this.path.push('Z');
  }

  closePath(x) {
    this.lineTo(x, this.props.height);
  }

  goToStart() {
    this.moveTo(this.lastKnownX, this.maxY);
  }

  combineElevations() {
    const { activities } = this.props;

    this.elevations = activities.map(a => a.elevations);
    this.combinedElevations = [].concat(...this.elevations);
  }

  reduceTotalDistance() {
    const { activities } = this.props;
    this.totalDistance = activities.every(({ distance }) => !!distance)
      ? activities.reduce((total, { distance }) => total + distance, 0)
      : undefined;
  }

  setBoundariesY() {
    const { combinedElevations } = this;
    const { bufferTop, bufferBottom, height } = this.props;

    const { min, max } = getMinMaxElevation(combinedElevations);
    this.displaceY = getHeightAboveSeaLevel(min);

    const minAbs = Math.abs(min);

    // if the min elevation is below our threshold then our elevation range
    // should be extended, eg. -12 to 100 = range of 112
    const seaBuffer = (min < bufferBottom) ? minAbs : 0;
    const range = Math.round(max - min);

    // Calculate the percentage of the new total range includign the buffer.
    // This is the px which needs to 'exist' beneath the min and
    // the bottom of the svg in order to see all the contents.
    const minBase = Math.ceil(seaBuffer / range * 100) + bufferBottom;

    // The maxY is the height in PX we are allowed to draw and ensures
    //  we can draw the entire profile, even if below sea level.
    this.maxY = height - minBase;

    // The scale is the ratio of px to elevation meters.
    // the subtracted buffer ensures a small gap at the top (avoids cropping)
    // and the subtracted displacement removes any 'unused' height above
    // sea level,meaning our profile should always hug the bottom
    this.scaleY = (this.maxY - bufferTop) / (max - this.displaceY);

  }

  getActivityScaleX(activity) {
    const { activities } = this.props;
    const elevationPoints = activity.elevations.length;

    // x-scale for the current activity, based on the distance of the current
    // activity in relation to the total distance for the combined activities:
    // i.e. if this activity has a distance of 10m and the total tistance for
    // all activites is 50m then the scale would be 0.2
    const activityScale = (this.totalDistance)
      ? (activity.distance / this.totalDistance)
      : (1 / activities.length) * 100;

    const gap = this.getActivityGap();
    const activityWidthPx = this.props.width * activityScale - gap;

    return activityWidthPx / elevationPoints;
  }

  // If we have multiple activities we render a gap between each day
  getActivityGap() {
    return (this.props.activities.length >= 2) ? MULTIPLE_ACTIVITY_GAP : 0;
  }

  renderActivityPath = (activity, index) => {
    this.path = [];
    const { activities } = this.props;

    const isLastActivity = (index === activities.length - 1);
    const scaleX = this.getActivityScaleX(activity);
    const gap = this.getActivityGap();
    const { elevations } = activity;

    let i = 0;
    let x;
    let y;

    this.moveTo(this.lastKnownX, this.props.height);

    for (i; i < elevations.length; i++) {
      const lastIndex = (i === elevations.length - 1);

      // Our scale removes 'unused' height above sea level, so subtracting the
      // displacement means we only draw the valid elevation range
      const elevation = elevations[i] - this.displaceY;

      x = (isLastActivity && lastIndex)
        ? this.props.width
        : scaleX * i + this.lastKnownX;
      if (lastIndex) x = Math.ceil(x);
      y = this.maxY - (elevation * this.scaleY);
      this.lineTo(x, y);
    }

    this.closePath(x);
    this.lastKnownX = x + gap;

    const d = this.path.join(' ');
    const className = `profile profile-${index + 1} profile-activity-${activity.id}`;
    return (
      <g key={className} className={className}>
        <path vectorEffect="non-scaling-stroke" d={d} />
      </g>
    );
  }

  renderElevations() {
    this.path = [];
    this.lastKnownX = 0;

    this.combineElevations();
    this.reduceTotalDistance();
    this.setBoundariesY();

    this.goToStart();
    return this.props.activities.map(this.renderActivityPath);
  }

  render() {
    const { activities, width, height, bufferTop, bufferBottom, ...props } = this.props;
    return (
      <figure {...props}>
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="none">
          {this.renderElevations()}
        </svg>
      </figure>
    );
  }
}

UnstyledElevationProfile.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  bufferTop: PropTypes.number,
  bufferBottom: PropTypes.number,
  activities: PropTypes.arrayOf(PropTypes.shape({
    distance: PropTypes.number,
    elevations: PropTypes.arrayOf(PropTypes.number),
    pathColor: PropTypes.string,
    elevationProfileColor: PropTypes.string,
  })),
};
UnstyledElevationProfile.defaultProps = {
  width: 100,
  height: 100,
  bufferTop: 2,
  bufferBottom: 6,
  activities: [],
};

const ElevationProfile = styled(UnstyledElevationProfile)`
  margin: 0;
  padding: 0;

  svg {
    display: block;
    width: 100%;
  }

  path {
    stroke: none;
    fill: currentColor;
  }

  .profile {
    color: currentColor;
  }

  ${p => p.activities.map(a => css`
    .profile-activity-${a.id} {
      color: ${a.elevationProfileColor};
    }
  `)}
`;

export { UnstyledElevationProfile, ElevationProfile };
export default ElevationProfile;
