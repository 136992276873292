import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import classnames from 'classnames';

import { colours, fonts } from '../../theme';
import { Text } from '../Text';

class UnstyledFileDroploader extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    onDragOver: PropTypes.func,
    onDragEnter: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDrop: PropTypes.func,
  };

  static defaultProps = {
    open: false,
    message: '',
    onDragOver: () => {},
    onDragEnter: () => {},
    onDragLeave: () => {},
    onDrop: () => {},
  };

  getClassNames() {
    return classnames(this.props.className, {
      'is-open': this.props.open,
    });
  }

  render() {
    const { message, ...restProps } = this.props;
    return (
      <aside {...restProps}>
        <Text>{this.props.message}</Text>
      </aside>
    );
  }
}

const FileDroploader = styled(UnstyledFileDroploader)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  opacity: 0;
  background: ${colours.primary};
  border: 2rem solid white;

  will-change: opacity;
  transition: 0.4s 0.1s ease opacity;

  ${props => props.open && css`
    pointer-events: initial;
    opacity: 1;
    transition-delay: 0.4s;
  `}

  ${Text} {
    pointer-events: none;
    font-size: ${fonts.size08};
    color: ${colours.white};
  }
`;

export { FileDroploader, UnstyledFileDroploader };

export default FileDroploader;
