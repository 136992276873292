import Immutable from 'seamless-immutable'

import { printUuid } from '../../utils/uuid'
import { themesEnum } from '../WithThemes/model'
import {
  ORIENTATION_LANDSCAPE,
  ORIENTATION_PORTRAIT,
} from '../WithPrint/constants'

import londonMarathonLatlngs from './default-activities/london-marathon-latlngs.json'
import londonMarathonElevations from './default-activities/london-marathon-elevations.json'
import londonToParisLatlngs1 from './default-activities/london-paris-01-latlngs.json'
import londonToParisElevations1 from './default-activities/london-paris-01-elevations.json'
import londonToParisLatlngs2 from './default-activities/london-paris-02-latlngs.json'
import londonToParisElevations2 from './default-activities/london-paris-02-elevations.json'
import grandCanyonLatlngs from './default-activities/grand-canyon-latlngs.json'
import grandCanyonElevations from './default-activities/grand-canyon-elevations.json'
import dunwichDynamoLatlngs from './default-activities/dunwich-dynamo-latlngs.json'
import dunwichDynamoElevations from './default-activities/dunwich-dynamo-elevations.json'
import lejogLatlngs from './default-activities/lejog-latlngs.json'
import lejogElevations from './default-activities/lejog-elevations.json'
import dragonTour1Latlngs from './default-activities/dragon-tour-1.json'
import dragonTour1Elevations from './default-activities/dragon-tour-1-elevations.json'
import dragonTour2Latlngs from './default-activities/dragon-tour-2.json'
import dragonTour2Elevations from './default-activities/dragon-tour-2-elevations.json'
import dragonTour3Latlngs from './default-activities/dragon-tour-3.json'
import dragonTour3Elevations from './default-activities/dragon-tour-3-elevations.json'

import theclassicImg from './default-activities/theclassic-dragontour-portrait.jpg'
import outlines1Img from './default-activities/outlines1-dunwich-landscape.jpg'
import coppermineImg from './default-activities/coppermine-dunwich-landscape.jpg'
import heatherImg from './default-activities/heather-lejog-portrait.jpg'
import reddeadImg from './default-activities/reddead-grandcanyon-portrait.jpg'
import americanrustImg from './default-activities/americanrust-london-landscape.jpg'
import paperchaseImg from './default-activities/paperchase-londontoparis-portrait.jpg'
import moorakerImg from './default-activities/moonraker-london-landscape.jpg'
import crimsonrideImg from './default-activities/crimsonride-lejog-portrait.jpg'

import thirteenpeaks from './events/13peaks.json'

import ggDorsetLatLngs from './events/gloriousgravel/gg-dorset-x-latlngs.json'
import ggDorsetElevations from './events/gloriousgravel/gg-dorset-x-elevations.json'
import ggPeaksLatLngs from './events/gloriousgravel/gg-peaks-x-latlngs.json'
import ggPeaksElevations from './events/gloriousgravel/gg-peaks-x-elevations.json'
import ggSherwoodLatLngs from './events/gloriousgravel/gg-sherwood-x-latlngs.json'
import ggSherwoodElevations from './events/gloriousgravel/gg-sherwood-x-elevations.json'
import ggSuffolkLatLngs from './events/gloriousgravel/gg-suffolk-x-latlngs.json'
import ggSuffolkElevations from './events/gloriousgravel/gg-suffolk-x-elevations.json'
import ggNorthWalesLatLngs from './events/gloriousgravel/gg-northwales-x-latlngs.json'
import ggNorthWalesElevations from './events/gloriousgravel/gg-northwales-x-elevations.json'

// PC Gravel Rally
import pcGravelRally2022FullElevations from './events/panceltic/pc-gravelrally-2022-full-elevations.json'
import pcGravelRally2022FullLatLngs from './events/panceltic/pc-gravelrally-2022-full-latlngs.json'
import pcGravelRally2022ShortElevations from './events/panceltic/pc-gravelrally-2022-short-elevations.json'
import pcGravelRally2022ShortLatLngs from './events/panceltic/pc-gravelrally-2022-short-latlngs.json'

// PC Ultra 2022
import pcUltra2022FullElevations from './events/panceltic/pc-ultra-2022-full-elevations.json'
import pcUltra2022FullLatLngs from './events/panceltic/pc-ultra-2022-full-latlngs.json'
import pcUltra2022ShortElevations from './events/panceltic/pc-ultra-2022-short-elevations.json'
import pcUltra2022ShortLatLngs from './events/panceltic/pc-ultra-2022-short-latlngs.json'
// PC Ultra 2023
import pcUltra2023LongElevations from './events/panceltic/pc-ultra-2023-long-elevations.json'
import pcUltra2023LongLatLngs from './events/panceltic/pc-ultra-2023-long-latlngs.json'
import pcUltra2023ShortElevations from './events/panceltic/pc-ultra-2023-short-elevations.json'
import pcUltra2023ShortLatLngs from './events/panceltic/pc-ultra-2023-short-latlngs.json'

// Great British Divide
import gbDivide2022Latlngs from './events/gbdivide/gbdivide-2022-latlngs.json'
import gbDivide2022Elevations from './events/gbdivide/gbdivide-2022-elevation.json'
import gbDivide2023FullLatlngs from './events/gbdivide/gbdivide-2023-full_latlngs.json'
import gbDivide2023FullElevations from './events/gbdivide/gbdivide-2023-full_elevation.json'
import gbDivide2023ShortLatlngs from './events/gbdivide/gbdivide-2023-short_latlngs.json'
import gbDivide2023ShortElevations from './events/gbdivide/gbdivide-2023-short_elevation.json'

// TribeX Maverick
import tribexmaverickultra2022latlngs from './events/tribexmaverick/tribexmaverick-ultra-2022-latlngs.json'
import tribexmaverickultra2022elevations from './events/tribexmaverick/tribexmaverick-ultra-2022-elevations.json'
import tribexmaverickhalf2022latlngs from './events/tribexmaverick/tribexmaverick-half-2022-latlngs.json'
import tribexmaverickhalf2022elevations from './events/tribexmaverick/tribexmaverick-half-2022-elevations.json'
import tribexmaverickshort2022latlngs from './events/tribexmaverick/tribexmaverick-short-2022-latlngs.json'
import tribexmaverickshort2022elevations from './events/tribexmaverick/tribexmaverick-short-2022-elevations.json'
import tribexmaverickmarathon2022latlngs from './events/tribexmaverick/tribexmaverick-marathon-2022-latlngs.json'
import tribexmaverickmarathon2022elevations from './events/tribexmaverick/tribexmaverick-marathon-2022-elevations.json'

// STRAVA BEMC 2024
import stravaBemc2024Berlinlatlngs from './events/stravabemc2024/stravabemc2024-berlin-latlngs.json'
import stravaBemc2024Berlinlevations from './events/stravabemc2024/stravabemc2024-berlin-elevation.json'
import stravaBemc2024Chicagolatlngs from './events/stravabemc2024/stravabemc2024-chicago-latlngs.json'
import stravaBemc2024Chicagolevations from './events/stravabemc2024/stravabemc2024-chicago-elevation.json'
import stravaBemc2024NewYorklatlngs from './events/stravabemc2024/stravabemc2024-newyork-latlngs.json'
import stravaBemc2024NewYorklevations from './events/stravabemc2024/stravabemc2024-newyork-elevation.json'

export const initialState = Immutable({
  activitiesLoadingCount: 0,
  defaultActivities: [
    {
      id: printUuid(),
      title: "L'Etape Wales - Dragon Tour",
      latlngs: [dragonTour1Latlngs, dragonTour2Latlngs, dragonTour3Latlngs],
      elevations: [
        dragonTour1Elevations,
        dragonTour2Elevations,
        dragonTour3Elevations,
      ],
      elevationProfile: true,
      distance: 490000,
      themeName: themesEnum.theclassic,
      image: theclassicImg,
    },
    {
      id: printUuid(),
      title: 'Dunwich Dynamo',
      latlngs: [dunwichDynamoLatlngs],
      elevations: [dunwichDynamoElevations],
      elevationProfile: true,
      distance: 265000,
      themeName: themesEnum.coppermine,
      image: coppermineImg,
    },
    {
      id: printUuid(),
      title: "Land's End to John 'o Groats",
      latlngs: [lejogLatlngs],
      elevations: [lejogElevations],
      elevationProfile: true,
      distance: 1440000,
      themeName: themesEnum.heather,
      image: heatherImg,
    },
    {
      id: printUuid(),
      title: 'London Marathon',
      latlngs: [londonMarathonLatlngs],
      elevations: [londonMarathonElevations],
      elevationProfile: true,
      distance: 42000,
      themeName: themesEnum.moonraker,
      image: moorakerImg,
    },
    {
      id: printUuid(),
      title: 'Grand Canyon Rim-to-Rim',
      latlngs: [grandCanyonLatlngs],
      elevations: [grandCanyonElevations],
      elevationProfile: true,
      distance: 37000,
      themeName: themesEnum.reddead,
      image: reddeadImg,
    },
    {
      id: printUuid(),
      title: 'London Marathon',
      latlngs: [londonMarathonLatlngs],
      elevations: [londonMarathonElevations],
      elevationProfile: true,
      distance: 42000,
      themeName: themesEnum.americanrust,
      image: americanrustImg,
    },
    {
      id: printUuid(),
      title: 'London To Paris',
      latlngs: [londonToParisLatlngs1, londonToParisLatlngs2],
      elevations: [londonToParisElevations1, londonToParisElevations2],
      elevationProfile: true,
      distance: 288000,
      themeName: themesEnum.paperchase,
      image: paperchaseImg,
    },
    {
      id: printUuid(),
      title: 'Dunwich Dynamo',
      latlngs: [dunwichDynamoLatlngs],
      elevations: [dunwichDynamoElevations],
      distance: 265000,
      elevationProfile: true,
      themeName: themesEnum.outlines1,
      image: outlines1Img,
    },
    {
      id: printUuid(),
      title: "Land's End to John 'o Groats",
      latlngs: [lejogLatlngs],
      elevations: [lejogElevations],
      distance: 1440000,
      elevationProfile: true,
      themeName: themesEnum.crimsonride,
      image: crimsonrideImg,
    },
  ],
  eventActivities: [
    // THIRTEEN PEAKS
    // -------------------------------------------------------------------------
    {
      id: '13peaksoneday',
      title: '13 Peaks',
      latlngs: [thirteenpeaks],
      themeName: themesEnum.thirteenpeaksoneday,
    },
    {
      id: '13peakstwoday',
      title: '13 Peaks',
      latlngs: [thirteenpeaks],
      themeName: themesEnum.thirteenpeakstwoday,
    },
    {
      id: '13peaksmultiday',
      title: '13 Peaks',
      latlngs: [thirteenpeaks],
      themeName: themesEnum.thirteenpeaksmultiday,
    },
    // GLORIOUS GRAVEL
    // -------------------------------------------------------------------------
    {
      id: 'ggDorsetGravelX2022',
      title: 'Dorset Gravel X',
      secondaryTitle: '9th April 2022',
      latlngs: [ggDorsetLatLngs],
      elevations: ggDorsetElevations,
      themeName: themesEnum.ggdorsetgravelx,
      elevationProfile: true,
      elevationGain: 1350,
      distance: 70000,
      startNode: true,
      endNode: true,
      orientation: ORIENTATION_LANDSCAPE,
      zoom: 12.047387992532554,
      center: [-2.0439007972423724, 50.64047590878732],
    },
    {
      id: 'ggPeakDistrictGravelX2022',
      title: 'Peak District Gravel X',
      secondaryTitle: '9th April 2022',
      latlngs: [ggPeaksLatLngs],
      elevations: ggPeaksElevations,
      themeName: themesEnum.ggpeakdistrictgravelx,
      elevationProfile: true,
      elevationGain: 1750,
      distance: 85000,
      startNode: true,
      endNode: true,
    },
    {
      id: 'ggSherwoodGravelX2022',
      title: 'Sherwood Gravel X',
      secondaryTitle: '16th April 2022',
      latlngs: [ggSherwoodLatLngs],
      elevations: ggSherwoodElevations,
      themeName: themesEnum.ggsherwoodgravelx,
      elevationProfile: true,
      elevationGain: 650,
      distance: 80000,
      startNode: true,
      endNode: true,
      zoom: 11.170671582565893,
      center: [-1.0931731828400473, 53.23619807838875],
    },
    {
      id: 'ggSuffolkGravelX2022',
      title: 'Suffolk Gravel X (Long)',
      secondaryTitle: '23rd April 2022',
      latlngs: [ggSuffolkLatLngs],
      elevations: ggSuffolkElevations,
      themeName: themesEnum.ggsuffolkgravelx,
      elevationProfile: true,
      elevationGain: 600,
      distance: 100000,
      startNode: true,
      endNode: true,
      zoom: 10.73476510760615,
      center: [0.6785609518146885, 52.41027875667345],
    },
    {
      id: 'ggNorthWalesGravelX2022',
      title: 'North Wales Gravel X',
      secondaryTitle: '30th April 2022',
      latlngs: [ggNorthWalesLatLngs],
      elevations: ggNorthWalesElevations,
      themeName: themesEnum.ggnorthwalesgravelx,
      elevationProfile: true,
      elevationGain: 1300,
      distance: 75000,
      startNode: true,
      endNode: true,
      orientation: ORIENTATION_LANDSCAPE,
      zoom: 11.581749130575764,
      center: [-3.4490638920592858, 53.099981698312234],
    },
    // PAN CELTIC
    // -------------------------------------------------------------------------
    {
      id: 'pcGravelRally2022Full',
      title: 'The Gravel Rally',
      secondaryTitle: '5th June 2022',
      latlngs: [pcGravelRally2022FullLatLngs],
      elevations: pcGravelRally2022FullElevations,
      themeName: themesEnum.pcGravelRally2022Full,
      elevationProfile: true,
      elevationGain: 3120,
      distance: 128000,
      startNode: true,
      endNode: true,
      pathDashed: true,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 11.408856393634307,
      center: [-3.9048688935922087, 53.10896456532538],
    },
    {
      id: 'pcGravelRally2022Short',
      title: 'The Gravel Rally',
      secondaryTitle: '5th June 2022',
      latlngs: [pcGravelRally2022ShortLatLngs],
      elevations: pcGravelRally2022ShortElevations,
      themeName: themesEnum.pcGravelRally2022Short,
      elevationProfile: true,
      elevationGain: 2124,
      distance: 89000,
      startNode: true,
      endNode: true,
      pathDashed: true,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 11.326397803101667,
      center: [-3.9141430347213486, 53.110024977996346],
    },
    {
      id: 'pcUltra2022Full',
      title: 'Pan Celtic Ultra',
      secondaryTitle: '10th July 2022',
      latlngs: [pcUltra2022FullLatLngs],
      elevations: pcUltra2022FullElevations,
      themeName: themesEnum.pcUltra2022Full,
      elevationProfile: true,
      elevationGain: 20071,
      distance: 2604000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 6.738614519637165,
      center: [-7.230306732335748, 53.90172064957187],
    },
    {
      id: 'pcUltra2022Short',
      title: 'Pan Celtic Ultra',
      secondaryTitle: '10th July 2022',
      latlngs: [pcUltra2022ShortLatLngs],
      elevations: pcUltra2022ShortElevations,
      themeName: themesEnum.pcUltra2022Short,
      elevationProfile: true,
      elevationGain: 12596,
      distance: 1642000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 6.738614519637165,
      center: [-7.230306732335748, 53.90172064957187],
    },
    {
      id: 'pcUltra2022Mixed',
      title: 'Pan Celtic Ultra',
      secondaryTitle: '10th July 2022',
      latlngs: [],
      themeName: themesEnum.pcUltra2022Mixed,
      elevationProfile: true,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 6.738614519637165,
      center: [-7.230306732335748, 53.90172064957187],
    },

    {
      id: 'pcUltra2023Long',
      title: 'Pan Celtic Ultra',
      secondaryTitle: '2nd July 2023',
      latlngs: [pcUltra2023LongLatLngs],
      elevations: pcUltra2023LongElevations,
      themeName: themesEnum.pcUltra2023Full,
      elevationProfile: true,
      elevationGain: 22223,
      distance: 2293000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 6.43082391772325,
      center: [-3.814271997344406, 50.52411342189018],
    },
    {
      id: 'pcUltra2023Short',
      title: 'Pan Celtic Ultra',
      secondaryTitle: '2nd July 2023',
      latlngs: [pcUltra2023ShortLatLngs],
      elevations: pcUltra2023ShortElevations,
      themeName: themesEnum.pcUltra2023Short,
      elevationProfile: true,
      elevationGain: 16563,
      distance: 1715000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 6.510648786886495,
      center: [-3.7567597216447552, 50.71632263418434],
    },
    {
      id: 'pcUltra2023Mixed',
      title: 'Pan Celtic Ultra',
      secondaryTitle: '2nd July 2023',
      latlngs: [],
      themeName: themesEnum.pcUltra2023Mixed,
      elevationProfile: true,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 6.510648786886495,
      center: [-3.7567597216447552, 50.71632263418434],
    },

    // Great Brisish Divide
    {
      id: 'gbdivide2021',
      title: 'Great British Divide',
      secondaryTitle: '31st July 2021',
      latlngs: [],
      // elevations: [],
      themeName: themesEnum.gbdivide2021,
      elevationProfile: true,
      elevationGain: 40000,
      distance: 2000000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 5.851501881355365,
      center: [-2.9471032515945694, 54.46272545206037],
    },
    {
      id: 'gbdivide2022',
      title: 'Great British Divide',
      secondaryTitle: '30th July 2022',
      latlngs: [gbDivide2022Latlngs],
      elevations: gbDivide2022Elevations,
      themeName: themesEnum.gbdivide2022,
      elevationProfile: true,
      elevationGain: 40000,
      distance: 2000000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 5.851501881355365,
      center: [-2.9471032515945694, 54.46272545206037],
    },
    {
      id: 'gbdivide2023full',
      title: 'Great British Divide',
      secondaryTitle: '5th August 2023',
      latlngs: [gbDivide2023FullLatlngs],
      elevations: gbDivide2023FullElevations,
      themeName: themesEnum.gbdivide2023full,
      elevationProfile: true,
      elevationGain: 35000,
      distance: 2000000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 5.851501881355365,
      center: [-2.9471032515945694, 54.46272545206037],
    },
    {
      id: 'gbdivide2023short',
      title: 'Great British Divide',
      secondaryTitle: '5th August 2023',
      latlngs: [gbDivide2023ShortLatlngs],
      elevations: gbDivide2023ShortElevations,
      themeName: themesEnum.gbdivide2023short,
      elevationProfile: true,
      elevationGain: 27000,
      distance: 1400000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 5.851501881355365,
      center: [-2.9471032515945694, 54.46272545206037],
    },

    // TRIBE X MAVERICK
    {
      id: 'tribexmaverickultra2022',
      title: 'TRIBE x Maverick\\nRun Free Marathon+',
      secondaryTitle: 'Saturday 3rd September 2022',
      latlngs: [tribexmaverickultra2022latlngs],
      elevations: tribexmaverickultra2022elevations,
      themeName: themesEnum.tribeXMaverick2022Ultra,
      elevationProfile: true,
      elevationGain: 1310,
      distance: 52000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 11.998810299724877,
      center: [-0.42155504876060945, 51.208128084349994],
    },
    {
      id: 'tribexmaverickmarathon2022',
      title: 'TRIBE x Maverick\\nRun Free Marathon+',
      secondaryTitle: 'Saturday 3rd September 2022',
      latlngs: [tribexmaverickmarathon2022latlngs],
      elevations: tribexmaverickmarathon2022elevations,
      themeName: themesEnum.tribeXMaverick2022Marathon,
      elevationProfile: true,
      elevationGain: 1052,
      distance: 42000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 11.998810299724877,
      center: [-0.42155504876060945, 51.208128084349994],
    },
    {
      id: 'tribexmaverickhalf2022',
      title: 'TRIBE x Maverick\\nRun Free Marathon+',
      secondaryTitle: 'Saturday 3rd September 2022',
      latlngs: [tribexmaverickhalf2022latlngs],
      elevations: tribexmaverickhalf2022elevations,
      themeName: themesEnum.tribeXMaverick2022Half,
      elevationProfile: true,
      elevationGain: 542,
      distance: 22000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 11.998810299724877,
      center: [-0.42155504876060945, 51.208128084349994],
    },
    {
      id: 'tribexmaverickshort2022',
      title: 'TRIBE x Maverick\\nRun Free Marathon+',
      secondaryTitle: 'Saturday 3rd September 2022',
      latlngs: [tribexmaverickshort2022latlngs],
      elevations: tribexmaverickshort2022elevations,
      themeName: themesEnum.tribeXMaverick2022Short,
      elevationProfile: true,
      elevationGain: 312,
      distance: 11000,
      startNode: true,
      endNode: true,
      pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 11.998810299724877,
      center: [-0.42155504876060945, 51.208128084349994],
    },

    // STRAVA BEMC 2024
    {
      id: 'stravabemc2024light',
      // title: '',
      // secondaryTitle: 'Secondary title',
      latlngs: [],
      themeName: themesEnum.stravabemc2024light,
      // elevationProfile: true,
      // startNode: true,
      // endNode: true,
      // pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 5.35100672495237,
      center: [-4.536536001134124, 54.82063942899663],
    },
    {
      id: 'stravabemc2024dark',
      // title: '',
      // secondaryTitle: 'Secondary title',
      latlngs: [],
      themeName: themesEnum.stravabemc2024dark,
      // elevationProfile: true,
      // startNode: true,
      // endNode: true,
      // pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 5.35100672495237,
      center: [-4.536536001134124, 54.82063942899663],
    },
    {
      id: 'stravabemc2024orange',
      // title: '',
      // secondaryTitle: 'Secondary title',
      latlngs: [],
      themeName: themesEnum.stravabemc2024orange,
      // elevationProfile: true,
      // startNode: true,
      // endNode: true,
      // pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 5.35100672495237,
      center: [-4.536536001134124, 54.82063942899663],
    },
    {
      id: 'stravabemc2024berlin',
      title: 'Beautiful Berlin',
      secondaryTitle: 'Sunday, September 29th 2024',
      latlngs: [stravaBemc2024Berlinlatlngs],
      // elevations: stravaBemc2024Berlinlevations,
      themeName: themesEnum.stravabemc2024berlin,
      // elevationProfile: true,
      // startNode: true,
      // endNode: true,
      // pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 12.042579736948637,
      center: [13.35874215795107, 52.50496848750413],
    },
    {
      id: 'stravabemc2024chicago',
      title: 'Jazzing it up in Chicago',
      secondaryTitle: 'Sunday, October 13th 2024',
      latlngs: [stravaBemc2024Chicagolatlngs],
      // elevations: stravaBemc2024Chicagolevations,
      themeName: themesEnum.stravabemc2024chicago,
      // elevationProfile: true,
      // startNode: true,
      // endNode: true,
      // pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 12.211442342860323,
      center: [-87.66708440496558, 41.906499066898306],
    },
    {
      id: 'stravabemc2024newyork',
      title: "Don't mess with New York",
      secondaryTitle: 'Sunday, November 6th 2024',
      latlngs: [stravaBemc2024NewYorklatlngs],
      // elevations: stravaBemc2024NewYorklevations,
      themeName: themesEnum.stravabemc2024newyork,
      // elevationProfile: true,
      // startNode: true,
      // endNode: true,
      // pathDashed: false,
      orientation: ORIENTATION_PORTRAIT,
      zoom: 11.3811148992773,
      center: [-74.0070425731634, 40.7069743632423],
    },
  ],
})
