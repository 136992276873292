import _pullAll from 'lodash.pullall';

/**
 * thinToLength
 *
 * @param {array} arr
 * @param {number} num The number to reduce the array to
 */
export const thinToLength = (arr, num) => {
  const limit = arr.length;
  if (num > limit) return arr;
  let nthItems = [];
  // Reduce fraction decimatls to 2 points
  const step = parseFloat((arr.length / Math.min(num, arr.length)).toFixed(2));

  for (let i = 0; i < limit; i += step) {
    // i might be a fraction, so we get the nearest floored value
    const iFloored = Math.floor(i);
    nthItems.push(arr[iFloored]);
  }

  // because of fractions we might end up with more items in the array than
  // we want, and not always the last item. So we remove enough items off
  // the array to ensure the first and last elements still exist.
  nthItems = nthItems.slice(0, num - 1);
  nthItems.push(arr[limit - 1]);

  return nthItems;
};


/**
 * Returns a subset of the array taking every nth
 * item starting from the first
 * @param {array} arr
 * @param {number} nthToTake The number to take at every nth value
 */
export const takeNth = (arr, nthToTake) => {
  const nthItems = [];
  const count = arr.length;

  if (nthToTake <= 0) return [];
  if (nthToTake === 1) return arr;

  for (let i = 0; i < count; i += nthToTake) {
    nthItems.push(arr[i]);
  }

  return nthItems;
};


/**
 * Removes all falsy values from an array
 * @param {array} arr
 */
export const removeFalsy = arr => _pullAll(arr, [false, '', undefined, 0, null]);


/**
 * Does a shallow compare against two arrays. Good for checking if any
 * properties have changed from updated props
 * @param {array} a
 * @param {array} b
 * @returns {boolean}
 */
export const shallowCompare = (a = [], b = []) => {
  let i = a.length;
  if (i !== b.length) return false;
  while (i--) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};


/**
 * Returns unique items from an array
 * @param {array} arr
 */
export const unique = arr => [...new Set(arr)];
