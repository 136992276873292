import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colours, fonts } from '../../theme';
import { media } from '../../styles';

const UnstyledTitle = ({ tag, ...restProps }) => {
  const HTag = tag;
  return <HTag {...restProps} />;
};

UnstyledTitle.propTypes = {
  tag: PropTypes.string,
};
UnstyledTitle.defaultProps = {
  tag: 'h3',
};

const Title = styled(UnstyledTitle)`
  font-size: ${fonts.size04};
  line-height: ${fonts.lineHeight02};
  font-weight: ${fonts.weightNormal};
`;


/**
 * Featured Title
 * -----------------------------------------------------------------------------
 */
const FeaturedTitle = styled(Title)`
  font-size: ${fonts.size08};
  line-height: ${fonts.lineHeight01};

  ${media.footer`
    font-size: ${fonts.size04};
  `}

  ${media.nav`
    font-size: ${fonts.size03};
  `}
`;


/**
 * SectionTitle
 * -----------------------------------------------------------------------------
 */
const SectionTitle = styled(Title)`
  color: ${colours.tertiary};
  font-size: ${fonts.size09};
  font-weight: ${fonts.weightBold};
  font-family: ${fonts.family02};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2.4rem;
  letter-spacing: 0.08em;

  ${media.nav`
    font-size: ${fonts.size01};
  `}
`;


export { Title, FeaturedTitle, SectionTitle };
export default Title;
