import React from 'react';
import styled from 'styled-components';

const UnstyledIconGrid = props => <div {...props} />;

const IconGrid = styled(UnstyledIconGrid)`
  display: grid;
  grid-template-columns: repeat(${props => React.Children.count(props.children)}, minmax(0, 1fr));
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
`;

export { IconGrid };
export default IconGrid;
