import * as Constants from './constants';

export const initPrint = id => ({
  type: Constants.INIT_PRINT,
  id,
});

export const resetPrint = () => ({
  type: Constants.RESET_PRINT,
});

export const resetMapBounds = () => ({
  type: Constants.RESET_MAP_BOUNDS,
});

export const setDefaultPrintActivities = (activities, printSettings) => ({
  type: Constants.SET_DEFAULT_PRINT_ACTIVITIES,
  activities,
  printSettings,
});

export const addPrintActivities = activities => ({
  type: Constants.ADD_PRINT_ACTIVITIES,
  activities,
});

export const removePrintActivity = id => ({
  type: Constants.REMOVE_PRINT_ACTIVITY,
  id,
});

export const updatePrint = properties => ({
  type: Constants.UPDATE_PRINT,
  properties,
});

export const rebasePrint = properties => ({
  type: Constants.REBASE_PRINT,
  properties,
});

export const replacePrintWithJsonSaga = printJson => ({
  type: Constants.REPLACE_PRINT_WITH_JSON_SAGA,
  printJson,
});

export const editLabel = (id, propName, value) => ({
  type: Constants.EDIT_LABEL,
  id,
  propName,
  value,
});

export const moveLabel = (oldIndex, newIndex) => ({
  type: Constants.MOVE_LABEL,
  oldIndex,
  newIndex,
});

export const setAllPrintActivityStyles = styles => ({
  type: Constants.SET_ALL_ACTIVITY_STYLES,
  styles,
});

export const setActivityColor = (id, color) => ({
  type: Constants.SET_ACTIVITY_COLOUR,
  id,
  color,
});

export const useStravaName = () => ({
  type: Constants.USE_STRAVA_NAME,
});

export const savePrintConfig = () => ({
  type: Constants.SAVE_PRINT_CONFIG,
});

export const setPrintQuantity = (quantity = 1) => ({
  type: Constants.SET_PRINT_QUANTITY,
  quantity,
});

export const setRenderBeforeLayerId = layerId => ({
  type: Constants.SET_RENDER_BEFORE_LAYER_ID,
  layerId,
});

export const setActivityThicknesses = thickness => ({
  type: Constants.SET_ACTIVITY_THICKNESSES,
  thickness,
});

export const setActivityNodesVisible = visible => ({
  type: Constants.SET_ACTIVITY_NODES_VISIBLE,
  visible,
});

export const setActivitiesDashed = dashed => ({
  type: Constants.SET_ACTIVITIES_DASHED,
  dashed,
});

export const moveActivity = (oldIndex, newIndex) => ({
  type: Constants.MOVE_ACTIVITY,
  oldIndex,
  newIndex,
});

export const setActivitiesElevationProfiles = elevationProfiles => ({
  type: Constants.SET_ACTIVITIES_ELEVATION_PROFILES,
  elevationProfiles,
});

export const setActivityElevationProfileColor = (id, color) => ({
  type: Constants.SET_ACTIVITY_ELEVATION_PROFILE_COLOUR,
  id,
  color,
});
