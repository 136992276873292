import * as Constants from './constants'
import { initialState } from './model'
import * as dateUtils from '../../utils/dates'

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case Constants.SET_STRAVA_AUTH_TOKEN: {
      const { authToken } = action
      return state.merge({ authToken })
    }

    case Constants.SET_STRAVA_ACCESS_TOKENS: {
      const { accessToken, refreshToken, accessTokenExpires } = action
      // Strava's access token expiry timestamp is in seconds
      const readableExpiryDate = dateUtils.formatDate(accessTokenExpires * 1000)
      return state.merge({
        accessToken,
        refreshToken,
        accessTokenExpires: readableExpiryDate,
      })
    }

    case Constants.SET_STRAVA_ATHLETE_PROFILE: {
      const { athlete } = action
      return state.merge({ athlete })
    }

    case Constants.SET_SHIPPING_AND_PRODUCTS: {
      const { shipping, products } = action
      return state.merge({ shipping, products })
    }

    case Constants.SET_PRODUCTS: {
      const { products } = action
      return state.merge({ products })
    }

    case Constants.ACCEPT_COOKIES: {
      return state.merge({ showCookiePolicy: false })
    }

    case Constants.SET_HAS_TOUCH: {
      const { hasTouch } = action
      return state.merge({ hasTouch: !!hasTouch })
    }

    case Constants.SET_COUPON_CHECK_IN_PROGRESS: {
      const { inProgress } = action
      return state.set('couponCheckInProgress', !!inProgress)
    }

    case Constants.SET_COUPON_ID: {
      const { couponId } = action
      return state.set('couponId', couponId)
    }

    default: {
      return state
    }
  }
}

export default reducers
