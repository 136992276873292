import { combineReducers } from 'redux-seamless-immutable';
import { connectRouter } from 'connected-react-router';
import { uiStateReducer } from 'react-redux-ui-state';

import withSessionReducer from './hocs/WithSession/reducers';
import { SESSION_DOMAIN } from './hocs/WithSession/constants';

import withPrintReducer from './hocs/WithPrint/reducers';
import { PRINT_DOMAIN } from './hocs/WithPrint/constants';

import withActivitiesReducer from './hocs/WithActivities/reducers';
import { ACTIVITIES_DOMAIN } from './hocs/WithActivities/constants';

import withThemesReducer from './hocs/WithThemes/reducers';
import { THEMES_DOMAIN } from './hocs/WithThemes/constants';

import withToasterReducer from './hocs/WithToaster/reducers';
import { TOASTER_DOMAIN } from './hocs/WithToaster/constants';

import withStripeReducer from './hocs/WithStripe/reducers';
import { STRIPE_DOMAIN } from './hocs/WithStripe/constants';

import withOrdersReducer from './hocs/WithOrders/reducers';
import { ORDERS_DOMAIN } from './hocs/WithOrders/constants';

import withEventsReducer from './hocs/WithEvents/reducers';
import { EVENTS_DOMAIN } from './hocs/WithEvents/constants';

export default history => combineReducers({
  [PRINT_DOMAIN]: withPrintReducer,
  [SESSION_DOMAIN]: withSessionReducer,
  [ACTIVITIES_DOMAIN]: withActivitiesReducer,
  [THEMES_DOMAIN]: withThemesReducer,
  [TOASTER_DOMAIN]: withToasterReducer,
  [STRIPE_DOMAIN]: withStripeReducer,
  [ORDERS_DOMAIN]: withOrdersReducer,
  [EVENTS_DOMAIN]: withEventsReducer,
  router: connectRouter(history),
  uiState: uiStateReducer,
});
