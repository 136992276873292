/* istanbul ignore file */
import mapbox from 'mapbox-gl/dist/mapbox-gl-csp';

import { flipLatlngs, getOrientaion } from './maps';


/**
 * Returns the Mapbox bounds from an array of coordinates
 * Mapbox follows GeoJSON format and only accepts lng/lat format
 * @param {array} points array of lng/lat points
 * @example
 *  latlngs = [[10, 20], [30, 40]];
 * @returns {LngLatBounds} mapbox LngLatBounds
 */
export const mapboxReducePathToBounds = (points = []) => {
  if (!points || !points.length) return undefined;

  const firstLngLat = [...points[0]];
  const secondLngLat = [...points[1]];

  if (!firstLngLat || !secondLngLat) return undefined;

  const initBounds = new mapbox.LngLatBounds(firstLngLat, secondLngLat);

  return [...points].reduce((bounds, coord) =>
    bounds.extend(coord), initBounds);
};


/**
 * Returns the Mapbox bounds from multiple paths/routes
 * Mapbox follows GeoJSON format and only accepts lng/lat format
 * @param {array} paths array of paths containing lat/lng coords
 * @param {object} options
 * @example
 *   paths = [
 *     [[1, 2], [3, 4]], // path 1 with lat/lng coords
 *     [[5, 6], [7, 8]], // path 2 with lat/lng coords
 *   ]
 * @returns {LngLatBounds}
 */
export const mapboxReduceMultiplePathsToBounds = (paths = []) => {
  let bounds;
  const multipleBounds = paths
    .map(pathPoints => mapboxReducePathToBounds(pathPoints))
    .filter(Boolean);

  if (!multipleBounds.length) return undefined;
  for (let i = 0; i < multipleBounds.length; i++) {
    bounds = bounds || multipleBounds[i];
    bounds.extend(multipleBounds[i]);
  }
  return bounds;
};


/**
 * Returns the orientation of the Mapbox bounds.
 * For some reason bounds.toArray() returns lat/lng, not lng/lat as Mapbox
 * usually requires
 * @param {LngLatBounds} bounds
 * @returns {string}
 */
export const mapboxGetOrientationFromBounds = bounds =>
  getOrientaion(...flipLatlngs(bounds.toArray()));


/**
 * Calculates the bounds of all activities and returns the orientation
 * @param {array} activities
 * @return {string}
 */
export const detectOrientationFromActivites = (activities = []) => {
  const paths = [...activities].map(a => flipLatlngs(a.latlngs));
  const bounds = mapboxReduceMultiplePathsToBounds(paths);
  return mapboxGetOrientationFromBounds(bounds);
};


/**
 * Appends a query to optimise the style tiles hosted on Mapbox
 * https://docs.mapbox.com/help/glossary/style-optimized-vector-tiles
 *
 * @param {string} styleString
 */
export const optimiseMapboxTiles = (styleString = '') =>
  `${styleString}?optimize=true`;
