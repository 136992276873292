import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import ReactSelect from 'react-select';

import { uuid } from '../../utils/uuid';

import { colours, fonts, transitions } from '../../theme';
import { zIndexes } from '../../styles';
import { UploadButton } from '../Button';
import { SmallText } from '../Text';
import Icon from '../Icon';


/**
 * Input
 * -----------------------------------------------------------------------------
 */
const UnstyledInput = ({ className, invalid, placeholder, ...restProps }) => {
  const placeholderTxt = restProps.required ? `${placeholder} *` : placeholder;
  return (
    <div className={classnames(className, { 'is-invalid': invalid })}>
      <input className="input" placeholder={placeholderTxt} {...restProps} />
    </div>
  );
};

UnstyledInput.propTypes = {
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
};
UnstyledInput.defaultProps = {
  placeholder: undefined,
  required: false,
  invalid: false,
};

const Input = styled(UnstyledInput)`

  position: relative;

  input {
    ::-webkit-input-placeholder { color: ${colours.primary}; transition: ${transitions.inputDuration} ${transitions.inputEasing}; }
    ::-moz-placeholder { color: ${colours.primary}; transition: ${transitions.inputDuration} ${transitions.inputEasing}; }
    :-ms-input-placeholder { color: ${colours.primary}; transition: ${transitions.inputDuration} ${transitions.inputEasing}; }
    :-moz-placeholder { color: ${colours.primary}; transition: ${transitions.inputDuration} ${transitions.inputEasing}; }

    &[type="number"] {
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        opacity: 1;
      }
    }

    background: transparent;
    padding: 0;
    display: block;
    width: 100%;
    height: 4.4rem;

    font-size: ${fonts.size02};
    color: ${colours.primary};

    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px dotted ${colours.grey04};

    transition: ${transitions.buttonDuration} ${transitions.buttonEasing};

    &:hover {
      border-bottom-style: solid;
      border-bottom-color: ${colours.primary};
    }

    &:focus {
      outline: none;
      color: ${colours.secondary};
      border-bottom-style: solid;
      border-bottom-color: ${colours.primary};

      ::-webkit-input-placeholder { color: ${colours.grey04}; padding-left: 6px; }
      ::-moz-placeholder { color: ${colours.grey04}; padding-left: 6px; }
      :-ms-input-placeholder { color: ${colours.grey04}; padding-left: 6px; }
      :-moz-placeholder { color: ${colours.grey04}; padding-left: 6px; }
    }
  }

  &.is-invalid {
    input {
      color: ${colours.white};
      background-color: ${colours.secondary};
      border-bottom-color: ${colours.secondary};
      border-bottom-style: solid;
      border-radius: 3px;

      ::-webkit-input-placeholder { color: ${colours.white}; }
      ::-moz-placeholder { color: ${colours.white}; }
      :-ms-input-placeholder { color: ${colours.white}; }
      :-moz-placeholder { color: ${colours.white}; }
    }
  }

`;


/**
 * Input Label
 * -----------------------------------------------------------------------------
 */
const UnstyledInputLabel = props => (
  <label {...props} />
);

UnstyledInputLabel.propTypes = {};
UnstyledInputLabel.defaultProps = {};

const InputLabel = styled(UnstyledInputLabel)`
  font-family: ${fonts.family01};
  font-size: ${fonts.size02};
  font-weight: inherit;
  cursor: pointer;

  &:hover {
    color: ${colours.primary};
  }

  &:empty {
    display: none;
  }
`;


/**
 * InputRow
 * -----------------------------------------------------------------------------
 *
 */
const UnstyledInputRow = props => <div {...props} />;
UnstyledInputRow.propTypes = {};
UnstyledInputRow.defaultProps = {};

const InputRow = styled(UnstyledInputRow)`
  display: flex;
  flex-direction: row;
  align-items: center;

  > ${Input},
  > ${InputLabel} {
    flex: 1.5 0 0;

    &:first-child {
      flex: 1 0 0;
      margin-right: 2.5rem;
    }

    &:only-child {
      flex: 1 0 0;
      margin-right: 0;
    }
  }
`;


/**
 * File Input
 * -----------------------------------------------------------------------------
 *
 */
class UnstyledFileInput extends React.PureComponent {
  onClick = () => {
    this.fileInput.click();
  }

  fileInputRef = ref => this.fileInput = ref

  render() {
    const { className, children, ...restProps } = this.props;
    const classNames = classnames(className);
    return (
      <UploadButton onClick={this.onClick} className={classNames}>
        {children}
        <input ref={this.fileInputRef} type="file" {...restProps} />
      </UploadButton>
    );
  }
}

const FileInput = styled(UnstyledFileInput)`
  position: relative;

  [type="file"] {
    visibility: hidden;
    position: absolute;
    z-index: ${zIndexes.behind};
    width: 100%;
  }

  .isvg {
    width: 15px;
    height: 17px;
  }
`;


/**
 * Checkbox
 * -----------------------------------------------------------------------------
 *
 */
const UnstyledCheckbox = ({ className, description, label, button, title, id = uuid(10), ...props }) => (
  <div className={className} title={title}>
    <input id={id} className="checkbox" type="checkbox" {...props} />
    {button}
    {label && <InputLabel htmlFor={id}>
      {label}
      {description && <SmallText light>{description}</SmallText>}
    </InputLabel>}
  </div>
);

UnstyledCheckbox.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.node,
};
UnstyledCheckbox.defaultProps = {
  id: undefined,
  title: undefined,
  label: undefined,
  description: undefined,
  button: <span className="button" />,
};

const Checkbox = styled(UnstyledCheckbox)`
  position: relative;
  display: ${props => props.label ? 'flex' : 'inline-block'};
  align-items: center;
  font-size: ${fonts.size02};

  .checkbox,
  .button {
    width: 2.2rem;
    height: 2.2rem;
    flex-shrink: 0;
    margin-top: ${p => p.description ? `0.2em` : '0'};
    align-self: ${p => p.description ? `flex-start` : 'center'};
  }

  .checkbox {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    z-index: ${zIndexes.raised};
  }
  .button {
    transform: scale(1);
    transform-origin: 50% 50%;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    color: ${colours.primary};
    border: 2px solid currentColor;
    background-color: transparent;
    transition: ${transitions.buttonDuration} ${transitions.buttonEasing};
  }

  &:hover .button,
  .checkbox:focus + .button {
    transform: scale(0.9);
    transform-origin: 50% 50%;
  }

  .checkbox:focus ~ ${InputLabel} {
    color: ${colours.primary};
  }

  .checkbox:checked + .button {
    background-color: currentColor;
  }

  ${InputLabel} {
    margin-left: 1.4rem;

    &:empty {
      display: none;
    }
  }

  ${SmallText} {
    margin-top: 0.4em;
    &:before {
      content: '✽';
      margin-right: 0.4em;
      margin-left: 0.1em;
    }
  }
`;


/**
 * Select
 * -----------------------------------------------------------------------------
 *
 */
const SelectArrow = styled(props => <Icon {...props} icon="arrowDown" />)``;

// eslint-disable-next-line react/no-multi-comp
class UnstyledSelect extends React.PureComponent {
  state = {
    isOpen: false,
  };

  onMenuOpen = () => {
    this.setState({ isOpen: true });
  }

  onMenuClose = () => {
    this.setState({ isOpen: false });
  }

  get placeholder() {
    return this.props.required ? `${this.props.placeholder} *` : this.props.placeholder;
  }

  get reactSelectClassNames() {
    return classnames('select', {
      'is-open': this.state.isOpen,
    });
  }

  render() {
    const { className, invalid, placeholder, ...restProps } = this.props;
    const classNames = classnames(className, { 'is-invalid': invalid });
    return (
      <div className={classNames}>
        <ReactSelect
          isSearchable
          escapeClearsValue
          {...restProps}
          className={this.reactSelectClassNames}
          classNamePrefix="select"
          placeholder={this.placeholder}
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          components={{
            DropdownIndicator: SelectArrow,
          }}
        />
      </div>
    );
  }
}

UnstyledSelect.propTypes = UnstyledInput.propTypes;

UnstyledSelect.defaultProps = UnstyledInput.defaultProps;

const Select = styled(UnstyledSelect)`
  display: block;
  width: 100%;

  .select {
    font-size: ${fonts.size02};
    font-family: ${fonts.family01};
  }

  [aria-live] {
    border: 0;
  }

  .select__control {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px dotted ${colours.grey04};
    border-radius: 0;
    cursor: pointer;
    color: ${colours.primary};
    background-color: transparent;
    min-height: 4.4rem;

    transition: ${transitions.linkDuration} ${transitions.linkEasing};

    &:hover {
      border-bottom-color: ${colours.primary};
      border-bottom-style: solid;
    }
  }

  .select__placeholder {
    transition: ${transitions.inputDuration} ${transitions.inputEasing};
    padding-left: 0;
  }

  .select__single-value,
  .select__placeholder {
    margin: 0;
    color: inherit;
  }

  .select__single-value {
    color: ${colours.primary};
  }

  .select__value-container {
    padding: 0;
    color: inherit;

  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {}
  ${SelectArrow} {
    color: ${colours.primary};
    transition: 0.2s ${transitions.inputEasing};
    transform: rotateZ(0);
    width: 12px;
    height: 8px;
  }

  .select.is-open {

    ${SelectArrow} {
      transform: rotateZ(-180deg);
    }
  }

  .select__control--is-focused {
    box-shadow: none;
    border-bottom-style: solid;
    border-bottom-color: ${colours.primary} !important;
    color: ${colours.secondary};

    .select__placeholder {
      padding-left: 6px;
      color: ${colours.grey04};
    }
  }

  .select__menu {
    border-radius: 0;
    border-color: transparent;
  }

  .select__menu-list {
    padding: 0;
  }

  .select__option {
    cursor: pointer;
    font-size: ${fonts.size02};
    font-family: ${fonts.family01};

    transition: ${transitions.inputDuration} ${transitions.inputEasing};

    &:hover,
    &--is-focused {
      background-color: ${colours.grey01} !important;
      color: ${colours.primary} !important;
    }

    &--is-selected {
      background-color: ${colours.primary} !important;
      color: ${colours.white} !important;
    }
  }

  &.is-invalid {
    .select__control {
      color: ${colours.white};
      border-radius: 3px;
      background-color: ${colours.secondary};
      border-bottom-color: ${colours.secondary};
      border-bottom-style: solid;
    }

    ${SelectArrow} {
      color: ${colours.white};
    }

    .select__single-value,
    .select__placeholder {

    }
  }
`;


export { UnstyledInput, Input, InputLabel, FileInput, UnstyledFileInput,
  UnstyledInputRow, InputRow, Checkbox, Select, UnstyledSelect };
export default Input;
