import { css } from 'styled-components';

import { PrintTitle, PrintSecondaryTitle } from '../../../../components/PrintTitle';
import { PrintLabelTitle, PrintLabelValue, PrintLabel } from '../../../../components/PrintLabel';
import { loadGoogleFonts } from '../../../../utils/fonts';
import { LogoCredits, PoweredByStrava } from '../../../../components/Logo';
import { colours } from '../../../../theme';

export default (theme) => {
  const loadedGoogleFonts = loadGoogleFonts(['Playfair Display:900i', 'Laila', 'Laila:600']);

  return css`

    ${PrintTitle} {
      font-size: 4rem;
      letter-spacing: 1.6px;
      ${loadedGoogleFonts[0].styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 1.8rem;
      ${loadedGoogleFonts[2].styles};
    }

    ${PrintLabelValue} {
      font-size: 2rem;
      ${loadedGoogleFonts[2].styles};
    }

    ${PrintLabelTitle} {
      font-size: 1.4rem;
      ${loadedGoogleFonts[1].styles};
    }

    ${LogoCredits},
    ${PoweredByStrava} {
      color: ${colours.white};
      opacity: 0.6;
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: .3;
      }
    }
  `;
};
