import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import { colours, fonts, dimensions, transitions } from '../../theme';
import { zIndexes } from '../../styles';
import Link from '../Link';
import { Text } from '../Text';
import { PlainButton } from '../Button';
import { Badge } from '../Badge';
import { Icon } from '../Icon';

export const ThemePickerButton = styled(PlainButton)``;
export const ThemePickerBadge = styled(Badge)``;
export const ThemePickerLabel = styled(Text)``;

const UnstyledThemePicker = ({ className, active, image, label, ...props }) => (
  <div className={classnames(className, { active })}>
    <ThemePickerButton {...props}>
      {active && (
        <ThemePickerBadge>
          <Icon icon="tick" />
        </ThemePickerBadge>
      )}
      <div className="image">
        <img src={image} alt={label} />
      </div>
    </ThemePickerButton>
    {label && <ThemePickerLabel>{label}</ThemePickerLabel>}
  </div>
);

UnstyledThemePicker.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string, // eslint-disable-line
  image: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
UnstyledThemePicker.defaultProps = {
  label: undefined,
  color: undefined,
  image: undefined,
  active: false,
  onClick: () => {},
};

const ThemePicker = styled(UnstyledThemePicker)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;

  ${ThemePickerButton} {
    background-color: ${colours.grey04};
    border-radius: ${dimensions.borderRadius};
    height: 100%;
    width: 100%;
    max-width: 8rem;
    max-height: 8rem;
    display: block;
    position: relative;
  }

  &.active,
  ${ThemePickerButton}:hover {
    .image {
      transform: scale(0.7);
    }
  }

  .image {
    height: 100%;
    width: 100%;
    border-radius: ${dimensions.borderRadius};
    overflow: hidden;

    transform: scale(1.05);
    transition: ${transitions.colorButtonDuration} ${transitions.colorButtonEasing} transform;

    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  ${ThemePickerLabel} {
    margin-top: 1.2rem;
    font-size: ${fonts.size06};
    margin-bottom: 0;
    color: ${props => props.color};
    line-height: 1.2;
  }

  ${ThemePickerBadge} {
    position: absolute;
    top: 0;
    right: 0;
    z-indeX: ${zIndexes.raised};
    transform: translateX(50%) translateY(-50%);
  }
`;

const ThemesEventLink = styled(Link)`
  font-size: ${fonts.size02};
  color: ${colours.primary};

  &:hover {
    color: ${colours.secondary};
  }
`;

const ThemeGrid = styled.div`
  display: grid;
  grid-gap: 3.3rem;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
`;

const ThemesGroup = styled.section`
  margin-bottom: 6rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${ThemesEventLink} {
    display: inline-block;
    margin-bottom: 2.8rem;
  }
`;

export { ThemesEventLink, ThemesGroup, ThemeGrid, ThemePicker, UnstyledThemePicker };
export default ThemePicker;
