export const PRINT_DOMAIN = 'print';

export const MAX_LABEL_COUNT = 6;

export const PRINTS_API_ENDPOINT = `${process.env.REACT_APP_API_DOMAIN}/prints`;
export const SAVE_PRINT_CONFIG = 'SAVE_PRINT_CONFIG';

export const INIT_PRINT = 'INIT_PRINT';
export const RESET_PRINT = 'RESET_PRINT';
export const RESET_MAP_BOUNDS = 'RESET_MAP_BOUNDS';
export const UPDATE_PRINT = 'UPDATE_PRINT';
export const REBASE_PRINT = 'REBASE_PRINT';
export const REPLACE_PRINT_WITH_JSON_SAGA = 'REPLACE_PRINT_WITH_JSON_SAGA';
export const EDIT_LABEL = 'EDIT_LABEL';
export const MOVE_LABEL = 'MOVE_LABEL';
export const USE_STRAVA_NAME = 'USE_STRAVA_NAME';
export const SET_PRINT_QUANTITY = 'SET_PRINT_QUANTITY';
export const SET_RENDER_BEFORE_LAYER_ID = 'SET_RENDER_BEFORE_LAYER_ID';

export const ADD_PRINT_ACTIVITIES = 'ADD_PRINT_ACTIVITIES';
export const SET_DEFAULT_PRINT_ACTIVITIES = 'SET_DEFAULT_PRINT_ACTIVITIES';
export const REMOVE_PRINT_ACTIVITY = 'REMOVE_PRINT_ACTIVITY';
export const SET_ALL_ACTIVITY_STYLES = 'SET_ALL_ACTIVITY_STYLES';
export const SET_ACTIVITY_COLOUR = 'SET_ACTIVITY_COLOUR';
export const SET_ACTIVITY_THICKNESSES = 'SET_ACTIVITY_THICKNESSES';
export const SET_ACTIVITY_NODES_VISIBLE = 'SET_ACTIVITY_NODES_VISIBLE';
export const SET_ACTIVITIES_DASHED = 'SET_ACTIVITIES_DASHED';
export const MOVE_ACTIVITY = 'MOVE_ACTIVITY';
export const SET_ACTIVITIES_ELEVATION_PROFILES = 'SET_ACTIVITIES_ELEVATION_PROFILES';
export const SET_ACTIVITY_ELEVATION_PROFILE_COLOUR = 'SET_ACTIVITY_ELEVATION_PROFILE_COLOUR';

export const ORIENTATION_PORTRAIT = 'portrait';
export const ORIENTATION_LANDSCAPE = 'landscape';

export const LAYOUT_BOTTOM_HORIZONTAL = 'bottom-horizontal';
export const LAYOUT_BOTTOM_VERTICAL = 'bottom-vertical';
export const LAYOUT_TOP_HORIZONTAL = 'top-horizontal';
export const LAYOUT_TOP_VERTICAL = 'top-vertical';
