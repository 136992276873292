import React from 'react';
import styled, { css } from 'styled-components';
import Svg from 'react-inlinesvg';

import { colours } from '../../theme';
import { zIndexes, media } from '../../styles';

import featureBackgroundImg from './background-feature.jpg';
import elevationProfileImg from './elevation-profile.svg';
import topo1 from './topo-1.svg';
import topo2 from './topo-2.svg';

const topos = [
  topo1,
  topo2,
];

export const ElevationProfileSvg = styled(props => <Svg {...props} src={elevationProfileImg} />)``;

const animationStyles = css`
  position: absolute;
  height: 95%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${zIndexes.zero};
  background-color: ${colours.secondaryDark};
  background-repeat: no-repeat;
  background-size: 100%, cover;
  background-position: center center;
  background-image:
    linear-gradient(to bottom, ${colours.secondaryDark}, transparent 30%),
    url(${featureBackgroundImg});

  @keyframes routes {
    94% { stroke-dashoffset: 0; stroke-opacity: 1; }
    100% { stroke-dashoffset: 0; stroke-opacity: 0; }
  }

  @keyframes last-route {
    to { stroke-dashoffset: 0; }
  }

  ${ElevationProfileSvg} {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    color: ${colours.white};
    max-height: 18rem;

    svg {
      height: 100%;
      display: block;
      width: 100%;
    }
  }
`;

const FeatureBackground = styled(props => (
  <div {...props}>
    <ElevationProfileSvg />
  </div>
))`
  ${animationStyles}
`;

const TopoBackground = styled.div`
  background: transparent no-repeat center top;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.behind};
  pointer-events: none;
  background-image: url(${props => topos[props.topo - 1]});

  ${media.create`
    left: 50%;
    transform: translateX(-50%);
    min-width: 130vw;
  `}
  ${media.footer`
    min-width: 150vw;
  `}
  ${media.nav`
    min-width: 115rem;
  `}
`;

TopoBackground.defaultProps = {
  topo: 1,
};

export { FeatureBackground, TopoBackground };
