import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import axios from 'axios'
import _get from 'lodash.get'
import _omit from 'lodash.omit'
import Helmet from 'react-helmet'

import { dimensions } from '../../theme'
import { matchPropTypes } from '../../proptypes'
import WithThemes from '../../hocs/WithThemes'
import {
  activitySourceFromStrava,
  printHasDetails,
  printHasElevationProfileEnabled,
} from '../../utils/print'
import { ELEVATION_PROFILE_HEIGHT } from '../../utils/sizes'
import { GENERATE_UUID } from '../../routes'
import Print from '../../components/Print'
import { PrintDetails } from '../../components/PrintDetails'
import { MapboxMap } from '../../components/MapboxMap'
import ElevationProfile from '../../components/ElevationProfile'

class Generator extends React.Component {
  static propTypes = {
    themes: PropTypes.object.isRequired, // eslint-disable-line
    match: matchPropTypes.isRequired,
  }

  constructor(props) {
    super(props)

    window.__timeLoadedStart = Date.now()

    this.state = { print: undefined }

    // Injecting large javascript payloads into the window doesn't garantuee it'll
    // be available at this point - larger the content the slower the injection
    // in pupeteer injects async. Should fix this by maybe injectying a small
    // window var to indicate we're waiting for a payload
    const expectingPrintJson =
      window.location.search.match(/waitForPrintJson/gi)
    if (expectingPrintJson) {
      this.waitForPrintJson()
    } else {
      this.getPrint(this.props.match.params[GENERATE_UUID])
    }
  }

  onMapIdle = () => {
    const loadedDiv = document.createElement('div')
    loadedDiv.id = 'loaded'
    document.body.appendChild(loadedDiv)
    this.logTime('Map loaded')
  }

  hasPrintJson() {
    return !!window.__PRINT_JSON && !!window.__PRINT_JSON.id
  }

  waitForPrintJson() {
    setTimeout(() => {
      if (this.hasPrintJson()) {
        this.setState({
          print: this.supportLegacyFeatures(window.__PRINT_JSON),
        })
      } else {
        this.waitForPrintJson()
      }
    }, 100)
  }

  // When new features are added we can set defaults for existing
  // prints to default to
  supportLegacyFeatures(print) {
    print.padding = _get(print, 'padding', true)
    print.porthole = _get(print, 'porthole', false)
    return print
  }

  hasDetails(print) {
    const { title, secondaryTitle, labels } = print
    return printHasDetails(title, secondaryTitle, labels)
  }

  hasElevationProfile(print) {
    const { activities } = print
    return printHasElevationProfileEnabled(activities)
  }

  hasSourceFromStrava(print) {
    return activitySourceFromStrava(print.activities)
  }

  filterNonDefaultLabels(label = {}) {
    const hasDefaultValues = new RegExp(/(label|value)\s*\d*/, 'gi')
    return (
      !hasDefaultValues.test(label.title) && !hasDefaultValues.test(label.value)
    )
  }

  logTime(label = 'Time') {
    if (!window.__VERBOSE) return
    const timeDiff = (Date.now() - window.__timeLoadedStart) / 1000
    console.log(`${label}: ${timeDiff.toFixed(2)}s`)
  }

  getPrint(printId) {
    axios
      .get(`${this.apiEndpoint}/${printId}`)
      .then((resp) => {
        const { status, print, message } = resp.data
        if (status === 200 && print) {
          this.logTime('Fetch print')
          this.setState({
            print: this.supportLegacyFeatures(print),
          })
        } else {
          console.info('API error:', status, ',', message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  getMapStyles(themeName) {
    const availableThemes = this.props.themes
    return _get(availableThemes, [themeName, 'mapStyles'], {})
  }

  getPrintStyles() {
    const { print } = this.state
    return {
      themeName: print.themeName,
      primaryColor: print.primaryColor,
      secondaryColor: print.secondaryColor,
      backgroundColor: print.backgroundColor,
    }
  }

  getRenderBeforeLayerId(print) {
    const { renderBeforeLayerId, themeName } = print
    const mapStyles = this.getMapStyles(themeName)
    const layers = mapStyles.layers || []
    return (
      layers
        .map((styleLayer) => styleLayer.id)
        .find((layerId) => layerId === renderBeforeLayerId) || undefined
    )
  }

  // We can force which API to query the print ID from,
  // otherwise use the default local API URL
  get apiEndpoint() {
    const apiDomain = window.__API_DOMAIN
      ? window.__API_DOMAIN
      : process.env.REACT_APP_API_DOMAIN
    return `${apiDomain}/prints`
  }

  renderElevationProfile() {
    const { print } = this.state
    const hasElevationProfile = this.hasElevationProfile(print)

    if (!hasElevationProfile) return null

    const activitiesWithLatlngs = print.activities.map((activity) =>
      _omit(activity, ['latlngs', 'polyline']),
    )

    return (
      <ElevationProfile
        activities={activitiesWithLatlngs}
        height={ELEVATION_PROFILE_HEIGHT}
        // TODO: could width be more reusable?
        width={
          window.innerWidth - (print.padding ? dimensions.printPaddingPx : 0)
        }
      />
    )
  }

  renderPrintDetails() {
    const { print } = this.state
    const labelsWithoutDefaultValues = print.labels.filter(
      this.filterNonDefaultLabels,
    )
    return (
      <PrintDetails
        title={print.title}
        subtitle={print.secondaryTitle}
        labels={labelsWithoutDefaultValues}
        layout={print.layout}
        orientation={print.orientation}
      />
    )
  }

  renderMap() {
    const { print } = this.state
    this.logTime('Map render')
    return (
      <MapboxMap
        zoom={print.zoom}
        interactive={false}
        center={print.center}
        layout={print.layout}
        product={print.product}
        rotation={print.rotation}
        activities={print.activities}
        orientation={print.orientation}
        styles={this.getMapStyles(print.themeName)}
        renderBeforeLayerId={this.getRenderBeforeLayerId(print)}
        onMapIdle={this.onMapIdle}
      />
    )
  }

  render() {
    if (!this.state.print) return null
    const { print } = this.state
    return (
      <React.Fragment>
        <Helmet>
          <title>Generator</title>
          <meta name="api-domain" content={window.__API_DOMAIN} />
          <meta name="node-env" content={process.env.REACT_APP_ENV} />
        </Helmet>
        <Print
          style={{ width: '100vw', height: '100vh' }}
          layout={print.layout}
          theme={this.getPrintStyles()}
          mapStyles={this.getMapStyles(print.themeName)}
          orientation={print.orientation}
          padding={print.padding}
          porthole={print.porthole}
          hasDetails={this.hasDetails(print)}
          hasElevationProfile={this.hasElevationProfile(print)}
          strava={this.hasSourceFromStrava(print)}
        >
          {this.renderMap()}
          {this.renderElevationProfile()}
          {this.renderPrintDetails()}
        </Print>
      </React.Fragment>
    )
  }
}

const withThemes = WithThemes()

export default compose(withThemes)(Generator)
