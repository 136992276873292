import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Title } from '../Title';
import { Text } from '../Text';
import { colours, fonts } from '../../theme';

const UnstyledThemeCard = (props) => {
  const classNames = props.className;
  return (
    <figure className={classNames} data-img={props.img}>
      <img src={props.img} alt={props.title} />
    </figure>
  );
};

UnstyledThemeCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  orientation: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
};

UnstyledThemeCard.defaultProps = {
  img: undefined,
  title: undefined,
  orientation: 'portrait',
};

const ThemeCard = styled(UnstyledThemeCard)`
  margin: 0;

  img {
    transform: scale(1.01);
    display: block;
    border: 1px solid ${colours.grey09};
  }
`;

const ThemeCardTitle = styled(Title)`

`;

const ThemeCardSubtitle = styled(Text)`
  font-weight: ${fonts.weightMedium};
`;

const ThemeCardFooter = styled.footer`

`;

export { ThemeCard, UnstyledThemeCard, ThemeCardTitle, ThemeCardSubtitle, ThemeCardFooter };
export default ThemeCard;
