import { ORIENTATION_LANDSCAPE, ORIENTATION_PORTRAIT } from '../../../hocs/WithPrint/constants';

import logo from './gg-logo-alt.svg';
import badge from './gg-badge.png';
import metaImage from './gg-og-banner.jpg';

import ggDorset from './event-thumbs/events-gg-thumb-1-dorset.jpg';
import ggPeakDist from './event-thumbs/events-gg-thumb-2-peak.jpg';
import ggSherwood from './event-thumbs/events-gg-thumb-3-sherwood.jpg';
import ggSuffolk from './event-thumbs/events-gg-thumb-4-suffolk.jpg';
import ggNorthWales from './event-thumbs/events-gg-thumb-5-northwales.jpg';

export const themes = [{
  themeName: 'ggdorsetgravelx',
  image: ggDorset,
  orientation: ORIENTATION_LANDSCAPE,
  title: 'Dorset Gravel X',
  subtitle: '9th April 2022',
}, {
  themeName: 'ggpeakdistrictgravelx',
  image: ggPeakDist,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Peak District Gravel X',
  subtitle: '9th April 2022',
}, {
  themeName: 'ggsherwoodgravelx',
  image: ggSherwood,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Sherwood Forest Gravel X',
  subtitle: '16th April 2022',
}, {
  themeName: 'ggsuffolkgravelx',
  image: ggSuffolk,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Suffolk Gravel X',
  subtitle: '23rd April 2022',
}, {
  themeName: 'ggnorthwalesgravelx',
  image: ggNorthWales,
  orientation: ORIENTATION_LANDSCAPE,
  title: 'North Wales Gravel X',
  subtitle: '30th April 2022',
},
];

const themeNames = themes.map(theme => theme.themeName);

export default {
  logo,
  badge,
  uri: 'gloriousgravel',
  link: 'https://gloriousgravel.com/',
  linkText: 'Visit gloriousgravel.com',
  blurb: `Ride with Glorious Gravel off the beaten track, through forests, old fire road and gravel tracks into the wild. Leave the city life behind and explore the great outdoors of the UK & beyond. <br /><br />Single day rides & multi day epics await&hellip;`,
  title: 'Glorious Gravel',
  description: 'Select your event to personalise your print',
  logoMaxWidth: '59rem',
  metaTitle: 'Glorious Gravel Custom Prints',
  metaDescription: 'Turn your Glorious Gravel adventure into a work of art',
  metaImage,
  themes,
  themeNames,
};
