import { initialState } from './model';
import * as Constants from './constants';

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case Constants.SET_ORDER_IN_PROGRESS: {
      const { inProgress } = action;
      return state.set('orderInProgress', !!inProgress);
    }

    case Constants.SET_STRIPE_ACTION_REQUIRED: {
      const { stripeActionRequired, intentSecret, orderId } = action;
      return state.merge({
        stripeActionRequired,
        intentSecret,
        orderId,
      });
    }

    default: {
      return state;
    }
  }
};

export default reducers;
