import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import styled from 'styled-components'
import _get from 'lodash.get'
import Helmet from 'react-helmet'

import { colours, dimensions, fonts } from '../../theme'
import { media, zIndexes } from '../../styles'
import * as routes from '../../routes'

import WithSession from '../../hocs/WithSession'
import { LATLNGS_LIMIT_COUNT as maxAllowedPoints } from '../../hocs/WithActivities/constants'

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SiteHeader from '../../containers/SiteHeader'
import SiteContainer from '../../components/SiteContainer'

import { Link, LinkAsPrimaryButton } from '../../components/Link'
import { Title, FeaturedTitle, SectionTitle } from '../../components/Title'
import { Text } from '../../components/Text'
import { QuestionAnswer } from '../../components/QuestionAnswer'
import { TopoBackground } from '../../components/Background'
import Emoji from '../../components/Emoji'

const CreateTitle = styled(Title)``
const CreateLink = styled(LinkAsPrimaryButton)``

const UnstyledFaq = (props) => {
  const countriesCount = props.shippingCountries.length
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <Helmet>
        <title>Faq</title>
      </Helmet>
      <div className={props.className}>
        <TopoBackground />
        <TopoBackground topo={2} />
        <SiteHeader />
        <SiteContainer>
          <header>
            <FeaturedTitle tag="h1">Frequently Asked Questions</FeaturedTitle>
          </header>
          <ul>
            <QuestionAnswer
              id="where-is-my-digital-print"
              question="My digital print hasn't arrived"
              answer="We double check every order for any obvious errors before sending them out, so please allow up to 24 hours for your download email to arrive. If you need it urgently please email us."
            />
            <QuestionAnswer
              id="delivery"
              question="Where is my physical print?"
              answer={
                <React.Fragment>
                  <Text as="span">
                    It can take between 48 - 72 hours of lead time before prints
                    can shipped which is due to processing and printing time at
                    various locations worldwide. Delivery times after that
                    depend on country&hellip; see{' '}
                    <Link to="#shipping-locations">estimated times below</Link>.
                  </Text>
                  <br />
                  <br />
                  <Text light as="span">
                    <Emoji emoji="pointingRight" /> Please note: shipping times
                    exclude customs delays as these are unfortunately out of our
                    control.
                  </Text>
                </React.Fragment>
              }
            />
            <QuestionAnswer
              question="Do you offer tracking?"
              answer="We have tracking for the majority of orders which are sent out via email once dispatched, however some locations we might not be able to do so due to cost and logistics."
            />
            <QuestionAnswer
              id="where-do-you-ship-to"
              question="Where do you ship to?"
              answer={
                <span>
                  We currently ship to the {countriesCount} countries{' '}
                  <Link to="#shipping-locations">listed below</Link>.<br />
                  <br />
                  IMPORTANT: due to certain logistics we're unable to ship
                  framed prints to certain countries, including the US. We
                  suggest purchasing a paper-only print and purchasing a frame
                  locally.
                </span>
              }
            />
            <QuestionAnswer
              id="shipping"
              question="How much is shipping?"
              answer={
                <span>
                  Shipping is free, no matter where you are!{' '}
                  <Emoji emoji="cartwheel" />
                </span>
              }
            />
            <QuestionAnswer
              question="The app isn't loading on my phone/browser"
              answer="We aim to support every browser and device we can, but there might be issues loading our Create page, specifically for iPhone users. We're planing on resolving these issues in the future, but we ask that you bear with us until then and try a different browser or device if possible."
            />
            <QuestionAnswer
              question="What paper do you use?"
              answer="We use 200gsm Enhanced Matte Art paper, which is a premium, heavyweight and slightly textured fine art paper with a smooth, clean finish."
            />
            <QuestionAnswer
              question="I don’t use Strava"
              answer="No worries! We accept GPX or KML files from most popular devices, which you can upload manually."
            />
            <QuestionAnswer
              question="My Strava activities aren't loading"
              answer="Make sure you the Strava activities are yours, they are public and that you've ticked 'View data about your activities' when Strava asks you for access."
            />
            <QuestionAnswer
              question="Can I add multiple routes?"
              answer={`Of course! Theoretically there's no limit, though we constrain all activities coordinates to ${maxAllowedPoints} points. Ultimately it depends on how many routes your computer can handle.`}
            />
            <QuestionAnswer
              question="Can I use miles instead of kilometers?"
              answer="We’re currently looking into this but for now, we suggest converting your values manually and entering them into the relevant text areas within the print. Regardless, data from Strava or GPX files will always be measured in metric units and we’d recommend that you double-check the values are correct."
            />
            <QuestionAnswer
              question="Does it come framed?"
              answer="Of course! Please select which framed option you'd like when creating your print."
            />
            <QuestionAnswer
              question="Are my card details secure?"
              answer={
                <span>
                  We use <Link to="https://stripe.com">Stripe</Link> to handle
                  all transations using a certified, secure encryption and do
                  not store any financial data ourselves.
                </span>
              }
            />
            <QuestionAnswer
              id="returns-refunds"
              question="Do you offer returns or refunds?"
              answer="Since a print is highly personalised we can’t offer returns, but if you're really unsatisfied please let us know what it is you’re not happy with and we can either sort it out or offer a full refund."
            />
            <QuestionAnswer
              question="Can I just purchase a map without an activity/route?"
              answer="Absolutely! You could make a print of any area such as your local neighbourhood or a memorable, special place. If you don’t want any text labels, just delete them from the text fields in the ‘labels’ section and they'll disappear from the print."
            />
          </ul>
          <aside className="shipping-locations" id="shipping-locations">
            <SectionTitle tag="h3">Where we ship</SectionTitle>
            <div className="shipping-locations-box">
              <Text>
                Please note, it can take between 48 - 72 hours for processing
                and printing after which the delivery estimates for each country
                are as follows below. <br /> <br />
                <br />
                IMPORTANT: due to certain logistics we're unable to ship framed
                prints to certain countries, including the US. We suggest
                purchasing a paper-only print and purchasing a frame locally.
              </Text>
              <ul className="shipping-locations-list">
                {props.shippingCountries.map((country) => (
                  <li key={country.value} className="shipping-locations-item">
                    <Text className="shipping-locations-country">
                      <span>{country.label}</span>{' '}
                      <span>
                        {getCountryShippingDuration(
                          country,
                          props.cheapestShippedProduct,
                        )}
                      </span>
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          </aside>
          <aside className="create">
            <CreateTitle tag="h4">
              Cherish your own adventure, from{' '}
              {props.cheapestProductPriceFormatted}.
            </CreateTitle>
            <CreateLink to={routes.CREATE}>Let's get started!</CreateLink>
          </aside>
        </SiteContainer>
      </div>
    </React.Fragment>
  )
}

// TODO: move to utils
const getCountryShippingDuration = (country, cheapestShippedProduct) => {
  const shipping = _get(country, [
    'products',
    cheapestShippedProduct.sku,
    cheapestShippedProduct.shippingRate,
  ])
  if (!shipping) return '—'
  return `${shipping.shippingEstimate} business days via ${shipping.courier}`
}

UnstyledFaq.propTypes = {
  shippingCountries: PropTypes.arrayOf(PropTypes.shape({})),
  cheapestProductPriceFormatted: PropTypes.string,
  cheapestShippedProduct: PropTypes.shape({
    title: PropTypes.string,
  }),
}
UnstyledFaq.defaultProps = {
  shippingCountries: [],
  cheapestProductPriceFormatted: undefined,
  cheapestShippedProduct: {
    title: '',
  },
}

const Faq = styled(UnstyledFaq)`
  width: 100%;
  position: relative;
  overflow: hidden;

  ${FeaturedTitle} {
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 13rem;
  }

  .create {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
    position: relative;
    margin-top: 20rem;

    &:before {
      content: '';
      border-top: 2px solid ${colours.grey02};
      position: absolute;
      width: 100vw;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${CreateTitle} {
    margin-bottom: 5rem;
  }

  ${CreateLink} {
    text-decoration: none;
    font-size: ${fonts.size03};
    height: 8.8rem;
    max-width: 42rem;
  }

  .shipping-locations {
    padding-top: 20rem;
    padding-bottom: 10rem;
  }

  .shipping-locations-box {
    border-radius: ${dimensions.borderRadius};
    overflow: hidden;
    max-width: 104rem;
    margin: 3rem auto 0;
    border: 2px solid ${colours.tertiaryLight};
    padding: 8rem 6rem;

    > ${Text} {
      text-align: center;
      max-width: 27em;
      margin: auto;
    }
  }

  .shipping-locations-list {
    margin-top: 7rem;
  }

  .shipping-locations-item {
    padding: 2.2rem 5rem;
    border-top: 2px solid ${colours.grey02};

    ${Text} {
      line-height: 1.3;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    span:first-child {
      flex: 0 0 45%;
      padding-right: 1em;
      font-weight: 500;
    }

    span:last-child {
      flex: 0 0 55%;
      color: ${colours.tertiaryLight};
    }
  }

  ${Text},
  ${FeaturedTitle} {
    position: relative;
    z-index: ${zIndexes.raised};
  }

  ${TopoBackground} {
    z-index: ${zIndexes.zero};

    &:nth-child(0n + 1) {
      top: 10%;
    }
    &:nth-child(0n + 2) {
      bottom: 60rem;
      background-position: center bottom;
    }
  }

  /* MEDIA STYLES */

  ${media.footer`
    ${QuestionAnswer} {
      .question,
      .answer {
        font-size: ${fonts.size03};
      }
    }
    .shipping-locations {
      padding-left: 0;
      padding-right: 0;
    }

    .shipping-locations-box {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .shipping-locations-item {
      padding-left: 3rem;
      padding-right: 3rem;

      ${Text} {
        line-height: 1.3;
      }

    }
  `}

  ${media.nav`
    & > ${SiteContainer} {
      padding-left: 0;
      padding-right: 0;
    }

    ${FeaturedTitle} {
      margin-top: 0;
      margin-bottom: 6rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    ${QuestionAnswer} {
      .inner {
        flex-direction: column;
        padding: 3rem 3rem;
      }
      .question,
      .answer {
        padding-right: 0;
        flex: 1 0 auto;
        font-size: ${fonts.size02};

        span {
          font-size: inherit;
        }
      }
      .answer {
        margin-bottom: 0;
      }
    }

    .shipping-locations {
      padding-top: 12rem;
      padding-bottom: 8rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .shipping-locations-box {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    .shipping-locations-list {
      margin-top: 4rem;
    }

    .shipping-locations-item {
      padding-top: 1.6rem;
      padding-bottom: 1.4rem;
      padding-left: 0;
      padding-right: 0;

      ${Text} {
        flex-direction: column;
        align-items: flex-start;
      }

      span:first-child {
        display: block;
        margin-bottom: 0.3em;
      }
    }

    .create {
      margin-top: 8rem;
      padding: 6rem 3rem 8rem;
    }

    ${CreateTitle} {
      font-size: ${fonts.size03};
    }
  `}
`

const withSession = WithSession()

export { Faq, UnstyledFaq }
export default compose(withSession)(Faq)
