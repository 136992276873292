import { css } from 'styled-components';

import { fontGroup } from '../../styles';
import MapboxMap from '../../../../components/MapboxMap';
import { LogoCredits, PoweredByStrava } from '../../../../components/Logo';
import { PrintLabel } from '../../../../components/PrintLabel';
import { PrintTitle } from '../../../../components/PrintTitle';

export default () => css`
  ${fontGroup('interstateCondensedAlternateGothic')}

  ${PrintTitle} {
    margin-bottom: 0.2em;
  }

  ${LogoCredits},
  ${PoweredByStrava} {
    color: #3966a7;
    opacity: 1;
  }

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      color: #3966a7;
      opacity: 1;
    }
  }

  ${MapboxMap}:after {
    pointer-events: none;
    content: '';
    width: 32rem;
    height: 29.2rem;
    position: absolute;
    top: 12rem;
    right: 12rem;
    background: transparent  no-repeat center center;
    background-size: cover;
  }

  .mapboxgl-ctrl-bottom-left {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
  }

`;
