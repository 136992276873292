import * as Constants from './constants'

export const trackPrintOrdered = (quantity = 1) => ({
  type: Constants.TRACK_EVENT,
  category: Constants.PRINT_ORDERED_CATEGORY,
  action: Constants.PRINT_ORDERED_ACTION,
  value: quantity,
})

export const trackPurchase = ({
  orderId,
  printId,
  value,
  coupon,
  quantity,
  product,
}) => ({
  type: Constants.TRACK_PURCHASE,
  orderId,
  printId,
  value,
  coupon,
  quantity,
  product,
})

export const trackPrintMetrics = (print = {}) => ({
  type: Constants.TRACK_PRINT_METRICS,
  print,
})

export const trackActivityAdded = (source = '') => ({
  type: Constants.TRACK_EVENT,
  category: Constants.ACTIVITY_ADDED_CATEGORY,
  action: Constants.ACTIVITY_ADDED_ACTION,
  label: source,
})

export const trackCouponAdded = (couponId = '', percentOff) => ({
  type: Constants.TRACK_EVENT,
  category: Constants.COUPON_ADDED_CATEGORY,
  action: Constants.COUPON_ADDED_ACTION,
  label: couponId,
  value: percentOff,
})

export const trackCtaClicked = (label = '') => ({
  type: Constants.TRACK_EVENT,
  category: Constants.CTA_CLICKED_CATEGORY,
  action: Constants.CTA_CLICKED_ACTION,
  label,
})

export const trackPage = (page = '') => ({
  type: Constants.TRACK_PAGE,
  page,
})

export const trackEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction,
}) => ({
  type: Constants.TRACK_EVENT,
  category,
  action,
  label,
  value,
  nonInteraction,
})

export const trackError = (action = '', label = '') => ({
  type: Constants.TRACK_EVENT,
  category: Constants.ERROR_CATEGORY,
  action,
  label,
  nonInteraction: true, // revents mis-reporting bounce rates
})

export const trackException = (description = '', fatal = false) => ({
  type: Constants.TRACK_EXCEPTION,
  description,
  fatal,
})
