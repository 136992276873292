import { css } from 'styled-components';

import MapboxMap from '../../../../../components/MapboxMap';
import commonStyles from '../styles-common';

export default () => css`
  ${commonStyles}

  ${MapboxMap}:after {
    width: 50rem;
    height: 21.6rem;
    top: 12rem;
    left: 10rem;
    margin: auto;
  }
`;
