import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  printStyles,
  activityStyles,
  id: 'paperchase',
  name: 'Paper Chase',
  mapStyles: 'mapbox://styles/papertrails/ck1j20bc30lef1clemqdjk1e3',
  cssStyles,
  palette,
};
