import _omit from 'lodash.omit'
import { createSelector } from 'reselect'

import { activitySources } from '../WithActivities/constants'
import { dimensions } from '../../theme'
import {
  removeUnusedActivityData,
  removeUnusedPrintData,
  printHasActivityEndpointsEnabled,
  printHasDetails,
  activitySourceFromStrava,
  printHasElevationProfileEnabled,
} from '../../utils/print'
import { getPrintSize, ELEVATION_PROFILE_HEIGHT } from '../../utils/sizes'
import { PRINT_DOMAIN } from './constants'
import { PrintActivity } from './model'

export const withDomain = (state) => state[PRINT_DOMAIN]

export const printSelector = createSelector(withDomain, (domain) => domain)

export const idSelector = createSelector(printSelector, (print) => print.id)

export const activitiesSelector = createSelector(
  printSelector,
  (print) => print.activities,
)

export const defaultPrintActivitiesSelector = createSelector(
  activitiesSelector,
  (activities) =>
    activities.filter(
      (activity) =>
        ![activitySources.STRAVA, activitySources.GPX].includes(
          activity.source,
        ),
    ),
)

export const userPrintActivitiesSelector = createSelector(
  activitiesSelector,
  (activities) =>
    activities.filter((activity) =>
      [activitySources.STRAVA, activitySources.GPX].includes(activity.source),
    ),
)

export const orientationSelector = createSelector(
  printSelector,
  (print) => print.orientation,
)

export const productSelector = createSelector(
  printSelector,
  (print) => print.product,
)

export const productAttributesSelector = createSelector(
  printSelector,
  (print) => print.productAttributes,
)

export const quantitySelector = createSelector(
  printSelector,
  (print) => print.quantity,
)

export const layoutSelector = createSelector(
  printSelector,
  (print) => print.layout,
)

export const zoomSelector = createSelector(printSelector, (print) => print.zoom)

export const rotationSelector = createSelector(
  printSelector,
  (print) => print.rotation,
)

export const labelsSelector = createSelector(
  printSelector,
  (print) => print.labels,
)

export const centerSelector = createSelector(
  printSelector,
  (print) => print.center,
)

export const titleSelector = createSelector(
  printSelector,
  (print) => print.title,
)

export const secondaryTitleSelector = createSelector(
  printSelector,
  (print) => print.secondaryTitle,
)

export const stylesSelector = createSelector(printSelector, (print) => ({
  themeName: print.themeName,
  primaryColor: print.primaryColor,
  secondaryColor: print.secondaryColor,
  backgroundColor: print.backgroundColor,
}))

export const optionsSelector = createSelector(
  printSelector,
  (print) => print.options,
)

export const renderBeforeLayerIdSelector = createSelector(
  printSelector,
  (print) => print.renderBeforeLayerId,
)

export const activitiesDashedSelector = createSelector(
  activitiesSelector,
  (activities) => activities.some((a) => a.pathDashed),
)

export const paddingSelector = createSelector(
  printSelector,
  (print) => print.padding,
)

export const portholeSelector = createSelector(
  printSelector,
  (print) => print.porthole,
)

export const printActivitiesElevationProfilesSelector = createSelector(
  activitiesSelector,
  (activities) => activities.some((a) => a.elevationProfile),
)

export const printActivitiesThicknessSelector = createSelector(
  activitiesSelector,
  (activities = []) =>
    activities.length
      ? activities[0].pathThickness
      : PrintActivity.activityDefaults.pathThickness,
)

/**
 * Returns a bool if the print has details (labels, titles, etc.)
 *
 */
export const hasPrintDetailsSelector = createSelector(
  titleSelector,
  secondaryTitleSelector,
  labelsSelector,
  (title, secondaryTitle, labels) =>
    printHasDetails(title, secondaryTitle, labels),
)

/**
 * Returns a bool is the print has elevation profile enabled
 *
 */
export const hasElevationProfileSelector = createSelector(
  activitiesSelector,
  (activities) => printHasElevationProfileEnabled(activities),
)

/**
 * Returns true if at least one user activity has endpoints (start & end nodes)
 *
 */
export const hasActivityEndpointsSelector = createSelector(
  activitiesSelector,
  (activities) => printHasActivityEndpointsEnabled(activities),
)

/**
 * Return a bool if the print has an activity source from strava
 *
 * @returns {bool} bool
 */
export const hasSourceFromStravaSelector = createSelector(
  activitiesSelector,
  (activities) => activitySourceFromStrava(activities),
)

/**
 * Returns an object of the print size
 *
 * @returns {{ width: number, height: number }} { width: number, height: number }
 */
export const printSizeSelector = createSelector(
  orientationSelector,
  (orientation) => getPrintSize(orientation),
)

// -----------------------------------------------------------------------------
// USED FOR GENERATING PRINT CONFIG
// -----------------------------------------------------------------------------

export const zippedPrintActivitiesSelector = createSelector(
  activitiesSelector,
  (activities) =>
    activities.map((activity) =>
      removeUnusedActivityData({
        ...activity,
      }),
    ),
)

export const printDataToSave = createSelector(
  printSelector,
  zippedPrintActivitiesSelector,
  (print, zippedActivities) =>
    removeUnusedPrintData({
      ...print,
      activities: zippedActivities,
    }),
)

// -----------------------------------------------------------------------------
// COMPONENT-PROP SELECTORS
// -----------------------------------------------------------------------------

/**
 * Returns all print properties required to display the Print Component
 *
 * @returns {object} object
 */
export const printComponentPropsSelector = createSelector(
  layoutSelector,
  orientationSelector,
  portholeSelector,
  paddingSelector,
  stylesSelector,
  productAttributesSelector,
  hasPrintDetailsSelector,
  hasElevationProfileSelector,
  hasSourceFromStravaSelector,
  (
    layout,
    orientation,
    porthole,
    padding,
    theme,
    productAttributes,
    hasDetails,
    hasElevationProfile,
    strava,
  ) => ({
    layout,
    orientation,
    porthole,
    padding,
    theme,
    productAttributes,
    hasDetails,
    hasElevationProfile,
    strava, // TODO: rename
  }),
)

/**
 * Returns all print properties required to display the Mapbox Component
 *
 * @returns {object} object
 */
export const mapComponentPropsSelector = createSelector(
  zoomSelector,
  centerSelector,
  rotationSelector,
  productSelector,
  orientationSelector,
  activitiesSelector,
  layoutSelector,
  renderBeforeLayerIdSelector,
  productAttributesSelector,
  (
    zoom,
    center,
    rotation,
    product,
    orientation,
    activities,
    layout,
    renderBeforeLayerId,
    productAttributes,
  ) => ({
    zoom,
    center,
    rotation,
    product,
    orientation,
    activities,
    layout,
    renderBeforeLayerId,
    productAttributes,
  }),
)

/**
 * Returns all print properties required to display the PrintDetails Component
 *
 * @returns {object} object
 */
export const printDetailsComponentPropsSelector = createSelector(
  titleSelector,
  secondaryTitleSelector,
  labelsSelector,
  layoutSelector,
  orientationSelector,
  (title, subtitle, labels, layout, orientation) => ({
    title,
    subtitle, // TODO: rename
    labels,
    layout,
    orientation,
  }),
)

/**
 * Returns all print properties required to display the ElevationProfile Component
 *
 * @returns {object} object
 */
export const elevationProfileComponentPropsSelector = createSelector(
  activitiesSelector,
  printSizeSelector,
  paddingSelector,
  (activities, printSize, padding) => ({
    activities: activities.map((activity) =>
      _omit(activity, ['latlngs', 'polyline']),
    ),
    width: printSize.width - (padding ? dimensions.printPaddingPx : 0),
    height: ELEVATION_PROFILE_HEIGHT,
  }),
)
