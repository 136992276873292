export const CURRENCY = {
  GBP: '£',
  USD: '$',
  EUR: '€',
};


/**
 * Will pad any single digit zeros, such as in 24hr time
 * @param {number} unit
 */
export const padUnit = unit => ((unit < 10) ? `0${unit}` : `${unit}`);


/**
 * Formats a currency
 * @param {number} value the value to convert
 * @param {string} currency currency symbol
 * @param {number} decimals
 * @param {number} separator thousands separator
 */
export const formatCurrency = (value, currency, decimals = 2, separator = 4) => {
  const asNumber = Number(value);
  if (!isNumber(asNumber)) return undefined;
  const regex = `\\d(?=(\\d{${separator}})+${(decimals > 0 ? '\\.' : '$')})`;
  const fixedVal = asNumber.toFixed(Math.max(0, ~~decimals)); // eslint-disable-line
  const formattedVal = fixedVal.replace(new RegExp(regex, 'g'), '$&,');
  return addSymbolToCurrency(formattedVal, currency);
};


/**
 * Checks if a value can be parsed as a number
 * @param {any} value
 * @returns {bool}
 */
export const isNumber = value => !Number.isNaN(parseInt(value, 10));


/**
 * Adds the currency symbol to a value
 * @param {number|string} value
 * @param {CURRENCY} currency
 */
export const addSymbolToCurrency = (value, currency = 'GBP') => {
  const symbol = CURRENCY[currency.toUpperCase()];
  if (!isNumber(value) || !symbol) return undefined;
  return `${symbol}${value}`;
};


/**
 * Generates a random number between two numbers
 * @param {number} min
 * @param {number} max
 */
export const random = (min = 0, max = 10) => Math.floor((Math.random() * ((max - min) + 1)) + min);


/**
 * Round any number to the nearest value of 10
 * @param {number} num
 */
export const roundToNearestTen = (num = 0) =>
  Math.ceil(Math.abs(num) / 10) * 10 * (num < 0 ? -1 : 1);
