export const ACTIVITIES_DOMAIN = 'activities';

export const GET_STRAVA_ACTIVITIES_REQUEST = 'GET_STRAVA_ACTIVITIES_REQUEST';
export const GET_STRAVA_ACTIVITY_STREAMS_AND_DETAILS_REQUEST = 'GET_STRAVA_ACTIVITY_STREAMS_AND_DETAILS_REQUEST';
export const GET_STRAVA_ROUTES_REQUEST = 'GET_STRAVA_ROUTES_REQUEST';
export const GET_STRAVA_ROUTES_STREAMS_AND_DETAILS_REQUEST = 'GET_STRAVA_ROUTES_STREAMS_AND_DETAILS_REQUEST';

export const UPLOAD_XML_FILES = 'UPLOAD_XML_FILES';
export const UPLOAD_JSON_FILES = 'UPLOAD_JSON_FILES';
export const SELECT_DEFAULT_ACTIVITY = 'SELECT_DEFAULT_ACTIVITY';
export const SELECT_AFFILIATE_ACTIVITIES = 'SELECT_AFFILIATE_ACTIVITIES';

export const ACTIVITIES_LOADING_COUNT = 'ACTIVITIES_LOADING_COUNT';

export const activitySources = {
  STRAVA: 'strava',
  GPX: 'gpx',
};

export const LATLNGS_LIMIT_COUNT = 1000;
