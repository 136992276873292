import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { splitNewlines } from '../../utils/strings';

const PrintLabelTitle = styled('div')`

`;

const PrintLabelValue = styled('div')`

`;

const UnstyledPrintLabel = (props) => {
  if (!props.title && !props.value) return null;
  return (
    <div className={props.className}>
      <PrintLabelValue>{splitNewlines(props.value).map((line, i) => (
        <div key={`${line}-${i}`}>{line}</div> // eslint-disable-line react/no-array-index-key
      ))}</PrintLabelValue>
      <PrintLabelTitle>{splitNewlines(props.title).map((line, i) => (
        <div key={`${line}-${i}`}>{line}</div> // eslint-disable-line react/no-array-index-key
      ))}</PrintLabelTitle>
    </div>
  );
};

UnstyledPrintLabel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

UnstyledPrintLabel.defaultProps = {
  title: '',
  value: '',
};

const PrintLabel = styled(UnstyledPrintLabel)`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  ${PrintLabelTitle} {
    line-height: 1;
  }

  ${PrintLabelValue} {
    line-height: 1;
    ${p => p.title && css`
      margin-bottom: 0.8rem;
    `}
  }
`;
export { PrintLabel, UnstyledPrintLabel, PrintLabelTitle, PrintLabelValue };
export default PrintLabel;
