import * as Constants from './constants';

export const changeTheme = themeName => ({
  type: Constants.CHANGE_THEME,
  themeName,
});

export const setThemeName = themeName => ({
  type: Constants.SET_THEME_NAME,
  themeName,
});
