import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  id: 'pcUltra2022Mixed',
  name: 'Pan Celtic Ultra 2022',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cl10o2cxq000414qnnhqkanr1',
  cssStyles,
  palette,
};
