import { ORIENTATION_PORTRAIT } from '../../WithPrint/constants'

import themeNames from '../../WithThemes/themes/gbdivide/theme-names'

import logo from './logo.png'
import badge from './badge.png'
import metaImage from './og-banner.jpg'
import gbDivide2021 from './event-thumbs/gbdivide-2021-thumb.jpg'
import gbDivide2022 from './event-thumbs/gbdivide-2022-thumb.jpg'

const gbDivide2023 = gbDivide2021

export const themes = [
  {
    themeName: themeNames.gbdivide2023full,
    image: gbDivide2023,
    orientation: ORIENTATION_PORTRAIT,
    title: '2023 – Full',
    subtitle: '5th August 2023',
  },
  {
    themeName: themeNames.gbdivide2023short,
    image: gbDivide2023,
    orientation: ORIENTATION_PORTRAIT,
    title: '2023 – Short',
    subtitle: '5th August 2023',
  },
  {
    themeName: themeNames.gbdivide2022,
    image: gbDivide2022,
    orientation: ORIENTATION_PORTRAIT,
    title: '2022',
    subtitle: '30th July 2022',
  },
  {
    themeName: themeNames.gbdivide2021,
    image: gbDivide2021,
    orientation: ORIENTATION_PORTRAIT,
    title: '2021',
    subtitle: '31st July 2021',
  },
]

export default {
  logo,
  badge,
  uri: 'greatbritishdivide',
  link: 'https://greatbritishdivide.com/',
  linkText: 'greatbritishdivide.com',
  blurb: `The Great British Divide is an unsupported MTB bikepacking event with a distance of 2,000km and 40,000m of climbing. Travelling from the bottom to the top of Great Britain, often taking a less obvious route, riders pass through the most beautiful, dramatic and testing terrain the country has to offer.`,
  title: 'Great British Divide',
  description: 'Select your event to personalise your print',
  logoMaxWidth: '39rem',
  metaTitle: 'Great British Divide Custom Prints',
  metaDescription:
    'Turn your Great British Divide adventure into a work of art',
  metaImage,
  themes,
  themeNames: Object.values(themeNames),
}
