import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { colours, fonts } from '../../theme';

const UnstyledText = ({ light, ...restProps }) =>
  <p {...restProps} />;

UnstyledText.propTypes = {
  light: PropTypes.bool,
};
UnstyledText.defaultProps = {
  light: false,
};

export const textStyles = css`
  ${props => !!props.light && css`
    color: ${colours.grey03};
  `}
`;

export const Text = styled(UnstyledText)`
  ${textStyles}
`;


/**
 * Small Text
 * -----------------------------------------------------------------------------
 */
export const SmallText = styled(UnstyledText)`
  ${textStyles}
  font-size: ${fonts.size01};
  line-height: ${fonts.lineHeight03};
`;


/**
 * Large Text
 * -----------------------------------------------------------------------------
 */
export const LargeText = styled(UnstyledText)`
  ${textStyles}
  font-size: ${fonts.size04};
`;

export default Text;
