import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';

import HocComponent from '../HocComponent';

import * as Actions from './actions';
import * as Selectors from './selectors';

export const MakeWithActivitiesHoc = WrappedComponent => new HocComponent(WrappedComponent, 'withActivitiesHoc');

export const WithActivitiesHoc = () => (WrappedComponent) => {
  const WithActivities = MakeWithActivitiesHoc(WrappedComponent);

  const mapStateToProps = createStructuredSelector({
    defaultActivities: Selectors.defaultActivitiesSelector,
    activitiesLoadingCount: Selectors.activitiesLoadingCountSelector,
  });

  const mapDispatchToProps = dispatch => bindActionCreators({
    selectDefaultActivity: Actions.selectDefaultActivity,
    selectAffiliateActivities: Actions.selectAffiliateActivities,
    uploadXmlFiles: Actions.uploadXmlFiles,
    uploadJsonFiles: Actions.uploadJsonFiles,
    getStravaActivities: Actions.getStravaActivities,
    getStravaActivityStreamsAndDetails: Actions.getStravaActivityStreamsAndDetails,
    getStravaRoutes: Actions.getStravaRoutes,
    getStravaRoutesStreamsAndDetails: Actions.getStravaRoutesStreamsAndDetails,
  }, dispatch);

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const composed = compose(
    withRouter,
    withConnect,
  )(WithActivities);

  return composed;
};

export default WithActivitiesHoc;
