import React from 'react'
import styled from 'styled-components'
import Hero from '../../images/bemc-strava.jpg'
import HeroMobile from '../../images/strava-bemc-mobile.png'
import Badges from '../../images/bemc-strava-badges.jpg'

const Container = styled.div`
  width: 100%;
  max-width: 150rem;
  margin: auto;
  padding-left: 6rem;
  padding-right: 6rem;
  margin-bottom: 8rem;
  padding-bottom: 8rem;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 2rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  img.hero {
    width: 100%;
  }
  img.badges {
    width: 100%;
    max-width: 380px;
    margin-top: 4rem;
  }
  h2 {
    margin-top: 2rem;
    margin-top: 2rem;
    font-weight: 400;
    font-size: 4.6rem;
    line-height: 1.2;
    max-width: 1080px;
    margin-bottom: 4rem;
    @media (max-width: 768px) {
      font-size: 2.6rem;
      line-height: 1.2;
    }
  }
  ul {
    counter-reset: item;
    padding-left: 0;
    li {
      list-style: outside;
      margin-left: 1em;
      list-style-type: decimal;
      margin-bottom: 2rem;
      a {
        color: #ff3801;
        text-decoration: none;
        border-bottom: 2px solid #ff3801;
      }
    }
  }
  p,
  li {
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 1.55;
    max-width: 900px;
    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  a.button {
    display: block;
    text-decoration: none;
    background-color: #ff3801;
    color: #fff;
    border-radius: 12px;
    border: none;
    font-size: 26px;
    line-height: 50px;
    outline: 0;
    padding: 1.5rem 8rem;
    text-transform: uppercase;
    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }
  div.flex {
    display: flex;
    margin-top: 4rem;
    column-gap: 20px;
    padding-left: 90px;
    @media (max-width: 768px) {
      margin-top: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      flex-direction: column;
    }
  }
`

const Desktop = styled.div`
  display: block;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const StravaBEMCMicrosite = () => {
  return (
    <>
      <Desktop>
        <img className="hero" src={Hero} alt="" />
      </Desktop>
      <Container>
        <Mobile>
          <img className="hero" src={HeroMobile} alt="" />
        </Mobile>
        <div className="flex">
          <div>
            <h2>
              You’ve completed the Best Efforts Marathon Challenge, powered by
              adidas!
            </h2>
            <p>
              Now, it’s time to celebrate with your personalized print. Whether
              you earned a free print or got a 15% discount, the next part is
              simple:
            </p>
            <ul>
              <li>Click on your favorite theme below to get started.</li>
              <li>Connect your Strava account to Paper Trails.</li>
              <li>
                Follow the checkout steps and your print will be on the way.
              </li>
            </ul>
          </div>
          <Desktop>
            <img className="badges" src={Badges} alt="" />
          </Desktop>
        </div>
      </Container>
    </>
  )
}

export default StravaBEMCMicrosite
