import * as Constants from './constants'

export const createToast = (message, timeout, variant) => ({
  type: Constants.CREATE_TOAST,
  message,
  timeout,
  variant,
})

export const addToast = (id, message, timeout, variant) => ({
  type: Constants.ADD_TOAST,
  id,
  message,
  timeout,
  variant,
})

export const removeToast = (id) => ({
  type: Constants.REMOVE_TOAST,
  id,
})

export const removeAllToasts = () => ({
  type: Constants.REMOVE_ALL_TOASTS,
})
