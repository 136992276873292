import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { colours, fonts } from '../../theme';
import { media, zIndexes } from '../../styles';
import config from '../../config';

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import SiteHeader from '../../containers/SiteHeader';
import SiteContainer from '../../components/SiteContainer';

import { ContentBox } from '../../components/ContentBox';
import { Link } from '../../components/Link';
import { FeaturedTitle, SectionTitle } from '../../components/Title';
import { Text } from '../../components/Text';
import { TopoBackground } from '../../components/Background';
import Emoji from '../../components/Emoji';

const FeaturedBox = styled(ContentBox)``;
const HeaderBox = styled(ContentBox)``;
const GenericBox = styled(ContentBox)``;

const LAST_UPDATED = '30 March 2019';

const UnstyledPrivacy = props => (
  <React.Fragment>
    <ScrollToTopOnMount />
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>
    <div className={props.className}>
      <TopoBackground topo={2} />
      <SiteHeader />
      <SiteContainer>
        <article>
          <header>
            <SectionTitle tag="h2">More Legal Bits</SectionTitle>
            <FeaturedTitle tag="h1">Privacy Policy<br /> <Emoji emoji="shush" /></FeaturedTitle>
          </header>
          <FeaturedBox>

            <Text>
              At Paper Trails, accessible from {config.company.website}, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Paper Trails and how we use it. <br />
              <br />
              If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at <Link to={`mailto:${config.email.contact}`}>{config.email.contact}</Link>. <br />
              <br />
              We amend these Policies from time to time. Please check these to ensure you understand the terms which will apply at that time. This Policy was  updated on {LAST_UPDATED}. <br />
              <br />
              This Privacy Policy is only in the English language. <br />
            </Text>
          </FeaturedBox>

          <HeaderBox>
            <SectionTitle tag="h3">1. In A Nutshell</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              We use <Link to="#cookies">cookies</Link>, <Link to="#log-files">log files</Link>, <Link to="#activity-data">Strava and uploaded activity data</Link> and <Link to="#personal-information">information provided by you</Link> when you decide to pruchase a print. We use third-party services to handle both <Link to="#financial-data">payments</Link> and <Link to="#personal-information">shipping</Link>. Please constult the relevant sections below and make sure you understand what you're consenting to.
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3" id="gdpr">2. General Data Protection Regulation (GDPR)</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              Paper Trails' legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information: <br />
              <br />
            </Text>
            <ul>
              <li>Paper Trails needs to perform a contract with you</li>
              <li>You have given {config.company.name} permission to do so</li>
              <li>Processing your personal information is in Paper Trails' legitimate interests</li>
              <li>Paper Trails needs to comply with the law</li>
            </ul>
            <Text>
              {config.company.name} will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies. Our Privacy Policy was generated with the help of GDPR Privacy Policy Generator and the GDPR Privacy Policy Template. <br />
              <br />
                If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us. <br />
              <br />
                In certain circumstances, you have the following data protection rights: <br />
            </Text>
            <ul>
              <li>The right to access, update or to delete the information we have on you.</li>
              <li>The right of rectification.</li>
              <li>The right to object.</li>
              <li>The right of restriction.</li>
              <li>The right to data portability</li>
              <li>The right to withdraw consent</li>
            </ul>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3" id="cookies">3. How We Use Cookies</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. <br />
              <br />
              We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system. <br />
              <br />
              Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. <br />
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3" id="log-files">4. Log Files</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              Paper Trails follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. <br />
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3" id="financial-data">5. Financial Data</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              We use <Link to="https://stripe.com">Stripe</Link> to handle all financial transations and information and do not store this data ourselves. We are not responsible for any financial data used to complete a purchase. Please consult <Link to="https://stripe.com/gb/privacy">Stripe's Privacy Policy</Link> if you wish to know more. <br />
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3" id="personal-information">6. Personal Information</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              When you complete a purchase on our checkout page you will be required to provide your name, address as well as your contact email and number in order to fulfil your order. This information is not stored by us, but rather is sent to our print provider, <Link to="http://pwinty.com">Pwinty</Link>, which we then have access to. Please consult <Link to="http://pwinty.com/privacy-and-cookie-policy/">Pwinty's Privacy Policy</Link> if you wish to know more. <br />
              <br />
              Since we do not store this information ourselves we do not use it for any unsolicited marketing. <br />
              <br />
              Your contact email and phone number are only used to update you on the progress of an order.
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3" id="activity-data">7. Activity Data</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              When you design your own print the information you enter is kept by us in either a database or a plain text file. We do not use this information in any way other than to produce a digital version of your design. We do not give this information out to any external or third-parties. This includes the information from any GPX or FIT files you upload as well as the information you consent Strava to provide us access to when you use the Strava 'sign-in' button. <br />
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3" id="online-privacy">8. Online Privacy Policy Only</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Paper Trails. This policy is not applicable to any information collected offline or via channels other than this website. <br />
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3" id="consent">9. Consent</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              By using our website, you hereby consent to our Privacy Policy and agree to its terms. <br />
            </Text>
          </GenericBox>

        </article>
      </SiteContainer>
    </div>
  </React.Fragment>
);

UnstyledPrivacy.propTypes = {};
UnstyledPrivacy.propTypes = {};

const Privacy = styled(UnstyledPrivacy)`
  width: 100%;
  position: relative;

  ${SectionTitle} {
    margin-top: 7rem;
  }

  ${FeaturedTitle} {
    text-align: center;
    margin-bottom: 13rem;

    ${Emoji} {
      display: inline-block;
      margin-top: 2rem;
    }
  }

  ${FeaturedBox} {
    background: transparent;
    margin-top: 0;
    padding: 0 6.5rem;
    margin-bottom: 14rem;
  }

  ${Text} ${Link} {
    color: ${colours.primary};
    &:hover,
    &:focus {
      color: ${colours.secondary};
      text-decoration: none;
    }
  }

  ${HeaderBox} {
    background: transparent;
    padding: 0 6.5rem;
    margin-top: 0;
    margin-bottom: 0;
    align-items: flex-start;

    ${SectionTitle} {
      margin-top: 0;
      text-align: left;
    }
  }

  ${GenericBox} {
    margin-top: 0;
    margin-bottom: 8rem;
    padding: 6rem 6.5rem;
    align-items: flex-start;

    &:last-of-type {
      margin-bottom: 25rem;
    }

    ${Text} {
      font-size: ${fonts.size02};
    }

    ul {
      font-size: ${fonts.size02};
      padding-left: 3rem;
      &:not(:last-child) {
        margin-bottom: 3em;
      }

      li {
        list-style: disc inside;
      }
    }
  }

  ${Text},
  ${FeaturedTitle} {
    position: relative;
    z-index: ${zIndexes.raised};
  }

  ${TopoBackground} {
    z-index: ${zIndexes.zero};

    &:nth-child(0n+1) {
      top: 30rem;
    }
  }

  /* MEDIA STYLES */

  ${media.nav`
    ${FeaturedTitle} {
      margin-bottom: 6rem;
    }
    ${SectionTitle} {
      margin-top: 0;
    }

    ${SiteHeader} + ${SiteContainer} {
      padding-left: 0;
      padding-right: 0;
    }

    ${FeaturedBox} {
      margin-bottom: 10rem;
    }

    ${FeaturedBox},
    ${HeaderBox} {
      padding: 0 3rem;
    }
    ${GenericBox} {
      padding: 0 3rem 3rem;
      margin-bottom: 6rem;

      &:last-of-type {
        margin-bottom: 10rem;
      }
    }
  `}
`;

export { Privacy, UnstyledPrivacy };
export default Privacy;
