import * as Constants from './constants';

import { initialState } from './model';

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case Constants.ADD_TOAST: {
      const { type, ...toastProps } = action;
      return state.set('toasts', state.toasts.concat([toastProps]));
    }

    case Constants.REMOVE_TOAST: {
      return state.set('toasts', state.toasts.filter(toast => toast.id !== action.id));
    }

    case Constants.REMOVE_ALL_TOASTS: {
      return state.set('toasts', initialState.toasts);
    }

    default:
      return state;
  }
};

export default reducers;
