import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  id: 'pcGravelRally2022Short',
  name: 'Pan Celtic Gravel Rally 2022',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cl10n6f76000314s4p0ioqs7o',
  cssStyles,
  palette,
};
