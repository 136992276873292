/**
 * Return an array of form fields with their names and values
 *
 * @param {node} form
 * @returns {object[]}
 */
export const getFormData = form => {
  if (!form) return [];
  return Object.values(form)
    // filter all inputs with a valid name attribute
    .filter(el => (el && el.tagName === 'INPUT' && !!el.name))
    .map(({ name, required, value }) => ({
      name,
      value,
      // react-select doesn't accept a required prop so this is hard-coded
      required: (name === 'address_country' ? true : required),
    })).reduce((fields, field) => {
      if (!fields.some(f => f.name === field.name)) fields.push(field);
      return fields;
    }, []);
};


/**
 * Serializes an array of form data
 * @param {object[]} formData
 * @param {string} formdata[].name
 * @param {string} formdata[].value
 * @returns {object}
 */
export const serializeFormData = (formData = []) =>
  formData.reduce((data, field) => {
    data[field.name] = field.value;
    return data;
  }, {});


/**
 * Validates an array of form data
 * @param {object[]} formData
 * @param {string} formdata[].name
 * @param {string} formdata[].value
 * @returns {object}
 */
export const validateFormData = (formData = []) => {
  const requiredFields = formData.filter(field => field.required);
  const invalidFieldObjects = requiredFields
    .filter(field => [undefined, null, ''].includes(field.value));
  const invalidFields = invalidFieldObjects.map(field => field.name);
  const status = (invalidFields.length === 0);
  return {
    invalidFields,
    status,
  };
};
