import { takeLatest, select, put } from 'redux-saga/effects';

import * as PrintSelectors from './hocs/WithPrint/selectors';
import * as ThemeActions from './hocs/WithThemes/actions';
import { initialState } from './hocs/WithThemes/model';
import * as SessionActions from './hocs/WithSession/actions';

export const APP_INIT = 'APP_INIT';

/**
 * Runs all the setup stuff for the store
 *
 */
export function* appInit() {
  try {
    const { themeName = initialState.themeName } = yield select(PrintSelectors.stylesSelector);
    yield put(ThemeActions.setThemeName(themeName));

    yield put(SessionActions.getShippingAndProducts());
    // Make sure you load the sagas you intend to use
    // in sagaMiddleware.run(<saga>) in configureStore.js
  } catch (error) {
    // TODO: redirect / global error
    console.error(error);
  }
}


export function* watchAppInit() {
  yield takeLatest(APP_INIT, appInit);
}

export default [
  watchAppInit,
];
