import React, { useState } from 'react'
import styled from 'styled-components'
import ImageFooter from '../../images/strava-bemc-footer.png'

const Container = styled.div`
  width: 100%;
  max-width: 150rem;
  margin: auto;
  padding-right: 6rem;
  padding-left: 90px;
  margin-bottom: 8rem;
  padding-bottom: 8rem;
  @media (max-width: 768px) {
    margin-top: 0;
    padding-bottom: 4rem;
    padding-left: 0;
    padding-right: 0;
  }
  h2 {
    margin-top: 2rem;
    font-size: 4.6rem;
    line-height: 1.55;
    max-width: 1080px;
    margin-bottom: 4rem;
    padding-left: 6rem;
    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
`

const Footer = styled.div`
  margin-top: 10rem;
  @media (max-width: 768px) {
    margin-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const QuestionContainer = styled.li`
  padding-left: 6rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 34px;
  line-height: 44px;
  @media (max-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 20px;
    line-height: 26px;
  }
  &:nth-of-type(even) {
    background-color: #f7f7f7;
  }
`

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 2.8rem;
  line-height: 1.55;
  span {
    max-width: calc(100% - 120px);
    @media (max-width: 768px) {
      max-width: calc(100% - 40px);
    }
  }
  svg {
    margin-right: 70px;
    transition: all 0.25s ease-in-out;
    @media (max-width: 768px) {
      margin-right: 0;
      width: 24px;
      display: block;
    }
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`

const Answer = styled.div`
  font-weight: 300;
  transition: all 0.25s ease-in-out;
  max-width: 1068px;
  font-size: 2.8rem;
  line-height: 1.55;
  a {
    color: #ff3801;
    text-decoration: none;
    border-bottom: 2px solid #ff3801;
  }
  &.closed {
    overflow-y: hidden;
    max-height: 0px;
    height: 100%;
    opacity: 0;
  }
  &.open {
    overflow-y: visible;
    max-height: none;
    height: auto;
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
`

const QuestionAnswer = ({ question, answer, setOpen, open }) => {
  return (
    <QuestionContainer
      onClick={() => setOpen(open === question ? false : question)}
    >
      <Question className={open === question ? 'open' : 'closed'}>
        <span>{question}</span>
        <div>
          <svg
            width="27"
            height="16"
            viewBox="0 0 27 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 16L27 2.86102e-06L1.43055e-06 5.53062e-07L13.5 16Z"
              fill="#FF3801"
            />
          </svg>
        </div>
      </Question>
      <Answer className={open === question ? 'open' : 'closed'}>
        {answer}
      </Answer>
    </QuestionContainer>
  )
}

const StravaBEMCMicrositeFAQ = () => {
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <div className="flex">
        <h2>Frequently Asked Questions</h2>
        <ul>
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="How do I redeem my free or discounted print after completing the Best Efforts Marathon Challenge?"
            answer="Follow the link in your email or select a theme above, log into strava.com, and follow the steps to connect your Strava account and redeem your print."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="I completed the Best Efforts Marathon Challenge but haven’t received my reward. What should I do?"
            answer="Check your email for instructions on how to claim your reward. If you don't see the email, check your spam folder."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="I just upgraded to an annual subscription but haven’t received my reward email, or I only received a 15% discount towards a print. How can I claim my free print?"
            answer={
              <>
                If you upgraded to an annual subscription but didn’t receive
                your unique code for a free print, please contact support at
                <a href="mailto:support@strava.zendesk.com">
                  support@strava.zendesk.com
                </a>
              </>
            }
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="I am not an annual subscriber but I completed the Best Efforts Marathon Challenge. Do I get a reward?"
            answer="Yes, you qualify for a 15% discount, which has been sent to your email. Follow the steps in the email to redeem it."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="Why do I need to be logged in on strava.com to redeem my print?"
            answer="Logging in allows PaperTrails to connect with Strava’s API to create a custom print of your best effort."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="What privacy settings are required to connect my Strava activity to PaperTrails?"
            answer={
              <>
                To connect your Strava activity to PaperTrails, your activity's
                privacy controls must be set to either "Everyone" or
                "Followers." You can find more information on how to adjust
                these settings,{' '}
                <a
                  rel="nofollow noopener noreferrer"
                  href="https://support.strava.com/hc/en-us/articles/216919377-Activity-Privacy-Controls#:~:text=Your%20activity%20privacy%20controls%20allow,the%20visibility%20of%20individual%20activities."
                >
                  here
                </a>
                . After completing your purchase, you can choose to reset your
                activity privacy to "Only You."
              </>
            }
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="How do I upgrade to an annual Strava subscription?"
            answer={
              <>
                To upgrade from a monthly to an annual subscription, follow the
                details provided{' '}
                <a
                  rel="nofollow noopener noreferrer"
                  href="https://support.strava.com/hc/en-us/articles/360021900331-Changing-Subscription-Length"
                >
                  here
                </a>
                . If you are not currently a subscriber, you can sign up{' '}
                <a
                  href="https://www.strava.com/account"
                  rel="nofollow noopener noreferrer"
                >
                  here
                </a>
                . Remember, you must upgrade within the timeframe specified in
                your challenge completion email.
              </>
            }
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="Does the print come framed?"
            answer="No."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="I completed a marathon but didn’t join the Best Efforts Marathon Challenge. Can I still receive a free print?"
            answer="The free print offer is exclusive to those who joined and completed the Best Efforts Marathon Challenge."
          />
          <QuestionAnswer
            open={open}
            setOpen={setOpen}
            question="I have a Family Plan or Student Plan subscription. Does that count as an annual subscription?"
            answer="Yes, Family Plan and Student Plan subscriptions are eligible for the free print. "
          />
        </ul>
      </div>
      <Footer>
        <a href="https://strava.com" rel="nofollow noopener noreferrer">
          <img src={ImageFooter} alt="" />
        </a>
      </Footer>
    </Container>
  )
}

export default StravaBEMCMicrositeFAQ
