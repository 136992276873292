import { initialState } from './model';
import * as Constants from './constants';

export default (state = initialState, action) => {
  switch (action.type) {
    case Constants.ACTIVITIES_LOADING_COUNT: {
      const { count } = action;
      return state.set('activitiesLoadingCount', count);
    }

    default: {
      return state;
    }
  }
};
