import { css } from 'styled-components';

import { PrintTitle, PrintSecondaryTitle } from '../../../../components/PrintTitle';
import { PrintLabelTitle, PrintLabelValue, PrintLabel } from '../../../../components/PrintLabel';
import { loadTypekitThemeFonts, loadGoogleFonts } from '../../../../utils/fonts';

export default (theme) => {
  const loadedTypekitFonts = loadTypekitThemeFonts(['alternate-gothic-no-3-d']);
  const loadedGoogleFonts = loadGoogleFonts(['Alegreya:800i', 'Alegreya:700i']);

  return css`
    ${PrintTitle} {
      font-size: 4.6rem;
      ${loadedGoogleFonts[0].styles};
    }

    ${PrintSecondaryTitle} {
      font-size: 2rem;
      text-transform: uppercase;
      ${loadedTypekitFonts[0].styles}
    }

    ${PrintLabelValue} {
      font-size: 2.4rem;
      ${loadedGoogleFonts[1].styles}
    }

    ${PrintLabelTitle} {
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      ${loadedTypekitFonts[0].styles}
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: .2;
      }
    }
  `;
};
