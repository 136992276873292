import React from 'react';

import { attemptScrollToHash } from '../../utils/dom';

class ScrollToTopOnMount extends React.PureComponent {
  componentDidMount() {
    const { hash } = window.location;
    if (hash && hash.length) {
      setTimeout(() => {
        attemptScrollToHash(hash.replace('#', ''));
      }, 50);
    } else {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}


export default ScrollToTopOnMount;
