import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _debounce from 'lodash.debounce';

import { PAYPAL_SRC, PAYPAL_INTENT } from '../constants';

class PayPalButtonClass extends React.Component {
  static propTypes = {
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    product: PropTypes.shape({
      title: PropTypes.string,
      sku: PropTypes.string,
      paper: PropTypes.string,
      sizeImperial: PropTypes.string,
      sizeMetric: PropTypes.string,
      currency: PropTypes.string,
      price: PropTypes.number,
    }),
    onApproved: PropTypes.func,
    onCreateOrder: PropTypes.func,
    onError: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    product: {},
    onApproved: () => {},
    onCreateOrder: () => {},
    onError: () => {},
    onCancel: () => {},
  };

  constructor(props) {
    super(props);
    this.paypalRef = React.createRef();
    this.loadScript();
  }

  onCreateOrder = (_data, actions) => {
    const orderPayload = this.makeOrderPayload();
    this.props.onCreateOrder();
    return actions.order.create(orderPayload);
  }

  onApprove = (data /* ,actions */) => {
    this.props.onApproved(data.orderID);
  }

  onShippingChange = (_data, actions) => actions.resolve();

  onError = _debounce(() => {
    // From PayPal docs:
    // "This error handler is a catch-all. Errors at this point are not
    // expected to be handled beyond showing a generic error message or page."
    // Also debounce because sometimes multiple errors
    // are dispatched from one stack
    this.props.onError();
  }, 400)

  onCancel = () => {
    this.props.onCancel();
  };

  onPaypalScriptLoaded = () => {
    const { paypal } = window;
    if (paypal) {
      paypal.Buttons({
        onError: this.onError,
        onCancel: this.onCancel,
        onApprove: this.onApprove,
        createOrder: this.onCreateOrder,
        onShippingChange: this.onShippingChange,
        style: this.paypalButtonStyle,
      }).render(this.paypalRef.current);
    }
  }

  // https://developer.paypal.com/docs/api/orders/v2/#definition-purchase_unit_request
  // https://developer.paypal.com/docs/orders-integration-guide/#authorize-and-capture-payment-for-an-order
  makeOrderPayload() {
    return {
      intent: PAYPAL_INTENT,
      purchase_units: [{
        // shows on customer's invoice and in the PayPal pop-up modal
        // description: '',
        custom_id: `${this.props.product.sku}`,
        amount: this.paypalAmount,
        items: this.paypalItems,
        // NOT needed if the customer selects their own addres, but
        // could prefill if use has already filled out form
        // shipping: {
        //   name: {
        //     full_name: '',
        //   },
        //   address: {
        //     address_line_1: '',
        //     address_line_2: '',
        //     admin_area_2: '',
        //     admin_area_1: '',
        //     postal_code: '',
        //     country_code: '',
        //   },
        // },
      }],
    };
  }

  loadScript() {
    if (window.paypal) return;
    const script = document.createElement('script');
    script.onload = this.onPaypalScriptLoaded;
    document.head.appendChild(script);
    script.setAttribute('src', PAYPAL_SRC);
  }

  get totalPriceString() {
    const totalPrice = this.props.product.price * this.quantityInt;
    return `${totalPrice / 100}`;
  }

  get productPriceString() {
    return `${this.props.product.price / 100}`;
  }

  get quantityInt() {
    return parseInt(this.props.quantity, 10);
  }

  get paypalAmount() {
    const { totalPriceString } = this;
    const { product } = this.props;
    const { currency } = product;
    return {
      value: totalPriceString,
      currency_code: currency,
      breakdown: {
        item_total: {
          currency_code: currency,
          value: totalPriceString,
        },
      },
    };
  }

  get paypalItems() {
    const { product } = this.props;
    const { currency } = product;
    return Array(this.quantityInt).fill({
      quantity: 1,
      name: `${product.title} / ${product.sizeMetric}`,
      unit_amount: {
        currency_code: currency,
        value: this.productPriceString,
      },
    });
  }

  // https://developer.paypal.com/docs/commerce-platform/v1/checkout/customize-checkout-button
  get paypalButtonStyle() {
    return {
      layout: 'horizontal',
      size: 'responsive',
      label: 'paypal',
      tagline: false,
    };
  }

  render() {
    return <div id="paypal-button" className={this.props.className} ref={this.paypalRef} />;
  }
}

export const PayPalButton = styled(PayPalButtonClass)`
  width: 100%;
`;
