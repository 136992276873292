import { createSelector } from 'reselect';

import { STRIPE_DOMAIN } from './constants';

export const withDomain = state => state[STRIPE_DOMAIN];

export const tokenSelector = createSelector(
  withDomain,
  domain => domain.token,
);
