import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { dimensions } from '../../theme';

const UnstyledTransitionGroup = props => (
  <CSSTransitionGroup
    className={props.className}
    component={props.element}
    transitionName="transition"
    transitionAppear
    transitionAppearTimeout={600}
    transitionEnterTimeout={600}
    transitionLeaveTimeout={600}
  >
    {props.children}
  </CSSTransitionGroup>
);

UnstyledTransitionGroup.propTypes = {
  element: PropTypes.string,
};

UnstyledTransitionGroup.defaultProps = {
  element: 'ul',
};

const TransitionGroup = styled(UnstyledTransitionGroup)`

  .transition-appear,
  .transition-enter {
    opacity: 0.01;
    max-height: 0;
    overflow: hidden;
    transition:
      0.2s cubic-bezier(0.19, 1, 0.22, 1) opacity,
      0.6s cubic-bezier(0.19, 1, 0.22, 1) max-height;
  }

  .transition-appear.transition-appear-active,
  .transition-enter.transition-enter-active {
    opacity: 1;
    max-height: 10rem;
  }

  .transition-leave {
    opacity: 1;
    max-height: ${dimensions.buttonHeight};
    overflow: hidden;
    transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .transition-leave.transition-leave-active {
    opacity: 0.01;
    max-height: 0;
  }
`;

export { TransitionGroup, UnstyledTransitionGroup };
export default TransitionGroup;
