import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';
import * as mapStyles from './map.json';

const { id, name } = mapStyles;

export default {
  id,
  name,
  printStyles,
  activityStyles,
  mapStyles,
  cssStyles,
  palette,
};
