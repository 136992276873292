import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { colours, fonts } from '../../theme';
import { media, zIndexes } from '../../styles';
import * as routes from '../../routes';
import config from '../../config';

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import SiteHeader from '../../containers/SiteHeader';
import SiteContainer from '../../components/SiteContainer';

import { ContentBox } from '../../components/ContentBox';
import { Link } from '../../components/Link';
import { FeaturedTitle, SectionTitle } from '../../components/Title';
import { Text } from '../../components/Text';
import { TopoBackground } from '../../components/Background';
import Emoji from '../../components/Emoji';

const FeaturedBox = styled(ContentBox)``;
const HeaderBox = styled(ContentBox)``;
const GenericBox = styled(ContentBox)``;

const LAST_UPDATED = '30 March 2019';

const UnstyledTerms = props => (
  <React.Fragment>
    <ScrollToTopOnMount />
    <Helmet>
      <title>Terms & Conditions</title>
    </Helmet>
    <div className={props.className}>
      <TopoBackground topo={2} />
      <SiteHeader />
      <SiteContainer>
        <article>
          <header>
            <SectionTitle tag="h2">The Legal Bits</SectionTitle>
            <FeaturedTitle tag="h1">Terms &amp; Conditions <br /> <Emoji emoji="curious" /></FeaturedTitle>
          </header>
          <FeaturedBox>
            <Text>
              This page (together with our <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>) tells you information about us and the legal terms and conditions (Terms) on which you use any of the services (Services) on our website and app (our site) and on which we sell any of the products (Products) available through our site to you. <br />
              <br />
              These Terms will apply to any contract between us for the sale of Products to you (Contract). Please read these Terms carefully and make sure that you understand them, before ordering any Products from our site.  Please note that in placing an order you agree to be bound by these Terms. You should print a copy of these Terms or save them to your computer for future reference. <br />
              <br />
              We amend these Terms from time to time. Every time you wish to order Products, please check these Terms to ensure you understand the terms which will apply at that time. These Terms were most recently updated on {LAST_UPDATED}. <br />
              <br />
              These Terms, and any Contract between us, are only in the English language. <br />
            </Text>
          </FeaturedBox>

          <HeaderBox>
            <SectionTitle tag="h3">1. Information About Us</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              1.1  We operate the website {config.company.website}. We are {config.company.name}, a company registered in {config.company.registered} under company number {config.company.number}. <br />
              <br />
              1.2  Contacting us: <br />
              <br />
              (a)  You may contact us by e-mailing us at <Link to={`mailto:${config.email.contact}`}>{config.email.contact}</Link> <br />
              (b)  If we have to contact you or give you notice in writing, we will do so by e-mail or by pre-paid post to the address you provide to us in your order. <br />
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3">2. Our Products</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              2.1  The example images of the Products on our site are for illustrative purposes only. Although we have made every effort to display the colours accurately, we cannot guarantee that your computer's display of the colours accurately reflect the colour of the Products. Your Products may vary slightly from those images. <br />
              <br />
              2.2  Our Products are bespoke and whilst many component parts of our Products are standard, all Products available for purchase are described on their specific design page on our site. We always try to represent each design as accurately as possible in our Products. <br />
              <br />
              2.3  We have a policy of continuous Product development so that we can provide you with what we consider the best design combined with best performance, and thus reserve the right to amend the specifications of Products, their price and packaging without prior notice. Before ordering, we thus invite you to have a close look at the Product description and design. <br />
              <br />
              2.4  The packaging of the Products may vary from that shown on images on our site. <br />
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3">3. Use of Our Site</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              Your use of our site is governed by our terms of website use and Website Acceptable Use Policy, which are set out in the schedule to these Terms. Please take the time to read these, as they include important terms which apply to you.
            </Text>
          </GenericBox>

          <HeaderBox>
            <SectionTitle tag="h3">4. Your Personal Information</SectionTitle>
          </HeaderBox>
          <GenericBox>
            <Text>
              4.1 We only use your personal information in accordance with our <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>. Please take the time to read our Privacy Policy, as it includes important terms which apply to you. <br />
              <br />
              4.2 You warrant to us that you are the owner of any personal information and any data that you send to us whether this is for inclusion in our Products or not and you indemnify us for any third party claims arising out of our use of personal information and data supplied to us by you in fulfilment of your order for our Products.  You warrant to us that you have the right to use the data that you access through <Link to="https://strava.com">strava.com</Link> for the purpose of producing a Paper Trails Product. <br />
              <br />
              4.3 We accept no liability where you do not have permission to use any data that you access through <Link to="https://strava.com">strava.com</Link> for the purpose of producing a Paper Trails Product.  It is your responsibility to understand the terms and conditions of use and access to any information accesses on strava.com before using our site. <br />
            </Text>
          </GenericBox>

        </article>
      </SiteContainer>
    </div>
  </React.Fragment>
);

UnstyledTerms.propTypes = {};
UnstyledTerms.propTypes = {};

const Terms = styled(UnstyledTerms)`
  width: 100%;
  position: relative;

  ${SectionTitle} {
    margin-top: 7rem;
  }

  ${FeaturedTitle} {
    text-align: center;
    margin-bottom: 13rem;

    ${Emoji} {
      display: inline-block;
      margin-top: 2rem;
    }
  }

  ${FeaturedBox} {
    background: transparent;
    margin-top: 0;
    padding: 0 6.5rem;
    margin-bottom: 14rem;
  }

  ${Text} ${Link} {
    color: ${colours.primary};
    &:hover,
    &:focus {
      color: ${colours.secondary};
      text-decoration: none;
    }
  }

  ${HeaderBox} {
    background: transparent;
    padding: 0 6.5rem;
    margin-top: 0;
    margin-bottom: 0;
    align-items: flex-start;

    ${SectionTitle} {
      margin-top: 0;
      text-align: left;
    }
  }

  ${GenericBox} {
    margin-top: 0;
    margin-bottom: 8rem;
    padding: 6rem 6.5rem;

    &:last-of-type {
      margin-bottom: 25rem;
    }

    ${Text} {
      font-size: ${fonts.size02};
    }
  }

  ${Text},
  ${FeaturedTitle} {
    position: relative;
    z-index: ${zIndexes.raised};
  }

  ${TopoBackground} {
    z-index: ${zIndexes.zero};

    &:nth-child(0n+1) {
      top: 30rem;
    }
  }


  /* MEDIA STYLES */
  ${media.nav`
    ${FeaturedTitle} {
      margin-bottom: 6rem;
    }
    ${SectionTitle} {
      margin-top: 0;
    }

    ${SiteHeader} + ${SiteContainer} {
      padding-left: 0;
      padding-right: 0;
    }

    ${FeaturedBox} {
      margin-bottom: 10rem;
    }

    ${FeaturedBox},
    ${HeaderBox} {
      padding: 0 3rem;
    }
    ${GenericBox} {
      padding: 0 3rem 3rem;
      margin-bottom: 6rem;

      &:last-of-type {
        margin-bottom: 10rem;
      }
    }
  `}
`;

export { Terms, UnstyledTerms };
export default Terms;
