import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled, { css } from 'styled-components'
import { uiState } from 'react-redux-ui-state'
import { compose } from 'redux'

import { colours, fonts } from '../../theme'
import { media, zIndexes } from '../../styles'
import * as routes from '../../routes'

import SiteContainer from '../../components/SiteContainer'
import { Logo } from '../../components/Logo'
import Icon from '../../components/Icon'
import { Link, NavLink, IconNavLink, ShopNavLink } from '../../components/Link'
import { PlainButton } from '../../components/Button'
import { TopoBackground } from '../../components/Background'

const LogoLink = styled(Link)``
const HomeNavLink = styled(NavLink)``
const MenuButton = styled(PlainButton)``

class SiteHeader extends React.PureComponent {
  componentDidMount() {
    this.props.setUiState({
      menuOpen: false,
    })
  }

  onMenuClick = () => {
    this.props.setUiState({
      menuOpen: !this.props.menuOpen,
    })
  }

  isCreateActive = (match, location) =>
    !!match || location.pathname.includes(routes.PREVIEW)

  getClassNames() {
    return classnames(this.props.className, {
      'menu-is-open': !!this.props.menuOpen,
    })
  }

  get isHomePage() {
    return this.props.page === routes.HOME
  }

  render() {
    return (
      <header className={this.getClassNames()}>
        <SiteContainer>
          <LogoLink to={routes.HOME}>
            <Logo animate={this.props.animateLogo} light={this.isHomePage} />
          </LogoLink>
          <ul className="links">
            <TopoBackground topo={2} />
            <li className="link-home">
              <HomeNavLink exact to={routes.HOME}>
                Home
              </HomeNavLink>
            </li>
            <li>
              <NavLink to={routes.CREATE} isActive={this.isCreateActive}>
                Create
              </NavLink>
            </li>
            {/* <li><ShopNavLink to={routes.SHOP}>Art Prints</ShopNavLink></li> */}
            {/* <li>
              <ShopNavLink to={routes.EVENTS}>Events</ShopNavLink>
            </li> */}
            <li className="link-about">
              <NavLink to={routes.ABOUT}>About</NavLink>
            </li>
            <li>
              <NavLink to={routes.FAQ}>Help</NavLink>
            </li>
            <li>
              <IconNavLink to={routes.INSTAGRAM}>
                <Icon icon="instagram" />
              </IconNavLink>
            </li>
          </ul>
          <MenuButton onClick={this.onMenuClick}>
            {this.props.menuOpen ? <Icon icon="close" /> : <Icon icon="menu" />}
          </MenuButton>
        </SiteContainer>
      </header>
    )
  }
}

SiteHeader.propTypes = {
  animateLogo: PropTypes.bool,
  menuOpen: PropTypes.bool,
  setUiState: PropTypes.func.isRequired,
  page: PropTypes.string,
}
SiteHeader.defaultProps = {
  animateLogo: false,
  menuOpen: false,
  page: undefined,
}

const withUiState = uiState({
  name: () => 'SiteHeader',
  persist: true,
  state: () => ({
    menuOpen: SiteHeader.defaultProps.menuOpen,
  }),
})

const ComposedSiteHeader = compose(withUiState)(SiteHeader)

const StyledSiteHeader = styled(ComposedSiteHeader)`
  position: relative;

  ${SiteContainer} {
    padding-top: 5rem;
    padding-bottom: 21rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: none;
  }

  ${Logo} {
    width: 23rem;
    display: block;
    svg {
      display: block;
    }
  }

  ${TopoBackground} {
    visibility: hidden;
    background-size: auto;
  }

  .links {
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 2rem;
    overflow: hidden;
    position: relative;
    align-items: center;
  }

  ${LogoLink} {
    display: flex;

    &:hover {
      color: currentColor;
    }
  }

  ${IconNavLink} {
    margin-top: 3px;

    .isvg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .link-home {
    display: none;
  }

  ${MenuButton} {
    background-color: transparent;
    display: none;
    width: 2.4rem;
    height: 1.8rem;
    -webkit-tap-highlight-color: transparent;

    ${(p) =>
      p.page === routes.HOME &&
      css`
        &:focus {
          color: ${colours.primary};
        }
      `}
  }

  /* MEDIA: NAV */
  ${media.nav`

    ${TopoBackground} {
      visibility: visible;
      background-size: cover;
      min-width: 0;
      left: 0;
      transform: none;
    }

    ${SiteContainer} {
      padding-top: 4rem;
      padding-bottom: 8rem;
    }

    ${HomeNavLink},
    ${ShopNavLink},
    ${NavLink} {
      font-size: ${fonts.size03};
      border-bottom-width: 3px;
    }

    ${IconNavLink} {
      height: 5rem;
      width: 5rem;
    }

    .link-about,
    .link-home {
      display: block;
    }

    ${MenuButton} {
      display: block;
    }

    ${IconNavLink} {
      width: 4rem;
      height: 4rem;
      margin: auto

      .isvg {
        width: 4rem;
        height: 4rem;
      }
    }

    .links {
      background: ${colours.white};
      grid-template-columns: auto;
      justify-content: center;
      text-align: center;

      position: fixed;
      z-index: ${zIndexes.siteHeaderMobileLinks};
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      padding: 15vh 0;

      opacity: 0;
      pointer-events: none;
      transition: 0.2s opacity;
    }

    &.menu-is-open {
      ${MenuButton} {
        display: block;
        position: fixed;
        z-index: ${zIndexes.siteHeaderMobileCloseBtn};
        top: 5.6rem;
        right: 3rem;
      }

      .links {
        opacity: 1;
        pointer-events: initial;
      }
    }
  `}
`

export { SiteHeader, ComposedSiteHeader }
export default StyledSiteHeader
