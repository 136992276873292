import { css } from 'styled-components';

// import { fontGroup } from '../../../styles';
// import MapboxMap from '../../../../../components/MapboxMap';
// import { PrintLabel } from '../../../../../components/PrintLabel';
// import { PrintTitle } from '../../../../../components/PrintTitle';
// import { LogoCredits, PoweredByStrava } from '../../../../../components/Logo';

import commonStyles from '../styles-common';

export default () => css`
  ${commonStyles}
`;
