/**
 * Reads the contents of any file
 * @param {blob} file
 * @returns {Promise}
 */
export const parseFile = file => new Promise((resolve, reject) => {
  try {
    if (!file) throw Error('A file must be provided');
    const fr = new FileReader();
    fr.onload = ({ target }) => {
      if (target.error) reject(Error('Error while reading file'));
      resolve(target.result);
    };
    fr.onerror = () => {
      fr.abort();
      reject(new Error('Error while trying to read the file'));
    };
    fr.readAsText(file);
  } catch (error) {
    reject(error);
  }
});


/**
 * Reads the contents of a file and parses it into JSON
 * @param {blob} file
 * @returns {Promise}
 */
export const parseJsonFile = file => new Promise(async (resolve, reject) => {
  try {
    const content = await parseFile(file);
    resolve(JSON.parse(content));
  } catch (error) {
    reject(new Error('Error parsing JSON'));
  }
});


/**
 * Reads the contents of a file and parses it into XML DOM
 * @param {blob} file
 * @returns {Promise}
 */
export const parseXmlFile = file => new Promise(async (resolve, reject) => {
  try {
    const content = await parseFile(file);
    const xmlDom = new DOMParser().parseFromString(content, 'application/xml');
    resolve(xmlDom);
  } catch (error) {
    reject(new Error('Error parsing XML'));
  }
});


/**
 * Returns true if the file extension matches GPX or KML
 * @param {file[]} files
 */
export const filesAreXml = (files = []) =>
  Array.from([].concat(...files))
    .every(({ name }) => name.match(/\.gpx|\.kml$/gi));


/**
 * Returns true if the file extension matches JSON
 * @param {file[]} files
 */
export const filesAreJson = (files = []) =>
  Array.from([].concat(...files))
    .every(({ name, type }) => name.match(/\.json$/gi) && (!!type && type === 'application/json'));
