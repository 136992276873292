import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  printStyles,
  activityStyles,
  id: 'outlines2',
  name: 'Outlines: 2',
  mapStyles: 'mapbox://styles/papertrails/ckdeljdtd571h1hl6dot441b0',
  cssStyles,
  palette,
};
