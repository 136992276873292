import { flipLatlngs } from '../../utils/maps';
import { removeFalsy } from '../../utils/arrays';
import { activityUuid } from '../../utils/uuid';
import { PrintActivity } from '../../hocs/WithPrint/model';


/**
 * Generic Mapbox style object to create the structure required for the
 * various layer types
 *
 */
class MapboxLayer {
  constructor({ id = activityUuid(), latlngs }) {
    this.id = id;
    this.latlngs = latlngs;
    this.type = undefined;
    return this;
  }

  get layer() {
    return {
      id: `${this.id}`, // mapbox needs strings
      type: this.type,
      source: this.source,
      layout: this.layout,
      paint: this.paint,
    };
  }

  get layout() {
    return {};
  }

  get source() {
    return {};
  }

  get coords() {
    return flipLatlngs([...this.latlngs]);
  }
}


/**
 * Mapbox Line
 * @extends MapboxLayer
 * @docs https://docs.mapbox.com/mapbox-gl-js/style-spec#layers-line
 *
 */
export class MapboxLine extends MapboxLayer {
  static paintProps = {
    lineColor: 'line-color',
    lineWidth: 'line-width',
    lineDasharray: 'line-dasharray',
  };

  constructor({
    pathThickness = 4,
    pathColor = '#fff',
    pathDashed = false,
    ...props
  }) {
    super(props);
    this.type = 'line';
    this.pathThickness = this.sanitizePathThickness(pathThickness);
    this.pathColor = pathColor;
    this.pathDashed = pathDashed;
    this.paintProps = MapboxLine.paintProps;
    return this;
  }

  sanitizePathThickness(pathThickness) {
    return Number.isInteger(Number(pathThickness))
      ? Number(pathThickness)
      : PrintActivity.activityDefaults.pathThickness;
  }

  get paint() {
    const paint = {
      [MapboxLine.paintProps.lineColor]: this.pathColor,
      [MapboxLine.paintProps.lineWidth]: this.pathThickness,
    };
    if (this.pathDashed) {
      paint[MapboxLine.paintProps.lineDasharray] = [2, 2];
    }
    return paint;
  }

  get layout() {
    return this.pathDashed ? {
      'line-join': 'miter',
      'line-cap': 'butt',
    } : {
      'line-join': 'round',
      'line-cap': 'round',
    };
  }

  get source() {
    return {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: this.coords,
        },
      },
    };
  }
}


/**
 * Mapbox Circle
 * @extends MapboxLayer
 * @docs https://docs.mapbox.com/mapbox-gl-js/style-spec#layers-circle
 *
 */
export class MapboxCircle extends MapboxLayer {
  static paintProps = {
    color: 'circle-color',
    radius: 'circle-radius',
    strokeWidth: 'circle-stroke-width',
    strokeColor: 'circle-stroke-color',
  };

  constructor({
    latlngs = [],
    color = '#fff',
    radius = 4,
    opacity = 1,
    strokeWidth = 1,
    strokeColor = '#fff',
    strokeOpacity = 1,
    ...props
  }) {
    super(props);
    this.type = 'circle';
    this.color = color;
    this.radius = radius;
    this.opacity = opacity;
    this.strokeWidth = strokeWidth;
    this.strokeColor = strokeColor;
    this.strokeOpacity = strokeOpacity;
    this.latlngs = latlngs;
    this.paintProps = MapboxCircle.paintProps;
    return this;
  }

  get paint() {
    return {
      'circle-radius': this.radius,
      'circle-color': this.color,
      'circle-opacity': this.opacity,
      'circle-stroke-width': this.strokeWidth,
      'circle-stroke-color': this.strokeColor,
      'circle-stroke-opacity': this.strokeOpacity,
    };
  }

  get coords() {
    return [...this.latlngs].reverse();
  }

  get source() {
    return {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.coords,
        },
      },
    };
  }
}


/**
 * Mapbox Label
 * @extends MapboxLayer
 * @docs https://docs.mapbox.com/mapbox-gl-js/style-spec#layers-symbol
 *
 */
export class MapboxLabel extends MapboxLayer {
  constructor({
    title, size, color, offset = [0, 0], translate = [0, 0],
    anchor, rotate, justify, letterSpacing = 0, maxWidth, ...props
  }) {
    super(props);
    this.type = 'symbol';
    this.title = title;
    this.size = size;
    this.color = color;
    this.offset = offset;
    this.translate = translate;
    this.anchor = anchor;
    this.rotate = rotate;
    this.justify = justify;
    this.letterSpacing = letterSpacing;
    this.maxWidth = maxWidth;
    return this;
  }

  get paint() {
    return {
      'text-color': this.color,
      'text-translate': this.translate, // px
    };
  }

  get layout() {
    return {
      'text-field': '{title}',
      'symbol-placement': 'point',
      'text-font': ['Apercu'], // TODO: dynamic?
      'text-size': this.size, // px
      'text-offset': this.offset, // ems
      'text-anchor': this.anchor,
      'text-rotate': this.rotate,
      'text-justify': this.justify,
      'text-letter-spacing': this.letterSpacing, // ems
      'text-max-width': this.maxWidth, // ems
    };
  }

  get source() {
    return {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.coords,
        },
        properties: {
          title: this.title,
        },
      },
    };
  }
}


/**
 * creates a new Mapbox activity object from activity data, generating
 * all the possible interfaces and layers to use according
 * to the Mapbox style specification
 * @param {object} activity
 * @returns {MapboxActivity}
 */
export class MapboxActivity {
  static getDepandantLayerIds = ({ id, startNode, endNode }) => ({
    endNodeId: endNode ? `${id}-endnode` : undefined,
    startNodeId: startNode ? `${id}-startnode` : undefined,
  });

  // This method is useful to pull a list/enum of possible activity layer IDs,
  // used either when creating layers, or when mapbox has to move an activity
  // and needs to have a list of possible associated layer IDs which it owns
  static getAllPossibleLayerIds = activity => ({
    id: activity.id,
    ...MapboxActivity.getDepandantLayerIds(activity),
  });

  constructor(activity) {
    this._activityLine = this.createActivityLine(activity);
    this._activityStart = this.createActivityStart(activity);
    this._activityEnd = this.createActivityEnd(activity);
    return this;
  }

  createActivityLine(activity) {
    return new MapboxLine(activity);
  }

  createActivityStart(activity) {
    const { startNodeId } = MapboxActivity.getDepandantLayerIds(activity);
    if (!startNodeId) return undefined;
    return new MapboxCircle({
      id: startNodeId,
      latlngs: activity.latlngs.slice(0)[0],
      radius: this.calcEndpointSize(activity),
      color: activity.pathColor,
      strokeColor: activity.pathColor,
      strokeWidth: 0,
    });
  }

  createActivityEnd(activity) {
    const { endNodeId } = MapboxActivity.getDepandantLayerIds(activity);
    if (!endNodeId) return undefined;
    return new MapboxCircle({
      id: endNodeId,
      latlngs: activity.latlngs.slice(-1)[0],
      radius: this.calcEndpointSize(activity),
      color: activity.pathColor,
      strokeColor: activity.pathColor,
      strokeWidth: 0,
    });
  }

  calcEndpointSize(activity) {
    return (parseFloat(activity.pathThickness) * 1.8) + 1;
  }

  get activityLine() {
    return this._activityLine;
  }

  get activityStart() {
    return this._activityStart;
  }

  get activityEnd() {
    return this._activityEnd;
  }

  get activityLayers() {
    return removeFalsy([this.activityLine, this.activityStart, this.activityEnd]);
  }
}
