import { ORIENTATION_PORTRAIT } from '../../WithPrint/constants'

import badge from './pcultra-badge.png'
import logo from './pcultra-logo.png'
import metaImage from './pcultra-og-banner.jpg'

import pcu2022Full from './event-thumbs/pcultra-2022-full-thumb.jpg'
import pcu2022Short from './event-thumbs/pcultra-2022-short-thumb.jpg'
import pcu2022Mixed from './event-thumbs/pcultra-2022-mixed-thumb.jpg'
import pcu2023Full from './event-thumbs/pcultra-2023-full-thumb.png'
import pcu2023Short from './event-thumbs/pcultra-2023-short-thumb.png'
import pcu2023Mixed from './event-thumbs/pcultra-2023-mixed-thumb.png'

export const themes = [
  {
    themeName: 'pcUltra2023Full',
    image: pcu2023Full,
    orientation: ORIENTATION_PORTRAIT,
    title: 'PCR 2023: Long Route',
    subtitle: '2nd July 2023',
  },
  {
    themeName: 'pcUltra2023Short',
    image: pcu2023Short,
    orientation: ORIENTATION_PORTRAIT,
    title: 'PCR 2023: Short Route',
    subtitle: '2nd July 2023',
  },
  {
    themeName: 'pcUltra2023Mixed',
    image: pcu2023Mixed,
    orientation: ORIENTATION_PORTRAIT,
    title: 'PCR 2023: Mixed Route',
    subtitle: '2nd July 2023',
  },
  {
    themeName: 'pcUltra2022Full',
    image: pcu2022Full,
    orientation: ORIENTATION_PORTRAIT,
    title: 'PCR 2022: Full Route',
    subtitle: '10th July 2022',
  },
  {
    themeName: 'pcUltra2022Short',
    image: pcu2022Short,
    orientation: ORIENTATION_PORTRAIT,
    title: 'PCR 2022: Short Route',
    subtitle: '10th July 2022',
  },
  {
    themeName: 'pcUltra2022Mixed',
    image: pcu2022Mixed,
    orientation: ORIENTATION_PORTRAIT,
    title: 'PCR 2022: Mixed Route',
    subtitle: '10th July 2022',
  },
]

const themeNames = themes.map((theme) => theme.themeName)

export default {
  logo,
  badge,
  uri: 'pancelticultra',
  link: 'https://www.pancelticrace.com/',
  linkText: 'pancelticrace.com',
  blurb: `The Pan Celtic is a self-supported, ultra-endurance bicycle ride, journeying through the Celtic Nations of Wales, Scotland, Ireland, Cornwall, the Isle of Man and Brittany. A different route each year tackling stunning terrain and landscapes steeped in Celtic tradition.`,
  title: 'Pan Celtic Ultra',
  description: 'Select your event to personalise your print',
  logoMaxWidth: '39rem',
  metaTitle: 'Pan Celtic Ultra Custom Prints',
  metaDescription: 'Turn your Pan Celtic Ultra adventure into a work of art',
  metaImage,
  themes,
  themeNames,
}
