import { createSelector } from 'reselect'
import * as dateUtils from '../../utils/dates'
import * as stringUtils from '../../utils/numbers'
import * as cartUtils from '../../utils/cart'

import { SESSION_DOMAIN, PRODUCT_SKU_DIGITAL } from './constants'

export const withDomain = (state) => state[SESSION_DOMAIN]

export const authTokenSelector = createSelector(
  withDomain,
  (domain) => domain.authToken,
)

export const athleteSelector = createSelector(
  withDomain,
  (domain) => domain.athlete,
)

export const accessTokenSelector = createSelector(
  withDomain,
  (domain) => domain.accessToken,
)

export const refreshTokenSelector = createSelector(
  withDomain,
  (domain) => domain.refreshToken,
)

export const accessTokenExpirySelector = createSelector(
  withDomain,
  (domain) => domain.accessTokenExpires,
)

export const accessTokenHasExpiredSelector = createSelector(
  accessTokenExpirySelector,
  (accessTokenExpires) =>
    dateUtils.diffFromNow(accessTokenExpires, 'hours') < 1,
)

export const isAuthedWithStravaSelector = createSelector(
  accessTokenSelector,
  refreshTokenSelector,
  accessTokenHasExpiredSelector,
  (accessToken, refreshToken, accessTokenHasExpired) => {
    if (!!accessToken && !!refreshToken && !accessTokenHasExpired) return true
    return false
  },
)

export const shippingSelector = createSelector(
  withDomain,
  (domain) => domain.shipping,
)

export const productsSelector = createSelector(
  withDomain,
  (domain) => domain.products,
)

export const showCookiePolicySelector = createSelector(
  withDomain,
  (domain) => domain.showCookiePolicy,
)

export const hasTouch = createSelector(withDomain, (domain) => domain.hasTouch)

export const couponCheckInProgressSelector = createSelector(
  withDomain,
  (domain) => domain.couponCheckInProgress,
)

export const couponIdSelector = createSelector(
  withDomain,
  (domain) => domain.couponId,
)

// To use this selector the container needs to be wrapped in both
// WithPrint and WithSession HOCs. WithPrint needs to come first
export const currentProduct = createSelector(
  (_state, props) => props.printProduct,
  (_state, props) => props.printProductAttributes,
  productsSelector,
  (sku, attributes, products) =>
    cartUtils.getProduct(products, sku, attributes),
)

export const cheapestProductSelector = createSelector(
  productsSelector,
  cartUtils.cheapestProductReducer,
)

export const cheapestShippedProductSelector = createSelector(
  productsSelector,
  (products) =>
    cartUtils.cheapestProductReducer(
      products.filter(({ sku }) => ![PRODUCT_SKU_DIGITAL].includes(sku)),
    ),
)

export const cheapestProductPriceFormattedSelector = createSelector(
  cheapestProductSelector,
  (product) => {
    if (!product) return undefined
    return stringUtils.formatCurrency(product.price / 100, product.currency, 0)
  },
)
