import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  printStyles,
  activityStyles,
  id: 'heather',
  name: 'Heather',
  mapStyles: 'mapbox://styles/papertrails/ck1ain42b04tc1cmsphxw5d3p',
  cssStyles,
  palette,
};
