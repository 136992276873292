/* eslint-disable camelcase */
import { push } from 'connected-react-router'
import { takeLatest, takeLeading, select, put, call } from 'redux-saga/effects'
import _get from 'lodash.get'

import * as Actions from './actions'
import * as routes from '../../routes'
import * as Constants from './constants'
import * as Selectors from './selectors'
import { createPrintUuid } from '../../utils/print'
import { printUuid } from '../../utils/uuid'
import { getProduct, calcCartTotal } from '../../utils/cart'
import * as SessionSelectors from '../WithSession/selectors'
import * as SessionActions from '../WithSession/actions'
import * as PrintActions from '../WithPrint/actions'
import * as PrintSelectors from '../WithPrint/selectors'
import * as ToasterActions from '../WithToaster/actions'
import { TIMEOUT_DEFAULT } from '../WithToaster/constants'
import * as AnalyticsActions from '../WithAnalytics/actions'

import * as api from '../../api'

/**
 * Initiates a payment using Stripe details.
 * Handles if any further payment cnfirmation is required
 * by the user.
 * If payment confirmation is required, the response will include
 * stripeActionRequired and Stripe will open a popup in the browser.
 *
 */
export function* createStripeOrder(action) {
  try {
    const { paymentMethodId, user } = yield action

    yield put(Actions.setStripeActionRequired(false))
    yield put(Actions.setOrderInProgress(true))

    const print = yield select(PrintSelectors.printDataToSave)

    // It doesn't matter what print uuid the user currently has, calling
    // createPrintUuid here ensures we always receive a unique ID with the
    // most current date-prefix
    print.id = createPrintUuid()

    const response = yield call(
      api.apiCreateStripeOrder,
      print,
      paymentMethodId,
      user,
    )

    if (response && [200, 201, 202].includes(response.status)) {
      const { stripeActionRequired, intentSecret, orderId } = response
      if (stripeActionRequired) {
        yield put(Actions.setStripeActionRequired(true, intentSecret, orderId))
      } else {
        yield put(Actions.setOrderInProgress(false))
        const products = yield select(SessionSelectors.productsSelector)
        const product = yield getProduct(products, print.product)
        const total = yield calcCartTotal(product, print.quantity)
        const coupon = yield select(SessionSelectors.couponIdSelector)
        // yield put(AnalyticsActions.trackPrintOrdered(total / 100))

        yield put(
          AnalyticsActions.trackPurchase({
            id: print.id,
            coupon,
            value: total / 100,
            product: print.product,
            quantity: print.quantity,
          }),
        )

        yield put(AnalyticsActions.trackPrintMetrics(print))

        const currentProductsku = yield select(PrintSelectors.productSelector)
        const currentProductAttributes = yield select(
          PrintSelectors.productAttributesSelector,
        )
        yield put(PrintActions.resetPrint())
        yield put(
          PrintActions.updatePrint({
            product: currentProductsku,
            productAttributes: currentProductAttributes,
          }),
        )
        yield put(SessionActions.getProducts(/* no coupon */))
        yield put(SessionActions.setCouponId(undefined))

        yield put(push(routes.THANKS))
      }
    } else {
      yield put(Actions.setOrderInProgress(false))
      yield put(PrintActions.initPrint(printUuid()))
      // TODO: send me an email with the order & print id
      const errorMessage =
        _get(response, 'message') || _get(response, 'data.message') || null
      const message = `There was an error creating your order${
        errorMessage ? ` with the following message: "${errorMessage}"` : '.'
      } We're sorry about the hassle, but drop us a line and we'll try help where we can.`
      yield put(ToasterActions.createToast(message))
    }
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('createStripeOrder', error.message))
  }
}

/**
 * Called if payment confirmation is required by the user and the
 * Stripe popup is accepted.
 *
 */
export function* confirmStripeOrder(action) {
  try {
    const { paymentIntentId, user } = yield action

    yield put(Actions.setOrderInProgress(true))

    const orderId = yield select(Selectors.orderIdSelector)
    const print = yield select(PrintSelectors.printDataToSave)
    const response = yield call(
      api.apiConfirmStripeOrder,
      print,
      orderId,
      paymentIntentId,
      user,
    )

    yield put(Actions.setStripeActionRequired(false))
    yield put(Actions.setOrderInProgress(false))

    if (response && [200, 201, 202].includes(response.status)) {
      const products = yield select(SessionSelectors.productsSelector)
      const total = yield calcCartTotal(
        getProduct(products, print.product),
        print.quantity,
      )
      // yield put(AnalyticsActions.trackPrintOrdered(total / 100))

      const coupon = yield select(SessionSelectors.couponIdSelector)
      yield put(
        AnalyticsActions.trackPurchase({
          id: print.id,
          coupon,
          value: total / 100,
          product: print.product,
          quantity: print.quantity,
        }),
      )

      yield put(AnalyticsActions.trackPrintMetrics(print))

      const currentProductsku = yield select(PrintSelectors.productSelector)
      const currentProductAttributes = yield select(
        PrintSelectors.productAttributesSelector,
      )
      yield put(PrintActions.resetPrint())
      yield put(
        PrintActions.updatePrint({
          product: currentProductsku,
          productAttributes: currentProductAttributes,
        }),
      )

      yield put(SessionActions.getProducts(/* no coupon */))
      yield put(SessionActions.setCouponId(undefined))

      yield put(push(routes.THANKS))
    } else {
      yield put(PrintActions.initPrint(printUuid()))
      // TODO: send me an email with the order & print id
      const errorMessage =
        _get(response, 'message') || _get(response, 'data.message') || null
      const message = `There was an error creating your order${
        errorMessage ? ` with the following message: "${errorMessage}"` : '.'
      } We're sorry about the hassle, but drop us a line and we'll try help where we can.`
      yield put(ToasterActions.createToast(message))
    }
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('confirmStripeOrder', error.message))
  }
}

/**
 * Calls the api to create an order using PayPal details.
 *
 */
export function* createPaypalOrder(action) {
  try {
    const { paypalOrderId, coupon } = yield action

    yield put(Actions.setOrderInProgress(true))

    const print = yield select(PrintSelectors.printDataToSave)

    // It doesn't matter what print uuid the user currently has, calling
    // createPrintUuid here ensures we always receive a unique ID with the
    // most current date-prefix
    print.id = createPrintUuid()

    const response = yield call(
      api.apiCreatePaypalOrder,
      print,
      paypalOrderId,
      coupon,
    )
    yield put(Actions.setOrderInProgress(false))

    if (response && [200, 201, 202].includes(response.status)) {
      const products = yield select(SessionSelectors.productsSelector)
      const total = yield calcCartTotal(
        getProduct(products, print.product),
        print.quantity,
      )

      // yield put(AnalyticsActions.trackPrintOrdered(total / 100))
      yield put(
        AnalyticsActions.trackPurchase({
          id: print.id,
          coupon,
          value: total / 100,
          product: print.product,
          quantity: print.quantity,
        }),
      )

      yield put(AnalyticsActions.trackPrintMetrics(print))

      const currentProductsku = yield select(PrintSelectors.productSelector)
      yield put(PrintActions.resetPrint())
      yield put(PrintActions.updatePrint({ product: currentProductsku }))
      yield put(SessionActions.getProducts())

      yield put(push(routes.THANKS))
    } else {
      yield put(PrintActions.initPrint(printUuid()))
      // TODO: send me an email with the order & print id
      const errorMessage =
        _get(response, 'message') || _get(response, 'data.message') || null
      const message = `There was an error creating your order${
        errorMessage ? ` with the following message: "${errorMessage}"` : '.'
      } We're sorry about the hassle, but drop us a line and we'll try help where we can.`
      yield put(ToasterActions.createToast(message))
    }
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('createPaypalOrder', error.message))
  }
}

export function* createZeroCostOrderSaga(action) {
  try {
    const { user } = yield action

    yield put(Actions.setOrderInProgress(true))

    const print = yield select(PrintSelectors.printDataToSave)

    print.id = createPrintUuid()

    const response = yield call(api.apiCreateZeroCostOrder, print, user)

    if (response && [200, 201, 202].includes(response.status)) {
      yield put(Actions.setOrderInProgress(false))
      const products = yield select(SessionSelectors.productsSelector)
      const total = yield calcCartTotal(
        getProduct(products, print.product),
        print.quantity,
      )
      // yield put(AnalyticsActions.trackPrintOrdered(total / 100))
      const coupon = yield select(SessionSelectors.couponIdSelector)
      yield put(
        AnalyticsActions.trackPurchase({
          id: print.id,
          coupon,
          value: total / 100,
          product: print.product,
          quantity: print.quantity,
        }),
      )

      yield put(AnalyticsActions.trackPrintMetrics(print))

      const currentProductsku = yield select(PrintSelectors.productSelector)
      const currentProductAttributes = yield select(
        PrintSelectors.productAttributesSelector,
      )
      yield put(PrintActions.resetPrint())
      yield put(
        PrintActions.updatePrint({
          product: currentProductsku,
          productAttributes: currentProductAttributes,
        }),
      )
      yield put(SessionActions.getProducts(/* no coupon */))
      yield put(SessionActions.setCouponId(undefined))

      yield put(push(routes.THANKS))
    } else {
      yield put(Actions.setOrderInProgress(false))
      yield put(PrintActions.initPrint(printUuid()))
      // TODO: send me an email with the order & print id
      const errorMessage =
        _get(response, 'message') || _get(response, 'data.message') || null
      const message = `There was an error creating your order${
        errorMessage ? ` with the following message: "${errorMessage}"` : '.'
      } We're sorry about the hassle, but drop us a line and we'll try help where we can.`
      yield put(ToasterActions.createToast(message))
    }
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('createStripeOrder', error.message))
  }
}

/**
 * Fetches a print order from the store and
 * replaces the current print if successfull
 *
 */
export function* getPrintOrderSaga(action) {
  try {
    const { printId } = yield action
    const response = yield call(api.apiGetPrintOrder, printId)
    if (response && [200].includes(response.status)) {
      yield put(PrintActions.replacePrintWithJsonSaga(response.print))
    } else {
      yield put(
        ToasterActions.createToast(
          "Hmm, looks like we couldn't find that order 😱",
          TIMEOUT_DEFAULT,
        ),
      )
    }
  } catch (error) {
    console.error(error)
    yield put(AnalyticsActions.trackException(error.stack, false))
    yield put(AnalyticsActions.trackError('getPrintOrderSaga', error.message))
  }
}

export function* watchCreateStripeOrder() {
  // takeLeading means we only accept the first actions until the sagas completes
  yield takeLeading(Constants.CREATE_STRIPE_ORDER_REQUEST, createStripeOrder)
}

export function* watchConfirmStripeOrder() {
  yield takeLatest(Constants.CONFIRM_STRIPE_ORDER_REQUEST, confirmStripeOrder)
}

export function* watchCreatePaypalOrder() {
  yield takeLeading(Constants.CREATE_PAYPAL_ORDER_REQUEST, createPaypalOrder)
}

export function* watchZeroCostOrderSaga() {
  yield takeLeading(
    Constants.CREATE_ZERO_COST_ORDER_REQUEST,
    createZeroCostOrderSaga,
  )
}

export function* watchGetPrintOrderSaga() {
  yield takeLatest(Constants.GET_PRINT_ORDER_REQUEST, getPrintOrderSaga)
}

export default [
  watchCreateStripeOrder,
  watchConfirmStripeOrder,
  watchCreatePaypalOrder,
  watchZeroCostOrderSaga,
  watchGetPrintOrderSaga,
]
