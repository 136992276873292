import { takeLatest, select, put } from 'redux-saga/effects';

import * as Selectors from './selectors';
import * as Constants from './constants';
import * as Actions from './actions';
import * as PrintActions from '../WithPrint/actions';
import * as PrintSelectors from '../WithPrint/selectors';
import * as AnalyticsActions from '../WithAnalytics/actions';


/**
 * When changing themes we have to change the currently selected theme name,
 * but also update the current print styles with the theme's preint styles
 *
 */
export function* changeTheme(action) {
  try {
    const { themeName } = yield action;
    const themeOptions = yield select(Selectors.themeOptionsSelector);
    const eventThemeOptions = yield select(Selectors.eventThemeOptionsSelector);
    const allThemeOptions = [].concat(themeOptions, eventThemeOptions);
    const themeOptionExists = yield allThemeOptions.find(option => option.value === themeName);
    if (!themeOptionExists) return;

    yield put(Actions.setThemeName(themeName));

    const printStyles = yield select(Selectors.themePrintStylesSelector);
    const activityStyles = yield select(Selectors.themeActivityStylesSelector);

    const themeFirstTextMapLayerLayerIdSelector = yield select(Selectors.themeFirstTextMapLayerLayerIdSelector);
    const renderBeforeLayerId = yield select(PrintSelectors.renderBeforeLayerIdSelector);

    // If we load map styles from a URL we don't have access to the first
    // place/text label. If no possible valid place label exists we unset the option
    const printSettings = {
      renderBeforeLayerId: themeFirstTextMapLayerLayerIdSelector
        ? renderBeforeLayerId
        : undefined,
    };

    yield put(PrintActions.updatePrint({
      ...printStyles,
      ...printSettings,
      themeName,
    }));

    yield put(PrintActions.setAllPrintActivityStyles(activityStyles));
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('changeTheme', error.message));
  }
}

export function* watchChangeTheme() {
  yield takeLatest(Constants.CHANGE_THEME, changeTheme);
}

export default [
  watchChangeTheme,
];
