import { initialState } from './model';
import * as Constants from './constants';

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case Constants.SET_THEME_NAME: {
      const { themeName = initialState.themeName } = action;
      return state.merge({ themeName });
    }

    default: {
      return state;
    }
  }
};

export default reducers;
