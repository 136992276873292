import { css } from 'styled-components'

import { APERCU, TERMINA } from './fonts'

// COLOURS
// -----------------------------------------------------------------------------
export const colours = {
  primary: '#ff3801',
  primaryDark: '#d42e00',
  secondary: '#393772',
  secondaryLight: '#64639a',
  secondaryDark: '#2a267f',
  tertiary: '#f4a151',
  tertiaryLight: '#ffb369',
  logoGradient1: '#f2b761',
  logoGradient2: '#f9722d',
  logoGradient3: '#ff3801',

  promoBg01: '#fbc360',
  promoBg02: '#4d3da6',

  white: '#ffffff',
  black: '#000000',
  grey01: '#f6f6f6',
  grey02: '#eeeeee',
  grey03: '#b3b3b3',
  grey04: '#e2e2e2',
  grey05: '#7b7b7b',
  grey06: '#fafafa',
  grey07: '#f5f5f5',
  grey08: '#535353',
  grey09: '#dadada',
  grey10: '#9f9f9f',
  grey11: '#f9f9f9',
}

// FONTS
// -----------------------------------------------------------------------------
export const fonts = {
  family01: APERCU,
  family02: TERMINA,

  size01: '1.4rem',
  size02: '2rem',
  size03: '2.6rem',
  size04: '3.4rem',
  size05: '1.2rem',
  size06: '1.6rem',
  size07: '2.8rem',
  size08: '4.6rem',
  size09: '1.8rem',

  weightNormal: '400',
  weightMedium: '500',
  weightBold: '600',

  lineHeight01: '1.45',
  lineHeight02: '1.55',
  lineHeight03: '1.75',
  lineHeight04: '1.3',
}

// DIMENSIONS
// -----------------------------------------------------------------------------
export const dimensions = {
  siteGutter: '6rem',
  siteMaxWidth: '150rem',
  buttonHeight: '6.4rem',
  borderRadius: '1.2rem',
  printPadding: '4.5rem',
  printPaddingPx: 60,
}

// TRANSITIONS
// -----------------------------------------------------------------------------
export const transitions = {
  linkDuration: '0.1s',
  linkEasing: 'ease',

  buttonDuration: '0.1s',
  buttonEasing: 'ease',

  inputDuration: '0.1s',
  inputEasing: 'ease',

  sidebarDuration: '0.6s',
  sidebarEasing: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',

  colorButtonDuration: '0.15s',
  colorButtonEasing: 'ease',
}

// SHADOWS / MISC
// -----------------------------------------------------------------------------
export const shadows = {
  themeCard: '0 15px 40px 0 rgba(0, 0, 0, 0.15)',
  themeCardHover: '0 3px 10px 0 #00000036',

  printFrame: '0 10px 20px 0 rgba(0,0,0,0.15)',

  sidebarOpen: '0 -6px 20px 0 rgba(0,0,0,0.1)',
  sidebarClosed: 'none',
}

// EASINGS
// -----------------------------------------------------------------------------
export const easings = {
  expoIn: 'cubic-bezier(0.950, 0.050, 0.795, 0.035)',
  expoOut: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  expoInout: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
}

export default css`
  :root {
    /* insert CSS vars here */
  }
}`
