import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';

import HocComponent from '../HocComponent';

import * as Actions from './actions';
import * as Selectors from './selectors';

export const MakeWithPrintHoc = WrappedComponent => new HocComponent(WrappedComponent, 'withToasterHoc');

export const WithPrintHoc = () => (WrappedComponent) => {
  const WithPrint = MakeWithPrintHoc(WrappedComponent);

  const mapStateToProps = createStructuredSelector({
    toasts: Selectors.toastsSelector,
  });

  const mapDispatchToProps = dispatch => bindActionCreators({
    createToast: Actions.createToast,
    removeToast: Actions.removeToast,
    removeAllToasts: Actions.removeAllToasts,
  }, dispatch);

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const composed = compose(withConnect)(WithPrint);

  return composed;
};

export default WithPrintHoc;
