import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { shadows } from '../../theme';

const UnstyledPrintFrame = props => (
  <section className={classnames('printframe', props.className)} style={props.style}>
    <div className="inner">
      {props.children}
    </div>
  </section>
);

UnstyledPrintFrame.propTypes = {};

UnstyledPrintFrame.defaultProps = {};

const PrintFrame = styled(UnstyledPrintFrame)`
  box-shadow: ${shadows.printFrame};
  display: flex;
  overflow: hidden;
`;
export { PrintFrame, UnstyledPrintFrame };
export default PrintFrame;
