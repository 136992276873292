import { createGlobalStyle, css } from 'styled-components'
import styledNormalize from 'styled-normalize'

import theme, { colours, fonts, dimensions } from './theme'
import fontStyles from './fonts'
import { mediaSizes } from './utils/sizes'

let i = 3
export const zIndexes = {
  behind: -1,
  zero: 0,
  raised: 1,
  // Only unique UI elements below here
  createMobileActions: i++, // fixed to bottom on mobile
  colorPickerWrapper: i++, // clickable to close picker
  toaster: i++, // notifications
  dropLoader: i++,
  siteHeaderMobileLinks: i++,
  siteHeaderMobileCloseBtn: i++,
}

// https://www.styled-components.com/docs/advanced#media-templates
// Can use like: media.nav`...`
export const media = Object.keys(mediaSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${mediaSizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const makeMedia =
  (width) =>
  (...args) =>
    css`
      @media screen and (max-width: ${width}px) {
        ${css(...args)}
      }
    `

export default createGlobalStyle`
  ${styledNormalize}
  ${theme}
  ${fontStyles}

  ::-moz-selection { color: ${colours.secondary}; background: ${
  colours.primary
}; }
  ::selection { color: ${colours.secondary};background: ${colours.primary}; }
  a::-moz-selection { color: ${colours.white}; }
  a::selection { color: ${colours.white}; }

  * {
    box-sizing: border-box;
  }

  [title] {
    cursor: help;
  }

  html, body {
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%; // so our rem's can be proportional to a metric system
  }

  body {
    font-family: ${fonts.family01};
    background-color: ${colours.white};
    font-size: ${fonts.size07};
    font-weight: ${fonts.weightNormal};
    line-height: ${fonts.lineHeight02};
    color: ${colours.secondary};

    &.noscroll {
      overflow: hidden;
    }

    ${media.nav`
      font-size: ${fonts.size02};
    `}
  }

  h1, h2, h3, h4, h5 {
    font-family: ${fonts.family01};
    font-weight: inherit;
    font-size: inherit;
    margin: 0;
  }

  p { margin-top: 0; font-family: ${fonts.family01}; }
  p:last-child { margin-bottom: 0; }

  img { max-width: 100%; }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button, select, option, input, textarea {
    font-family: ${fonts.family01};
    color: inherit;
    font-size: inherit;
    font-weight: ${fonts.weightNormal};
  }

  a {
    font-family: ${fonts.family01};
    color: inherit;
    &:hover {
      color: ${colours.primary};
    }
  }


  /* Sticky footer stuff */
  .app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .main {
    display: flex;
    flex-grow: 1;
  }

  .sortable-sorting {
    pointer-events: auto !important;
    cursor: row-resize !important;
    z-index: ${zIndexes.raised};

    &:after {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      opacity: 0.5;
      background: ${colours.white};
      border: 2px dashed ${colours.primary};
      border-radius: ${dimensions.borderRadius};
    }
  }

  @keyframes imageCycle {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

`

export const dashedLine = css`
  background-image: linear-gradient(
    180deg,
    ${colours.primary} 25%,
    transparent 25%,
    transparent 50%,
    ${colours.primary} 50%,
    ${colours.primary} 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 32px 32px;
`
