import { css } from 'styled-components'

import { loadTypekitSiteFonts } from '../utils/fonts'

import apercu400woff from './apercu-400-woff'
import apercu400woff2 from './apercu-400-woff2'
import apercu500woff from './apercu-500-woff'
import apercu500woff2 from './apercu-500-woff2'

export const APERCU = 'Apercu'
export const TERMINA = 'termina'

window._font = `

  @font-face {
    font-family: APERCU;
    src: ${apercu400woff}, ${apercu400woff2};
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: APERCU;
    src: ${apercu500woff}, ${apercu500woff2};
    font-weight: 500;
    font-style: normal;
  }

`

const getTerminaStyles = () => {
  const [termina] = loadTypekitSiteFonts([TERMINA])
  return termina.styles
}

export default css`
  @font-face {
    font-family: APERCU;
    src: ${apercu400woff}, ${apercu400woff2};
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: APERCU;
    src: ${apercu500woff}, ${apercu500woff2};
    font-weight: 500;
    font-style: normal;
  }

  ${getTerminaStyles()}
`
