import React from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Hero from '../../images/bemc-strava.jpg'
import HeroMobile from '../../images/strava-bemc-mobile-rounded.png'
import { STRAVA_BEMC_2024, STRAVA_BEMC_2024_CAMPAIGN } from '../../routes'
import Link from '../Link'

const Outer = styled.div`
  border-bottom: 1px solid #e3e3e3;
`

const Container = styled.div`
  width: 100%;
  max-width: 150rem;
  margin: auto;
  padding-left: 6rem;
  padding-right: 6rem;
  max-width: calc(122.6rem + 6rem + 6rem);
  margin-top: 34px;
  margin-bottom: 8rem;
  padding-bottom: 8rem;
  @media (max-width: 768px) {
    margin-top: 0;
    padding-bottom: 4rem;
    padding-left: 0rem;
    padding-right: 0rem;
    img {
      width: 100vw;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  h2 {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 4.6rem;
    line-height: 1.2;
    max-width: 986px;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      font-size: 2.6rem;
      line-height: 1.2;
      margin-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  p {
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 1.55;
    max-width: 693px;
    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  a.button {
    display: block;
    text-decoration: none;
    background-color: #ff3801;
    color: #fff;
    border-radius: 12px;
    border: none;
    font-size: 26px;
    line-height: 50px;
    outline: 0;
    padding: 1.5rem 8rem;
    text-align: center;
    box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.21);
    &:hover {
      background-color: #d42e00;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
    }
    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
      margin-bottom: 4rem;
      display: inline-flex;
      font-size: 2rem;
      font-weight: 500;
      line-height: 24px;
      padding: 1em 6rem 1em 6rem;
    }
  }
  div.flex {
    display: flex;
    margin-top: 4rem;
    column-gap: 100px;
    @media (max-width: 768px) {
      flex-direction: column;
      padding-left: 2rem;
      padding-right: 2rem;
      span {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`

const Desktop = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const HomeStrava = () => {
  return (
    <Outer>
      <Container>
        <Desktop>
          <img src={Hero} alt="" />
        </Desktop>
        <Mobile>
          <img src={HeroMobile} alt="" />
        </Mobile>
        <h2>
          Join the Best Efforts Marathon Challenge powered by adidas and chase
          your best (or first) marathon PR.
        </h2>
        <div className="flex">
          <p>
            Record your best time on Strava. Annual Strava subscribers with a
            top 3 effort earn a free custom print to memorialize the route, the
            stats and all the sweat that got them to the finish line.
          </p>
          <span>
            <Link to={STRAVA_BEMC_2024_CAMPAIGN} className="button">
              Learn More
            </Link>
          </span>
        </div>
      </Container>
    </Outer>
  )
}

export default HomeStrava
