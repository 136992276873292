import { ORIENTATION_LANDSCAPE, ORIENTATION_PORTRAIT } from '../hocs/WithPrint/constants';

export const A2_WIDTH_PORTRAIT = 1191;
export const A2_HEIGHT_PORTRAIT = 1684;

export const MAX_WIDTH_PORTRAIT = 900;
export const MAX_HEIGHT_PORTRAIT = 1274;

export const ELEVATION_PROFILE_HEIGHT = 80;


/**
 * Retuns the print size depending on orientation.
 * For now we only deal with A sizes so we can reuse A2 dimentions
 * @param {string} orientation
 * @returns {object}
 */
export const getPrintSize = (orientation = ORIENTATION_PORTRAIT) => {
  const size = { width: A2_WIDTH_PORTRAIT, height: A2_HEIGHT_PORTRAIT };
  return (orientation === ORIENTATION_LANDSCAPE)
    ? { width: size.height, height: size.width }
    : size;
};


/**
 * The maximum size of the browser in the page, depending on orientation
 * @param {string} orientation
 * @returns {object}
 */
export const getPrintMaxSize = (orientation = ORIENTATION_PORTRAIT) => {
  const size = { width: MAX_WIDTH_PORTRAIT, height: MAX_HEIGHT_PORTRAIT };
  return (orientation === ORIENTATION_LANDSCAPE)
    ? { width: size.height, height: size.width }
    : size;
};


/**
 * The ratio of the portrait depending on the orientation
 * @param {string} orientation
 * @returns {number}
 */
export const getPrintRatio = (orientation) => {
  const size = getPrintSize(orientation);
  return size.width / size.height;
};


/**
 * Media breakpoints
 */
export const mediaSizes = {
  create: 1200,
  footer: 1060,
  nav: 800,
};

export default getPrintSize;
