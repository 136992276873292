import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { matchPropTypes, historyPropTypes } from '../../proptypes';
import * as routes from '../../routes';
import { dimensions, fonts, colours } from '../../theme';
import { media, makeMedia } from '../../styles';

import WithEvents from '../../hocs/WithEvents';
import WithSession from '../../hocs/WithSession';
import { eventPropTypes } from '../../hocs/WithEvents/model';

import SiteHeader from '../SiteHeader';
import SiteContainer from '../../components/SiteContainer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { LargeText, Text } from '../../components/Text';
import { Title } from '../../components/Title';
import { LinkAsButton, LinkAsPrimaryButton } from '../../components/Link';
import { ThemesList, ThemesListItem, ThemesListItemInner } from '../../components/ThemesList';

const { REACT_APP_SITE } = process.env;

const ThemesSiteContainer = styled(SiteContainer)``;
const CreateTitle = styled(Title)``;
const CreateLink = styled(LinkAsPrimaryButton)``;

const EventBadge = styled.img`
  max-width: 31.8rem;
  width: 100%;
`;

const Blurb = styled.div`
  padding: 10rem 8rem;
  background-color: ${colours.grey11};
  max-width: 100rem;
  margin: auto;
  text-align: center;
  border-radius: ${dimensions.borderRadius};
  overflow: hidden;
`;

class UnstyledEventDetail extends React.Component {
  static propTypes = {
    match: matchPropTypes.isRequired,
    history: historyPropTypes.isRequired,
    events: PropTypes.arrayOf(eventPropTypes).isRequired,
  };

  constructor(props) {
    super(props);
    const eventUri = props.match.params[routes.EVENT_UUID];

    const event = props.events.find(({ uri }) => uri === eventUri);
    if (!event) {
      props.history.replace(routes.FOUR_OH_FOUR);
    }

    this.state = {
      event,
    };
  }

  render() {
    const { event } = this.state;
    if (!event) return null;

    return (
      <React.Fragment>
        <ScrollToTopOnMount />
        <Helmet>
          <title>{event.title}</title>
          <meta name="description" content={event.description} />

          <meta property="og:url" content={`${REACT_APP_SITE}${routes.EVENTS}/${event.uri}`} />
          <meta property="og:title" content={event.metaTitle} />
          <meta property="og:description" content={event.metaDescription} />
          <meta property="og:image" content={`${REACT_APP_SITE}${event.metaImage}`} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="1200" />

          <meta name="twitter:title" content={event.metaTitle} />
          <meta name="twitter:description" content={event.metaDescription} />
          <meta name="twitter:image" content={`${REACT_APP_SITE}${event.metaImage}`} />

        </Helmet>
        <article className={this.props.className}>
          <SiteHeader />
          <ThemesSiteContainer>
            <div>
              <header className="page-header">
                <EventBadge className={event.uri} src={event.logo} alt={event.title} style={{ maxWidth: event.logoMaxWidth }} />
                <LargeText>{event.description}</LargeText>
              </header>
              <ThemesList>
                {event.themes.map(theme => (
                  <ThemesListItem
                    image={theme.image}
                    title={theme.title}
                    subtitle={theme.subtitle}
                    key={theme.themeName}
                    orientation={theme.orientation}
                    to={`${routes.CREATE}?themeName=${theme.themeName}`}
                  />
                ))}
              </ThemesList>
            </div>

          </ThemesSiteContainer>
          <section className="event-link">
            <SiteContainer>
              <Blurb>
                {!!event.blurb && (
                  <Text dangerouslySetInnerHTML={{ __html: event.blurb }} />
                )}
                <LinkAsButton to={event.link}>{event.linkText}</LinkAsButton>
              </Blurb>
            </SiteContainer>
          </section>

          <ThemesSiteContainer>
            <aside className="create">
              <CreateTitle tag="h4">Cherish your own adventure, from {this.props.cheapestProductPriceFormatted}.</CreateTitle>
              <CreateLink to={routes.CREATE}>Let's get started!</CreateLink>
            </aside>
          </ThemesSiteContainer>
        </article>
      </React.Fragment>
    );
  }
}

UnstyledEventDetail.propTypes = {

};
UnstyledEventDetail.defaultProps = {

};

const EventDetail = styled(UnstyledEventDetail)`
  width: 100%;
  overflow: hidden;
  position: relative;

  ${EventBadge} {
    margin-bottom: 4.5rem;
  }

  ${LargeText} {
    max-width: 26em;
    margin: 0 auto 2rem;
  }

  ${Blurb} {
    ${Text} {
      font-size: ${fonts.size07};
      margin-bottom: 2em;
    }
  }

  ${LinkAsButton} {
    margin: auto;
    font-size: inherit;
    height: auto;
    padding: 1.3em 3em;
    display: inline-block;
    width: auto;
  }

  .page-header {
    text-align: center;
  }

  ${SiteHeader} ${SiteContainer} {
      padding-bottom: 12rem
    }


  ${ThemesSiteContainer} {
    margin-top: -5rem;
    max-width: calc(144rem + ${dimensions.siteGutter} + ${dimensions.siteGutter});
  }

  ${ThemesList} {
    margin: 8rem auto 0;
    max-width: 130rem;
  }

  .event-link {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .create {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
    position: relative;

    &:before {
      content: '';
      border-top: 2px solid ${colours.grey02};
      position: absolute;
      width: 100vw;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${CreateTitle} {
    margin-bottom: 5rem;
  }

  ${CreateLink} {
    text-decoration: none;
    font-size: ${fonts.size03};
    height: 8.8rem;
    max-width: 42rem;
  }

  ${media.create`

  `}

  ${media.footer`

    ${Blurb} {
      padding: 8rem 6rem;
    }

    .event-link {
      padding-top: 12rem;
      padding-bottom: 15rem;
    }

    ${ThemesList} {
      margin-top: 12rem;
    }


    ${ThemesList} {
      margin-top: 6rem;
    }

  `}

  ${media.nav`

    ${ThemesSiteContainer} {
      margin-top: 0;
    }

    ${SiteHeader} ${SiteContainer} {
      padding-bottom: 4rem
    }

    ${EventBadge} {
      max-width: 24rem !important;
      margin-bottom: 2rem;

      &.gravelrally {
        width: 80%;
      }
    }

    ${Blurb} {
      padding: 6rem 2rem;
      ${Text} {
        font-size: ${fonts.size02};
      }
    }

    ${LargeText} {
      font-size: ${fonts.size03};
      margin-bottom: 2rem;
    }

    .event-link {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    ${LinkAsButton} {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .create {
      margin-top: 5rem;
      padding: 6rem 0 8rem;
    }

    ${CreateTitle} {
      font-size: ${fonts.size03};
    }

    ${CreateLink} {
      font-size: ${fonts.size02};
    }

  `}

  ${makeMedia(560)`

    ${ThemesList} {
      grid-row-gap: 14vw;
    }

    ${ThemesListItem} {
      &.portrait {
        &:nth-child(n+1) ${ThemesListItemInner} {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  `}

`;

const withSession = WithSession();
const withEvents = WithEvents();

export { EventDetail, UnstyledEventDetail };
export default compose(withSession, withEvents)(EventDetail);
