import generate from 'nanoid/generate';

const characterRange = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const uuid = (charLength = 16) => generate(characterRange, charLength);


/**
 * For toaster pop-ups
 *
 */
export const toasterUuid = () => uuid(10);


/**
 * For activities and labels
 *
 */
export const activityUuid = () => uuid(10);


/**
 * For Prints
 * Time-prefixed by time followed by a 10-digit uid
 *
 */
export const printUuid = () => uuid(10);


export default uuid;
