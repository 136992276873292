/* istanbul ignore file */
export const lockScrolling = (lock = false) => lock
  ? document.body.classList.add('noscroll')
  : document.body.classList.remove('noscroll');

export const attemptScrollToHash = (id) => {
  const hash = window.location.hash.replace('#', '');
  if (hash && hash === id) {
    const el = document.getElementById(hash);
    if (el) el.scrollIntoView();
  }
};


/**
 * Keeps track of the current device's screen
 *
 */
export class DeviceScreen {
  constructor() {
    this.updateSizes();
  }

  // call this after the window has resized
  updateSizes() {
    this._sh = window.screen.height || window.outerHeight;
    this._wh = window.outerHeight;
  }

  deviceScreenChanged() {
    return (this._sh !== window.screen.height);
  }

  windowHeightChanged() {
    return (this._wh !== window.outerHeight);
  }

  /**
   * Returns true if the device size hasn't changed, but the window has.
   * Useful to know if the window height has been affected by device
   * keyboards or location bar toggling
   *
   * @param {bool} hasTouch if the device has touch or not
   */
  mobileDeviceUiTriggeredResize(hasTouch = false) {
    return (hasTouch && !this.deviceScreenChanged() && this.windowHeightChanged());
  }

}
