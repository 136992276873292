import PropTypes from 'prop-types';

export const matchPropTypes = PropTypes.shape({
  params: PropTypes.objectOf(PropTypes.string),
});

export const historyPropTypes = PropTypes.shape({
  replace: PropTypes.func,
  push: PropTypes.func,
});

export const locationPropTypes = PropTypes.shape({
  search: PropTypes.string,
});
