import styled from 'styled-components';

import Figure from '../Figure';

import beer from './beer.png';

const ForScale = styled(Figure).attrs({
  image: beer,
  alt: 'Beer',
})`
  pointer-events: none;
  width: 34rem;
`;

export { ForScale };
export default ForScale;
