import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'

import HocComponent from '../HocComponent'

import * as Actions from './actions'
import * as Selectors from './selectors'

export const MakeWithSessionHoc = (WrappedComponent) =>
  new HocComponent(WrappedComponent, 'withSessionHoc')

export const WithSessionHoc = () => (WrappedComponent) => {
  const WithSession = MakeWithSessionHoc(WrappedComponent)

  const mapStateToProps = createStructuredSelector({
    athlete: Selectors.athleteSelector,
    authToken: Selectors.authTokenSelector,
    isAuthedWithStrava: Selectors.isAuthedWithStravaSelector,
    shippingCountries: Selectors.shippingSelector,
    products: Selectors.productsSelector,
    currentProduct: Selectors.currentProduct,
    cheapestProduct: Selectors.cheapestProductSelector,
    cheapestProductPriceFormatted:
      Selectors.cheapestProductPriceFormattedSelector,
    cheapestShippedProduct: Selectors.cheapestShippedProductSelector,
    showCookiePolicy: Selectors.showCookiePolicySelector,
    hasTouch: Selectors.hasTouch,
    couponCheckInProgress: Selectors.couponCheckInProgressSelector,
    couponId: Selectors.couponIdSelector,
  })

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        getStravaAuthToken: Actions.getStravaAuthToken,
        setStravaAuthToken: Actions.setStravaAuthToken,
        getStravaAccessToken: Actions.getStravaAccessToken,
        acceptCookies: Actions.acceptCookies,
        getCoupon: Actions.getCoupon,
        setCouponId: Actions.setCouponId,
      },
      dispatch,
    )

  const withConnect = connect(mapStateToProps, mapDispatchToProps)

  const composed = compose(withRouter, withConnect)(WithSession)

  return composed
}

export default WithSessionHoc
