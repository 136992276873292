import React from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';

import { colours } from '../../theme';
import { loadGoogleFonts } from '../../utils/fonts';
import attributionSvg from './map-attribution.svg';

export const UnstyledAttribution = ({ className }) => (
  <span className={className}>
    &copy; Mapbox, &copy; OpenStreetmap
  </span>
);

UnstyledAttribution.propTypes = {};
UnstyledAttribution.defaultProps = {};

export const Attribution = styled(UnstyledAttribution)`
  ${loadGoogleFonts(['Roboto Mono'])[0].styles}
  padding: 0.8rem 0.6rem;
  font-size: 1.1rem;
  padding: 0.8rem 0.6rem;
  line-height: 1;
  color: ${colours.grey08};
`;

const UnstyledAttributionSvg = props => <Svg {...props} src={attributionSvg} />;

export const AttributionSvg = styled(UnstyledAttributionSvg)`
  color: black;
  display: block;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;
