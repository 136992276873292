import { createSelector } from 'reselect';
import { ORDERS_DOMAIN } from './constants';

export const withDomain = state => state[ORDERS_DOMAIN];

export const orderInProgressSelector = createSelector(
  withDomain,
  domain => domain.orderInProgress,
);

export const stripeActionRequiredSelector = createSelector(
  withDomain,
  domain => domain.stripeActionRequired,
);

export const intentSecretSelector = createSelector(
  withDomain,
  domain => domain.intentSecret,
);

export const orderIdSelector = createSelector(
  withDomain,
  domain => domain.orderId,
);
