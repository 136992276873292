/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react'
import _get from 'lodash.get'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _debounce from 'lodash.debounce'
import Immutable from 'seamless-immutable'

import mapbox from 'mapbox-gl/dist/mapbox-gl-csp'
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'

import './styles.css'

import { zIndexes } from '../../styles'
import { shallowCompare, removeFalsy } from '../../utils/arrays'
import { flipLatlngs, mapStylesHaveChanged } from '../../utils/maps'
import { ORIENTATION_OPTIONS, LAYOUT_OPTIONS } from '../../hocs/WithPrint/model'
import {
  mapboxReduceMultiplePathsToBounds,
  optimiseMapboxTiles,
} from '../../utils/mapbox'

import { Attribution } from '../Attribution'

import { MapboxActivity } from './models'

const { REACT_APP_OPENMAPTILES_KEY, REACT_APP_MAPBOX_ACCESS_TOKEN } =
  process.env

// Padding between routes and the edge of the map in px
const MAPBOX_PADDING_BOUNDS = 100

mapbox.workerClass = MapboxWorker
mapbox.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN

export const propTypes = {
  mapStyles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      // TODO: add more here
    }),
  ]),
}

class UnstyledMapboxMap extends React.Component {
  static propTypes = {
    interactive: PropTypes.bool,
    animate: PropTypes.bool,
    controls: PropTypes.bool,
    styles: propTypes.mapStyles,
    orientation: PropTypes.oneOf(ORIENTATION_OPTIONS || []), // imports are empty in tests?
    layout: PropTypes.oneOf(LAYOUT_OPTIONS || []),
    product: PropTypes.string,
    zoom: PropTypes.number,
    center: PropTypes.arrayOf(PropTypes.number),
    rotation: PropTypes.number,
    activities: PropTypes.arrayOf(PropTypes.shape({})),
    renderBeforeLayerId: PropTypes.string,

    onZoomChanged: PropTypes.func,
    onCenterChanged: PropTypes.func,
    onRotationChanged: PropTypes.func,
    onTilesLoaded: PropTypes.func,
    onMapIdle: PropTypes.func,
    onMapFailed: PropTypes.func,
  }

  static defaultProps = {
    interactive: true,
    animate: false,
    controls: false,
    styles: undefined,
    orientation: undefined,
    layout: undefined,
    product: undefined,
    zoom: undefined,
    center: undefined,
    rotation: undefined,
    activities: [],
    renderBeforeLayerId: undefined,

    onZoomChanged: () => {},
    onCenterChanged: () => {},
    onRotationChanged: () => {},
    onTilesLoaded: () => {},
    onMapIdle: () => {},
    onMapFailed: () => {},
  }

  constructor(props) {
    super(props)
    this.map = undefined
  }

  componentDidMount() {
    this.drawMap()
  }

  componentWillReceiveProps(nextProps) {
    // reposition the map over the loaded activities when any
    // of the props change
    if (
      nextProps.zoom === undefined &&
      nextProps.center === undefined &&
      nextProps.rotation === undefined
    )
      this.fitBounds(nextProps)
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.activitiesHaveChanged(nextProps) ||
      this.props.styles !== nextProps.styles ||
      this.props.layout !== nextProps.layout ||
      this.props.product !== nextProps.product ||
      this.props.orientation !== nextProps.orientation ||
      this.props.renderBeforeLayerId !== nextProps.renderBeforeLayerId
    )
  }

  componentDidUpdate(prevProps) {
    this.updateMap(prevProps)

    // TODO: there's a 'shouldMapBoxResize' method in the parent
    // container which controls mapbox resizing if any external
    // factors have been changed.
    // Remember to check there if you need to add anything, but should these props
    // and resize in general all be controlled there?
    if (
      prevProps.layout !== this.props.layout ||
      prevProps.styles.name !== this.props.styles.name ||
      prevProps.orientation !== this.props.orientation ||
      // Alrways trigger a resize if any activities have been updated
      prevProps.activities.length !== this.props.activities.length
    )
      this.map.resize()
  }

  onTilesLoaded = () => {
    this.props.onTilesLoaded()
  }

  onZoomChanged = _debounce(() => {
    this.props.onZoomChanged(this.map.getZoom())
  }, 500)

  onCenterChanged = _debounce(() => {
    const center = this.map.getCenter()
    this.props.onCenterChanged([center.lng, center.lat])
  }, 500)

  onRotationChanged = _debounce(() => {
    this.props.onRotationChanged(this.map.getBearing())
  }, 500)

  onMapIdle = () => {
    this.props.onMapIdle()
  }

  onMapLoad = () => {
    this.drawLayers()
    this.fitBounds(this.props)
    // required to retrigger the canvas size to avoid stretching
    this.map.resize()
    this.onTilesLoaded()

    // These events have to be declared after load and after fitbounds
    // has been called, otherwise we'll the map will always update
    // to the default zoom and center values and not actually fit around
    // the route
    this.map.on('zoomend', this.onZoomChanged)
    this.map.on('moveend', this.onCenterChanged)
    this.map.on('rotate', this.onRotationChanged)

    // TODO: use this hook to send back valid 'render before' layers
    // this.map.on('styledata', (data) => {
    //   console.log(data)
    // });
  }

  activitiesHaveChanged(nextProps) {
    const nextActivities = nextProps.activities
    const currentActivities = this.props.activities

    const nextActivitiesSources = nextActivities.map((a) => a.source)
    const currentActivitiesSources = currentActivities.map((a) => a.source)
    if (!shallowCompare(nextActivitiesSources, currentActivitiesSources)) {
      this.removeUnusedLayers(nextProps)
      return true
    }

    if (!nextActivities.length) return false

    const activityPropsChanged = currentActivities.some((activity, index) => {
      const nextActivity = _get(nextActivities, [index])
      if (!nextActivity) return false
      return Object.keys(activity).some(
        (prop) => activity[prop] !== nextActivity[prop],
      )
    })
    if (activityPropsChanged) {
      this.removeDependantLayers(nextProps)
      return true
    }
    return false
  }

  drawMap() {
    this.map = new mapbox.Map({
      container: this.mapRef,
      interactive: this.props.interactive,
      preserveDrawingBuffer: false,
      easeTo: false,
      dragPan: true,
      boxZoom: false,
      dragRotate: true,
      touchZoomRotate: true,
      pitchWithRotate: false,
      attributionControl: false,
      trackResize: true,
      transformRequest: (url) => ({
        url: url.replace('{key}', REACT_APP_OPENMAPTILES_KEY),
      }),
    })

    // We're manually adding attribution to the DOM
    // if (this.props.attribution) {
    //   this.map.addControl(new mapbox.AttributionControl({ compact: true }));
    // }

    if (this.props.controls) {
      this.map.addControl(new mapbox.NavigationControl(), 'top-right')
    }

    this.addStyles()

    // Fired after the last frame rendered before the map enters an "idle" state:
    // • No camera transitions are in progress
    // • All currently requested tiles have loaded
    // • All fade / transition animations have completed
    this.map.on('idle', this.onMapIdle)

    // Fired immediately after all necessary resources have been downloaded
    // and the first visually complete rendering of the map has occurred.
    this.map.on('load', this.onMapLoad)
  }

  updateMap(prevProps) {
    if (this.stylesHaveChanged(prevProps)) this.addStyles()
    this.fitBounds(this.props)
    this.drawLayers()
  }

  stylesHaveChanged = (prevProps) =>
    mapStylesHaveChanged(this.props.styles, prevProps.styles)

  // TODO:
  // Could optimise this by stacking the updated styles and only once the
  // map is ready to accept styles the latest one is taken
  addStyles = () => {
    this._addStyleAttempts = this._addStyleAttempts || 1
    let currentStyle

    try {
      currentStyle = this.map.getStyle()
    } catch (e) {
      // Yup, Mapbox throws an error if we call getStyle with no style
      // being set
    }

    if (!currentStyle || this.map.loaded()) {
      const styleIsString = typeof this.props.styles === 'string'

      // forcing diff to false if the styles are loaded via a URL
      // Should really find a way to compare previous styles with new styles
      // and find out if the sources are different.
      // Need to test switching between two URL styles and a normal object theme
      const diff = !styleIsString && !!currentStyle && this.map.isStyleLoaded()

      const styledObject = this.props.styles.default
        ? this.props.styles.default
        : this.props.styles

      const styles = styleIsString
        ? optimiseMapboxTiles(this.props.styles)
        : Immutable.asMutable(styledObject, { deep: true })

      this.map.setStyle(styles, { diff })
      this._addStyleAttempts = undefined
      return true
    }

    this._addStyleAttempts++
    setTimeout(this.addStyles, 200)
    return false
  }

  drawLayers = () => {
    if (!this.map.isStyleLoaded()) {
      this._drawLayersAttempts = this._drawLayersAttempts || 0
      this._drawLayersAttempts++
      if (this._drawLayersAttempts >= 30) {
        // 500ms x 60 = 30 seconds
        const activities =
          this.props.activities.length > 1 ? 'activities' : 'activity'
        this.props.onMapFailed(
          `Sorry, loading your ${activities} seems to be a bit slow, please try reloading the page`,
        )
        return
      }
      setTimeout(this.drawLayers, 500)
      return
    }

    this._drawLayersAttempts = 0

    this.props.activities.forEach(this.drawActivity)
  }

  drawActivity = (activity) => {
    if (!activity.latlngs || !activity.latlngs.length) return
    const { activityLayers } = new MapboxActivity(activity)
    activityLayers.forEach((activityLayer) => {
      const existingLayer = this.map.getLayer(activityLayer.id)
      if (existingLayer) {
        this.updateLayer(activityLayer)
      } else {
        this.map.addLayer(activityLayer.layer, this.props.renderBeforeLayerId)
      }
    })
  }

  updateLayer(layerObj) {
    const { layer } = layerObj
    Object.values(layerObj.paintProps).forEach((paintProp) => {
      const layerPaintProp = _get(layer, ['paint', paintProp])
      const mapPaintProp = this.map.getPaintProperty(layer.id, paintProp)
      const propIsArray =
        Array.isArray(layerPaintProp) || Array.isArray(mapPaintProp)
      if (propIsArray && !shallowCompare(mapPaintProp, layerPaintProp)) {
        this.map.setPaintProperty(layer.id, paintProp, layerPaintProp)
      } else if (mapPaintProp !== layerPaintProp) {
        this.map.setPaintProperty(layer.id, paintProp, layerPaintProp)
      }
    })
    this.updateLayerOrder(layer.id)
  }

  updateLayerOrder(layerId) {
    this.map.moveLayer(layerId, this.props.renderBeforeLayerId)
  }

  removeUnusedLayers(nextProps) {
    // compares the next/previous activity ids - if any have been added or
    // removed we remove all unecessary layers for that activity
    const nextActivityIds = nextProps.activities.map(
      (activity) => `${activity.id}`,
    )
    this.props.activities.forEach((currentActivity) => {
      if (!nextActivityIds.includes(`${currentActivity.id}`)) {
        this.removeAllLayersForActivity(currentActivity)
      }
    })
  }

  removeDependantLayers(nextProps) {
    const nextDeps = nextProps.activities.reduce(
      this.reduceMapActivitiesToDependantLayers,
      {},
    )
    const currentDeps = this.props.activities.reduce(
      this.reduceMapActivitiesToDependantLayers,
      {},
    )
    Object.keys(currentDeps).forEach((activityId) => {
      const depKeys = Object.keys(currentDeps[activityId])
      depKeys.forEach((depKey) => {
        const currVal = _get(currentDeps, [activityId, depKey])
        const nextVal = _get(nextDeps, [activityId, depKey])
        if (currVal && !nextVal) {
          this.removeLayer(currVal)
        }
      })
    })
  }

  reduceMapActivitiesToDependantLayers = (acc = {}, activity) => {
    acc[activity.id] = MapboxActivity.getDepandantLayerIds(activity)
    return acc
  }

  removeAllLayersForActivity(activity) {
    const depActivityIds = MapboxActivity.getAllPossibleLayerIds(activity)
    removeFalsy(Object.values(depActivityIds)).forEach(this.removeLayer)
  }

  removeLayer = (layerId) => {
    const layerIdStr = `${layerId}`
    if (this.map.getLayer(layerIdStr)) {
      this.map.removeLayer(layerIdStr)
      this.map.removeSource(layerIdStr)
    }
  }

  fitBounds(props) {
    const bounds = this.getBounds() || [
      [0, 0],
      [0, 0],
    ]
    const options = {
      padding: MAPBOX_PADDING_BOUNDS,
      // duration 0 and linear true removes the fly-to animation
      ...(this.props.animate
        ? {
            duration: 800,
            linear: false,
          }
        : {
            duration: 0,
            linear: true,
          }),
    }
    if (props.zoom) options.zoom = props.zoom
    if (props.center) options.center = props.center
    if (props.rotation) options.bearing = props.rotation
    this.map.fitBounds(bounds, options)
  }

  getBounds() {
    const activityPaths = [...this.props.activities].map((a) =>
      flipLatlngs(a.latlngs),
    )
    return mapboxReduceMultiplePathsToBounds(activityPaths)
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className="mapbox-wrapper">
          <div
            id="mapboxmap"
            data-version={mapbox.version}
            ref={(r) => (this.mapRef = r)}
          >
            {/* Mapbox DOM appended here */}
          </div>
          <Attribution />
        </div>
      </div>
    )
  }
}

const MapboxMap = styled(UnstyledMapboxMap)`
  position: relative;
  width: 100%;
  height: 100%;

  .mapbox-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .mapboxgl-map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-canvas-container {
    height: 100%;
  }

  .mapboxgl-canvas {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    &:focus {
      outline: none;
    }
  }

  .mapboxgl-ctrl-logo {
    opacity: 0.9;
  }

  ${Attribution} {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.9;
    writing-mode: vertical-lr;
    transform: rotate(0deg);
    z-index: ${zIndexes.raised};
  }
`

export { UnstyledMapboxMap, MapboxMap }

export default MapboxMap
