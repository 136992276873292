import { ORIENTATION_PORTRAIT } from '../../WithPrint/constants';

import logo from './pcgr-logo.png';
import badge from './pcgr-badge.png';
import metaImage from './pcgr-og-banner.jpg';

import pcgrFull from './event-thumbs/pcgr-2022-full-thumb.jpg';
import pcgrShort from './event-thumbs/pcgr-2022-short-thumb.jpg';

export const themes = [{
  themeName: 'pcGravelRally2022Full',
  image: pcgrFull,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Gravel Rally: Full',
  subtitle: '5th June 2022',
}, {
  themeName: 'pcGravelRally2022Short',
  image: pcgrShort,
  orientation: ORIENTATION_PORTRAIT,
  title: 'Gravel Rally: Short',
  subtitle: '5th June 2022',
}];

const themeNames = themes.map(theme => theme.themeName);

export default {
  logo,
  badge,
  uri: 'gravelrally',
  link: 'https://www.pancelticrace.com/gravel-rally/',
  linkText: 'pancelticrace.com/gravel-rally',
  blurb: `The Gravel Rally is an all-new one day micro-adventure taking place in Snowdonia, North Wales on June 5th 2022`,
  title: 'Pan Celtic Gravel Rally',
  description: 'Select your event to personalise your print',
  logoMaxWidth: '39rem',
  metaTitle: 'Pan Celtic Gravel Rally Custom Prints',
  metaDescription: 'Turn your Gravel Rally adventure into a work of art',
  metaImage,
  themes,
  themeNames,
};
