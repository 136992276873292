/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colours, fonts, transitions, shadows, dimensions } from '../../theme';
import { Title } from '../Title';
import Link from '../Link';
import { splitNewlines } from '../../utils/strings';

export const EventTitle = styled(Title)`
  font-weight: ${fonts.weightMedium};
  color: inherit;
  margin-top: 1.1em;
`;

export const EventTitleLine = styled('span')`
  display: inline-block;
`

const Inner = styled.figure`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  background-color: ${colours.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

const Logo = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 100%;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.11);
  border-radius: ${dimensions.borderRadius};
  transform: scale(1);
  transition: ${transitions.linkDuration} ${transitions.linkEasing};
  backface-visibility: hidden;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colours.primary};
    opacity: 0;
    pointer-events: none;
    transition: .3s ease opacity;
  }
`;

const LinkWrapper = styled(Link)`
  text-align: center;
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: ${colours.primary};

  ${EventTitle} {
    text-decoration: underline;
  }

  &:hover {
    ${Logo} {
      box-shadow: ${shadows.themeCardHover};
      transform: scale(.99);

      &:after {
        opacity: 0.75;
      }
    }
  }
`;

export const EventCard = styled(props => (
  <LinkWrapper to={props.to} className={props.className}>
    <Logo>
      <Inner>
        <img src={props.image} alt={props.title} />
      </Inner>
    </Logo>
    <EventTitle>
      {splitNewlines(props.title).map((line, i) => <EventTitleLine key={`${props.title}-${i}`}>{line}</EventTitleLine>)}
    </EventTitle>
  </LinkWrapper>
))`

`;

EventCard.propTypes = {
  to: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

EventCard.defaultProps = {
  color: 'inherit',
};


// -----------------------------------------------------------------------------
// PLACEHOLDER WRAPPEr
// -----------------------------------------------------------------------------

const PlaceholderWrapper = styled.div`
  text-align: center;
  display: block;
  cursor: initial;
  text-decoration: none;
  color: ${colours.grey04};

  ${EventTitle} {}

  ${Logo} {
    background-image: none;
    background-color: ${colours.grey02};
  }

  span {
    color: ${colours.grey02};
    font-size: 5.5rem;
  }
`;

export const EventCardPlaceholder = styled(props => (
  <PlaceholderWrapper className={props.className}>
    <Logo>
      <Inner>
        <span>•&nbsp;•&nbsp;•</span>
      </Inner>
    </Logo>
    <EventTitle>{props.title}</EventTitle>
  </PlaceholderWrapper>
))``;

EventCardPlaceholder.propTypes = {
  title: PropTypes.string,
};
EventCardPlaceholder.defaultProps = {
  title: 'More coming soon',
};

export default EventCard;
