import Immutable from 'seamless-immutable';

export const initialState = Immutable({
  orderInProgress: false,

  // These are only set when manual confirmation of
  // the charge is required from the user
  stripeActionRequired: false,
  intentSecret: undefined,
  orderId: undefined,
});

export default initialState;
