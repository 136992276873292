import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { splitNewlines } from '../../utils/strings';


/**
 * Default/ Primary Header
 *
 */
const UnstyledPrintTitle = props => (
  <h1 className={props.className}>
    {splitNewlines(props.children).map((line, i) => (
      <div key={`${line}-${i}`}>{line}</div> // eslint-disable-line react/no-array-index-key
    ))}
  </h1>
);

UnstyledPrintTitle.propTypes = {
  children: PropTypes.string,
};

UnstyledPrintTitle.defaultProps = {
  children: '',
};

const PrintTitle = styled(UnstyledPrintTitle)`
  line-height: 1.1;
  font-family: inherit;
`;


/**
 * Secondary Header
 *
 */
const UnstyledPrintSecondaryTitle = props => (
  <h2 className={props.className}>
    {splitNewlines(props.children).map((line, i) => (
      <div key={`${line}-${i}`}>{line}</div> // eslint-disable-line react/no-array-index-key
    ))}
  </h2>
);

UnstyledPrintSecondaryTitle.propTypes = {
  children: PropTypes.string,
};

UnstyledPrintSecondaryTitle.defaultProps = {
  children: '',
};

const PrintSecondaryTitle = styled(UnstyledPrintSecondaryTitle)`
  line-height: 1.4;
  font-family: inherit;
`;

export { PrintTitle, UnstyledPrintTitle, PrintSecondaryTitle, UnstyledPrintSecondaryTitle };
export default PrintTitle;
