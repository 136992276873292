export const TOASTER_DOMAIN = 'toaster';

export const TIMEOUT_DEFAULT = 5000;

export const CREATE_TOAST = 'CREATE_TOAST';
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS';

// export const ERR_MSG_GENERAL = 'Oops, something broke! If you see this again please let us know so we can fix it';
export const ERR_FILE_UPLOAD = 'Oops! Looks like something\'s broken in your file, sorry 😟';
