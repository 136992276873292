import Immutable from 'seamless-immutable'
import _get from 'lodash.get'

import { activityUuid } from '../../utils/uuid'
import * as googleUtils from '../../utils/google'
import * as arrayUtils from '../../utils/arrays'
import { calcElevationGain } from '../../utils/print'

import {
  LATLNGS_LIMIT_COUNT,
  activitySources,
} from '../WithActivities/constants'
import * as Constants from './constants'

const orientationOptions = [
  {
    label: 'Portrait',
    value: Constants.ORIENTATION_PORTRAIT,
    icon: 'orientationPortrait',
  },
  {
    label: 'Landscape',
    value: Constants.ORIENTATION_LANDSCAPE,
    icon: 'orientationLandscape',
  },
]

const layoutOptions = [
  {
    title: 'Bottom Horizontal',
    value: Constants.LAYOUT_BOTTOM_HORIZONTAL,
    icon: 'layoutBottomHorizontal',
  },
  {
    label: 'Top Horizontal',
    value: Constants.LAYOUT_TOP_HORIZONTAL,
    icon: 'layoutTopHorizontal',
  },
  {
    title: 'Bottom Vertical',
    value: Constants.LAYOUT_BOTTOM_VERTICAL,
    icon: 'layoutBottomVertical',
  },
  {
    label: 'Top Vertical',
    value: Constants.LAYOUT_TOP_VERTICAL,
    icon: 'layoutTopVertical',
  },
]

const printColorOptions = [
  {
    label: 'Primary Text Colour',
    value: 'primaryColor',
  },
  {
    label: 'Secondary Text Colour',
    value: 'secondaryColor',
  },
  {
    label: 'Background Colour',
    value: 'backgroundColor',
  },
]

/**
 * @important
 * Any values added herre also need to be added to the Print model
 * in api.papertrails.io
 */
export const initialState = Immutable({
  id: undefined,
  title: 'My Epic Adventure',
  secondaryTitle: undefined,
  product: undefined,
  productAttributes: undefined,
  quantity: 1,
  zoom: undefined,
  center: undefined,
  rotation: undefined,
  renderBeforeLayerId: undefined,
  orientation: orientationOptions[0].value,
  layout: layoutOptions[0].value,
  padding: true,
  porthole: false,
  activities: [],
  labels: [],

  // Themeable
  // TODO: should these live as constants somewhere?
  primaryColor: undefined,
  secondaryColor: undefined,
  backgroundColor: undefined,

  // Not used when saving or generating prints
  options: {
    orientation: orientationOptions,
    layout: layoutOptions,
    printColors: printColorOptions,
  },
})

/**
 * All print options that can be used in prop-types
 */
export const LAYOUT_OPTIONS = layoutOptions.map(layout => layout.value)
export const ORIENTATION_OPTIONS = orientationOptions.map(
  orientation => orientation.value,
)

/**
 * class PrintLabel
 * -----------------------------------------------------------------------------
 *
 */
export class PrintLabel {
  static labelDefaults = {
    title: 'New Label',
    value: 'Put something here',
  }

  constructor(props = PrintLabel.labelDefaults) {
    const labelProps = { ...PrintLabel.labelDefaults, ...props }
    return {
      id: activityUuid(),
      ...labelProps,
    }
  }
}

/**
 * PrintActivity
 * -----------------------------------------------------------------------------
 *
 * Activities could come from either Strava Activities, Strava
 * activity streams or GPX files
 *
 */
export class PrintActivity {
  static activityDefaults = {
    id: undefined,
    title: undefined,
    secondaryTitle: undefined,
    distance: undefined,
    movingTime: undefined,
    elapsedTime: undefined,
    elevationGain: undefined,
    elevationLoss: undefined,
    elevations: undefined,
    latlngs: undefined,
    polyline: undefined,
    calories: undefined,
    source: undefined,
    wattsAvg: undefined,
    wattsMax: undefined,
    wattsWeighted: undefined,
    speedAvg: undefined,
    speedMax: undefined,
    pace: undefined,
    startNode: false,
    endNode: false,

    // themed properties
    pathThickness: 3,
    pathColor: undefined,
    pathDashed: false,
    elevationProfile: false,
    elevationProfileColor: undefined,
  }

  constructor(props, styles = {}) {
    this.props = props
    // TODO: should find another way the styles are added to an activity
    this.styles = styles
    return this.activity
  }

  get activity() {
    return {
      ...this.activityStyles,
      id: this.id,
      title: this.title,
      secondaryTitle: this.secondaryTitle,
      date: this.date,
      calories: this.calories,
      polyline: this.polyline,
      latlngs: this.latlngs,
      distance: this.distance,
      movingTime: this.movingTime,
      elapsedTime: this.elapsedTime,
      elevations: this.elevations,
      elevationGain: this.elevationGain,
      elevationLoss: this.elevationLoss,
      elevationProfile: this.elevationProfile,
      pace: this.pace,
      speedAvg: this.speedAvg,
      speedMax: this.speedMax,
      startNode: this.startNode,
      endNode: this.endNode,
      pathDashed: this.pathDashed,
    }
  }

  get id() {
    return this.props.id || activityUuid()
  }

  get title() {
    return this.props.title
  }

  get secondaryTitle() {
    return this.props.secondaryTitle
  }

  get activityStyles() {
    return {
      ...PrintActivity.activityDefaults,
      ...this.styles,
    }
  }

  get polyline() {
    return this.props.polyline
  }

  get latlngs() {
    return this.props.latlngs
  }

  get distance() {
    return this.props.distance
  }

  get date() {
    return this.props.date
  }

  get movingTime() {
    return this.props.movingTime
  }

  get elapsedTime() {
    return this.props.elapsedTime
  }

  get elevationGain() {
    return this.props.elevationGain
  }

  get elevations() {
    return this.props.elevations
  }

  get elevationLoss() {
    return this.props.elevationLoss
  }

  get elevationProfile() {
    return this.props.elevationProfile
  }

  get calories() {
    return this.props.calories
  }

  get wattsAvg() {
    return this.props.wattsAvg
  }

  get wattsMax() {
    return this.props.wattsMax
  }

  get wattsWeighted() {
    return this.props.wattsWeighted
  }

  get speedAvg() {
    return this.props.speedAvg
  }

  get speedMax() {
    return this.props.speedMax
  }

  get pace() {
    return this.props.pace
  }

  get endNode() {
    return this.props.endNode
  }

  get startNode() {
    return this.props.startNode
  }

  get pathDashed() {
    return this.props.pathDashed
  }
}

/**
 * GpxPrintActivity
 * -----------------------------------------------------------------------------
 *
 * Extended class specifically for creating
 * Print activity models from GPX data
 *
 */
export class GpxPrintActivity extends PrintActivity {
  get title() {
    return this.props.name
  }

  get activity() {
    const activity = super.activity
    return {
      ...activity,
      source: activitySources.GPX,
    }
  }

  get latlngs() {
    return arrayUtils.thinToLength(this.props.latlngs, LATLNGS_LIMIT_COUNT)
  }

  get speedAvg() {
    if (!this.distance || !this.elapsedTime) return undefined
    return this.distance / this.elapsedTime
  }

  get pace() {
    const mins = this.elapsedTime / 60
    const kms = this.distance / 1000
    return parseFloat((mins / kms).toFixed(2))
  }

  get elevations() {
    return arrayUtils.thinToLength(this.props.altitudes, LATLNGS_LIMIT_COUNT)
  }
}

/**
 * StravaPrintActivity
 * -----------------------------------------------------------------------------
 *
 * Extended class specifically for creating
 * Print activity models from Strava data
 *
 */
export class StravaPrintActivity extends PrintActivity {
  get title() {
    return this.props.name
  }

  get date() {
    return this.props.start_date
  }

  get streams() {
    return this.props.streams
  }

  get polyline() {
    let polyline = _get(
      this.props,
      'map.summary_polyline',
      _get(this.props, 'map.polyline', null),
    )
    if (!polyline) {
      const latlngs = _get(this.streams, 'latlng.data', [])
      polyline = googleUtils.googleEncodeLatLngToPolyline(latlngs)
    }
    return polyline
  }

  get latlngs() {
    const latlngs = this.props.latlngs
      ? this.props.latlngs
      : googleUtils
          .googleDecodePolylineToLatLngs(
            _get(
              this.props,
              'map.polyline',
              _get(this.props, 'map.summary_polyline', ''),
            ),
          )
          .map(latlng => [latlng.lat(), latlng.lng()])
    return arrayUtils.thinToLength(latlngs, LATLNGS_LIMIT_COUNT)
  }

  get distance() {
    return this.props.distance
      ? this.props.distance
      : _get(this.streams, 'distance.data', []).pop()
  }

  get movingTime() {
    return this.props.moving_time
    // TODO: streams
  }

  get elapsedTime() {
    return this.props.elapsed_time
      ? this.props.elapsed_time
      : _get(this.streams, 'time.data', []).pop()
  }

  get elevationGain() {
    return this.props.total_elevation_gain
      ? this.props.total_elevation_gain
      : calcElevationGain(_get(this.streams, 'altitude.data', []))
  }

  get elevations() {
    const altitudes = _get(this.streams, 'altitude.data', [])
    return arrayUtils.thinToLength(altitudes, LATLNGS_LIMIT_COUNT)
  }

  get activity() {
    const activity = super.activity
    return {
      ...activity,
      source: activitySources.STRAVA,
    }
  }

  get wattsAvg() {
    return this.props.average_watts
  }

  get wattsMax() {
    return this.props.max_watts
  }

  get wattsWeighted() {
    return this.props.weighted_average_watts
  }

  // strava always comes back with m/s
  get speedAvg() {
    return this.props.average_speed
  }

  get speedMax() {
    return this.props.max_speed
  }

  get pace() {
    const mins = this.elapsedTime / 60
    const kms = this.distance / 1000
    return parseFloat((mins / kms).toFixed(2))
  }
}
