import { css } from 'styled-components';

import { PrintTitle, PrintSecondaryTitle } from '../../../../components/PrintTitle';
import { PrintLabelTitle, PrintLabelValue, PrintLabel } from '../../../../components/PrintLabel';
import { loadTypekitThemeFonts } from '../../../../utils/fonts';
import { LogoCredits, PoweredByStrava } from '../../../../components/Logo';
import { colours } from '../../../../theme';

export default (theme) => {
  const loadedTypekitFonts = loadTypekitThemeFonts([
    'termina:700',
    'termina:600',
    'alternate-gothic-no-3-d',
  ]);

  return css`
    ${PrintTitle} {
      font-size: 3.2rem;
      letter-spacing: 1.2px;
      ${loadedTypekitFonts[0].styles}
    }

    ${PrintSecondaryTitle} {
      text-transform: uppercase;
      font-size: 1.8rem;
      letter-spacing: 0.4px;
      ${loadedTypekitFonts[2].styles}
    }

    ${PrintLabelValue} {
      font-size: 1.6rem;
      ${loadedTypekitFonts[1].styles}
    }

    ${PrintLabelTitle} {
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      ${loadedTypekitFonts[2].styles}
    }

    ${LogoCredits},
    ${PoweredByStrava} {
      color: ${colours.white};
      opacity: 0.6;
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.primaryColor};
        opacity: .17;
      }
    }
  `;
};
