import _get from 'lodash.get'
import _sum from 'lodash.sum'
import arrayMove from 'array-move'
import _omit from 'lodash.omit'

import { activitySources } from '../WithActivities/constants'
import * as PrintUtils from '../../utils/print'
import * as DateUtils from '../../utils/dates'
import * as Constants from './constants'
import { PrintLabel, PrintActivity, initialState } from './model'

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case Constants.INIT_PRINT: {
      return state.set('id', action.id)
    }

    case Constants.RESET_PRINT: {
      return initialState
    }

    case Constants.RESET_MAP_BOUNDS: {
      return state.merge({
        ...resetMapBoundsProps(),
      })
    }

    // TODO: break this down into specific actions so we can test each one
    case Constants.UPDATE_PRINT: {
      const { properties } = action
      // Changing orientation shouuld re-fit map bounds?
      let updatedProperties = properties
      if (Object.keys(properties).includes('orientation')) {
        updatedProperties = {
          ...updatedProperties,
          ...resetMapBoundsProps(),
        }
      }
      return state.merge(updatedProperties)
    }

    case Constants.REBASE_PRINT: {
      const { properties } = action
      const strippedProps = {
        ..._omit(properties, [
          'id',
          '_id',
          'orderId',
          'chargeId',
          'chargeType',
          'status',
          'updatedAt',
          'createdAt',
        ]),
        ...resetMapBoundsProps(),
      }
      return state.merge(strippedProps)
    }

    case Constants.SET_DEFAULT_PRINT_ACTIVITIES: {
      const { activities, printSettings = {} } = action
      const { zoom, center, rotation, orientation } = printSettings
      return state.merge({
        activities,
        ...generatePrintFields(activities),
        ...resetMapBoundsProps({
          zoom,
          center,
          rotation,
        }),
        ...resetNonActivityRelatedProps({
          orientation,
        }),
      })
    }

    case Constants.ADD_PRINT_ACTIVITIES: {
      // This makes sure we always replace any previous default activities
      const nonDefaultActivites = [...state.activities].filter((activity) =>
        [activitySources.STRAVA, activitySources.GPX].includes(activity.source),
      )

      // Elevation profiles
      const newActivitiesSupportElevations = action.activities.every(
        (a) => !!a.elevations,
      )
      const previouslyEnabledElevationProfiles =
        !!nonDefaultActivites.length &&
        nonDefaultActivites.every((a) => a.elevationProfile)
      const elevationProfile =
        (previouslyEnabledElevationProfiles &&
          newActivitiesSupportElevations) ||
        (nonDefaultActivites.length === 0 && newActivitiesSupportElevations)

      // start & end nodes visible
      const currentActivitiesHaveNodes =
        PrintUtils.printHasActivityEndpointsEnabled(nonDefaultActivites)

      // activity thicknesses
      const pathThickness = nonDefaultActivites.length
        ? nonDefaultActivites[0].pathThickness
        : PrintActivity.activityDefaults.pathThickness

      const activities = [...nonDefaultActivites, ...action.activities].map(
        (activity) => ({
          ...activity,
          elevationProfile,
          pathThickness,
          startNode: currentActivitiesHaveNodes,
          endNode: currentActivitiesHaveNodes,
        }),
      )

      return state.merge({
        activities,
        ...generatePrintFields(activities),
        ...resetMapBoundsProps(),
      })
    }

    case Constants.REMOVE_PRINT_ACTIVITY: {
      const { id } = action
      const activities = [...state.activities]
      const i = activities.findIndex((activity) => activity.id === id)
      if (i >= 0) activities.splice(i, 1)
      return state.merge({
        activities,
        ...generatePrintFields(activities),
        ...resetMapBoundsProps(),
      })
    }

    case Constants.EDIT_LABEL: {
      const { id, propName, value } = action
      const labelIndex = state.labels.findIndex((label) => label.id === id)
      return state.setIn(['labels', labelIndex, propName], value)
    }

    case Constants.MOVE_LABEL: {
      const { oldIndex, newIndex } = action
      const labels = arrayMove([...state.labels], oldIndex, newIndex)
      return state.set('labels', labels)
    }

    case Constants.SET_ALL_ACTIVITY_STYLES: {
      const { styles } = action
      const activities = [...state.activities].map((activity) => ({
        ...activity,
        ...styles,
      }))
      return state.setIn(['activities'], activities)
    }

    case Constants.SET_ACTIVITY_COLOUR: {
      const { id, color } = action
      const index = findActivityIndex(id, state.activities)
      return state.setIn(['activities', index, 'pathColor'], color)
    }

    case Constants.SET_ACTIVITY_THICKNESSES: {
      const { thickness } = action

      // Empty string can be 0
      const canBeNumber = Number.isInteger(Number(thickness))
      const pathThickness = canBeNumber
        ? action.thickness
        : PrintActivity.activityDefaults.pathThickness
      const activities = [...state.activities].map((activity) => ({
        ...activity,
        pathThickness,
      }))

      return state.set('activities', activities)
    }

    case Constants.SET_ACTIVITY_NODES_VISIBLE: {
      const { visible } = action
      const activities = [...state.activities].map((activity) => ({
        ...activity,
        startNode: visible,
        endNode: visible,
      }))
      return state.set('activities', activities)
    }

    case Constants.SET_ACTIVITIES_DASHED: {
      const { dashed } = action
      const activities = [...state.activities].map((activity) => ({
        ...activity,
        pathDashed: !!dashed,
      }))
      return state.set('activities', activities)
    }

    case Constants.SET_PRINT_QUANTITY: {
      // Empty string can be 0
      const canBeNumber = Number.isInteger(Number(action.quantity))
      const quantity = canBeNumber ? action.quantity : initialState.quantity
      return state.set('quantity', quantity)
    }

    case Constants.SET_RENDER_BEFORE_LAYER_ID: {
      const { layerId } = action
      return state.merge({ renderBeforeLayerId: layerId })
    }

    case Constants.MOVE_ACTIVITY: {
      const { oldIndex, newIndex } = action
      const activities = arrayMove([...state.activities], oldIndex, newIndex)
      return state.set('activities', activities)
    }

    case Constants.SET_ACTIVITIES_ELEVATION_PROFILES: {
      const { elevationProfiles } = action
      const activities = [...state.activities].map((activity) => ({
        ...activity,
        elevationProfile: !!elevationProfiles,
      }))
      return state.set('activities', activities)
    }

    case Constants.SET_ACTIVITY_ELEVATION_PROFILE_COLOUR: {
      const { id, color } = action
      const index = findActivityIndex(id, state.activities)
      return state.setIn(['activities', index, 'elevationProfileColor'], color)
    }

    default: {
      return state
    }
  }
}

/**
 * Resets all properties not related to activities
 * @param {object} { padding, porthole, orientation }
 * @return {object}
 */
export const resetNonActivityRelatedProps = ({
  padding,
  porthole,
  orientation,
}) => ({
  padding: padding || initialState.padding,
  porthole: porthole || initialState.porthole,
  orientation: orientation || initialState.orientation,
})

/**
 * Updates all props required to trigger a reset of map bounds
 * @param {object} { zoom, center }
 * @return {object}
 */
export const resetMapBoundsProps = ({ zoom, center, rotation } = {}) => ({
  zoom: zoom || initialState.zoom,
  center: center || initialState.center,
  rotation: rotation || initialState.rotation,
})

/**
 * Helper to group all the print fields
 * @param {array} activities
 * @return {object}
 */
export const generatePrintFields = (activities = []) => ({
  title: generateTitle(activities),
  labels: generateLabels(activities),
  secondaryTitle: generateSecondaryTitle(activities),
})

/**
 * Creates the primary title for the print
 * @param {array} activities
 * @return {string}
 */
export const generateTitle = (activities = []) => {
  const firstActivityTitle = _get(activities, '[0].title')
  const allTitlesMatch = activities.every(
    (activity) => activity.title === firstActivityTitle,
  )
  if (activities.length >= 2 && allTitlesMatch && firstActivityTitle)
    return firstActivityTitle
  if (activities.length === 1 && firstActivityTitle) return firstActivityTitle
  return initialState.title
}

/**
 * Creates the secondary label title
 * @param {array} activities
 * @return {string}
 */
export const generateSecondaryTitle = (activities = [], name = 'Your Name') => {
  const firstActivitySecondaryTitle = _get(activities, '[0].secondaryTitle')
  if (firstActivitySecondaryTitle) return firstActivitySecondaryTitle
  const firstActivityDate = _get(activities, '[0].date', DateUtils.now())
  const formattedDate = DateUtils.formatDate(firstActivityDate, 'Do MMMM YYYY')
  return `${name} – ${formattedDate}`
}

/**
 * Creates the initial labels for new activities, but only returns the ones
 * where there's a valid value
 * @param {array} activities
 * @return {array} print labels
 */
export const generateLabels = (activities = []) => {
  const distances = []
  const elevationGains = []
  const elapsedTimes = []
  const paceValues = []
  const speedValues = []
  const calories = []
  activities.forEach((activity) => {
    distances.push(activity.distance || 0)
    elevationGains.push(activity.elevationGain || 0)
    elapsedTimes.push(activity.elapsedTime || 0)
    paceValues.push(activity.pace || 0)
    speedValues.push(activity.speedAvg || 0)
    calories.push(activity.calories || 0)
  })
  const labels = [
    new PrintLabel({
      title: 'Distance',
      value: PrintUtils.formatDistance(_sum(distances), 'm', 'km', 2),
    }),
    new PrintLabel({
      title: 'Time',
      value: PrintUtils.formatDuration(_sum(elapsedTimes), 'seconds'),
    }),
    new PrintLabel({
      title: 'Elevation',
      value: PrintUtils.formatElevation(_sum(elevationGains), 'm', 'm', 0),
    }),
    new PrintLabel({
      title: 'Pace',
      value: PrintUtils.formatPace(_sum(paceValues), 'km', 'km'),
    }),
    new PrintLabel({
      title: 'Speed',
      value: PrintUtils.formatSpeed(_sum(speedValues), 'm/s', 'km/h', 1),
    }),
    new PrintLabel({
      title: 'Calories',
      value: PrintUtils.formatCalories(_sum(calories)),
    }),
  ].filter((label) => ![undefined, null, ''].includes(label.value))
  return new Array(Constants.MAX_LABEL_COUNT).fill({}).map((item, i) =>
    labels[i]
      ? labels[i]
      : new PrintLabel({
          title: `Label ${i + 1}`,
          value: `Value ${i + 1}`,
        }),
  )
}

/**
 * Returns the index of an activity by the specified ID
 * @param {string} id
 * @param {array} activities
 */
export const findActivityIndex = (id, activities = []) =>
  activities.findIndex((a) => a.id === id)

export default reducers
