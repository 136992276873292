export const TRACK_PAGE = 'TRACK_PAGE'
export const TRACK_EVENT = 'TRACK_EVENT'
export const TRACK_PURCHASE = 'TRACK_PURCHASE'
export const TRACK_PRINT_METRICS = 'TRACK_PRINT_METRICS'

export const PRINT_ORDERED_CATEGORY = 'Print'
export const PRINT_ORDERED_ACTION = 'Ordered'

export const PRINT_METRICS_CATEGORY = 'Print'
export const PRINT_METRICS_ACTION_LAYOUT = 'Layout'
export const PRINT_METRICS_ACTION_PRODUCT = 'Product'
export const PRINT_METRICS_ACTION_THEME_NAME = 'Theme Name'
export const PRINT_METRICS_ACTION_ORIENTATION = 'Orientation'

export const ACTIVITY_ADDED_CATEGORY = 'Activity'
export const ACTIVITY_ADDED_ACTION = 'Added'

export const COUPON_ADDED_CATEGORY = 'Coupon'
export const COUPON_ADDED_ACTION = 'Added'

export const CTA_CLICKED_CATEGORY = 'CTA'
export const CTA_CLICKED_ACTION = 'Clicked'

export const TRACK_EXCEPTION = 'TRACK_EXCEPTION'
export const ERROR_CATEGORY = 'Error'
