const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;

export const PAYPAL_INTENT = 'CAPTURE';

// https://developer.paypal.com/docs/checkout/reference/customize-sdk/
export const paypalQueryParams = [
  'commit=false',
  'currency=GBP',
  'components=buttons',
  `intent=${PAYPAL_INTENT.toLowerCase()}`,
  `client-id=${REACT_APP_PAYPAL_CLIENT_ID}`,
].join('&');

export const PAYPAL_SRC = `https://www.paypal.com/sdk/js?${paypalQueryParams}`;
