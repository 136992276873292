import { css } from 'styled-components';

import { fontGroup } from '../../styles';
import { PrintLabel } from '../../../../components/PrintLabel';

export default theme => css`
  ${fontGroup('azoCirceAlternateGothic')(theme)}

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      color: ${theme.secondaryColor};
      opacity: 0.9;
    }
  }

  .mapboxgl-ctrl-logo {
    opacity: 0.4;
  }
`;
