import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

const { REACT_APP_GOOGLE_API_KEY } = process.env;

export const GOOGLE_STATIC_MAP_URI = '//maps.googleapis.com/maps/api/staticmap';

export const GoogleStaticMap = (props) => {
  const src = GoogleStaticMap.create(props);
  return (
    <figure className={classnames('gstaticmap', props.className)}>
      <img src={src} alt={props.title} />
    </figure>
  );
};

GoogleStaticMap.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  polyline: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mapType: PropTypes.oneOf(['roadmap', 'satellite', 'hybrid', 'terrain']),
  theme: PropTypes.shape({}),
  /* eslint-enable */
};

GoogleStaticMap.defaultProps = {
  polyline: undefined,
  title: '',
  color: 'red',
  width: 640,
  height: 400,
  scale: 1,
  mapType: 'roadmap',
  theme: {},
};

GoogleStaticMap.create = ({
  // Map Parameters
  scale = 1,
  width = 640,
  height = 640,
  mapType = 'roadmap',
  // Feature Parameters
  polyline = '',
  theme = '',
  color = 'red',
  weight = 3,
}) => {
  const params = [
    'sensor=false',
    `scale=${scale}`,
    `size=${width}x${height}`,
    `mapType=${mapType}`,
    `path=weight:${weight}%7Ccolor:${color}%7Cenc:${polyline}`,
    `style=${theme}`, // TODO: add theme support for static maps
    `key=${REACT_APP_GOOGLE_API_KEY}`,
  ].join('&');
  return `${GOOGLE_STATIC_MAP_URI}?${params}`;
};

const StyledGoogleStaticMap = styled(GoogleStaticMap)`
  margin: 0;

  img {
    display: block;
    max-width: 100%;
  }
`;

export default StyledGoogleStaticMap;
