import React from 'react'
import PropTypes from 'prop-types'
import { PaymentElement } from '@stripe/react-stripe-js'
import styled from 'styled-components'
import _without from 'lodash.without'
import _get from 'lodash.get'

import Emoji from '../Emoji'
import { Link } from '../Link'
import { media } from '../../styles'
import * as routes from '../../routes'
import { SectionTitle } from '../Title'
import { Text, SmallText } from '../Text'
import { ContentBox } from '../ContentBox'
import { Price, OriginalPrice } from '../Price'
import { colours, fonts, transitions } from '../../theme'
import { Input, InputLabel, InputRow, Select } from '../Input'
import { PrimaryButton, WithProgress, Button } from '../Button'

import { mediaSizes } from '../../utils/sizes'
import {
  serializeFormData,
  validateFormData,
  getFormData,
} from '../../utils/forms'
import { formatCurrency } from '../../utils/numbers'
import initialState from '../../hocs/WithOrders/model'
import { PayPalButton } from '../../hocs/WithPaypal'
import { makeStripeBillingDetails } from '../../hocs/WithStripe/utils'
import { PRODUCT_SKU_DIGITAL } from '../../hocs/WithSession/constants'

const FEATURE_PAYPAL_BUTTON = process.env.REACT_APP_FEATURE_PAYPAL_BUTTON

const EditLink = styled(Link)``
const CheckYourChangesText = styled(Text)``

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const stripeStyles = {
  base: {
    color: colours.primary,
    fontFamily: 'Apercu',
    fontSmoothing: 'antialiased',
    fontSize: '26px',
    '::placeholder': {
      color: colours.primary,
    },
    ':hover': {
      '::placeholder': {
        color: colours.primary,
      },
    },
    ':focus': {
      color: colours.secondary,
      '::placeholder': {
        color: colours.grey06,
      },
    },
  },
  empty: {
    color: colours.primary,
  },
  invalid: {
    color: colours.white,
    backgroundColor: colours.primary,
    ':focus': {
      color: colours.white,
    },
  },
}

const SubmitButton = WithProgress(PrimaryButton)
const ApplyCouponButton = styled(WithProgress(Button))`
  &:after {
    border-color: ${colours.grey05};
  }
`

/**
 * UnstyledCheckoutForm
 * -----------------------------------------------------------------------------
 *
 */
class UnstyledCheckoutForm extends React.Component {
  static propTypes = {
    currentProduct: PropTypes.shape({
      title: PropTypes.string,
      sku: PropTypes.string,
      paper: PropTypes.string,
      sizeImperial: PropTypes.string,
      sizeMetric: PropTypes.string,
      currency: PropTypes.string,
      price: PropTypes.number,
      leadTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price: PropTypes.number,
    originalPrice: PropTypes.string,
    inProgress: PropTypes.bool,
    couponCheckInProgress: PropTypes.bool,
    couponId: PropTypes.string,
    novalidate: PropTypes.bool,
    stripe: PropTypes.shape({
      createPaymentMethod: PropTypes.func,
      handleCardAction: PropTypes.func,
    }),
    stripeElements: PropTypes.shape({}),
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    stripeActionRequired: PropTypes.bool,
    intentSecret: PropTypes.string,
    onQuantityChange: PropTypes.func,
    onZeroCheckoutPaymentMethod: PropTypes.func,
    onStripePaymentMethodCreated: PropTypes.func,
    onStripePaymentIntentConfirmed: PropTypes.func,
    onValidationError: PropTypes.func,
    onApplyCoupon: PropTypes.func,
    onPaypalOrderConfirmed: PropTypes.func,
    onPaypalError: PropTypes.func,

    trackCtaClicked: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currentProduct: undefined,
    price: undefined,
    originalPrice: undefined,
    quantity: 1,
    stripe: null,
    stripeElements: null,
    inProgress: false,
    couponCheckInProgress: false,
    couponId: undefined,
    novalidate: false,
    countries: [],
    stripeActionRequired: initialState.stripeActionRequired,
    intentSecret: initialState.intentSecret,
    onQuantityChange: () => {},
    onZeroCheckoutPaymentMethod: () => {},
    onStripePaymentMethodCreated: () => {},
    onStripePaymentIntentConfirmed: () => {},
    onValidationError: () => {},
    onApplyCoupon: () => {},
    onPaypalOrderConfirmed: () => {},
    onPaypalError: () => {},
  }

  state = {
    invalidFields: [],
    shippingEstimate: undefined,
    courier: undefined,
    destination: undefined,
    coupon: undefined,
  }

  componentDidUpdate(prevProps) {
    const intentSecretChanged =
      this.props.intentSecret !== prevProps.intentSecret
    if (
      this.props.stripeActionRequired &&
      this.props.intentSecret &&
      intentSecretChanged
    ) {
      this.handleCardAction()
    }
  }

  onFocus = (fieldName) => () => {
    this.setState({
      invalidFields: _without(this.state.invalidFields, fieldName),
    })
  }

  onPaypalCreateOrder = () => {
    this.setState({
      invalidFields: [],
    })
  }

  onPaypalApproved = (paypalOrderId) => {
    this.props.onPaypalOrderConfirmed(paypalOrderId, this.state.coupon)
  }

  onPaypalError = () => this.props.onPaypalError()

  onSubmit = (evt) => {
    evt.preventDefault()

    const formData = getFormData(this.form)

    const validation = this.validate(formData)

    if (validation.status) {
      this.setState({
        invalidFields: [],
      })
      if (this.props.price > 0) {
        this.createStripePaymentMethod(formData)
      } else {
        this.createZeroCheckout(formData)
      }
    } else {
      // Follows stripes validation schema
      this.props.onValidationError({
        type: 'validation_error',
        message: "Oops, looks like you've left out some fields! 👆",
      })
      this.setState({
        invalidFields: validation.invalidFields,
      })
    }
  }

  onCountryChange = ({ products, label }) => {
    let newState = {}
    const { currentProduct } = this.props

    if (!products || !currentProduct) {
      newState = {
        shippingEstimate: null,
        courier: null,
        destination: null,
      }
    } else if (this.isDigitalOrder) {
      newState = {
        shippingEstimate: null,
        courier: null,
        destination: label,
      }
    } else {
      const { sku, shippingRate } = currentProduct
      const selectedProduct = products[sku]
      newState = {
        shippingEstimate: selectedProduct[shippingRate].shippingEstimate,
        courier: selectedProduct[shippingRate].courier,
        destination: label,
      }
    }
    this.setState(newState)
  }

  onCouponChange = ({ target }) => {
    this.setState({
      coupon: target.value.trim(),
    })
  }

  onApplyCoupon = (evt) => {
    evt.preventDefault()
    this.props.onApplyCoupon(this.state.coupon)
  }

  // Handled when the the transaction returns from the API with Stripe
  // requiring the user for further authentication
  handleCardAction = async () => {
    try {
      const result = await this.props.stripe.handleCardAction(
        this.props.intentSecret,
      )
      if (result.error) {
        this.props.onValidationError(result.error)
      } else {
        const formData = getFormData(this.form)
        const paymentIntentId = result.paymentIntent.id
        this.props.onStripePaymentIntentConfirmed(
          paymentIntentId,
          serializeFormData(formData),
        )
      }
    } catch (error) {
      console.warn(error)
    }
  }

  // Called when the when the user isn't required to pay anything
  createZeroCheckout = async (formData) => {
    this.props.onZeroCheckoutPaymentMethod(serializeFormData(formData))
  }

  // Called when the initial payment intent is initiated
  createStripePaymentMethod = async (formData) => {
    const billingDetails = makeStripeBillingDetails(formData)
    try {
      this.props.stripeElements.submit()
      const result = await this.props.stripe.createPaymentMethod({
        elements: this.props.stripeElements,
        params: {
          billing_details: billingDetails,
        },
      })
      if (result.error) {
        this.props.onValidationError(result.error)
      } else {
        const paymentMethodId = result.paymentMethod.id
        this.props.onStripePaymentMethodCreated(
          paymentMethodId,
          serializeFormData(formData),
        )
      }
    } catch (error) {
      console.warn(error)
    }
  }

  validate(formData) {
    // We can skip validation of non-stripe fields if we need to
    return this.props.novalidate
      ? validateFormData([])
      : validateFormData(formData)
  }

  isInvalid = (fieldName) => this.state.invalidFields.includes(fieldName)

  formatTotal(total = 0) {
    const currency = _get(this.props, 'currentProduct.currency', 'GBP')
    return formatCurrency(total / 100, currency)
  }

  trackCtaClicked = (label) => () => this.props.trackCtaClicked(label)

  getStripeStyles() {
    if (window.innerWidth <= mediaSizes.nav) {
      stripeStyles.base.fontSize = '20px'
    }
    return stripeStyles
  }

  get couponHasValue() {
    return !!this.state.coupon
  }

  get priceFormatted() {
    return this.formatTotal(this.props.price)
  }

  // TODO: should this be a selector?
  get isDigitalOrder() {
    const { currentProduct } = this.props
    return currentProduct && currentProduct.sku === PRODUCT_SKU_DIGITAL
  }

  get countriesFilteredByCurrentProduct() {
    const { currentProduct, countries } = this.props
    if (currentProduct && currentProduct.sku) {
      return countries.filter(({ products }) =>
        Object.keys(products).includes(currentProduct.sku),
      )
    }
    return countries
  }

  renderShippingDuration() {
    if (this.isDigitalOrder) return null

    const { currentProduct } = this.props
    const { shippingEstimate, courier } = this.state
    return shippingEstimate && courier && currentProduct ? (
      <SmallText light>
        Your print will take up to{' '}
        <strong>{currentProduct.leadTime} hrs</strong> in addition to{' '}
        <strong>
          {shippingEstimate} business days via {courier}
        </strong>
        .
      </SmallText>
    ) : (
      <SmallText light>
        We'll estimate shipping times when you've selected your country. If your
        country isn't listed we recomend ordering a digital print.
      </SmallText>
    )
  }

  renderPrintSummary() {
    const { currentProduct } = this.props
    if (!currentProduct) return null

    const { isDigitalOrder } = this

    const { destination } = this.state
    const destinationText = destination ? (
      <strong>{destination}</strong>
    ) : (
      'your destination'
    )

    const productText = isDigitalOrder
      ? `${currentProduct.title}`
      : `${currentProduct.title} / ${currentProduct.sizeMetric}`

    const canEditQuantity = !isDigitalOrder && !this.props.couponId

    return (
      <div>
        <Text>
          {productText} <br />
          {currentProduct.paper}
        </Text>
        <CheckYourChangesText>
          <EditLink
            to={routes.PREVIEW}
            onClick={this.trackCtaClicked('Back to Preview')}
            title="Please make sure you're 100% happy before ordering"
          >
            Need to go back and check?
          </EditLink>
        </CheckYourChangesText>
        {canEditQuantity && (
          <div className="quantity-input">
            <InputLabel htmlFor="quantity">Quantity:</InputLabel>
            <Input
              required
              min="1"
              step="1"
              id="quantity"
              name="quantity"
              type="number"
              value={this.props.quantity}
              onChange={this.props.onQuantityChange}
              placeholder="¯\_(ツ)_/¯"
              onFocus={this.onFocus('quantity')}
              invalid={this.isInvalid('quantity')}
            />
          </div>
        )}
        <PriceContainer>
          {this.props.originalPrice && (
            <OriginalPrice>{this.props.originalPrice}</OriginalPrice>
          )}
          <Price>{this.priceFormatted}</Price>
        </PriceContainer>
        {isDigitalOrder ? (
          <SmallText light>
            Digital prints can take{' '}
            <Link to={`${routes.FAQ}/#where-is-my-digital-print`}>
              up to 24hrs to arrive.
            </Link>
          </SmallText>
        ) : (
          <div>
            <SmallText light>
              Price includes shipping to {destinationText}.
            </SmallText>
            {this.renderShippingDuration()}
          </div>
        )}
      </div>
    )
  }

  renderSubmitButton() {
    const { inProgress } = this.props
    return (
      <SubmitButton busy={inProgress} disabled={inProgress} type="submit">
        Send My Print!
      </SubmitButton>
    )
  }

  render() {
    return (
      <form
        noValidate
        id="checkout-form"
        ref={(r) => (this.form = r)}
        className={this.props.className}
        onSubmit={this.onSubmit}
      >
        <ContentBox>
          <fieldset>
            <header>
              <SectionTitle tag="h3">Your Details</SectionTitle>
            </header>
            <InputRow>
              <Input
                required
                name="name"
                placeholder="Full Name"
                onFocus={this.onFocus('name')}
                invalid={this.isInvalid('name')}
              />
            </InputRow>
            <InputRow>
              <Input
                required
                name="email"
                type="email"
                placeholder="Your email"
                onFocus={this.onFocus('email')}
                invalid={this.isInvalid('email')}
              />
            </InputRow>
            <InputRow>
              <Input
                required
                name="phone_mobile"
                placeholder="Phone number"
                onFocus={this.onFocus('phone_mobile')}
                invalid={this.isInvalid('phone_mobile')}
              />
            </InputRow>
            <aside>
              <SmallText light>
                Your email is only used for order confirmation/notifications and
                your phone number only for delivery queries.
              </SmallText>
            </aside>
          </fieldset>

          {!this.isDigitalOrder && (
            <fieldset>
              <header>
                <SectionTitle tag="h3">Shipping</SectionTitle>
              </header>
              <InputRow>
                <Input
                  required
                  name="address_line1"
                  placeholder="Address Line 1"
                  onFocus={this.onFocus('address_line1')}
                  invalid={this.isInvalid('address_line1')}
                />
              </InputRow>
              <InputRow>
                <Input placeholder="Address Line 2" name="address_line2" />
              </InputRow>
              <InputRow>
                <Input
                  required
                  name="address_city"
                  placeholder="Town or City"
                  onFocus={this.onFocus('address_city')}
                  invalid={this.isInvalid('address_city')}
                />
              </InputRow>
              <InputRow>
                <Input
                  name="address_state"
                  placeholder="State or County"
                  onFocus={this.onFocus('address_state')}
                  invalid={this.isInvalid('address_state')}
                />
              </InputRow>
              <InputRow>
                <Input
                  required
                  name="address_zip"
                  placeholder="Postal or Zip Code"
                  onFocus={this.onFocus('address_zip')}
                  invalid={this.isInvalid('address_zip')}
                />
              </InputRow>
              <InputRow>
                <Select
                  required
                  name="address_country"
                  placeholder="Select a Country"
                  options={this.countriesFilteredByCurrentProduct}
                  onChange={this.onCountryChange}
                  onFocus={this.onFocus('address_country')}
                  invalid={this.isInvalid('address_country')}
                />
              </InputRow>
              {this.renderShippingDuration()}
            </fieldset>
          )}

          <fieldset>
            <header>
              <SectionTitle tag="h3">Discount Voucher</SectionTitle>
            </header>
            <div className="coupon-inputs">
              <InputRow>
                <Input
                  name="coupon"
                  placeholder="Enter code"
                  defaultValue={
                    !!this.props.originalPrice && !!this.props.couponId
                      ? this.props.couponId
                      : undefined
                  }
                  onChange={this.onCouponChange}
                  onFocus={this.onFocus('coupon')}
                />
              </InputRow>
              {this.couponHasValue && (
                <ApplyCouponButton
                  busy={this.props.couponCheckInProgress}
                  disabled={this.props.couponCheckInProgress}
                  onClick={this.onApplyCoupon}
                >
                  Apply Coupon
                </ApplyCouponButton>
              )}
            </div>
            {!!this.props.originalPrice && !!this.props.couponId && (
              <aside className="coupon-accepted">
                <Text light>
                  ✨ {`${this.props.couponId}`.toUpperCase()} coupon applied ✨
                </Text>
              </aside>
            )}
          </fieldset>

          {this.props.price > 0 && (
            <fieldset>
              <header>
                <SectionTitle tag="h3">Payment</SectionTitle>
              </header>
              <InputRow>
                <PaymentElement />
              </InputRow>
            </fieldset>
          )}
        </ContentBox>

        <ContentBox>
          <fieldset className="summary-fieldset">
            <header>
              <SectionTitle tag="h3">Summary</SectionTitle>
            </header>
            {this.renderPrintSummary()}
            {this.renderSubmitButton()}
            {FEATURE_PAYPAL_BUTTON && (
              <PayPalButton
                product={this.props.currentProduct}
                quantity={this.props.quantity}
                onApproved={this.onPaypalApproved}
                onCreateOrder={this.onPaypalCreateOrder}
                onError={this.onPaypalError}
              />
            )}
          </fieldset>
        </ContentBox>
      </form>
    )
  }
}

const CheckoutForm = styled(UnstyledCheckoutForm)`
  margin: auto;

  fieldset {
    border: none;
    padding: 0;
    margin: 0 0 10rem;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .summary-fieldset {
    text-align: center;
  }

  ${SectionTitle} {
    margin-bottom: 3.8rem;
  }

  ${SubmitButton} {
    margin: 5rem auto 0;
    max-width: 42rem;
    height: auto;
    &:after {
      border-color: ${colours.grey05};
    }
  }

  .coupon-inputs {
    ${(p) =>
      p.originalPrice &&
      `
      display: none;
    `}
  }

  .quantity-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${InputLabel} {
      font-size: ${fonts.size03};
      color: ${colours.primary};
      margin: 0 0.4em 0 0;
    }

    ${Input} {
      max-width: 12.5rem;
      input {
        &:empty {
          ::-webkit-input-placeholder {
            color: ${colours.grey04};
          }
          ::-moz-placeholder {
            color: ${colours.grey04};
          }
          :-ms-input-placeholder {
            color: ${colours.grey04};
          }
          :-moz-placeholder {
            color: ${colours.grey04};
          }
        }
      }
    }
  }

  ${OriginalPrice} {
    margin-right: 0.6em;
  }

  ${PriceContainer} {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  ${Price} {
    ${SmallText} {
      margin: 0 0 0 1em;
    }
  }

  ${InputRow} {
    margin-bottom: 3.3rem;
  }

  ${SmallText} {
    text-align: center;
    margin: auto;
    max-width: 32em;

    strong {
      color: ${colours.grey08};
      font-weight: ${fonts.weightMedium};
    }
  }

  ${ContentBox} {
    margin: 0 auto 1rem;
    background-color: transparent;
    border: 3px solid ${colours.grey07};
    border-radius: 6px;
  }

  .StripeElement {
    font-family: 'Apercu';
    /* height: 4.4rem; */
    width: 100%;
    /* 310 otherwise the card date overlaps the card number */
    min-width: 310px;

    border-radius: 0;

    /* border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px dotted ${colours.grey04}; */

    display: flex;
    align-items: center;

    transition: ${transitions.inputDuration} ${transitions.inputEasing};
    /*
    &:hover {
      border-bottom-style: solid;
      border-bottom-color: ${colours.primary};
    } */

    > div {
      flex: 1 0 0;
    }
  }

  ${CheckYourChangesText} {
    margin: 5rem auto 6rem;
  }

  ${EditLink} {
    color: ${colours.primary};

    &:hover {
      text-decoration: none;
    }
  }

  .StripeElement--focus {
    border-bottom-style: solid;
    color: ${colours.secondary};
    border-bottom-style: solid;
    border-bottom-color: ${colours.primary};
  }

  .StripeElement--invalid {
    border-color: crimson;
    background-color: ${colours.secondary};
    border-radius: 3px;
    border-bottom-style: ${colours.secondary};
  }

  .select,
  .select__option,
  .input {
    font-size: ${fonts.size03};
  }

  ${ApplyCouponButton} {
    width: 100%;
    max-width: 32rem;
    margin: auto;
  }

  .coupon-accepted {
    text-align: center;

    ${Emoji} {
      position: relative;
      top: 3px;
    }
  }

  ${media.footer`

    ${ContentBox} {
      padding-left: 10rem;
      padding-right: 10rem;
    }
  `}

  ${media.nav`
    .select,
    .select__option,
    .input {
      font-size: ${fonts.size02};
    }
    ${InputRow} {
      margin-bottom: 2rem;
    }
    fieldset {
      margin-bottom: 5rem;
    }
    ${ContentBox} {
      padding: 6rem 3rem;
    }

    ${PriceContainer} {
      flex-direction: column;
      margin-bottom: 2rem;

      ${OriginalPrice} {
        margin-right: 0;
        margin-bottom: 0.4em;
      }
      ${OriginalPrice},
      ${Price} {
        line-height: 1;
        font-size: ${fonts.size04};
      }
    }
    ${ApplyCouponButton} {
      max-width: none;
    }
  `}
`

export default CheckoutForm
