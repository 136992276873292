export default {
  email: {
    contact: 'contact@papertrails.io',
  },
  company: {
    name: 'Paper Trails Map Company Ltd',
    number: '11914935',
    registered: 'England and Wales',
    website: 'https://papertrails.io',
  },
  // could use string.startsWith()
  // Any coupon starting with this prefix will require the
  // user to be authed with Strava and be a preimium subscriber.
  stravaPremiumCouponPrefix: new RegExp('^STRAVA-', 'i'),
}
