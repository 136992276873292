import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import WithSession from '../../hocs/WithSession';

import config from '../../config';
import { colours, fonts } from '../../theme';
import { media } from '../../styles';
import * as routes from '../../routes';

import SiteHeader from '../../containers/SiteHeader';
import SiteContainer from '../../components/SiteContainer';

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { Title, FeaturedTitle, SectionTitle } from '../../components/Title';
import { Link, LinkAsPrimaryButton } from '../../components/Link';
import { Figure } from '../../components/Figure';
import { Text } from '../../components/Text';
import { TopoBackground } from '../../components/Background';

import image01 from '../../images/adam-and-jake-01.jpg';
import image02 from '../../images/adam-jake-and-grant-01.jpg';
import image03 from '../../images/adam-01.jpg';
import image04 from '../../images/adam-and-jake-03.jpg';

const CreateTitle = styled(Title)``;
const CreateLink = styled(LinkAsPrimaryButton)``;

const figures = [
  {
    image: image01,
    alt: 'Adam and Jake running through the Alps',
    caption: 'Halfway through a 160km (over a few days) UTMB recce run – 2016',
  }, {
    image: image02,
    alt: 'Adam, Jake and Grant',
    caption: 'Halfway back to London after the Dunwich Dynamo – 2018',
  }, {
    image: image03,
    alt: 'Adam',
    caption: 'Somewhere cold and miserable – good memories are best served cold',
  }, {
    image: image04,
    alt: 'Adam and Jake',
    caption: 'Beers after cycling from London to Brugge, in one of those alcoves. You use this word, "alcove"?',
  },
];

const UnstyledAbout = props => (
  <React.Fragment>
    <ScrollToTopOnMount />
    <Helmet>
      <title>About</title>
    </Helmet>
    <div className={props.className}>
      <TopoBackground />
      <TopoBackground topo={2} />
      <SiteHeader />
      <SiteContainer>
        <article>
          <header>
            <FeaturedTitle tag="h1">
              Paper Trails was created by South African brothers Adam (the developer) and Jake (the designer) Brewer, based in Bristol, UK. Runners, climbers and cyclists in a digital world.
            </FeaturedTitle>
          </header>
          <section className="grid">
            <div className="grid-block">
              <Figure {...figures[0]} />
            </div>
            <div className="grid-block">
              <Figure {...figures[1]} />
            </div>
            <div className="grid-block">
              <Text>
                We wanted to create something beautiful and tactile for those memorable moments and achievements – something to display in the home or office and treasure rather than being tossed into a box.
              </Text>
            </div>
            <div className="grid-block">
              <Figure {...figures[2]} />
            </div>
            <div className="grid-block">
              <Figure {...figures[3]} />
            </div>
            <div className="grid-block">
              <Text>You can recreate any single or multi-stage adventure using any combination of Strava activities or GPX file(s), customised with your own theme, colours and activity details.</Text>
            </div>
          </section>
        </article>

        <section className="imagination">
          <SectionTitle tag="h3">Bespoke Prints</SectionTitle>
          <Title tag="h4">
            Are you an event organiser? Or perhaps you simply want to remember a favourite holiday location, an epic road trip or admire the city you live in? We can create unique prints! Get in touch: <br />
            <Link to={`mailto:${config.email.contact}`}>{config.email.contact}</Link>
          </Title>
        </section>

        <aside className="create">
          <CreateTitle tag="h4">Cherish your own adventure, from {props.cheapestProductPriceFormatted}.</CreateTitle>
          <CreateLink to={routes.CREATE}>Let's get started!</CreateLink>
        </aside>
      </SiteContainer>
    </div>
  </React.Fragment>
);

UnstyledAbout.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    price: PropTypes.number,
    currency: PropTypes.string,
  })).isRequired,
  cheapestProductPriceFormatted: PropTypes.string,
};
UnstyledAbout.defaultProps = {
  cheapestProductPriceFormatted: undefined,
};

const About = styled(UnstyledAbout)`
  width: 100%;
  overflow: hidden;
  position: relative;

  ${FeaturedTitle} {
    text-align: center;
    margin: 0 auto 13rem;
    max-width: 22em;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(23, 1fr); /* 1380/60px = 23 */
    grid-gap: 0;
    margin-bottom: 20rem;

    ${Text} {
      font-size: ${fonts.size04};
    }
  }

  .grid-block {
    &:nth-child(0n+1) {
      grid-row-start: 1;
      grid-column: 1 / 13;
      position: relative;
      left: -6rem;

      .caption {
        padding-left: 6rem;
      }
    }

    &:nth-child(0n+2) {
      grid-row-start: 1;
      grid-column: 14 / 22;
      align-self: center;
    }

    &:nth-child(0n+3) {
      margin: 4rem 0;
      align-self: center;
      grid-row-start: 2;
      grid-column: 1 / 11;
    }

    &:nth-child(0n+4) {
      margin-top: 2rem;
      grid-row-start: 2;
      grid-column: 12 / 24;
      margin-top: 8rem;
      position: relative;
      right: -6rem;
    }

    &:nth-child(0n+5) {
      grid-row-start: 3;
      grid-column: 1 / 9;
    }

    &:nth-child(0n+6) {
      align-self: center;
      grid-row-start: 3;
      grid-column: 13 / 24;
    }

  }

  .imagination {
    text-align: center;
    margin-bottom: 20rem;

    ${Title} {
      max-width: 32em;
      margin: auto;
    }

    ${Link} {
      color: ${colours.primary};
      &:hover {
        text-decoration: none;
      }
    }
  }

  .create {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
    position: relative;

    &:before {
      content: '';
      border-top: 2px solid ${colours.grey02};
      position: absolute;
      width: 100vw;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${CreateTitle} {
    margin-bottom: 5rem;
  }

  ${CreateLink} {
    text-decoration: none;
    font-size: ${fonts.size03};
    height: 8.8rem;
    max-width: 42rem;
  }

  ${TopoBackground} {
    &:nth-child(0n+1) {
      top: 40rem;
    }
    &:nth-child(0n+2) {
      bottom: 60rem;
      background-position: center bottom;
    }
  }

  /* MEDIA STYLES */
  ${media.footer`
    .grid {
      ${Text} {
        font-size: ${fonts.size03};
      }
    }
  `}

  ${media.nav`

    ${FeaturedTitle} {
      margin-bottom: 6rem;
    }

    .grid {
      display: flex;
      flex-direction: column;
      margin-bottom: 6rem;

      ${Text} {
        font-size: ${fonts.size02};
      }
    }
    .grid-block:nth-child(1n+0) { /* all children, without over-specifying */
      left: 0;
      right: 0;
      margin: 0 0 4rem;

      .caption {
        padding-left: 0;
        padding-bottom: 0;
        padding-top: 1rem;
      }
    }

    .imagination {
      margin-top: 12rem;
      margin-bottom: 16rem;
    }

    ${Title} {
      font-size: ${fonts.size02};
    }

    .create {
      padding: 6rem 0 8rem;
    }

    ${CreateTitle} {
      font-size: ${fonts.size03};
    }

    ${CreateLink} {
      font-size: ${fonts.size02};
    }
  `}

`;

const withSession = WithSession();

export { About, UnstyledAbout };
export default compose(withSession)(About);
