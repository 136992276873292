import { css } from 'styled-components';

import MapboxMap from '../../../../../components/MapboxMap';
import commonStyles from '../styles-common';

export default () => css`
  ${commonStyles}

  ${MapboxMap}:after {
    top: 12rem;
    right: initial;
    left: 10rem
  }
`;
