import palette from './palette';
import cssStyles from './styles';
import mapStyles from '../mapstyles-common';
import printStyles from './print';
import activityStyles from './activity';
import themeNames from '../theme-names'

export default {
  id: themeNames.half,
  name: 'Half Marathon 2022',
  printStyles,
  activityStyles,
  mapStyles,
  cssStyles,
  palette,
};
