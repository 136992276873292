import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { attemptScrollToHash } from '../../utils/dom';

import { colours, fonts } from '../../theme';
import { Text } from '../Text';
import Link from '../Link';

const toNode = prop => (typeof prop === 'function')
  ? prop
  : () => prop;

class UnstyledQuestionAnswer extends React.PureComponent {
  componentDidMount() {
    attemptScrollToHash(this.props.id);
  }

  render() {
    const { className, question, answer, ...props } = this.props;
    const Question = toNode(question);
    const Answer = toNode(answer);
    return (
      <li className={className} {...props}>
        <article className="inner">
          <Text className="question"><Question /></Text>
          <Text className="answer"><Answer /></Text>
        </article>
      </li>
    );
  }
}

UnstyledQuestionAnswer.propTypes = {
  id: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
  answer: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
};
UnstyledQuestionAnswer.defaultProps = {
  id: undefined,
  question: '',
  answer: '',
};

const QuestionAnswer = styled(UnstyledQuestionAnswer)`
  background-color: ${colours.grey06};

  &:nth-of-type(2n+1) {
    background-color: ${colours.white};
  }

  .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8rem 10rem 8rem 7rem;
  }

  ${Text} {
    font-size: ${fonts.size03};
  }

  .question {
    flex: 0 1 46%;
    padding-right: 12%;
    font-weight: 500;
  }
  .answer {
    flex: 0 1 54%;
  }

  ${Link} {
    color: ${colours.primary};
    &:hover {
      color: ${colours.secondary};
    }
  }

`;

export { QuestionAnswer, UnstyledQuestionAnswer };
export default QuestionAnswer;
