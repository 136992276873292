import palette from './palette'
import cssStyles from './styles'
import printStyles from './print'
import activityStyles from './activity'

export default {
  id: 'pcUltra2023Short',
  name: 'Pan Celtic Ultra 2023',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cljpmfiml00vw01qyflpkdd8h',
  cssStyles,
  palette,
}
