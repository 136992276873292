import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'

import HocComponent from '../HocComponent'

import * as Actions from './actions'
import * as Selectors from './selectors'

export const MakeWithPrintHoc = WrappedComponent =>
  new HocComponent(WrappedComponent, 'withPrintHoc')

export const WithPrintHoc = () => WrappedComponent => {
  const WithPrint = MakeWithPrintHoc(WrappedComponent)

  const mapStateToProps = createStructuredSelector({
    print: Selectors.printSelector,
    printId: Selectors.idSelector,
    printActivities: Selectors.activitiesSelector,
    userPrintActivities: Selectors.userPrintActivitiesSelector,
    defaultPrintActivities: Selectors.defaultPrintActivitiesSelector,
    printProduct: Selectors.productSelector,
    printProductAttributes: Selectors.productAttributesSelector,
    printOrientation: Selectors.orientationSelector,
    printLayout: Selectors.layoutSelector,
    printZoom: Selectors.zoomSelector,
    printCenter: Selectors.centerSelector,
    printRotation: Selectors.rotationSelector,
    printLabels: Selectors.labelsSelector,
    printTitle: Selectors.titleSelector,
    printSecondaryTitle: Selectors.secondaryTitleSelector,
    printStyles: Selectors.stylesSelector,
    printOptions: Selectors.optionsSelector,
    printQuantity: Selectors.quantitySelector,
    renderBeforeLayerId: Selectors.renderBeforeLayerIdSelector,
    printActivitiesDashed: Selectors.activitiesDashedSelector,
    printActivitiesElevationProfiles:
      Selectors.printActivitiesElevationProfilesSelector,
    printActivitiesThickness: Selectors.printActivitiesThicknessSelector,
    printPadding: Selectors.paddingSelector,
    printPorthole: Selectors.portholeSelector,

    hasElevationProfile: Selectors.hasElevationProfileSelector,
    hasSourceFromStrava: Selectors.hasSourceFromStravaSelector,
    hasActivityEndpoints: Selectors.hasActivityEndpointsSelector,

    printSize: Selectors.printSizeSelector,
    printComponentProps: Selectors.printComponentPropsSelector,
    mapComponentProps: Selectors.mapComponentPropsSelector,
    printDetailsComponentProps: Selectors.printDetailsComponentPropsSelector,
    elevationProfileComponentProps:
      Selectors.elevationProfileComponentPropsSelector,
  })

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        removePrintActivity: Actions.removePrintActivity,
        updatePrint: Actions.updatePrint,
        resetMapBounds: Actions.resetMapBounds,
        editLabel: Actions.editLabel,
        moveLabel: Actions.moveLabel,
        setAllPrintActivityStyles: Actions.setAllPrintActivityStyles,
        setActivityColor: Actions.setActivityColor,
        savePrintConfig: Actions.savePrintConfig,
        setPrintQuantity: Actions.setPrintQuantity,
        setRenderBeforeLayerId: Actions.setRenderBeforeLayerId,
        setActivityThicknesses: Actions.setActivityThicknesses,
        setActivityNodesVisible: Actions.setActivityNodesVisible,
        setActivitiesDashed: Actions.setActivitiesDashed,
        setActivitiesElevationProfiles: Actions.setActivitiesElevationProfiles,
        setActivityElevationProfileColor:
          Actions.setActivityElevationProfileColor,
        moveActivity: Actions.moveActivity,
      },
      dispatch,
    )

  const withConnect = connect(mapStateToProps, mapDispatchToProps)

  const composed = compose(withConnect, withRouter)(WithPrint)

  return composed
}

export default WithPrintHoc
