import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'

import { colours, fonts, transitions } from '../../theme'
import { Button, primaryButtonStyles } from '../Button'

const shouldScrollIntoView = (to) => {
  const hashIndex = to.indexOf('#')
  if (hashIndex !== -1) {
    const el = document.getElementById(to.slice(hashIndex + 1))
    if (el) {
      return () => el.scrollIntoView()
    }
  }
  return null
}

/**
 * Standard Link
 *
 */
const UnstyledLink = ({ to, children, ...restProps }) => {
  const isExternal = to.indexOf('://') !== -1 || to.indexOf('mailto:') !== -1
  const onClick = shouldScrollIntoView(to)
  return isExternal ? (
    <a
      href={to}
      target="_blank"
      rel="nofollow noopener noreferrer"
      {...restProps}
    >
      {children}
    </a>
  ) : (
    <RouterLink onClick={onClick} to={to} {...restProps}>
      {children}
    </RouterLink>
  )
}

UnstyledLink.propTypes = {
  to: PropTypes.string.isRequired,
}

UnstyledLink.defaultProps = {}

const Link = styled(UnstyledLink)`
  transition: ${transitions.linkDuration} ${transitions.linkEasing};

  &:hover {
    color: ${colours.primary};
  }
`

/**
 * Nav Links to pages in the site
 *
 */
const NavLink = styled(RouterNavLink)`
  font-size: ${fonts.size03};
  text-decoration: none;
  color: ${colours.primary};
  padding-bottom: 0.1em;
  font-weight: ${fonts.weightMedium};
  border-bottom: 2px solid transparent;
  transition: ${transitions.linkDuration} ${transitions.linkEasing};

  &:hover {
    border-bottom-color: currentColor;
  }

  &.active {
    border-bottom-color: currentColor;
  }
`

/**
 * Icon NavLink
 *
 */
const IconNavLink = styled(Link)`
  color: ${colours.primary};
  border-radius: 50%;
  border: 3px solid transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  .isvg {
    width: 2.8rem;
    height: 2.8rem;
    color: ${colours.primary};
  }

  &[href*='instagram'] {
    border-radius: 11px;
  }

  &:hover {
    color: ${colours.white};
    border-color: ${colours.primary};
    background-color: ${colours.primary};

    .isvg {
      color: ${colours.white};
    }
  }
`

/**
 * Link as Button
 *
 */
const LinkAsButton = styled(Button.withComponent(Link))`
  text-decoration: none;
`

/**
 * Link as PrimaryButton
 *
 */
export const LinkAsPrimaryButton = styled(Button.withComponent(Link))`
  text-decoration: none;
  ${primaryButtonStyles}
`

/**
 * Link to the shop/store
 *
 */
export const ShopNavLink = NavLink.withComponent((props) => (
  <Link rel="noopener external" {...props} />
))

export { Link, NavLink, IconNavLink, LinkAsButton }
export default Link
