import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import Immutable from 'seamless-immutable';

import createReducer from './reducers';
import appSagas from './sagas';
import printSagas from './hocs/WithPrint/sagas';
import themeSagas from './hocs/WithThemes/sagas';
import ordersSagas from './hocs/WithOrders/sagas';
import toasterSagas from './hocs/WithToaster/sagas';
import sessionSagas from './hocs/WithSession/sagas';
import analyticsSagas from './hocs/WithAnalytics/sagas';
import activitiesagas from './hocs/WithActivities/sagas';

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState = {}, history) => {

  const actionSanitizer = action => (
    action.type === 'FILE_DOWNLOAD_SUCCESS' && action.data ?
      { ...action, data: '<<LONG_BLOB>>' } : action
  );

  const devToolsOptions = {
    // traceLimit: 25,
    // trace: true,

    // Redux devtools: excessive use of memory and CPU
    // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
    actionSanitizer,
    stateSanitizer: state => state.data ? { ...state, data: '<<LONG_BLOB>>' } : state,
  };

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    typeof window === 'object' &&
    process.env.REACT_APP_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsOptions)
      : compose;

  const store = createStore(
    createReducer(history),
    Immutable(initialState),
    composeEnhancers(applyMiddleware(
      // Makes redux-sagas work
      sagaMiddleware,
      // Syncs the location / URL path to the state
      routerMiddleware(history),
    )),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;

  [
    ...appSagas,
    ...printSagas,
    ...themeSagas,
    ...ordersSagas,
    ...sessionSagas,
    ...toasterSagas,
    ...activitiesagas,
    ...analyticsSagas,
  ].forEach(sagaMiddleware.run);

  return store;
};

export default configureStore;
