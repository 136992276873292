import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';

import { colours, fonts } from '../../theme';

import { activitySources } from '../../hocs/WithActivities/constants';
import { formatDistance, formatDuration } from '../../utils/print';
import { formatDate } from '../../utils/dates';

import GoogleStaticMap from '../GoogleStaticMap';
import { StravaLogo, GpxLogo } from '../Logo';
import { SmallText } from '../Text';
import { Title } from '../Title';
import { PlainButton } from '../Button';

import skeletonSvg from './skeleton.svg';

const RemoveButton = styled(PlainButton)`
  font-size: ${fonts.size05};
  color: ${colours.grey03};
  text-align: right;

  &:hover {
    color: ${colours.primary};
  }
`;


/**
 * ActivityCard
 *
 * -----------------------------------------------------------------------------
 *
 */
const UnstyledActivityCard = (props) => {
  const classNames = classnames('activitycard', props.className);
  const getSourceIcon = (source) => {
    switch (source) {
      case activitySources.STRAVA:
        return <StravaLogo />;
      case activitySources.GPX:
        return <GpxLogo />;
      default:
        return null;
    }
  };

  // distance from Strava should always be in meters
  // http://developers.strava.com/docs/reference/#api-models-SummaryActivity
  const distanceOutputUnits = props.units === 'feet' ? 'mi' : 'km';
  const distance = formatDistance(props.distance / 1000, 'km', distanceOutputUnits);

  const time = formatDuration(props.time) || '—';
  const date = props.date
    ? formatDate(props.date, 'DD.MM.YY')
    : '—';

  return (
    <article id={props.id} className={classNames}>
      <header className="header">
        <GoogleStaticMap
          width={400}
          height={300}
          scale={2}
          polyline={props.polyline}
          title={props.title}
        />
        {getSourceIcon(props.source)}
      </header>
      <section className="content">
        <Title tag="h4">{props.title}</Title>
        <SmallText className="stats" light>
          <span>{date}</span>
          <span>{distance}</span>
          <span>{time}</span>
        </SmallText>
        <footer className="content-footer">
          <RemoveButton onClick={props.onRemove}>Remove</RemoveButton>
        </footer>
      </section>
    </article>
  );
};

UnstyledActivityCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  polyline: PropTypes.string,
  date: PropTypes.string,
  distance: PropTypes.number,
  time: PropTypes.number,
  source: PropTypes.oneOf([activitySources.STRAVA, activitySources.GPX]),
  units: PropTypes.oneOf(['meters', 'feet']),
  onRemove: PropTypes.func,
};

UnstyledActivityCard.defaultProps = {
  id: undefined,
  title: undefined,
  polyline: undefined,
  date: undefined,
  distance: undefined,
  time: undefined,
  source: undefined,
  units: 'meters',
  onRemove: () => {},
};

const ActivityCard = styled(UnstyledActivityCard)`
  display: flex;
  flex-direction: row;

  .header {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 9rem;
    flex: 0 0 12rem;
  }

  .content {
    padding: 0 0 0 3rem;
    font-size: ${fonts.size06};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .content-footer {
    display: flex;
    justify-content: flex-end;

  }

  .stats {
    margin-top: 0.4rem;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span:not(:first-of-type):not(:empty) {
      &:before {
        content: '·';
        margin-left: 0.4em;
        margin-right: 0.4em;
      }
    }
  }

  ${Title} {
    font-size: ${fonts.size06};
    font-weight: ${fonts.weightMedium};
  }

  ${GoogleStaticMap} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale(1.6);
    transform-origin: 50% 50%;
    opacity: 1;
    filter: grayscale(1);
  }

  ${GpxLogo},
  ${StravaLogo} {
    position: absolute;
  }

  ${StravaLogo} {
    width: 5.3rem;
  }

  ${GpxLogo} {
    width: auto;
  }

`;


/**
 * ActivityCard Loader / Seleton
 *
 * -----------------------------------------------------------------------------
 *
 */
const UnstyledActivityCardSkeleton = props => (
  <Svg {...props} src={skeletonSvg} />
);

UnstyledActivityCard.propTypes = {};
UnstyledActivityCard.defaultProps = {};

const ActivityCardSkeleton = styled(UnstyledActivityCardSkeleton)`
  display: block;
  width: 100%;

  svg { width: 100%; }
`;


export { ActivityCard, ActivityCardSkeleton, UnstyledActivityCard, UnstyledActivityCardSkeleton };
export default ActivityCard;
