import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { media, zIndexes } from '../../styles';
import * as routes from '../../routes';

import SiteHeader from '../../containers/SiteHeader';
import SiteContainer from '../../components/SiteContainer';
import { ContentBox } from '../../components/ContentBox';

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { FeaturedTitle, SectionTitle } from '../../components/Title';
import { Text } from '../../components/Text';
import { LinkAsButton } from '../../components/Link';
import { TopoBackground } from '../../components/Background';
import Emoji from '../../components/Emoji';

const UnstyledThanks = props => (
  <React.Fragment>
    <ScrollToTopOnMount />
    <Helmet>
      <title>Thanks</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div className={props.className}>
      <TopoBackground />
      <SiteHeader />
      <SiteContainer>
        <header>
          <SectionTitle tag="h3">Wahey!</SectionTitle>
          <FeaturedTitle tag="h1">
            Excellent choice! <Emoji emoji="ok" /> We're creating your print and preparing your order as we speak.
          </FeaturedTitle>
        </header>
        <ContentBox>
          <Text>
            In the meantime, follow us for updates on new themes and features: <br />
          </Text>
          <LinkAsButton to={routes.INSTAGRAM}>instagram.com/papertrails.io</LinkAsButton>
        </ContentBox>
      </SiteContainer>
    </div>
  </React.Fragment>
);

UnstyledThanks.propTypes = {};
UnstyledThanks.defaultProps = {};

const Thanks = styled(UnstyledThanks)`
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 10rem;

  text-align: center;

  ${FeaturedTitle} {
    text-align: center;
    margin: 0 auto 10rem;
    max-width: 22em;
  }

  ${Text} {
    max-width: 18em;
    margin: auto;
  }

  ${LinkAsButton} {
    margin-top: 5rem;
    max-width: 50rem;
    font-size: inherit;
  }

  ${Text},
  ${LinkAsButton},
  ${SectionTitle},
  ${FeaturedTitle} {
    position: relative;
    z-index: ${zIndexes.raised};
  }

  ${TopoBackground} {
    z-index: ${zIndexes.zero};
    top: 20%;
    opacity: 0.8;
  }


  /* MEDIA STYLES */
  ${media.footer`
    ${ContentBox} {
      padding-left: 10rem;
      padding-right: 10rem;
    }
  `}

  ${media.nav`
    margin-bottom: 0rem;

    ${FeaturedTitle} {
      margin-bottom: 10rem;
    }
    ${ContentBox} {
      padding: 6rem 3rem;
    }

  `}
`;

export { Thanks, UnstyledThanks };
export default Thanks;
