import React from 'react'
import PropTypes from 'prop-types'
import FsLightbox from 'fslightbox-react'
import styled from 'styled-components'

import sample01 from '../../images/frame-samples/pt-frames-01.jpg'
import sample02 from '../../images/frame-samples/pt-frames-02.jpg'
import sample03 from '../../images/frame-samples/pt-frames-03.jpg'
import sample04 from '../../images/frame-samples/pt-frames-04.jpg'
import sample05 from '../../images/frame-samples/pt-frames-05.jpg'

import './styles.css'

const images = [sample01, sample02, sample03, sample04, sample05]

const UnstyledFrameSamplesLightbox = ({ open, ...props }) => (
  <FsLightbox toggler={open} sources={images} {...props} />
)

UnstyledFrameSamplesLightbox.propTypes = {
  open: PropTypes.bool,
}
UnstyledFrameSamplesLightbox.defaultProps = {
  open: false,
}

export const FrameSamplesLightbox = styled(UnstyledFrameSamplesLightbox)``
