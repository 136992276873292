import { css } from 'styled-components';

import { fontGroup } from '../../styles';
import { PrintLabel } from '../../../../components/PrintLabel';

export default theme => css`
  ${fontGroup('terminaMonarcha')(theme)}

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      opacity: 0.2;
    }
  }

  .mapboxgl-ctrl-logo {
    opacity: 0.8;
  }
`;
