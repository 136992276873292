import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled, { css } from 'styled-components'

import { dimensions } from '../../theme'
import * as printUtils from '../../utils/print'

import { ORIENTATION_OPTIONS, LAYOUT_OPTIONS } from '../../hocs/WithPrint/model'
import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
} from '../../hocs/WithPrint/constants'

import { PrintLabel, PrintLabelValue } from '../PrintLabel'
import { PrintTitle, PrintSecondaryTitle } from '../PrintTitle'

class UnstyledPrintDetails extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    /* eslint-disable react/no-unused-prop-types */
    layout: PropTypes.oneOf(LAYOUT_OPTIONS),
    orientation: PropTypes.oneOf(ORIENTATION_OPTIONS),
    /* eslint-enable */
  }

  static defaultProps = {
    title: undefined,
    subtitle: undefined,
    layout: undefined,
    orientation: undefined,
    labels: [],
  }

  get hasNoLabels() {
    const { labels } = this.props
    return !labels.length || printUtils.allLabelFieldsEmpty(labels)
  }

  get hasNoTitles() {
    return !this.props.title && !this.props.subtitle
  }

  get hasNoContent() {
    return this.hasNoLabels && this.hasNoTitles
  }

  renderTitles() {
    if (this.hasNoTitles) return null
    const { title, subtitle } = this.props
    return (
      <div className="print-details-titles">
        {title && <PrintTitle>{title}</PrintTitle>}
        {subtitle && <PrintSecondaryTitle>{subtitle}</PrintSecondaryTitle>}
      </div>
    )
  }

  renderLabels() {
    if (this.hasNoLabels) return null
    return (
      <div className="print-details-labels">
        {this.props.labels.map((label) => (
          <PrintLabel key={label.id} title={label.title} value={label.value} />
        ))}
      </div>
    )
  }

  render() {
    if (this.hasNoContent) return null
    return (
      <aside className={classnames(this.props.className, 'print-details')}>
        <div className="print-details-inner">
          {this.renderTitles()}
          {this.renderLabels()}
        </div>
      </aside>
    )
  }
}

const PrintDetails = styled(UnstyledPrintDetails)`
  .print-details-inner {
    display: flex;

    ${(p) =>
      isVertical(p)
        ? css`
            /* VERTICAL */
            flex-direction: column;
            align-items: center;
            text-align: center;

            padding-left: ${dimensions.printPadding};
            padding-right: ${dimensions.printPadding};

            ${isTop(p)
              ? css`
                  /* TOP */
                  padding-top: ${isLandscape(p) ? '1.5rem' : '1.5rem'};
                `
              : css`
                  /* BOTTOM */
                  padding-top: ${isLandscape(p) ? 0 : '0.4rem'};
                  padding-bottom: 1rem;
                `}
          `
        : css`
            /* HORIZONTAL */

            flex-direction: row;
            align-items: flex-start;
            text-align: left;

            ${isTop(p)
              ? css`
                  /* TOP */
                  padding-top: ${isLandscape(p) ? '2.8rem' : '4rem'};
                `
              : css`
                  /* BOTTOM */
                  padding-bottom: ${isLandscape(p) ? '2.8rem' : '4rem'};
                `}
          `}
  }

  .print-details-titles {
    display: flex;
    flex-direction: column;
    flex: 1 1 62.5%;

    ${(p) =>
      isHorizontal(p) &&
      css`
        /* HORIZONTAL */
        padding-right: 6rem;
      `}
  }

  ${PrintTitle} {
    margin-top: -0.1em;

    ${(p) =>
      isVertical(p) &&
      css`
        max-width: 24em;
      `};
  }

  ${PrintSecondaryTitle} {
    ${(p) =>
      hasTitle(p)
        ? css`
            /* HAS TITLE */
            margin-top: 0.6rem;
          `
        : css`
            /* NO TITLE */
            margin-top: 0;
          `}

    ${(p) =>
      isVertical(p)
        ? css`
            max-width: 36em;
            margin-left: auto;
            margin-right: auto;
          `
        : css`
            max-width: 32em;
          `}
  }

  .print-details-labels {
    display: grid;
    position: relative;

    ${(p) =>
      isVertical(p)
        ? css`
            /* VERTICAL */

            grid-template-rows: 1fr;
            grid-template-columns: repeat(
              ${(props) => calcLabelColums(props)},
              auto
            );

            ${hasTitles(p) &&
            css`
              /* HAS TITLE OR SUBTITLE */
              margin-top: 1.6rem;
              padding-top: 2.4rem;

              &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 2px;
                background-color: currentColor;
                opacity: ${lineOpacity};
              }
            `}
          `
        : css`
            /* HORIZONTAL */

            flex: 0 0 auto;
            grid-template-rows: ${isPortrait(p) ? 'repeat(2, auto)' : 'auto'};
            grid-template-columns: repeat(
              ${(props) => calcLabelColums(props)},
              auto
            );
            grid-auto-flow: column;
            align-items: flex-start;
            overflow: hidden;
            padding-bottom: 0.08em;
          `}
  }

  ${PrintLabel} {
    position: relative;

    ${(p) =>
      isVertical(p)
        ? css`
            /* VERTICAL */

            padding: 0 2.5rem 0;
            white-space: initial;

            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }

            ${isLandscape(p) &&
            css`
              /* LANDSCAPE */
              flex-direction: row-reverse;
              align-items: center;

              ${PrintLabelValue} {
                margin-bottom: 0;
                margin-left: 1rem;
              }
            `}
          `
        : css`
            /* HORIZONTAL */

            height: 100%;

            ${isPortrait(p)
              ? css`
                  /* PORTRAIT */
                  padding-left: 2rem;
                  padding-right: 4.5rem;

                  ${printUtils.countVisiblePrintLabels(p.labels) % 2 === 0
                    ? css`
                        &:nth-last-child(0n + 1),
                        &:nth-last-child(0n + 2) {
                          padding-right: 0;
                        }
                      `
                    : css`
                        &:nth-last-child(0n + 1) {
                          padding-right: 0;
                        }
                      `}

                  &:nth-child(1n+1) {
                    &:before {
                      ${columnSeparatorStyles}
                    }
                  }

                  &:nth-child(2n + 1) {
                    padding-bottom: 3rem;
                  }

                  ${!hasTitles(p) &&
                  css`
                    /* NO TITLES */
                    &:nth-child(0n + 1),
                    &:nth-child(0n + 2) {
                      padding-left: 0;
                      &:before {
                        content: none;
                      }
                    }
                  `}

                  /* These last even/odd selectors make the borders/separators
        fill the height of the labels box */
        &:last-child:nth-child(odd) {
                    &:before {
                      height: 300%;
                    }
                  }

                  &:last-child:nth-child(even) {
                    &:before {
                      height: 300%;
                    }
                  }
                `
              : css`
                  /* LANDSCAPE */

                  padding-left: 1.7rem;
                  padding-right: 4rem;

                  &:last-child {
                    padding-right: 0;
                  }

                  &:nth-child(n + 1) {
                    &:before {
                      ${columnSeparatorStyles}
                    }
                  }

                  ${!hasTitles(p) &&
                  css`
                    /* NO TITLES */
                    &:nth-child(0n + 1) {
                      padding-left: 0;
                      &:before {
                        content: none;
                      }
                    }
                  `}
                `}
          `}
  }
`

const lineOpacity = 0.17

const columnSeparatorStyles = css`
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: currentColor;
  opacity: ${lineOpacity};
`

const isVertical = (props) => printUtils.isLayoutVertical(props.layout)
const isHorizontal = (props) => printUtils.isLayoutHorizontal(props.layout)
const isPortrait = (props) => props.orientation === ORIENTATION_PORTRAIT
const isLandscape = (props) => props.orientation === ORIENTATION_LANDSCAPE
const isTop = (props) => printUtils.isLayoutTop(props.layout)

const hasTitle = (props) => props.title
const hasSubtitle = (props) => props.subtitle
const hasTitles = (props) => hasTitle(props) || hasSubtitle(props)

export const calcLabelColums = (props) =>
  printUtils.isLayoutVertical(props.layout)
    ? props.labels.length
    : Math.ceil(props.labels.length / 2)

export { PrintDetails, UnstyledPrintDetails }
export default PrintDetails
