import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const EMOJIS = {
  pointingDown: {
    img: '👇',
    label: 'Pointing down',
  },
  pointingRight: {
    img: '👉',
    label: 'Pointing right',
  },
  thumbsUp: {
    img: '👍',
    label: 'Thumbs up',
  },
  metal: {
    img: '🤘',
    label: 'Rock and roll',
  },
  shush: {
    img: '🤫',
    label: 'Shush',
  },
  curious: {
    img: '🧐',
    label: 'Curious Face',
  },
  ok: {
    img: '👌',
    label: 'Okay',
  },
  angry: {
    img: '😠',
    label: 'Angry',
  },
  smile: {
    img: '😀',
    label: 'Smile',
  },
  money: {
    img: '💵',
    label: 'money',
  },
  sunglasses: {
    img: '😎',
    label: 'sunglasses',
  },
  cartwheel: {
    img: '🤸',
    label: 'cartwheel',
  },
};

export const EMOJI_KEYS = Object.keys(EMOJIS);

export const Emoji = styled(({ emoji, ...props }) =>
  <span {...props} role="img" aria-label={EMOJIS[emoji].label}>{EMOJIS[emoji].img}</span>)`

  `;

Emoji.propTypes = {
  emoji: PropTypes.oneOf(EMOJI_KEYS),
};

export default Emoji;
