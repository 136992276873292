import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { colours } from '../../theme';

const SIDEBAR_WIDTH = 540;
const SIDEBAR_WIDTH_PX = `${SIDEBAR_WIDTH}px`;


/**
 * Sidebar
 *
 */
const UnstyledPageSidebar = ({ children, className, ...props }) => (
  <div className={classnames('pagesplit-siderbar', className)} {...props}>
    <div className="inner">{children}</div>
  </div>
);

const PageSidebar = styled(UnstyledPageSidebar)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .inner {
    background-color: ${colours.white};
  }
`;


/**
 * Main bit
 *
 */
const UnstyledPageMain = ({ className, ...props }) =>
  <div className={classnames('pagesplit-main', className)} {...props} />;

const PageMain = styled(UnstyledPageMain)`

`;


/**
 * The page split container
 *
 */
const UnstyledPageSplit = (props) => {
  const classNames = classnames('pagesplit', props.className);
  return (
    <div className={classNames}>{props.children}</div>
  );
};

const PageSplit = styled(UnstyledPageSplit)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${colours.grey06};

  ${PageMain} {
    width: calc(100% - ${SIDEBAR_WIDTH_PX});
  }

  ${PageSidebar} {
    width: ${SIDEBAR_WIDTH_PX};
  }
`;

export { PageSplit, PageMain, PageSidebar, SIDEBAR_WIDTH, SIDEBAR_WIDTH_PX };
export default PageSplit;
