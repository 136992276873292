import { css } from 'styled-components'

import {
  PrintTitle,
  PrintSecondaryTitle,
} from '../../../../components/PrintTitle'
import {
  PrintLabelTitle,
  PrintLabelValue,
  PrintLabel,
} from '../../../../components/PrintLabel'
import { loadGoogleFonts, loadTypekitThemeFonts } from '../../../../utils/fonts'
import { LogoCredits, PoweredByStrava } from '../../../../components/Logo'
import { Attribution } from '../../../../components/Attribution'
import { colours } from '../../../../theme'

export default (theme) => {
  const loadedGoogleFonts = loadGoogleFonts(['Cabin Condensed:700'])
  const loadedTypekitFonts = loadTypekitThemeFonts(['alternate-gothic-no-3-d'])

  return css`
    ${PrintTitle} {
      text-transform: uppercase;
      font-size: 4rem;
      letter-spacing: 2px;
      ${loadedGoogleFonts[0].styles};
    }

    ${PrintSecondaryTitle} {
      text-transform: uppercase;
      font-size: 2.2rem;
      letter-spacing: 0.6px;
      ${loadedTypekitFonts[0].styles}
    }

    ${PrintLabelValue} {
      font-size: 2.4rem;
      ${loadedGoogleFonts[0].styles};
    }

    ${PrintLabelTitle} {
      text-transform: uppercase;
      font-size: 1.4rem;
      letter-spacing: 0.6px;
      ${loadedTypekitFonts[0].styles}
    }

    ${Attribution},
    ${LogoCredits},
    ${PoweredByStrava} {
      color: ${colours.white};
      opacity: 0.6;
    }

    .print-details-labels,
    ${PrintLabel}:nth-child(1n+1) {
      &:before {
        color: ${theme.secondaryColor};
        opacity: 0.18;
      }
    }
  `
}
