import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';

import HocComponent from '../HocComponent';

import * as Selectors from './selectors';

export const MakeWithEventsHoc = WrappedComponent => new HocComponent(WrappedComponent, 'withEventsHoc');

export const WithEventsHoc = () => (WrappedComponent) => {
  const WithOrders = MakeWithEventsHoc(WrappedComponent);

  const mapStateToProps = createStructuredSelector({
    events: Selectors.eventsSelector,
    eventThemeNames: Selectors.eventThemeNamesSelector,
  });

  const mapDispatchToProps = dispatch => bindActionCreators({

  }, dispatch);

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const composed = compose(
    withRouter,
    withConnect,
  )(WithOrders);

  return composed;
};

export default WithEventsHoc;
