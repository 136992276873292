/* eslint-disable react/prefer-stateless-function */
import React from 'react';

const HocComponent = (WrappedComponent, hocName = 'withHoc') =>
  class WithHoc extends React.Component {
    static displayName = `${hocName}(${
      WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'
    })`;

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default HocComponent;
