import { createSelector } from 'reselect';

import { TOASTER_DOMAIN } from './constants';

export const withDomain = state => state[TOASTER_DOMAIN];

export const toastsSelector = createSelector(
  withDomain,
  domain => domain.toasts,
);
