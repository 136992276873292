import React from 'react';
import styled from 'styled-components';

import { colours } from '../../theme';

const UnstyledContentBox = props => <div {...props} />;

const ContentBox = styled(UnstyledContentBox)`
  background-color: ${colours.grey06};
  border-radius: 5px;
  padding: 11rem 17rem 12.4rem;
  max-width: 100rem;
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { UnstyledContentBox, ContentBox };
export default ContentBox;
