import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import { zIndexes } from '../../styles';

const SortableHandle = styled(sortableHandle(props => <span className={props.className}>⋮</span>))`
  position: absolute;
  z-index: ${zIndexes.zero};
  left: -3.9rem;
  top: 0;
  width: 4rem;
  height: 100%;
  margin: auto;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

const SortableItem = styled(sortableElement(({ children, className, handle, props }) => (
  <li className={classnames('sortable-item', className)} {...props}>
    {handle && <SortableHandle />}
    {children}
  </li>
)))`
  ${props => props.handle ? css`
  position: relative;

  ${SortableHandle} {
    opacity: 0;
    transition: 0.1s ease opacity;
  }

  ${SortableHandle}:hover,
  &:hover ${SortableHandle} {
    opacity: 1;
  }

  ` : css`
    cursor: grab;
  `};
`;

const SortableList = styled(sortableContainer(({
  handle,
  children,
  className,
}) => (
  <ul className={classnames('sortable-list', className)}>
    {React.Children.map(children, (child, i) => (
      <SortableItem key={`item-${i}`} index={i} handle={handle}>
        {child}
      </SortableItem>
    ))}
  </ul>
)))`
  /* styles */
`;

class UnstyledSortable extends React.Component {
  static propTypes = {
    handle: PropTypes.bool,
    delayPress: PropTypes.bool,
    onSort: PropTypes.func,
  };

  static defaultProps = {
    handle: false,
    delayPress: false,
    onSort: () => {},
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onSort(oldIndex, newIndex);
  };

  onSortStart = () => {
    if ('vibrate' in navigator) window.navigator.vibrate(100);
  }

  onSortOver = () => {
    if ('vibrate' in navigator) window.navigator.vibrate(50);
  }

  render() {
    // NOTE:
    // The sortable helper container is appended to document.body,
    // so the styles for it live in /src/styles.js
    const { onSort, delayPress, ...props } = this.props;
    return (
      <SortableList
        useDragHandle={this.props.handle}
        onSortEnd={this.onSortEnd}
        onSortStart={this.onSortStart}
        onSortOver={this.onSortOver}
        helperClass="sortable-sorting"
        pressDelay={delayPress ? 100 : 0}
        {...props}
      />
    );
  }
}

const Sortable = styled(UnstyledSortable)``;

export { Sortable, SortableList, SortableItem, SortableHandle };
export default Sortable;
