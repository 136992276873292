import togeojson from '@mapbox/togeojson';
import _get from 'lodash.get';
import _startCase from 'lodash.startcase';

import { activityUuid } from './uuid';
import * as DateUtils from './dates';
import * as mapUtils from './maps';
import * as googleUtils from './google';
import * as fileUtils from './files';
import { calcElevationGain } from './print';

export const GEOJSON_FEATURE_LINESTRING = 'LineString';

/**
 * Accepts a file name and geoJson, returns an object containing GPX
 * properties to use for Print Activities
 * @param {string} name
 * @param {json} geoJson
 * @returns {Object} {
 *   id: string,
 *   name: string,
 *   date: string,
 *   distance: number,
 *   polyline: string,
 *   elapsedTime: number,
 *   elevationGain: number,
 *   latlngs: number[][],
 *   altitudes: number[]
 * }
 */
export class GpxModel {
  constructor(name = '', geoJson) {
    this.props = geoJson;
    this.props.name = name;
    return this.gpxActivity;
  }

  get gpxActivity() {
    return {
      id: this.id,
      name: this.name,
      date: this.date,
      latlngs: this.latlngs,
      altitudes: this.altitudes,
      distance: this.distance,
      polyline: this.polyline,
      elapsedTime: this.elapsedTime,
      elevationGain: this.elevationGain,
    };
  }

  get id() {
    return activityUuid(10);
  }

  get name() {
    const name = _get(this.lineStringFeature, 'properties.name', this.props.name.replace(/\.gpx|\.kml/gi, ''));
    return _startCase(name);
  }

  get date() {
    return _get(this.lineStringFeature, 'properties.time', undefined)
      || _get(this.lineStringFeature, 'properties.coordTimes.0', undefined);
  }

  get lineStringFeature() {
    const features = _get(this.props, 'features', []);
    return features.find((feature) => {
      const type = _get(feature, 'geometry.type', undefined);
      return type === GEOJSON_FEATURE_LINESTRING;
    });
  }

  get coordinates() {
    return _get(this.lineStringFeature, 'geometry.coordinates', []);
  }

  get latlngs() {
    return this.coordinates.map(coord => [coord[1], coord[0]]);
  }

  get distance() {
    return parseFloat(mapUtils.getLengthAlongPath(this.latlngs, 'm').toFixed(2));
  }

  get altitudes() {
    return this.coordinates.map(coord => coord[2]);
  }

  get polyline() {
    return googleUtils.googleEncodeLatLngToPolyline(this.latlngs);
  }

  get elapsedTime() {
    const datetimes = _get(this.lineStringFeature, 'properties.coordTimes', []);
    if (!datetimes.length) return null;
    const start = datetimes[0];
    const end = datetimes[datetimes.length - 2];
    return Math.abs(DateUtils.diff(start, end, 'seconds'));
  }

  get elevationGain() {
    return calcElevationGain(this.altitudes);
  }
}


/**
 * Third-party util wrapped in a Promise to turn
 * GPX/KML DOM to a geo JSON object
 * @param {object} xml
 * @returns {Promise}
 */
export const xmlToGeoJson = (xml, filename) => (filename.match(/\.kml$/gi))
  ? Promise.resolve(togeojson.kml(xml))
  : Promise.resolve(togeojson.gpx(xml));


/**
 * Filters all LineString features from a geoJson file
 * @param {object} geoJson
 * @param {object[]} geoJson.features
 * @returns {object[]} array of goeJson lineString features
 */
export const filerLineStringFeatures = (geoJson) => {
  const features = _get(geoJson, 'features', []);
  return features.filter(feature =>
    _get(feature, 'geometry.type', '__NOT_VALID_LINESTRING__') === GEOJSON_FEATURE_LINESTRING);
};


/**
 * Combines a sequence of methods to transform the contents of a GPX file
 * to more readable stats
 * @param {blob} file
 * @param {string} name
 * @returns {Promise}
 */
export const extractGpxModelsFromFile = (file, name = '') => fileUtils.parseXmlFile(file)
  .then(xmlDom => xmlToGeoJson(xmlDom, name))
  .then(geoJson => filerLineStringFeatures(geoJson)
    .map(feature => new GpxModel(name, {
      ...geoJson,
      features: [feature],
    })));
