import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';

export default {
  id: 'ggsherwoodgravelx',
  name: 'Glorious Gravel Sherwood Gravel X',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cl0tqkmat000815qr4zh3c9z5',
  cssStyles,
  palette,
};
