import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { media, zIndexes } from '../../styles';

import { FeaturedTitle } from '../../components/Title';
import SiteHeader from '../../containers/SiteHeader';
import SiteContainer from '../../components/SiteContainer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { TopoBackground } from '../../components/Background';

import videoSrc from './404-clip-640.mp4';

const UnstyledFourOhFour = props => (
  <React.Fragment>
    <ScrollToTopOnMount />
    <Helmet>
      <title>404</title>
    </Helmet>
    <div className={props.className}>
      <TopoBackground />
      <SiteHeader />
      <SiteContainer>
        <header>
          <FeaturedTitle tag="h1">
            Umm, I think we're lost. We should probably head back&hellip;
          </FeaturedTitle>
        </header>
        <div className="video-container">
          <video loop autoPlay playsInline src={videoSrc} controls />
        </div>
      </SiteContainer>
    </div>
  </React.Fragment>
);

const FourOhFour = styled(UnstyledFourOhFour)`
  flex-grow: 1;
  padding: 0 0 30rem;
  position: relative;

  ${FeaturedTitle} {
    text-align: center;
    margin: 0 auto 13rem;
    max-width: 16em;
  }

  .video-container {
    height: 0;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  ${FeaturedTitle} {
    position: relative;
    z-index: ${zIndexes.raised};
  }

  ${TopoBackground} {
    z-index: ${zIndexes.zero};
    top: 20%;
    opacity: 0.8;
  }

  /* MEDIA STYLES */
  ${media.footer`
    padding-bottom: 10rem;
  `}

  ${media.nav`
    ${FeaturedTitle} {
      margin-bottom: 6rem;
    }
  `}
`;

export default FourOhFour;
