import palette from './palette';
import cssStyles from './styles';
import printStyles from './print';
import activityStyles from './activity';
import themeNames from '../theme-names'

export default {
  id: themeNames.gbdivide2022,
  name: 'Great British Divide 2022',
  printStyles,
  activityStyles,
  mapStyles: 'mapbox://styles/papertrails/cl628vx40000c14lf8lz9blbn',
  cssStyles,
  palette,
};
