import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colours, fonts } from '../../theme';

const UnstyledBadge = props => <div {...props} />;

UnstyledBadge.propTypes = {
  children: PropTypes.node,
};

UnstyledBadge.defaultProps = {
  children: undefined,
};

const Badge = styled(UnstyledBadge)`
  border-radius: 50%;
  background: ${colours.primary};
  color: ${colours.white};
  line-height: 1;
  height: 2.1rem;
  width: 2.1rem;
  font-size: ${fonts.size05};
  font-weight: ${fonts.weightMedium};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Badge, UnstyledBadge };
export default Badge;
