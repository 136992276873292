export const SESSION_DOMAIN = 'session'

export const GET_STRAVA_AUTH_TOKEN_REQUEST = 'GET_STRAVA_AUTH_TOKEN_REQUEST'
export const SET_STRAVA_AUTH_TOKEN = 'SET_STRAVA_AUTH_TOKEN'
export const GET_STRAVA_ACCESS_TOKEN_REQUEST = 'GET_STRAVA_ACCESS_TOKEN_REQUEST'
export const SET_STRAVA_ACCESS_TOKENS = 'SET_STRAVA_ACCESS_TOKENS'
export const REFRESH_STRAVA_ACCESS_TOKEN_REQUEST =
  'REFRESH_STRAVA_ACCESS_TOKEN_REQUEST'
export const SET_STRAVA_ATHLETE_PROFILE = 'SET_STRAVA_ATHLETE_PROFILE'

export const GET_SHIPPING_AND_PRODUCTS_REQUEST =
  'GET_SHIPPING_AND_PRODUCTS_REQUEST'
export const SET_SHIPPING_AND_PRODUCTS = 'SET_SHIPPING_AND_PRODUCTS'
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const SET_PRODUCTS = 'SET_PRODUCTS'

export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST'
export const SET_COUPON_CHECK_IN_PROGRESS = 'SET_COUPON_CHECK_IN_PROGRESS'
export const SET_COUPON_ID = 'SET_COUPON_ID'

export const ACCEPT_COOKIES = 'ACCEPT_COOKIES'
export const SET_HAS_TOUCH = 'SET_HAS_TOUCH'

// This must match the sku returned form the API
export const PRODUCT_SKU_DIGITAL = 'DIGITAL'
