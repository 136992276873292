import { createSelector } from 'reselect';

import { THEMES_DOMAIN } from './constants';
import { findRenderBeforeLayerId } from '../../utils/print';

export const withDomain = state => state[THEMES_DOMAIN];

export const themesSelector = createSelector(
  withDomain,
  domain => domain.themes,
);

export const themeNameSelector = createSelector(
  withDomain,
  domain => domain.themeName,
);

export const themeSelector = createSelector(
  themesSelector,
  themeNameSelector,
  (themes, themeName) => themes[themeName],
);

export const themeMapStylesSelector = createSelector(
  themeSelector,
  theme => theme.mapStyles,
);

// First valid layer ID which contains a place label
// Used to identify the layer id where the activites could be rendered behind
export const themeFirstTextMapLayerLayerIdSelector = createSelector(
  themeMapStylesSelector,
  mapStyles => findRenderBeforeLayerId(mapStyles.layers),
);

export const themeActivityStylesSelector = createSelector(
  themeSelector,
  theme => theme.activityStyles,
);

export const themePrintStylesSelector = createSelector(
  themeSelector,
  theme => theme.printStyles,
);

export const themeColorPaletteSelector = createSelector(
  themeSelector,
  theme => theme.palette || [],
);

export const optionsSelector = createSelector(
  withDomain,
  domain => domain.options,
);

export const themeOptionsSelector = createSelector(
  optionsSelector,
  options => options.themes,
);

export const eventThemeOptionsSelector = createSelector(
  optionsSelector,
  options => options.eventThemes,
);
