import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';
import classnames from 'classnames';

import * as ICONS from './svg';

const UnstyledIcon = ({ icon, className, ...restProps }) => {
  const classNames = classnames(className, `icon-${icon}`);
  return (
    <Svg {...restProps} className={classNames} src={ICONS[icon]} />
  );
};

UnstyledIcon.propTypes = {
  icon: PropTypes.string,
};

UnstyledIcon.defaultProps = {
  icon: undefined,
};

const Icon = styled(UnstyledIcon)`
  display: block;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export { Icon, UnstyledIcon };
export default Icon;
