import FontLoader from 'webfontloader'
import { css } from 'styled-components'

import { slugify } from './strings'

const {
  REACT_APP_TYPEKIT_SITE,
  REACT_APP_TYPEKIT_THEMES,
  REACT_APP_TYPEKIT_THEME_SETS,
} = process.env
/* istanbul ignore next */
let fontsActive = []

/**
 * Callback when the font loads so we can handle simple caching and
 * prevent unnecessary requests. Returns the font fvd-id only when a
 * new font has been added
 *
 * @param {string} font
 * @param {string} fvd
 * @returns {string} font fvd-id
 */
export const onFontActive = (fontName, fvd) => {
  const fontId = getTypekitFontSlug(fontName, fvd)
  if (fontId && !fontsActive.includes(fontId)) {
    const className = `pt-${fontId}-active`
    document.documentElement.classList.add(className)
    fontsActive = fontsActive.concat(fontId)
    return fontId
  }
  return undefined
}

const config = {
  // classes: false and custom fontactive callback ensures we don't
  // thrash the <html> element when React triggeres multiple font-loads
  classes: false,
  fontactive: onFontActive,
}

/**
 * Loads fonts used generally in the site
 * @param {array} fonts
 */
export const loadTypekitSiteFonts = (fonts) =>
  loadTypekitFontsFromKit(fonts, REACT_APP_TYPEKIT_SITE)

/**
 * Loads fonts used in map themes
 * @param {array} fonts
 */
export const loadTypekitThemeFonts = (fonts) =>
  loadTypekitFontsFromKit(fonts, REACT_APP_TYPEKIT_THEMES)

/**
 * Loads fonts used in the new theme fonts sets/groups
 * @param {array} fonts
 */
export const loadTypekitThemeSetFonts = (fonts) =>
  loadTypekitFontsFromKit(fonts, REACT_APP_TYPEKIT_THEME_SETS)

/**
 * Load fonts for Print styles
 *
 * If specific font-loading events are needed then
 * consider using FontFaceObserver
 *
 */
export const loadTypekitFontsFromKit = (fonts, projectId) => {
  if (!Array.isArray(fonts) || (Array.isArray(fonts) && !fonts.length)) {
    throw new Error('Please provide an array of fonts')
  }
  // NOTE: requesting only a selection of fonts from the same kit
  // will fetch all the fonts from that kit.
  const typeKitFontNames = fonts.map(getFontName)
  FontLoader.load({
    ...config,
    typekit: {
      id: projectId,
      families: typeKitFontNames,
    },
  })
  return fonts.map(getFontDetails)
}

/**
 * Returns the Typekit font fvd-ID
 * @param {string} fontName
 * @param {string} fvd
 */
export const getTypekitFontSlug = (fontName, fvd) =>
  fontName ? [slugify(fontName), fvd].filter(Boolean).join('-') : undefined

/**
 * Can load any locally hosted font styles.
 *
 */
export const loadLocalFonts = (fonts) => {
  if (!Array.isArray(fonts) || (Array.isArray(fonts) && !fonts.length)) {
    throw new Error('Please provide an array of fonts')
  }
  const urls = fonts.map((font) => `/fonts/${font}.css`)
  FontLoader.load({
    ...config,
    custom: {
      urls,
      families: fonts,
    },
  })
  return fonts.map(getFontDetails)
}

/**
 * Load fonts from Google Fonts
 *
 */
export const loadGoogleFonts = (fonts) => {
  if (!Array.isArray(fonts) || (Array.isArray(fonts) && !fonts.length)) {
    throw new Error('Please provide an array of fonts')
  }
  FontLoader.load({
    ...config,
    google: {
      families: fonts,
    },
  })
  return fonts.map(getFontDetails)
}

/**
 * Extracts the font style and weight abbreviation using the Google font syntax
 * @param {string} font
 */
export const getFontDetails = (font) => {
  const fontAttrs = getFontAttributes(font)
  const name = getFontName(font)
  const weight = getFontWeight(fontAttrs)
  const style = getFontSytle(fontAttrs)
  const fvd = getFontSlug(name, style, weight)
  const transitionStyles = fontTransitionStyles(fvd)
  return {
    name,
    fvd,
    weight,
    style,
    transitionStyles,
    styles: css`
      font-weight: ${weight};
      font-style: ${style};
      font-family: ${name};
      ${transitionStyles};
    `,
  }
}

/**
 * Used to apply a transition to the element using a particular font variant
 * @param {string} fontFvd
 * @returns {string}
 */
export const fontTransitionStyles = (fontFvd) => css`
  opacity: 0;
  transition: 0.2s ease opacity;

  .pt-${fontFvd}-active & {
    opacity: 1;
  }
`

/**
 *
 * Extracts the font attributes such as style and weight
 * @param {string} font
 * @example
 * getFontAttributes('Font Name') // 400 (default)
 * getFontAttributes('Font Name:500i') // '500i'
 */
export const getFontAttributes = (font) =>
  font.indexOf(':') !== -1 ? font.split(':').pop() : '400'

/**
 * Extracts the font name from a font attribute string
 * @param {string} font
 * @example
 * getFontName('Font Name:500i') // 'Font Name'
 */
export const getFontName = (font) => font.split(':')[0]

/**
 * Returns the font weight from font attributes
 * @param {string} fontAttrs
 * @example
 * getFontWeight('') // 400
 * getFontWeight('500') // 500i
 */
export const getFontWeight = (fontAttrs) =>
  `${fontAttrs.match(/\d{1,3}/) || '400'}`

/**
 * Returns the font style from font attributes, such as '400i'
 * @param {string} fontAttrs
 * @example
 * getFontSytle('') // normal
 * getFontSytle('500i') // italic
 */
export const getFontSytle = (fontAttrs = '') => {
  const styleId = `${fontAttrs.match(/([in])/gi)}`
  if (styleId === 'i') return 'italic'
  return 'normal'
}

/**
 * Returns the Google font syntax to a font fvd/ID
 * @param {string} font
 * @example Font Name:700i = font-name-i7 / Font Name = <font-name>-n4
 */
export const getFontSlug = (name, style = 'n', weight = '400') =>
  name ? slugify(`${name}-${style.charAt(0)}${weight.charAt(0)}`) : undefined
