import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colours, fonts, dimensions, transitions } from '../../theme'
import { StravaLogo } from '../Logo'
import { SmallText } from '../Text'

const DISABLED_STYLES = css`
  color: ${colours.grey05};
  border-color: ${colours.grey02};
  background-color: ${colours.grey02};
  cursor: initial;
  box-shadow: none;

  &:active,
  &:hover {
    box-shadow: none;
    color: ${colours.grey05};
    border-color: ${colours.grey02};
    background-color: ${colours.grey02};
  }
`

const UnstyledButton = ({ active, busy, ...props }) => <button {...props} />

UnstyledButton.propTypes = {
  active: PropTypes.bool,
  busy: PropTypes.bool,
}

UnstyledButton.defaultProps = {
  active: false,
  busy: false,
}

/**
 * Default Button
 * --------------------------------
 */
const Button = styled(UnstyledButton)`
  border-radius: ${dimensions.borderRadius};
  color: ${colours.primary};
  background: ${colours.white};
  transition: ${transitions.buttonDuration} ${transitions.buttonEasing};
  border: 2px solid ${colours.grey04};
  cursor: pointer;

  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  width: 100%;
  padding: 1em 1em;
  font-size: ${fonts.size02};
  font-weight: ${fonts.weightMedium};

  &:active,
  &:hover {
    color: ${colours.white};
    background: ${colours.primary};
    border-color: ${colours.primary};
  }

  ${(props) => props.disabled && DISABLED_STYLES}

  ${(props) =>
    props.active &&
    css`
      border-color: ${colours.primary};

      &:hover {
        cursor: initial;
        color: ${colours.primary};
        background-color: transparent;
      }
    `}
`

/**
 * Primary Button
 * --------------------------------
 */
export const primaryButtonStyles = css`
  font-size: ${fonts.size03};
  border-color: ${colours.primary};
  color: ${colours.white};
  background-color: ${colours.primary};
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.21);
  position: relative;
  border-radius: ${dimensions.borderRadius};

  &:active,
  &:hover {
    border-color: ${colours.primaryDark};
    background-color: ${colours.primaryDark};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
  }

  ${(props) => props.disabled && DISABLED_STYLES}
`
const PrimaryButton = styled(Button)`
  ${primaryButtonStyles}
`

/**
 * WithProgress Wrapper
 * Allows a busy state to be applied to any button provided
 *
 */
export const WithProgress = (ButtonComponent) => styled(ButtonComponent)`
  position: relative;
  ${(p) =>
    p.busy &&
    css`
      color: transparent;
      &:focus,
      &:hover {
        color: transparent;
      }
    `};

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid white;
    border-radius: 50%;
    border-right-color: transparent !important;
    opacity: ${(p) => (p.busy ? 1 : 0)};
    animation: ${(p) =>
      p.busy ? 'spin 0.6s 0s linear forwards infinite' : 'none'};
  }
`

/**
 * Layout Button
 * --------------------------------
 */
const LayoutButton = styled(Button)`
  height: initial;
  padding: 2rem 2.6rem 1.8rem;
  min-height: 6.4rem;
  flex-direction: column;

  .icon-orientationPortrait,
  .icon-orientationLandscape {
  }

  .icon-orientationPortrait {
    width: 1.8rem;
    height: 2.5rem;
  }
  .icon-orientationLandscape {
    width: 2.6rem;
    height: 2.3rem;
  }

  > div {
    margin-top: 1rem;
  }
`

/**
 * Size choice button
 * --------------------------------
 */
const SizeButton = styled(LayoutButton)`
  padding: 2.6rem 1rem 2rem;

  ${SmallText} {
    white-space: nowrap;
    line-height: ${fonts.lineHeight04};
    margin-top: 1.6rem;
    font-weight: ${fonts.weightNormal};
    color: currentColor;

    &:last-child {
      color: ${colours.secondary};
      margin-top: 0;
    }

    &.price {
      font-size: ${fonts.size02};
    }
  }

  &:hover {
    ${SmallText} {
      color: currentColor;
    }
  }
  ${(props) =>
    props.active &&
    css`
      &:hover {
        ${SmallText}:last-child {
          color: ${colours.secondary};
        }
      }
    `}
`

export const VariantSizeButton = styled(SizeButton)`
  min-height: 10rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding-left: 2.6rem;
  padding-right: 2.6rem;

  ${SmallText}.description {
    margin-top: 0.4em;
    color: ${colours.grey03};
    white-space: initial;
  }

  ${SmallText}.price {
    align-self: flex-start;
  }

  &:hover {
    ${SmallText}.description {
      color: currentColor;
    }
  }
`

/**
 * Upload / Starva Button
 * --------------------------------
 */
const UploadButton = styled(Button)`
  ${StravaLogo} {
    width: 7rem;
    margin-left: 0.4em;
    position: relative;
    top: 0.05em;
  }

  &:active,
  &:hover {
    ${StravaLogo} path {
      fill: ${colours.white};
    }
  }
`

/**
 * Plain Button
 * --------------------------------
 */
const PlainButton = styled(Button)`
  border-radius: 0;
  padding: 0;
  color: ${colours.primary};
  border: none;
  width: initial;
  height: initial;

  &:active,
  &:hover {
    background-color: transparent;
    color: ${colours.secondary};
  }
`

/**
 * Fab Button
 * --------------------------------
 */
const FabButton = styled(Button)`
  padding: 0;
  height: 4rem;
  width: 4rem;
  color: ${colours.primary};
  border-radius: 50%;
`

export {
  Button,
  PrimaryButton,
  UploadButton,
  LayoutButton,
  SizeButton,
  PlainButton,
  FabButton,
}
export default Button
