import React from 'react';
import styled from 'styled-components';

import { fonts, colours } from '../../theme';

export const UnstyledPrice = props => <div {...props} />;

UnstyledPrice.propTypes = {};
UnstyledPrice.defaultProps = {};

export const Price = styled(UnstyledPrice)`
  font-size: ${fonts.size08};
  font-family: ${fonts.family01};
  letter-spacing: 0.03em;
  line-height: ${fonts.lineHeight03};
`;

export const OriginalPrice = styled(Price)`
  color: ${colours.grey09};
  text-decoration: line-through;
`;


export default Price;
