import { css } from 'styled-components'

import { fontGroup } from '../../styles'
import MapboxMap from '../../../../components/MapboxMap'
import { LogoCredits, PoweredByStrava } from '../../../../components/Logo'
import { PrintLabel, PrintLabelValue } from '../../../../components/PrintLabel'
import { PrintTitle } from '../../../../components/PrintTitle'
import { Attribution } from '../../../../components/Attribution'

export default () => css`
  ${fontGroup('montserratAlternateGothic')}\


  ${PrintTitle} {
    font-size: 4rem;
    margin-bottom: 0.2em;
    font-weight: 600;
  }

  ${PrintLabelValue} {
    font-size: 2.3rem;
    text-transform: initial;
    font-weight: 600;
  }

  .mapboxgl-ctrl-logo {
    opacity: 0.5;
  }

  ${LogoCredits},
  ${PoweredByStrava} {
    color: #427585;
    opacity: 0.9;
  }

  ${Attribution} {
    opacity: 0.3;
    color: #ffffff;
  }

  .print-details-labels,
  ${PrintLabel}:nth-child(1n+1) {
    &:before {
      color: #427585;
      opacity: 0.6;
    }
  }

  ${MapboxMap}:after {
    pointer-events: none;
    content: '';
    width: 33rem;
    height: 81rem;
    position: absolute;
    bottom: 5rem;
    left: 8rem;
    background: transparent no-repeat center center;
    background-size: cover;
  }

  .mapboxgl-map {
    border: 1px solid #427585;
  }
`
