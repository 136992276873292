import Immutable from 'seamless-immutable'

export const initialState = Immutable({
  authToken: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  accessTokenExpires: undefined,
  athlete: undefined,
  lastSave: undefined,
  commitHash: undefined,
  shipping: undefined,
  products: [],
  showCookiePolicy: true,
  hasTouch: false,
  couponCheckInProgress: false,
  couponId: undefined,
})
