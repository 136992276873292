import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import { ChromePicker } from 'react-color';

import { colours, transitions, fonts } from '../../theme';
import { zIndexes } from '../../styles';

import { Text } from '../Text';
import { PlainButton, FabButton } from '../Button';
import { Icon } from '../Icon';

const ColourButton = styled(PlainButton)``;
const ClosePickerButton = styled(FabButton)`
  border-color: ${colours.primary};
  width: 2.8rem;
  height: 2.8rem;

  .isvg {
    width: 1.2rem;
    height: 1.2rem;

    g {
      stroke-width: 3;
    }
  }
`;

class UnstyledColourPicker extends React.PureComponent {
  constructor(props) {
    super();
    this.state.open = props.open || false;
  }

  state = {
    open: false,
  };

  componentDidUpdate() {
    if (this.state.open) {
      window.addEventListener('keyup', this.onWindowKeyup);
    } else {
      window.removeEventListener('keyup', this.onWindowKeyup);
    }
  }

  onWindowKeyup = (evt) => {
    if (evt.keyCode === 27) this.setState({ open: false });
  }

  onColourChange = (colour) => {
    this.props.onChange(colour.hex);
  }

  onPaletteColorClick = colorHex => () => {
    this.props.onChange(colorHex);
  }

  onClick = () => {
    this.toggle();
  }

  toggle = (state = !this.state.open) => {
    this.setState({
      open: state,
    });
  }

  close = () => {
    this.setState({
      open: false,
    });
  }

  open = () => {
    this.setState({
      open: true,
    });
  }

  renderPalette() {
    if (!this.props.palette) return null;
    return (
      <ul className="picker-palette">
        {this.props.palette.map(color => (
          // eslint-disable-next-line
          <li
            key={`palette-${color}`}
            style={{ color }}
            className={`picker-palette-color ${(this.props.colour === color) && 'active'}`}
            onClick={this.onPaletteColorClick(color)}
          >
            {color}
          </li>
        ))}
      </ul>
    );
  }

  renderPicker() {
    return this.state.open
      ? (
        <React.Fragment>
          <div className="picker-background" onClick={this.close} />
          <div className="picker-wrapper">
            <ClosePickerButton onClick={this.close}><Icon icon="close" /></ClosePickerButton>
            <ChromePicker
              disableAlpha
              color={this.props.colour}
              onChangeComplete={this.onColourChange}
            />
            {this.renderPalette()}
          </div>
        </React.Fragment>
      ) : null;
  }

  render() {
    return (
      <div className={classnames(this.props.className, { 'is-open': this.state.open })}>
        <div className="picker-inner" onClick={this.onClick}>
          <ColourButton>
            <div className="colour" />
          </ColourButton>
          {this.props.label && <Text className="colour-label">{this.props.label}</Text>}
        </div>
        {this.renderPicker()}
      </div>
    );
  }
}

UnstyledColourPicker.propTypes = {
  label: PropTypes.string,
  colour: PropTypes.string,
  open: PropTypes.bool,
  palette: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
UnstyledColourPicker.defaultProps = {
  label: undefined,
  colour: 'transparent',
  open: false,
  palette: [],
  onChange: () => {},
};

const borderColorConditions = [undefined, 'transparent', 'white', '#fff', '#ffffff'];

const ColourPicker = styled(UnstyledColourPicker)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  ${ColourButton} {
    margin-right: 3rem;
    background-color: ${colours.grey04};
    border-radius: 50%;
    overflow: hidden;
  }


  .picker-inner {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.is-open,
  .picker-inner:hover,
  ${ColourButton}:hover {
    .colour {
      transform: scale(0.6);
    }
  }

  .colour {
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    background-color: ${props => props.colour};
    border: 1px solid transparent;
    border-color: ${props => borderColorConditions.includes(props.colour)
    ? colours.grey09
    : props.colour};

    transform: scale(1);
    transition: ${transitions.colorButtonDuration} ${transitions.colorButtonEasing} transform;
  }

  ${Text} {
    margin-bottom: 0;
    font-size: ${fonts.size02};
  }

  .picker-background {
    position: fixed;
    z-index: ${zIndexes.raised};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
  }

  .picker-wrapper {
    padding: 1.5rem;
    position: absolute;
    top: 0;
    left: 7.2rem;
    z-index: ${zIndexes.colorPickerWrapper};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    background-color: ${colours.white};
    width: 26rem;
  }

  ${ClosePickerButton} {
    position: absolute;
    z-index: ${zIndexes.raised};
    top: -1.5rem;
    right: -1.5rem;
  }

  .chrome-picker {
    box-shadow: none !important;
    font-family: inherit !important;
    width: 100% !important;
  }

  .picker-palette {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    place-items: center;
  }

  .picker-palette-color {
    width: 3.4rem;
    height: 3.4rem;
    font-size: 0;
    border-radius: 50%;
    background-color: currentColor;
    border: 1px solid ${colours.grey02};
    cursor: pointer;
    transition: ${transitions.colorButtonDuration} ${transitions.colorButtonEasing} border;

    &.active,
    &:hover {
      border-width: 6px;
    }
  }
`;

export { ColourPicker, UnstyledColourPicker };
export default ColourPicker;
