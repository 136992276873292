import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colours, dimensions, fonts } from '../../theme';

const UnstyledFigure = props => (
  <figure className={props.className} style={props.style}>
    <img src={props.image} alt={props.alt} />
    {props.caption && <figcaption className="caption">{props.caption}</figcaption>}
  </figure>
);

UnstyledFigure.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
  caption: PropTypes.string,
};
UnstyledFigure.defaultProps = {
  alt: undefined,
  caption: undefined,
};

const Figure = styled(UnstyledFigure)`
  margin: 0;

  img {
    display: block;
    border-radius: ${dimensions.borderRadius};
    overflow: hidden;
  }

  figcaption {
    color: ${colours.grey03};
    font-size: ${fonts.size01};
    line-height: ${fonts.lineHeight03};
    padding: 2rem;

    &:empty {
      display: none;
    }
  }
`;

export { Figure, UnstyledFigure };
export default Figure;
